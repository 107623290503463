import {Popover, PopoverSideE} from '@components/ui/popover/Popover.tsx'
import {
    DocumentPaidGradientIcon,
    MicromorgiIcon,
    PayForMediaIcon,
    PayForTextIcon,
    UploadAudioGoldIcon
} from '@assets/icons/icons.tsx'
import {useTranslation} from 'react-i18next'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {StyledContainerOptions, StyledItem, StyledPaidText} from '@/features/chat/components/AttachmentsPanel/style.ts'
import {useRootStore} from '@/store'
import {selectPaidMessageModal} from '@/store/selectors.ts'

export const enum TypePaidMessageE {
    TEXT = 'text',
    MEDIA = 'media',
    DOCUMENT = 'document',
    AUDIO = 'audio'
}
export const SendPaidMessage = ({isDisabled}: {isDisabled: boolean}) => {
    const {t} = useTranslation()
    const {openModal} = useRootStore(selectPaidMessageModal)

    return isDisabled ? (
        <StyledItem isDisabled={isDisabled}>
            <Flexbox gap={1} align={'center'} justify={'center'} direction={'column'}>
                <MicromorgiIcon />
                <span>{t('chat:attachment_paid_media')}</span>
            </Flexbox>
        </StyledItem>
    ) : (
        <Popover
            isModal
            withArrow
            side={PopoverSideE.top}
            trigger={
                <StyledItem isDisabled={isDisabled}>
                    <Flexbox gap={1} align={'center'} justify={'center'} direction={'column'}>
                        <MicromorgiIcon />
                        <span>{t('chat:attachment_paid_media')}</span>
                    </Flexbox>
                </StyledItem>
            }
            triggerProps={{
                asChild: true
            }}
        >
            <Flexbox direction={'column'}>
                <StyledContainerOptions
                    gap={1}
                    align={'center'}
                    justify={'flex-start'}
                    onClick={() =>
                        !isDisabled &&
                        openModal({
                            type: TypePaidMessageE.TEXT
                        })
                    }
                    withBorder
                >
                    <PayForTextIcon />
                    <StyledPaidText>{t('chat:attachment_paid_media')}</StyledPaidText>
                </StyledContainerOptions>

                <StyledContainerOptions
                    gap={1}
                    align={'center'}
                    justify={'flex-start'}
                    onClick={() =>
                        !isDisabled &&
                        openModal({
                            type: TypePaidMessageE.MEDIA
                        })
                    }
                    withBorder
                >
                    <PayForMediaIcon />
                    <StyledPaidText>{t('chat:teaser_tooltip_paid_media')}</StyledPaidText>
                </StyledContainerOptions>

                <StyledContainerOptions
                    gap={1}
                    align={'center'}
                    justify={'flex-start'}
                    onClick={() =>
                        !isDisabled &&
                        openModal({
                            type: TypePaidMessageE.AUDIO
                        })
                    }
                    withBorder
                >
                    <UploadAudioGoldIcon />
                    <StyledPaidText>{t('chat:audio')}</StyledPaidText>
                </StyledContainerOptions>

                <StyledContainerOptions
                    gap={1}
                    align={'center'}
                    justify={'flex-start'}
                    onClick={() =>
                        !isDisabled &&
                        openModal({
                            type: TypePaidMessageE.DOCUMENT
                        })
                    }
                >
                    <DocumentPaidGradientIcon />
                    <StyledPaidText>{t('chat:paid_document')}</StyledPaidText>
                </StyledContainerOptions>
            </Flexbox>
        </Popover>
    )
}

import {SendFirstMessageToGuestModal} from '@/features/approach-first/send-first-message-to-guest-modal/SendFirstMessageToGuestModal.tsx'
import {PauseConnectionModal} from '@/features/chat/pause-connection-modal/PauseConnectionModal.tsx'
import StarredMessageModal from '@/features/chat/starred-message-modal/StarredMessageModal'
import GifsModal from '@/features/gifs/components/gifs-modal/GifsModal'
import SendGifModal from '@/features/gifs/components/send-gif-modal/SendGifModal'
import {BuyMorgisModal} from '@/features/gift/buy-morgis-modal/BuyMorgisModal'
import {ConfirmSendGiftModal} from '@/features/gift/confirm-send-gift-modal/ConfirmSendGiftModal'
import {CustomSendGiftModal} from '@/features/gift/custom-send-gift-modal/CustomSendGiftModal'
import {OneTimeGifsModal} from '@/features/gift/one-time-gifs-modal/OneTimeGifsModal'
import {ReachedLimitModal} from '@/features/gift/reachedLimitModal/ReachedLimitModal'
import {GoalDeletionSuccessModal} from '@/features/goal/components/goal-deletion-success-modal/GoalDeletionSuccessModal.tsx'
import {GoalPageModal} from '@/features/goal/components/goal-page-modal/GoalPageModal'
import {GoalTeaserModal} from '@/features/goal/components/goal-teaser-modal/GoalTeaserModal'
import {WalletModal} from '@/features/goal/wallet-modal/WalletModal'
import ChoosePaymentMethodModal from '@/features/guest-payments/components/choose-payment-method-modal/ChoosePaymentMethodModal'
import {UpdateCreditCardModal} from '@/features/guest/update-credit-card-modal/UpdateCreditCardModal'
import {SendMegaGiftModal} from '@/features/mega-gift/send-mega-gift-modal/SendMegaGiftModal'
import {SuccessMegaGiftOverlay} from '@/features/mega-gift/success-mega-gift-overlay/SuccessMegaGiftOverlay'
import {ConnectorNamesE} from '@/features/moengage/services/moengage.model'
import {ConnectNowModal} from '@/features/profile/components/connect-now-modal/ConnectNowModal'
import {HostProfileModal} from '@/features/profile/components/host-profile-modal/HostProfileModal.tsx'
import {CancelRecurringGiftModal} from '@/features/recurring-gift/cancel-recurring-gift-modal/CancelRecurringGiftModal'
import {GiftModal} from '@/features/recurring-gift/gift-modal/GiftModal'
import {TopFanCompleteModal} from '@/features/top-fan/top-fan-complete-modal/TopFanCompleteModal'
import {TopFanPendingModal} from '@/features/top-fan/top-fan-pending-modal/TopFanPendingModal'
import {SelectGlobalLanguageModal} from '@/features/translation/select-global-language-modal/SelectGlobalLanguageModal'
import {useMoengageCombinationUI} from '@/integrations/moengage/MoengageProvider'
import {useRootStore} from '@/store'
import {selectModals} from '@/store/selectors'
import {useLocationChange} from '@hooks/useLocationChange.ts'
import {FC} from 'react'
import {Outlet} from 'react-router-dom'
import {SendPaidMessageModal} from '@/features/chat/components/AttachmentsPanel/components/SendPaidMessageModal.tsx'
import {MeetMoreHostModal} from '@/features/meetMoreHost/MeetMoreHostModal'
import {OpenSurprisesModal} from '@/features/my-surprises/components/open-surprises-modal/OpenSurprisesModal'
import SendBffModal from '@/features/morgis-taxi/components/send-bff-modal/SendBffModal'
import { AnonRetryModal } from '@/features/anonRetryModal/AnonRetryModal'

export const PrivateLayout: FC = () => {
    const {
        buyMorgisModal,
        cancelRecurringGiftModal,
        choosePaymentsMethodModal,
        confirmSendGiftModal,
        customSendGiftModal,
        openSurprisesModal,
        goalModal,
        paidGifsModal,
        recurringGiftModal,
        selectGlobalLanguageModal,
        sendMegaGiftModal,
        successMegaGiftOverlay,
        topFanCompleteModal,
        topFanPendingModal,
        updateCreditCardModal,
        walletModal,
        connectNowModal,
        approachFirstModal,
        goalDeletionSuccessModal,
        pauseConnectionModal,
        starredMessageModal,
        gifModal,
        sendGifModal,
        sendBffModal,
        reachedLimitModal,
        paidMessageModal,
        goalTeaserModal,
        hostProfileModal,
        meetMoreHostModal,
        anonRetryModal
    } = useRootStore(selectModals)

    useLocationChange(() => {
        goalModal.closeModal()
        hostProfileModal.closeModal()
    })

    // moengage
    const {moengageCombinationUI, combinationName} = useMoengageCombinationUI()

    const hasMoengagePopup =
        !!combinationName && combinationName.includes(ConnectorNamesE.popup) && !!moengageCombinationUI

    return (
        <>
            {recurringGiftModal.isOpen && <GiftModal />}
            {confirmSendGiftModal.isOpen && <ConfirmSendGiftModal />}
            {customSendGiftModal.isOpen && <CustomSendGiftModal />}
            {openSurprisesModal.isOpen && <OpenSurprisesModal />}
            {paidGifsModal.isOpen && <OneTimeGifsModal />}
            {cancelRecurringGiftModal.isOpen && <CancelRecurringGiftModal />}
            {updateCreditCardModal.isOpen && <UpdateCreditCardModal />}
            {buyMorgisModal.isOpen && <BuyMorgisModal />}
            {selectGlobalLanguageModal.isOpen && <SelectGlobalLanguageModal />}
            {sendMegaGiftModal.isOpen && <SendMegaGiftModal />}
            {successMegaGiftOverlay.isOpen && <SuccessMegaGiftOverlay />}
            {topFanCompleteModal.isOpen && <TopFanCompleteModal />}
            {topFanPendingModal.isOpen && <TopFanPendingModal />}
            {goalModal.isOpen && <GoalPageModal />}
            {walletModal.isOpen && <WalletModal />}
            {choosePaymentsMethodModal.isOpen && <ChoosePaymentMethodModal />}
            {connectNowModal.isOpen && <ConnectNowModal />}
            {goalDeletionSuccessModal.isOpen && <GoalDeletionSuccessModal />}
            {pauseConnectionModal.isOpen && <PauseConnectionModal />}
            {approachFirstModal.isOpen && <SendFirstMessageToGuestModal />}
            {starredMessageModal.isOpen && <StarredMessageModal />}
            {gifModal.isOpen && <GifsModal />}
            {sendGifModal.isOpen && <SendGifModal />}
            {sendBffModal.isOpen && <SendBffModal />}
            {goalTeaserModal.isOpen && <GoalTeaserModal />}
            {reachedLimitModal.isOpen && <ReachedLimitModal />}
            {hostProfileModal.isOpen && <HostProfileModal />}
            {hasMoengagePopup && moengageCombinationUI}
            {paidMessageModal.isOpen && <SendPaidMessageModal />}
            {meetMoreHostModal.isOpen && <MeetMoreHostModal />}
            {anonRetryModal.isOpen && <AnonRetryModal/>}
            <Outlet />
        </>
    )
}

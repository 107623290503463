import {MegaphoneIcon, MicromorgiIcon, SurprisesIcon} from '@/assets/icons/icons'
import {DonationType} from '@/features/recurring-gift/types'
import {replaceThousands} from '@/utilities/helpers'
import {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {
    StyledMicromorgiContainer,
    StyledPlanOfSubscription,
    StyledPlanOfSubscriptionContent,
    StyledPlanOfSubscriptionPartOfContent
} from './style'

type PlanOfSubscriptionProps = {
    plan: DonationType
    isSelected: boolean
    onSelectPlanHandler: () => void
    currencySymbol: string
    disabled?: boolean
    renderMegaphoneText?: () => React.ReactNode
}

export const PlanOfSubscription: FC<PlanOfSubscriptionProps> = ({
    plan,
    isSelected,
    currencySymbol,
    onSelectPlanHandler,
    disabled = false,
    renderMegaphoneText = () => <></>
}) => {
    const {t} = useTranslation()

    return (
        <StyledPlanOfSubscription
            disabled={disabled}
            onClick={!disabled ? onSelectPlanHandler : undefined}
            isSelected={isSelected}
        >
            <StyledPlanOfSubscriptionContent>
                <StyledPlanOfSubscriptionPartOfContent>
                    <StyledMicromorgiContainer>
                        <MicromorgiIcon height={31} width={32} />
                        <h1>{plan.amount}</h1>
                    </StyledMicromorgiContainer>
                    <span>
                        {t('configure_membership_plans_modal:plan_price_details', {
                            CURRENCY: currencySymbol,
                            AMOUNT: replaceThousands(plan.dollar_amount),
                            MORGI: replaceThousands(plan.amount)
                        })}
                    </span>
                </StyledPlanOfSubscriptionPartOfContent>
                <StyledPlanOfSubscriptionPartOfContent>
                    <div>
                        <SurprisesIcon height={20} width={21} />
                        <h2>{plan.surprises_count}</h2>
                        <p>{t(`configure_membership_plans_modal:surprises`)}</p>
                    </div>
                    <div>
                        <MegaphoneIcon height={21} width={20} stroke={'#433C92'} />
                        <p>{renderMegaphoneText()}</p>
                    </div>
                </StyledPlanOfSubscriptionPartOfContent>
            </StyledPlanOfSubscriptionContent>
        </StyledPlanOfSubscription>
    )
}

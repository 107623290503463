import styled, {css} from 'styled-components'

export const StyledGiftListWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    height: 100%;
    text-align: center;
`
export const StyledGift = styled.div`
    ${({theme: {palette, spacing}}) => css`
        width: 100%;
        min-height: 100%;
        display: grid;
        align-content: center;
        padding: ${spacing * 0.75}px 0;
        &:hover {
            background-color: ${palette.primary[300]};
            cursor: pointer;
        }
        &[aria-disabled='true'] {
            opacity: 0.5;
            cursor: not-allowed;
        }
    `}
`

export const StyledGiftContent = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        padding: 0 ${spacing * 1}px;
        border-right: 1px solid rgb(221, 221, 221);
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 4px;
        position: relative;
        height: 100%;
        cursor: pointer;
        ${mediaQueries.m} {
            padding: 0 ${spacing * 1.5}px;
        }
    `}
`
export const StyledAmount = styled.span`
    ${({theme: {typography}}) => css`
        ${typography.textSm};
        font-weight: 600;
        display: flex;
        align-items: center;
    `}
`

export const StyledWallet = styled.span`
    ${({theme: {typography, palette}}) => css`
        ${typography.textSm}
        color: ${palette.primary[200]};
        font-weight: 700;
        letter-spacing: -0.05em;
    `}
`
export const StyledPresentImage = styled.img`
    width: 20px;
    height: 20px;
`
export const StyledMonthlySupportContent = styled.span`
    ${({theme: {palette, typography}}) => css`
        ${typography.textXxs}
        color: ${palette.primary[900]};
        text-align: center;
        font-weight: 600;
        line-height: 110%; /* 11px */
        letter-spacing: -0.5px;
    `}
`

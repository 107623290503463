import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from './keys'
import {CHAT_FAV_TOPICS_API} from '../services/chatFavTopics.http'
import {useTranslation} from 'react-i18next'

export const useGetFavoriteActivities = (is_popular = true, enabled: boolean | undefined = true) => {
    const {
        i18n: {language}
    } = useTranslation()
    return useQuery({
        queryKey: [QUERY_KEYS.favorite_activities, {is_popular, language}],
        queryFn: () => CHAT_FAV_TOPICS_API.getFavActivities(is_popular),
        enabled
    })
}

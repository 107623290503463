import {palette} from '@/theme/palette'
import {GoalStatusesE} from '../types'

export const pastGoalStatues = [
    GoalStatusesE.SUCCESSFUL,
    GoalStatusesE.CANCELED,
    GoalStatusesE.AWAITING_PROOF,
    GoalStatusesE.PROOF_STATUS_DECLINED,
    GoalStatusesE.PROOF_PENDING_APPROVAL,
    GoalStatusesE.REVIEW
]

export const MIN_SUCCESS_PERCENTAGE = 75

export const SORT_OPTIONS = [
    {
        id: 1,
        name: 'sort_goals_modal:option_1',
        param: {}
    },
    {
        id: 5,
        name: 'sort_goals_modal:option_5',
        param: {connected_hosts: true}
    },
    {
        id: 2,
        name: 'sort_goals_modal:option_2',
        param: {time_range: 24}
    },
    {
        id: 3,
        name: 'sort_goals_modal:option_3',
        param: {order_by: 'target_amount', order_direction: 'asc'}
    },
    {
        id: 4,
        name: 'sort_goals_modal:option_4',
        param: {order_by: 'target_amount', order_direction: 'desc'}
    }
]

export const ACTIVE_PAST_SORT_OPTIONS = [
    {
        id: 1,
        name: 'active_goals_modal:option_1',
        value: 1
    },
    {
        id: 2,
        name: 'active_goals_modal:option_2',
        value: 0
    }
]

export const footerData = {
    not_canceled_not_supported: {
        title: '',
        text: 'goal_page:micromorgies_bar:title_micromorgies_first',
        withIcon: false,
        background: `${palette.light.primary['200']}`
    },
    not_canceled_supported: {
        title: 'goal_page:micromorgies_bar:subtitle_first',
        text: 'goal_page:micromorgies_bar:subtitle_third',
        withIcon: true,
        background: `${palette.light.primary['200']}`
    },
    not_active_supported: {
        title: 'goal_page:micromorgies_bar:subtitle_second',
        text: 'goal_page:micromorgies_bar:subtitle_third',
        withIcon: true,
        background: `${palette.light.primary['900']}`
    },
    canceled_supported: {
        title: 'goal_page:micromorgies_bar:title_micromorgies_second',
        text: 'goal_page:micromorgies_bar:title_micromorgies_third',
        withIcon: false,
        background: `${palette.light.primary['900']}`
    }
}

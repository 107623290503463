import {MenuIcon} from '@/assets/icons/icons'
import morgisLogo from '@/assets/images/morgisLogo3x.png'
import morgisLogoLight from '@/assets/images/morgisLogoLight3x.png'
import {Container} from '@/components/ui/container/Container'
import {StatusBadge} from '@/components/ui/statusBadge/StatusBadge'
import {useMe} from '@/features/authentication/queries/useMe.ts'
import {useRootStore} from '@/store'
import {selectHeaderModeStore} from '@/store/selectors'
import {
    ROUTE_HOME,
    ROUTE_LOGIN_GUEST,
    ROUTE_LOGIN_HOST,
    ROUTE_SIGNUP_GUEST,
    ROUTE_SIGNUP_GUEST_SOCIAL,
    ROUTE_SIGNUP_HOST,
    ROUTE_SIGNUP_HOST_SOCIAL,
    ROUTE_WELCOME_BACK_PAGE
} from '@/utilities/constants/routeNames'
import {BLOCKED_USER_STATUSES, USER_ROLE_GUEST} from '@/utilities/constants/user'
import {
    HeaderGrid,
    HeaderWrapper,
    StyledLogo,
    StyledNavBarBox,
    StyledNavbarToggler
} from '@components/commons/header/style.ts'
import {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {Nav} from '../nav/Nav'
import {HeaderBanner} from './header-banner/HeaderBanner'
import {HeaderLogo} from './header-logo/HeaderLogo'
import {RightCtaHeader} from './right-header-cta/RightCtaHeader'
import {GuestType} from '@/features/authentication/types.ts'
import {useUltimateStatus} from '@/features/ultimate-status-modal/components/ultimateStatusWrapper/UltimateStatusWrapper'
import {RouteVisibilityFragment} from '@/components/ui/routeVisibilityFragment/RouteVisibilityFragment'
import useFeedStore from '@/features/feed/store'
interface HeaderProps {
    isLight?: boolean
}

export const Header = ({isLight = true}: HeaderProps) => {
    const navigate = useNavigate()
    const {data: user} = useMe()
    const {isHeaderLight} = useRootStore(selectHeaderModeStore)
    const [isNavBarOpen, setIsNavBarOpen] = useState(false)
    const isBlockedUser = !!user && BLOCKED_USER_STATUSES.includes(user?.status)
    const isLightMode = isLight && isHeaderLight
    const setIsActiveSearchBoxOnFeed = useFeedStore(state => state.setShowSearchBox)
    useUltimateStatus()
    const withoutLogoPages = [
        ROUTE_LOGIN_GUEST,
        ROUTE_SIGNUP_GUEST_SOCIAL,
        ROUTE_SIGNUP_GUEST,
        ROUTE_SIGNUP_HOST,
        ROUTE_LOGIN_HOST,
        ROUTE_SIGNUP_HOST_SOCIAL
    ]
    const isShowLogo = !withoutLogoPages.includes(location.pathname)
    return (
        <>
            {user?.type === USER_ROLE_GUEST && <HeaderBanner user={user as GuestType} />}

            <HeaderWrapper onlyNavBar={!isShowLogo} isLight={isLightMode}>
                <Container fullWidth>
                    <HeaderGrid>
                        {isBlockedUser ? (
                            <StyledLogo
                                src={isLightMode ? morgisLogo : morgisLogoLight}
                                onClick={() => navigate(ROUTE_HOME)}
                            />
                        ) : (
                            <>
                                <StyledNavBarBox>
                                    <StyledNavbarToggler
                                        isLight={isLightMode}
                                        onClick={() => {
                                            setIsNavBarOpen(!isNavBarOpen)
                                            setIsActiveSearchBoxOnFeed(false)
                                        }}
                                    >
                                        <MenuIcon />
                                    </StyledNavbarToggler>

                                    {user?.type === USER_ROLE_GUEST && (
                                        <RouteVisibilityFragment forbiddenRoutes={[ROUTE_WELCOME_BACK_PAGE]}>
                                            <StatusBadge isLight={isLightMode} user={user as GuestType} />
                                        </RouteVisibilityFragment>
                                    )}
                                </StyledNavBarBox>

                                {isShowLogo ? <HeaderLogo isLight={isLightMode} user={user as GuestType} /> : <div />}

                                <RightCtaHeader isLight={isLightMode} />
                            </>
                        )}
                    </HeaderGrid>
                </Container>
            </HeaderWrapper>
            <Nav isOpen={isNavBarOpen} onClose={() => setIsNavBarOpen(!isNavBarOpen)} />
        </>
    )
}

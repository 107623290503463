export const MUTATION_KEYS = {
    store_filters: 'store-filters',
    clear_filters: 'clear-filters',
    send_seen_talents: 'send-seen-talents',
    hosts_new_posts: 'hosts-new-posts',
    host_new_post: 'host-new-post',
    send_seen_posts: 'send_seen_posts'
}

export const QUERY_KEYS = {
    feed: 'feed',
    monthly_support: 'monthly-support',
    active_host: 'active-host',
}

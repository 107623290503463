import {z} from 'zod'
import {FeaturedHostResponseSchema, FeaturedHostSchema, SuggestedResponseSchema} from './services/morgisTaxi.schema'

export type FeaturedHostType = z.infer<typeof FeaturedHostSchema>
export type FeaturedHostResponseType = z.infer<typeof FeaturedHostResponseSchema>
export type SuggestedHostResponseType = z.infer<typeof SuggestedResponseSchema>

export enum HostListTypesE {
    FEATURED_HOSTS = 'featured',
    SUGGESTED_HOSTS = 'suggested',
    CHAT_TEASER = 'chat_teaser',
    PROFILE = 'profile',
    CHAT_FEATURED = 'chat-featured',
    CHAT_SUGGESTED = 'chat-suggested'
}

import {HostPartialSchema} from '@/features/authentication/services/auth.schema'
import {PageSchema} from '@/features/common/services/common.schema'
import {z} from 'zod'

export const FeaturedHostSchema = z.object({
    id: z.number(),
    rookie_id: z.number(),
    featured_rookie_id: z.number(),
    full_name: z.string(),
    birth_date: z.string().nullable().optional(),
    username: z.string().optional(),
    position: z.number(),
    description: z.string().nullable().optional(),
    photos: z
        .array(
            z.object({
                id: z.number(),
                user_id: z.number(),
                url: z.string().nullable(),
                path_location: z.string().nullable(),
                main: z.boolean(),
                created_at: z.string(),
                under_validation: z.boolean()
            })
        )
        .optional(),
    path: z
        .object({
            id: z.number(),
            name: z.string(),
            prepend: z.string(),
            key_name: z.string(),
            is_subpath: z.boolean()
        })
        .nullable(),
    subpath: z
        .object({
            id: z.number(),
            name: z.string(),
            prepend: z.string(),
            key_name: z.string(),
            is_subpath: z.boolean()
        })
        .nullable()
        .optional(),
    expertise: z
        .object({
            id: z.number(),
            name: z.string(),
            key_name: z.string(),
            category: z.string(),
            created_at: z.string(),
            updated_at: z.string()
        })
        .nullable()
        .optional()
})

export const FeaturedHostResponseSchema = z.array(FeaturedHostSchema)
export const SuggestedResponseSchema = PageSchema(HostPartialSchema)

import React, {useEffect, useRef, useState} from 'react'
import WaveSurfer from 'wavesurfer.js'
import {PauseIcon, PlayButton} from '@/assets/icons/icons'
import {Spinner} from '../spinner/Spinner'
import {useMe} from '@/features/authentication/queries/useMe'
import {UserRoleE} from '@/types'
import {PlayerContainer, PlayerWrapper, StyledPlayButton, WaveSurferContainer} from './style'
import {useAudioPlayer} from './context'

interface WaveSurferPlayerProps {
    audioSrc?: string
    playerId: string
    autoPlay?: boolean
}

const WaveSurferPlayer: React.FC<WaveSurferPlayerProps> = ({audioSrc, playerId, autoPlay = false}) => {
    const {data: user} = useMe()
    const isGuest = user?.type === UserRoleE.Guest
    const containerRef = useRef<HTMLDivElement>(null)
    const waveSurferRef = useRef<WaveSurfer | null>(null)
    const [isPlaying, setIsPlaying] = useState(false)
    const {currentPlayerId, setCurrentPlayerId} = useAudioPlayer()
    const isSeeking = useRef(false)

    useEffect(() => {
        if (!containerRef.current || !audioSrc) return

        waveSurferRef.current = WaveSurfer.create({
            container: containerRef.current,
            waveColor: isGuest ? '#222222' : '#FFFFFF',
            progressColor: isGuest ? '#CCCCCC' : '#7a5af5',
            cursorColor: isGuest ? '#CCCCCC' : '#7a5af5',
            barWidth: 2,
            responsive: true,
            height: 26,
            interact: true
        })

        waveSurferRef.current.load(audioSrc)

        waveSurferRef.current.on('finish', () => {
            setIsPlaying(false)
            setCurrentPlayerId(null)
        })

        return () => {
            waveSurferRef.current?.destroy()
        }
    }, [audioSrc])

    const togglePlay = () => {
        if (!waveSurferRef.current) return

        if (currentPlayerId && currentPlayerId !== playerId) {
            setCurrentPlayerId(playerId)
            waveSurferRef.current.play()
            setIsPlaying(true)
        } else if (!isPlaying) {
            waveSurferRef.current.play()
            setCurrentPlayerId(playerId)
            setIsPlaying(true)
        } else {
            waveSurferRef.current.pause()
            setIsPlaying(false)
        }
    }
    useEffect(() => {
        const handleReady = () => autoPlay && togglePlay()
        if (waveSurferRef.current) waveSurferRef.current.on('ready', handleReady)
        return () => {
            waveSurferRef.current?.un('ready', handleReady)
        }
    }, [autoPlay, waveSurferRef, togglePlay])

    useEffect(() => {
        if (currentPlayerId !== playerId && isPlaying) {
            waveSurferRef.current?.pause()
            setIsPlaying(false)
        }
    }, [currentPlayerId, playerId, isPlaying])

    useEffect(() => {
        const handleSeek = () => {
            if (isSeeking.current) {
                isSeeking.current = false
            } else if (!isPlaying) {
                waveSurferRef.current?.pause()
                setIsPlaying(false)
            }
        }

        if (waveSurferRef.current) {
            waveSurferRef.current.on('seek', handleSeek)
            return () => {
                waveSurferRef.current?.un('seek', handleSeek)
            }
        }
    }, [isPlaying])

    useEffect(() => {
        if (waveSurferRef.current) {
            const handleInteraction = () => {
                isSeeking.current = true
            }

            const updatePlayButton = () => {
                setIsPlaying(waveSurferRef.current?.isPlaying() || false)
            }

            waveSurferRef.current.on('interaction', handleInteraction)
            waveSurferRef.current.on('play', updatePlayButton)
            waveSurferRef.current.on('pause', updatePlayButton)

            return () => {
                waveSurferRef.current?.un('interaction', handleInteraction)
                waveSurferRef.current?.un('play', updatePlayButton)
                waveSurferRef.current?.un('pause', updatePlayButton)
            }
        }
    }, [isPlaying])

    const handleSeeking = () => {
        if (isPlaying) {
            waveSurferRef.current?.play()
        }
    }

    useEffect(() => {
        if (waveSurferRef.current) {
            waveSurferRef.current.on('seek', handleSeeking)
            return () => {
                waveSurferRef.current?.un('seek', handleSeeking)
            }
        }
    }, [isPlaying])

    return (
        <PlayerContainer>
            {audioSrc ? (
                <PlayerWrapper>
                    <StyledPlayButton $isGuest={isGuest} onClick={togglePlay}>
                        {isPlaying ? (
                            <PauseIcon width={20} height={20} />
                        ) : (
                            <PlayButton width={11} height={14} fill={isGuest ? 'white' : '#222222'} />
                        )}
                    </StyledPlayButton>

                    <WaveSurferContainer ref={containerRef} />
                </PlayerWrapper>
            ) : (
                <Spinner inline />
            )}
        </PlayerContainer>
    )
}

export default WaveSurferPlayer

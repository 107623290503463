import axios from '@/clientAPI.ts'
import {AnonTransactionType, RetryTransactionGiftResponse} from './types'
import {AxiosResponse} from 'axios'
import {Message} from '@/features/common/types'
import {CreditCardUrl} from '@/features/recurring-gift/types'

export const ANON_API = {
    getAvoidedTransaction: async (id?: number | null): Promise<AnonTransactionType> => {
        const url = `/transactions/show/${id}`
        const {data} = await axios.get(url)
        return data
    },
    retryTransaction: async (id?: number | null): Promise<AxiosResponse<CreditCardUrl | Message | RetryTransactionGiftResponse>> => {
        const url = `/transactions/${id}/retry`
        const {data} = await axios.get(url)
        return data
    }
}

import {ChannelID, StoreChannel} from '@/features/chat/types'
import {useRootStore} from '@/store'
import {selectChatStore, selectChatChannels} from '@/store/selectors'
import {useEffect, useRef, useState} from 'react'
import {dateToPubnubTimetoken, getCorrectUserId} from '@/utilities/helpers'
import Pubnub, {ListenerParameters} from 'pubnub'
import {MessageTypeE} from '@/utilities/constants/chat'
import {UserRoleE} from '@/types'
import {useMe} from '@/features/authentication/queries/useMe'

type MessageCountProps = {
    pubnubInstance?: Pubnub
}

const useUnreadMessagesCount = ({pubnubInstance}: MessageCountProps) => {
    const {data: user} = useMe()
    const channels = useRootStore(selectChatChannels)
    const {increaseUnreadCounter, setUnreadCounter} = useRootStore(selectChatStore)
    const correctUserId = user ? getCorrectUserId(user) : undefined
    const [isMessageCountInitialized, setIsMessageCountInitialized] = useState(false)
    const isAgent = user?.type === UserRoleE.Agent
    const isRepresentative = user?.type === UserRoleE.Representative

    useEffect(() => {
        if (!isAgent && !isRepresentative && pubnubInstance && !isMessageCountInitialized) {
            getMessageCounts()
        }
    }, [isMessageCountInitialized, Object.keys(channels).length])

    useEffect(() => {
        if (correctUserId) setIsMessageCountInitialized(false)
    }, [correctUserId])

    // Here we get the last read messages counters for all the chats
    const getMessageCounts = async () => {
        try {
            const filteredChannels = Object.keys(channels).filter(
                key => !key.includes('rookie') && key.includes(String(correctUserId))
            )
            if (filteredChannels.length && pubnubInstance) {
                const channelsNames: string[] = []
                const channelsLastReadTimetokens: string[] = []

                filteredChannels.forEach(key => {
                    const channelName = key as ChannelID
                    const channelItem: StoreChannel = channels[channelName]
                    if (channelItem.last_reads_timetokens?.length || channelItem.created_at) {
                        channelsNames.push(channelName)
                        const lastReadTimetoken = channelItem.last_reads_timetokens?.length
                            ? channelItem.last_reads_timetokens[0].timetoken
                            : String(dateToPubnubTimetoken(channelItem?.created_at ?? ''))
                        channelsLastReadTimetokens.push(lastReadTimetoken)
                    }
                })
                const counters = await pubnubInstance.messageCounts({
                    channels: channelsNames,
                    channelTimetokens: channelsLastReadTimetokens
                })
                Object.keys(counters.channels).forEach(key => {
                    const channelName = key as ChannelID
                    setUnreadCounter(channelName, counters.channels[key])
                })
                setIsMessageCountInitialized(true)
            }
        } catch (error) {
            console.log('Error in getting message counts', error)
        }
    }

    // Subscribe to all channels to get notified about new messages
    const listenerRef = useRef<ListenerParameters | null>(null)
    useEffect(() => {
        if (pubnubInstance && !isAgent && !isRepresentative && Object.keys(channels).length) {
            listenerRef.current = {
                message: receivedMessage => {
                    const targetChannel = receivedMessage.channel as ChannelID
                    if (
                        String(receivedMessage.publisher) !== String(correctUserId) &&
                        receivedMessage.message.type !== MessageTypeE.DELETED
                    ) {
                        increaseUnreadCounter(targetChannel)
                    }
                }
            }
            pubnubInstance.addListener(listenerRef.current)
        }
        return () => {
            if (listenerRef.current) {
                pubnubInstance?.removeListener(listenerRef.current)
            }
        }
    }, [pubnubInstance, user, correctUserId])
}

export default useUnreadMessagesCount

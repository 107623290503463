import axios from '@/clientAPI'
import axiosRefactor from '@/refactorClientApi'
import {FeaturedHostResponseType, SuggestedHostResponseType} from '../types'
import {FeaturedHostResponseSchema, SuggestedResponseSchema} from './morgisTaxi.schema'
import {HostPartialType} from '@/features/authentication/types'

export const MORGIS_TAXI_API = {
    getFeaturedHosts: async (): Promise<FeaturedHostResponseType> => {
        const url = '/rookies/featured?response_type=extended'
        const {data} = await axios.get(url)
        return FeaturedHostResponseSchema.parse(data)
    },
    getSuggestedHosts: async ({
        page,
        limit,
        requestType,
        search
    }: {
        page: string
        limit: number
        requestType?: 'small'
        search: string
    }): Promise<SuggestedHostResponseType> => {
        const url = `/rookies/featured/suggested`
        const {data} = await axios.get(url, {params: {limit, page, request_type: requestType, search}})
        return SuggestedResponseSchema.parse(data)
    },
    getFeaturedHostById: async ({
        id,
        representedUserId
    }: {
        id?: number
        representedUserId?: number | string
    }): Promise<HostPartialType> => {
        const params = representedUserId
            ? {
                  represented_rookie_id: representedUserId
              }
            : undefined

        const url = `/rookies/featured/${id}?response_type=extended`
        const {data} = await axios.get(url, {params})
        return data
    },
    addFeaturedHost: async (hostId: number) => {
        const url = `/rookies/featured`
        const {data} = await axiosRefactor.post(url, {rookieId: hostId})
        return data
    },
    deleteFeaturedHosts: async ({id}: {id: number}) => {
        const url = `/rookies/featured/${id}`
        return await axiosRefactor.delete(url)
    }
}

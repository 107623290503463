import {useMe} from '@/features/authentication/queries/useMe'
import {useGetLanguages} from '@/features/country/queries/useGetLanguages'
import {USER_ROLE_GUEST, USER_ROLE_HOST, USER_ROLE_REPRESENTATIVE} from '@/utilities/constants/user'

export const useGetAllLanguages = () => {
    const {data: user} = useMe()
    const enabled =
        user?.type === USER_ROLE_GUEST ||
        [USER_ROLE_HOST, USER_ROLE_REPRESENTATIVE].includes(user?.type as string) ||
        !user

    const {data: languages} = useGetLanguages(false, enabled)

    return {
        languages: languages?.length ? languages.map(language => ({...language, key_name: language.code})) : []
    }
}

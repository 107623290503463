import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from './keys'
import {COUNTRY_API} from '../services/country.http'
import {useTranslation} from 'react-i18next'

export const useGetLanguages = (is_translatable = false, enabled = true) => {
    const {
        i18n: {language}
    } = useTranslation()
    return useQuery({
        queryKey: [QUERY_KEYS.languages, language],
        queryFn: () => COUNTRY_API.getLanguages({is_translatable}),
        enabled
    })
}

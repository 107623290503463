import styled, {css} from 'styled-components'

export const PlayerContainer = styled.div`
    width: 100%;
    max-width: 500px;
`

export const PlayerWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
`

export const StyledPlayButton = styled.div<{$isGuest: boolean}>`
    ${({$isGuest}) => css`
        padding: 10px;
        background-color: #ffffff1a;
        color: rgb(255, 255, 255);
        border: none;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;

        & svg {
            & path {
                fill: ${$isGuest ? '#222222' : 'white'};
            }
        }
    `}
`

export const WaveSurferContainer = styled.div`
    flex-grow: 1;
`

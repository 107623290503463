import axios from '@/clientAPI'
import {DonationType} from '@/features/recurring-gift/types'
import refactorAxios from '../../../../refactorClientApi'

export const MEMBERSHIP_PLANS_API = {
    getMembershipPlans: async (params: {rookieId: string}): Promise<DonationType[]> => {
        const {data} = await axios.get(`/rookies/${params.rookieId}/packages`)
        return data
    },
    pathMembershipPlan: async (params: {
        groupId: string | number
        body: {name: string | null; description?: string; imagePathLocation?: string | null}
    }) => {
        const {data} = await refactorAxios.patch(`/groups/${params.groupId}`, {
            ...params.body,
            description: params.body.description || null
        })
        return data
    },
    removeMembershipPlan: async ({
        groupId
    }: {groupId: string | number}) => {
        const {data} = await refactorAxios.delete(`/groups/${groupId}`)
        return data
    }
}

import {Trans, useTranslation} from 'react-i18next'
import {ConfirmIcon} from '@/assets/icons/icons'
import {StyledConfirmIcon, StyledOption, StyledOptionText} from './style'
import useUnreadMessagesCountReps from '@/features/representative/hooks/useUnreadMessageCountReps'
import {HostPartialType} from '@/features/authentication/types'
import {ChannelsType} from '@/features/representative/types'
import {Spinner} from '@/components/ui/spinner/Spinner'

type ChatHostOptionProps = {
    onClick: () => void
    host: HostPartialType & {hostChannels?: ChannelsType}
    selectedHostId?: number
}

export const ChatHostOption = ({onClick, host, selectedHostId}: ChatHostOptionProps) => {
    const {t} = useTranslation()
    const isSelectedHost = selectedHostId === host.id
    const {count, isLoading} = useUnreadMessagesCountReps({host, enabled: !isSelectedHost})

    return (
        <StyledOption key={host.id} onClick={onClick}>
            <StyledConfirmIcon checked={isSelectedHost}>
                <ConfirmIcon />
            </StyledConfirmIcon>

            <StyledOptionText>
                <Trans t={t} i18nKey={host?.username} components={{span: <span />}} />
            </StyledOptionText>

            {!isSelectedHost && (
                <StyledOptionText>
                    {count === null || isLoading ? (
                        <Spinner overlay={false} center={false} inline={true} size={8} />
                    ) : (
                        count > 0 && `(${count})`
                    )}
                </StyledOptionText>
            )}
        </StyledOption>
    )
}

import {Spinner} from '@/components/ui/spinner/Spinner'
import {useFetchMicromorgiPackages} from '@/features/gift/queries/useFetchMicromorgiPackages'
import {useTranslation} from 'react-i18next'
import {
    PurchaseBox,
    StyleMicromorgiIcon,
    StyledAmount,
    StyledAmountContainer,
    StyledPackPrice,
    StyledButtonBox,
    StyledPackContainer,
    StyledPackPriceBox,
    StyledDivider
} from './style'
import {Button} from '@/components/ui/button/Button'
import {MicromorgiPackage} from '@/features/gift/types'
import { useMe } from '@/features/authentication/queries/useMe'
import { userStatuses } from '@/components/ui/chatBadge/ChatBadge'
import { GuestType } from '@/features/authentication/types'
import { remapCurrency } from '@/features/profile/constants'

type RenderPurchaseStepProps = {
    selectedOption?: MicromorgiPackage
    findSelectedOption: (pack: MicromorgiPackage) => boolean
    selectPackClick: (pack: MicromorgiPackage) => void
    handleBuyMicromorgiPackage: (packageId: string) => void
}

export const RenderPurchaseStep = ({
    handleBuyMicromorgiPackage,
    selectedOption,
    findSelectedOption,
    selectPackClick
}: RenderPurchaseStepProps) => {
    const {t} = useTranslation()
    const {data}  = useMe()
    const user = data as GuestType
    
    const userStatus = user?.type_attributes.level_status as string

    const {data: micromorgiPackages, isLoading} = useFetchMicromorgiPackages()

    const filteredMicromorgiPackagesByStatus = micromorgiPackages?.filter(
        micromorgiPackage =>
            micromorgiPackage.level_status === (userStatus === userStatuses.SILVER ? userStatuses.GUEST : userStatus)
    )


    return (
        <>
            {isLoading ? (
                <Spinner inline size={30} />
            ) : (
                <div>
                    <StyledPackPriceBox>
                        <StyledPackPrice>{t('riched_limit_modal:description')}</StyledPackPrice>
                    </StyledPackPriceBox>
                    <PurchaseBox>
                        {filteredMicromorgiPackagesByStatus?.map(pack => (
                            <StyledPackContainer
                                onClick={() => selectPackClick(pack)}
                                selected={findSelectedOption(pack)}
                                key={pack.id}
                            >
                                <StyledAmountContainer>
                                    <StyleMicromorgiIcon />
                                    <StyledAmount>{pack.micromorgi_count}</StyledAmount>
                                </StyledAmountContainer>
                                <div>
                                    <StyledPackPrice>
                                        {t('riched_limit_modal:pricing', {
                                            CURRENCY: remapCurrency[user.currency],
                                            MICROMorgis_COUNT: pack.micromorgi_count,
                                            PRICE: pack.price,
                                        })}
                                    </StyledPackPrice>
                                </div>
                            </StyledPackContainer>
                        ))}
                        <StyledDivider />
                    </PurchaseBox>
                    <StyledButtonBox>
                        <Button
                            disabled={!selectedOption}
                            onClick={() => selectedOption && handleBuyMicromorgiPackage(`${selectedOption.id}`)}
                        >
                            {t('riched_limit_modal:purchase_now_button')}
                        </Button>
                    </StyledButtonBox>
                </div>
            )}
        </>
    )
}

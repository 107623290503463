import ToggleSwitch from '@/components/commons/toggleSwitch/ToggleSwitch'
import {useMe} from '@/features/authentication/queries/useMe'
import {useRootStore} from '@/store'
import {selectGlobalTranslateStore, selectSelectGlobalLanguageModal} from '@/store/selectors'
import {APP_LANGUAGES, FALLBACK_LANGUAGE} from '@/translations/i18n'
import {FC, MouseEvent, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {StyledLabel, StyledLanguageLabel, StyledSwitchWrapper} from './style'

// This component displays the Switch that controls the global translation, namely turning it on and off.
// Inside the label it is possible to open a modal window to select the global language to be translated.

// To use not the global language but the default language in the application, you need to cast prop(isUseMainAppLanguage=true) here.

// To hide this switch when used with a translation group. That is, if we have 3 texts that are written in different languages. And if at least one of them is not in the list of languages that the user understands, we will display this switch. This requires using languagesUsed
// in this format languagesUsed:{languages:[ua,it,es,it],count:4}.
// languages- list of language codes depending on which is moderated whether to show the switch.
// count - is the number of all texts

type SwitchProps = {
    isUseMainAppLanguage?: boolean
    languagesUsed?: {languages: string[]; count: number}
    isSmall?: boolean
    inMessage?: boolean
}

export type SwitchLanguage = {
    code: string
    name: string
    id?: string
}

const defaultLanguageForRepresentative = {
    id: 6,
    name: 'English',
    code: 'en',
    is_translatable: true
}

export const TranslateSwitch: FC<SwitchProps> = ({
    isUseMainAppLanguage = false,
    languagesUsed,
    isSmall = false,
    inMessage = false
}) => {
    const {t} = useTranslation()

    const {openModal: openModalSelectGlobalLanguageModal} = useRootStore(selectSelectGlobalLanguageModal)
    const {data: user} = useMe()
    const {overturnToggleSwitch, translateToggle, currentLanguage, initialTranslateConfig} =
        useRootStore(selectGlobalTranslateStore)

    const defaultAppLanguage = APP_LANGUAGES.find(lang => lang.value === FALLBACK_LANGUAGE)
    const defaultLanguage: SwitchLanguage = APP_LANGUAGES.reduce(
        (acc, item) =>
            item.value.toUpperCase() ===
            (user?.language ? user?.language.toUpperCase() : FALLBACK_LANGUAGE.toUpperCase())
                ? {code: item.value.toUpperCase(), name: item.label}
                : acc,
        {
            code: defaultAppLanguage ? defaultAppLanguage.value.toUpperCase() : '',
            name: defaultAppLanguage ? defaultAppLanguage.label : ''
        }
    )

    const spokenLanguage = user?.spoken_languages
    const language = isUseMainAppLanguage
        ? defaultLanguage
        : currentLanguage ||
          spokenLanguage?.[0] || {
              code: defaultLanguage.code.split('_')[0].toLowerCase(),
              name: defaultLanguage.name
          }
    const onOpenSelectLanguageModalHandler = (e: MouseEvent<HTMLSpanElement>) => {
        e.preventDefault()
        if (!isUseMainAppLanguage) openModalSelectGlobalLanguageModal({})
    }

    const showSwitch: boolean =
        languagesUsed && languagesUsed?.languages.length >= languagesUsed?.count
            ? !![...new Set(languagesUsed?.languages)].filter(
                  languageUsed => !spokenLanguage?.map(({code}) => code.toLowerCase()).includes(languageUsed)
              ).length
            : !languagesUsed

    useEffect(() => {
        if (!currentLanguage && user) {
            initialTranslateConfig({
                userID: String(user.id),
                currentLanguage: spokenLanguage?.length ? spokenLanguage[0] : defaultLanguageForRepresentative
            })
        }
    }, [currentLanguage])

    if (!showSwitch) return <></>
    return (
        <StyledSwitchWrapper inMessage={inMessage}>
            <ToggleSwitch
                isSmall={isSmall}
                checked={translateToggle}
                onClick={overturnToggleSwitch}
                labelPosition="right"
                label={
                    <StyledLabel htmlFor="switch" style={{paddingRight: 15}}>
                        {`${t('chat:see_translation')} `}
                        {`(`}
                        <StyledLanguageLabel
                            isActive={!isUseMainAppLanguage}
                            onClick={onOpenSelectLanguageModalHandler}
                        >{`${language?.name}`}</StyledLanguageLabel>
                        {`)`}
                    </StyledLabel>
                }
            />
        </StyledSwitchWrapper>
    )
}

import styled, {css} from 'styled-components'

export const StyledPlansListHeader = styled.div`
    ${({theme: {typography, palette}}) => css`
        display: flex;
        width: 100%;
        justify-content: space-around;
        align-items: center;
        & span {
            ${typography.textMd}
            color:${palette.primary[450]};
            text-align: center;
            font-weight: 700;
            line-height: 24px;
        }
    `}
`
export const StyledPlansList = styled.div`
    ${({theme: {scrollbar, mediaQueries, spacing}}) => css`
        ${scrollbar}
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: stretch;
        overflow-x: hidden;
        overflow-y: auto;
        padding-bottom: ${spacing}px;
        ${mediaQueries.m} {
            height: 444px;
        }
    `}
`

import {PageSchema} from '@/features/common/services/common.schema'
import {MediaTypeE} from '@/types'
import {z} from 'zod'

export const MySurprisesTypes = z.nativeEnum(MediaTypeE)

export const MySurpriseSchema = z.object({
    id: z.number(),
    rookie_id: z.number().optional(),
    blurred_url: z.string().optional().nullable(),
    blurred_path_location: z.string().optional(),
    type: MySurprisesTypes.optional(),
    url: z.string(),
    path_location: z.string(),
    created_at: z.string().optional()
})

export const UploadSurprisePayloadSchema = z.object({
    surprises: z.array(
        z.object({
            path_location: z.string(),
            type: MySurprisesTypes
        })
    )
})

export const UploadSurpriseResponseSchema = z.object({
    id: z.number(),
    rookie_id: z.number(),
    blurred_url: z.string(),
    blurred_path_location: z.string(),
    type: z.string(),
    created_at: z.string(),
    url: z.string(),
    path_location: z.string()
})

export const HostSurprisesResponseSchema = PageSchema(MySurpriseSchema)

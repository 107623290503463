import styled, {css} from 'styled-components'

export const StyledBody = styled.div`
    ${({theme: {spacing}}) => css`
        width: 100%;
        height: 100%;
        padding: ${spacing * 2}px;
        display: flex;
        flex-direction: column;
        text-align: center;
        gap: ${spacing * 2}px;
        justify-content: space-between;
    `}
`

export const StyledDivider = styled.div`
    ${({theme: {palette}}) => css`
        border-bottom: 1px solid ${palette.neutral[950]};
    `}
`
export const StyledButtonSection = styled.div`
    ${({theme: {spacing, typography}}) => css`
        gap: ${spacing}px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        text-align: center;
        gap: ${spacing * 2.5}px;
        margin: ${spacing * 2}px 0 ${spacing * 2}px;
        & button {
            ${typography.textMd}
            font-weight: 700;
        }
    `}
`

export const StyledActionButton = styled.span`
    ${({theme: {palette}}) => css`
        color: ${palette.neutral[50]};
        cursor: pointer;
        text-decoration: underline;
    `}
`

export const StyledInfoWrapper = styled.div`
    ${({theme: {spacing, palette}}) => css`
        margin-top: ${spacing * 2}px;
        color: ${palette.neutral.white};
        display: flex;
        flex-direction: column;
    `}
`

export const StyledTextWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        & p {
            margin-top: ${spacing * 2}px;
        }
    `}
`
export const StyledSubTitle = styled.h3`
    ${({theme: {typography}}) => css`
        margin: 0;
        ${typography.textXXl}
        font-weight: 400;
    `}
`

export const StyledAnimation = styled.div`
    margin: 0 auto;
    height: 100%;
    width: 100%;
    max-height: 220px;
    max-width: 240px;
`

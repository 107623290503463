import {ChatTopicsSchema, FavoriteActivitiesSchema, GenderSchema} from '@/features/authentication/services/auth.schema'
import {GoalSchema} from '@/features/goal/services/goal.schema'
import {TopFanSchema} from '@/features/top-fan/services/topFan.schema'
import {z} from 'zod'
import {SubscriptionTypesE} from '../helpers/helpers'

export const DocumentSchema = z.object({
    id: z.number(),
    file_name: z.string(),
    file_size_in_mb: z.number(),
    pages_count: z.number(),
    mime_type: z.string(),
    created_at: z.string(),
    updated_at: z.string()
})
export const ChatFileSchema = z.object({
    id: z.number(),
    sender_id: z.number(),
    receiver_id: z.number(),
    type: z.string(),
    path_location: z.string(),
    blurred_path_location: z.string().nullable(),
    title: z.string().nullable(),
    morgi: z.number().nullable(),
    representative_id: z.number().nullable(),
    representative_first_name: z.string().nullable(),
    url: z.string(),
    blurred_url: z.string().nullable(),
    paid_users: z.array(z.number()),
    group_key_name: z.string().nullable(),
    message: z.string().nullable().optional(),
    document_id: z.number().nullable(),
    document: DocumentSchema.nullable()
})

export const ChannelMessageSchema = z.object({
    channelId: z.string(),
    is_starred: z.boolean().optional(),
    morgi: z.number().optional(),
    message: z
        .object({
            text: z.string().nullable().optional(),
            type: z.string()?.optional(),
            user_id: z.string().or(z.number()),
            language: z.string()?.optional(),
            meta: z
                .object({
                    message: z.string().optional(),
                    attachmentId: z.number().optional(),
                    gif_url: z.string().optional(),
                    micromorgiAmount: z.number().optional(),
                    animation_id: z.number().optional(),
                    representative_id: z.number().optional(),
                    representative_first_name: z.string().optional(),
                    goal_id: z.number().optional(),
                    is_starred: z.boolean().optional(),
                    featured_rookie_id: z.number().optional()
                })
                .optional()
        })
        .optional()
})

const AvatarSchema = z
    .object({
        id: z.number(),
        user_id: z.number(),
        path_location: z.string(),
        main: z.boolean(),
        is_face_recognition_processed: z.boolean(),
        title: z.string().nullable(),
        content: z.string().nullable(),
        tags: z.string().nullable(),
        created_at: z.string(),
        updated_at: z.string(),
        url: z.string(),
        under_validation: z.boolean()
    })
    .nullable()

const PathSchema = z.object({
    id: z.number(),
    name: z.string(),
    key_name: z.string(),
    is_subpath: z.boolean(),
    prepend: z.string().nullable().optional()
})

const SubscriptionTypes = z.nativeEnum(SubscriptionTypesE)

export const LastReadsTimetokensSchema = z.object({
    id: z.number(),
    user_id: z.number(),
    channel_id: z.number(),
    timetoken: z.string(),
    representative_id: z.number().nullable(),
    created_at: z.string(),
    updated_at: z.string()
})

export const ChannelSchema = z.object({
    avatar: AvatarSchema,
    leader_first_message_at: z.string().nullable(),
    rookie_first_message_at: z.string().nullable(),
    subscription_id: z.number().nullable(),
    username: z.string(),
    full_name: z.string(),
    id: z.number(),
    channel_id: z.number(),
    is_online: z.boolean(),
    chat_is_online: z.boolean(),
    leader_awaiting_reply: z.boolean(),
    is_paused: z.boolean(),
    type: SubscriptionTypes,
    created_at: z.string(),
    is_chat_attachments_blurred: z.boolean(),
    is_chat_attachments_blurred_to_receiver: z.boolean(),
    is_representative_on_chat: z.boolean().nullable().optional(),
    representative_first_name: z.string().nullable().optional(),
    gender: GenderSchema.nullable(),
    has_ever_paid: z.boolean(),
    has_gifted_chat_micromorgi: z.boolean(),
    paid_rookie_micromorgi: z.boolean(),
    messages_languages: z.array(z.unknown()),
    subscription_at: z.string().nullable(),
    valid_until_at: z.string().nullable(),
    subscription_status: z.string().nullable(),
    is_converter: z.boolean().optional(),
    goal: z.array(GoalSchema).optional(),
    chat_topic: z.array(ChatTopicsSchema).optional(),
    favorite_activities: z.array(FavoriteActivitiesSchema).optional(),
    top_fans_infos: z.object({
        min_amount_to_reach: z.number(),
        completed_counter: z.number(),
        pending_counter: z.number(),
        group_id: z.number().nullable()
    }),
    rookie_path: PathSchema.nullable(),
    top_fans: TopFanSchema.nullable(),
    last_reads_timetokens: z.array(LastReadsTimetokensSchema).nullable(),
    rookie_first_name: z.string(),
    rookie_last_name: z.string(),
    level_status: z.string().optional(),
    status: z.string().nullable().optional(),
    channel_status: z.string().optional(),
    next_starred_message_at: z.union([z.date(), z.string()]).optional(),
    is_payment_required_for_connection: z.boolean().nullable().optional(),
    min_morgi_to_open_connection: z.number().nullable().optional(),
    leader_level_status: z.string().optional(),
    subscription_amount: z.number().optional().nullable()
})

// TODO: this is a Partial of ChannelSchema
export const ChannelLightSchema = z.object({
    avatar: AvatarSchema,
    subscription_id: z.string().nullable(),
    username: z.string(),
    full_name: z.string(),
    id: z.number(),
    channel_id: z.number(),
    is_online: z.boolean(),
    chat_is_online: z.boolean(),
    type: SubscriptionTypes,
    created_at: z.string(),
    subscription_at: z.string().nullable(),
    valid_until_at: z.string().nullable(),
    subscription_status: z.string().nullable(),
    last_reads_timetokens: z
        .array(
            z.object({
                id: z.number(),
                user_id: z.number(),
                channel_id: z.number(),
                timetoken: z.string(),
                representative_id: z.number().nullable(),
                created_at: z.string(),
                updated_at: z.string()
            })
        )
        .nullable(),
    rookie_first_name: z.string(),
    rookie_last_name: z.string(),
    has_gifted_chat_micromorgi: z.boolean().optional(),
    status: z.string().nullable().optional(),
    channel_status: z.string().optional()
})

export const AnimationSchema = z.object({
    id: z.number(),
    url: z.string(),
    key_name: z.string(),
    name: z.string(),
    amount: z.number()
})

export const PaidTextMessageSchema = z.object({
    id: z.number(),
    rookie_id: z.number(),
    morgi: z.number(),
    created_at: z.string(),
    updated_at: z.string(),
    message: z.string(),
    paid_users: z.array(z.number())
})

export const PaidMessageSchema = z.object({
    chat_attachments: z.array(ChatFileSchema),
    paid_messages: z.array(PaidTextMessageSchema)
})

export const ChatReportCategoriesSchema = z.object({
    id: z.number(),
    key_name: z.string(),
    name: z.string(),
    created_at: z.string(),
    updated_at: z.string()
})

export const ChatReportCategoriesResponseSchema = z.array(ChatReportCategoriesSchema)

export enum UserStatusesE {
    ACCEPTED = 'accepted',
    PENDING = 'pending',
    DELETED = 'deleted',
    REJECTED = 'rejected',
    UNTRUSTED = 'untrusted',
    BLOCKED = 'blocked',
    NEW = 'new',
    FRAUD = 'fraud',
    ANONYMOUS = 'anonymous',
}

export enum IdCardStatuses {
    PENDING = 'pending',
    APPROVED = 'approved',
    REJECTED = 'rejected',
    DEFAULT = 'default',
}

export enum GenderKeyNameE {
    ALL = 'all',
    MALE = 'male',
    FEMALE = 'female',
    OTHER = 'other',
    AGENDER = 'agender',
    NON_BINARY = 'non_binary',
    PANGENDER = 'pangender',
    TRANS_FEMALE = 'trans_female',
    TRANS_MALE = 'trans_male'
}

export enum OldRoles {
    leader = 'guest',
    rookie = 'host'
}

export enum DisplayNameTypes {
    NICKNAME = 'nickname',
    FULL_NAME = 'full_name'
}

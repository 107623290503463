import axios from '@/clientAPI.ts'
import {User} from '@/features/authentication/types.ts'

import * as types from '../types'
import {SavedGoalsPropsType} from '../queries/useFetchSavedGoals'
import {SupportedGoalsPropsType} from '../queries/useFetchSupportedGoals'

export const GOAL_API = {
    getGoalsTypes: async (): Promise<types.GoalSizeType[]> => {
        const {data} = await axios.get('/goals/types')
        return data
    },
    createGoal: async (body: types.CreationGoalType): Promise<types.GoalType> => {
        const {data} = await axios.post('/goals', body)
        return data
    },
    getHostGoals: async (hostID: string): Promise<types.GoalType[]> => {
        const {data} = await axios.get(`/rookies/${hostID}/goals`)
        return data
    },
    getGoal: async ({goalId}: {goalId: string}): Promise<types.GoalType> => {
        const {data} = await axios.get(`/goals/${goalId}`, {
            params: {response_type: 'extended'}
        })
        return data
    },
    editGoal: async (goalId: string, body: types.EditGoal | types.EditMedia): Promise<types.GoalType> => {
        const {data} = await axios.patch(`/goals/${goalId}`, body)
        return data
    },
    getGoalsList: async ({
        page,
        limit,
        typeId,
        sortParams,
        pathIDs,
        search
    }: types.GetGoalsListRequest): Promise<types.ResponseActiveGoalsList> => {
        const params = {page, limit, type_id: typeId, path_ids: pathIDs, search, ...sortParams}

        const {data} = await axios.get('/goals', {params})
        return data
    },
    donateGoal: async (hostID: string, goalId: string, amount: number): Promise<types.GoalType> => {
        const {data} = await axios.post(`/rookies/${hostID}/goals/${goalId}/donate`, {amount})
        return data
    },
    getGoalsPaths: async ({name, typeId, sortParams}: types.GoalsPathsRequest): Promise<types.PathGoalsResponse> => {
        const {data} = await axios.get('/goals/paths', {
            params: {name, type_id: typeId, ...sortParams}
        })
        return data
    },
    stopGoal: async (goalId: string): Promise<types.GoalType> => {
        const {data} = await axios.post(`/goals/${goalId}/cancel`)
        return data
    },
    saveGoal: async (goalId: string): Promise<types.GoalType> => {
        const {data} = await axios.post(`/goals/${goalId}/save`)
        return data
    },
    unsaveGoal: (goalId: string): Promise<types.GoalType> => {
        return axios.delete(`/goals/${goalId}/save`)
    },
    getSupporters: async (goalId: string): Promise<types.SupporterType[]> => {
        const {data} = await axios.get(`goals/${goalId}/supporters`)
        return data
    },
    getSavedGoalsList: async ({
        page = '1',
        limit = 10,
        sortParams
    }: SavedGoalsPropsType): Promise<types.ResponseActiveGoalsList> => {
        const {data} = await axios.get('/goals/saved', {params: {page, limit, ...sortParams}})
        return data
    },
    getPastGoals: async ({
        page = 1,
        limit = 10,
        rookieId
    }: types.GetPastGoalsList): Promise<types.ResponseActiveGoalsList> => {
        const {data} = await axios.get(`/rookies/${rookieId}/goals/past`, {
            params: {page, limit}
        })
        return data
    },
    getSupportedGoalsList: async ({
        page = '1',
        limit = 10,
        sortParams,
        isActive
    }: SupportedGoalsPropsType): Promise<types.ResponseActiveGoalsList> => {
        const {data} = await axios.get('/goals/supported', {
            params: {page, limit, ...sortParams, is_active: isActive}
        })
        return data
    },
    getBroadcastsSupportersList: async (representedUserId?: number | string): Promise<User[]> => {
        const params = representedUserId
            ? {
                  represented_rookie_id: representedUserId
              }
            : undefined
        const {data} = await axios.get('/leader', {params})
        return data
    },
    createBroadcast: async (
        body: types.CreateBroadcast,
        representedUserId?: number | string
    ): Promise<types.Broadcast> => {
        const {data} = await axios.post('/broadcasts?response_type=extended', {
            ...body,
            represented_rookie_id: representedUserId
        })
        return data
    },
    addLeaderNickname: async ({data: body, leaderId}: types.AddLeaderNickname): Promise<types.NicknameOfLeader> => {
        const {data} = await axios.post(`/leader/${leaderId}/nickname`, body)
        return data
    },

    updateLeaderNickname: async ({
        data: body,
        leaderId,
        nicknameId
    }: types.UpdateLeaderNicknameRequest): Promise<types.NicknameOfLeader> => {
        const {data} = await axios.patch(`/leader/${leaderId}/nickname/${nicknameId}`, body)
        return data
    },
    deleteLeaderNickname: async ({leaderId, nicknameId}: types.DeleteLeaderNicknameRequest): Promise<void> => {
        const {data} = await axios.delete(`/leader/${leaderId}/nickname/${nicknameId}`)
        return data
    },
    uploadMedia: async ({data: body, fileType, options}: types.UploadMediaRequest) => {
        const {data} = await axios.post(`/upload?filetype=${fileType}`, body, {
            ...options,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        return data
    },
    goalProof: async (goalId: string, body: types.ProofRequest) => {
        const {data} = await axios.post(`/goals/${goalId}/proofs`, body)
        return data
    },
    notifyAdminGoalUpdated: async (goalId: string): Promise<types.GoalType> => {
        const {data} = await axios.post(`/goals/${goalId}/request-review`)
        return data
    },
    withdrawMicroMorgis: async (goalId: string): Promise<types.GoalType> => {
        const {data} = await axios.post(`/goals/${goalId}/retrieve`)
        return data
    }
}

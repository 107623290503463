import {useMe} from '@/features/authentication/queries/useMe'
import {GuestType} from '@/features/authentication/types'
import {remapCurrency} from '@/features/profile/constants'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import {useRootStore} from '@/store'
import {selectChoosePaymentsMethodModal} from '@/store/selectors'
import {USER_ROLE_GUEST} from '@/utilities/constants/user'
import {useTranslation} from 'react-i18next'
import {StyledBody, StyledChangePaymentMethodsButton, StyledContentWrapper, StyledDivider, StyledModal} from './style'
import NoPreferredPaymentMethodVariant from '../no-preverred-payment-method-variant/NoPreferredPaymentMethodVariant'
import PaymentPlatformSection from '../payment-platform-section/PaymentPlatformSection'
import HasPreferredPaymentMethodVariant from '../has-preferred-payment-method-variant/HasPreferredPaymentMethodVariant'
import {BillingInfoModal} from '@/features/billing/components/billing-info-modal/BillingInfoModal'
import {useEffect, useMemo, useState} from 'react'
import {remapAndFilterAPMLinks} from '../../utilities/helpers'
import {Spinner} from '@/components/ui/spinner/Spinner'
import CustomerBillingInfoSection from '../customer-billing-info-section/CustomerBillingInfoSection'
import {LocalStorageManager} from '@/utilities/localStorage'

const ChoosePaymentMethodModal = () => {
    const {t} = useTranslation()
    const {height} = useWindowDimensions()
    const {
        currencyAmount,
        morgisAmount,
        paymentMethodUrls,
        isUpdateCreditCard,
        isMonthlySupport,
        isMegaGift,
        closeModal,
        apiCallback
    } = useRootStore(selectChoosePaymentsMethodModal)

    const {data} = useMe()
    const user = data?.type === USER_ROLE_GUEST ? (data as GuestType) : null
    const hasPreferredPaymentMethod = !!user?.type_attributes?.preferred_payment_method
    const hasBillingData = !!user?.type_attributes?.has_invoicing_profile
    const [showBillingInfoModal, setShowBillingInfoModal] = useState(false)

    useEffect(() => {
        if (!hasBillingData) {
            setShowBillingInfoModal(true)
        }
    }, [])

    const handleBillingInfoModalClose = () => {
        LocalStorageManager.openBuyMorgisModal.remove()
        closeModal()
        setShowBillingInfoModal(false)
    }

    const onSendBillingInfoSuccess = () => {
        setShowBillingInfoModal(false)
        apiCallback?.(true, true)
    }

    const APMLinksList = useMemo(() => {
        return remapAndFilterAPMLinks(paymentMethodUrls)
    }, [paymentMethodUrls])

    const onActiveCardClick = () => {
        apiCallback?.()
        closeModal()
    }

    return (
        <>
            <StyledModal
                title={
                    morgisAmount
                        ? t('choose_payment_method:title', {
                              MORGIS_AMOUNT: morgisAmount,
                              CURRENCY: remapCurrency[user?.currency || 'USD'],
                              CURRENCY_AMOUNT: currencyAmount
                          })
                        : t('choose_payment_method:default_title')
                }
                onClose={() => {
                    closeModal()
                    LocalStorageManager.openBuyMorgisModal.remove()
                }}
                vh={height}
                isCloseAfterClickOutside={false}
            >
                {!paymentMethodUrls ? (
                    <Spinner />
                ) : (
                    <StyledBody>
                        <StyledContentWrapper>
                            {!isUpdateCreditCard && !!user?.type_attributes?.preferred_payment_method && (
                                <StyledChangePaymentMethodsButton>
                                    {t('choose_payment_method:change_payment_method')}
                                </StyledChangePaymentMethodsButton>
                            )}

                            {isUpdateCreditCard &&
                                hasBillingData &&
                                !!user?.type_attributes?.preferred_payment_method && (
                                    <CustomerBillingInfoSection onSendBillingInfoSuccess={onSendBillingInfoSuccess} />
                                )}
                            {!isMonthlySupport && !isUpdateCreditCard && (
                                <PaymentPlatformSection APMlinks={APMLinksList} />
                            )}
                            {((!hasPreferredPaymentMethod && hasBillingData) || isUpdateCreditCard || isMegaGift) || (hasPreferredPaymentMethod && hasBillingData && !isUpdateCreditCard && !isMegaGift)  && (
                                <StyledDivider>- {t('common:or')} -</StyledDivider>
                            )}

                            {((!hasPreferredPaymentMethod && hasBillingData) || isUpdateCreditCard || isMegaGift) && (
                                <NoPreferredPaymentMethodVariant
                                    paymentMethodUrls={paymentMethodUrls}
                                    apiCallback={apiCallback}
                                    isUpdateCreditCard={isUpdateCreditCard}
                                    isMonthlySupport={isMonthlySupport}
                                />
                            )}

                            {hasPreferredPaymentMethod && hasBillingData && !isUpdateCreditCard && !isMegaGift && (
                                <HasPreferredPaymentMethodVariant
                                    paymentMethodUrls={paymentMethodUrls}
                                    onActiveCardClick={onActiveCardClick}
                                />
                            )}
                        </StyledContentWrapper>
                    </StyledBody>
                )}
            </StyledModal>

            {showBillingInfoModal && (
                <BillingInfoModal
                    onClose={handleBillingInfoModalClose}
                    onSendBillingInfoSuccess={onSendBillingInfoSuccess}
                />
            )}
        </>
    )
}

export default ChoosePaymentMethodModal

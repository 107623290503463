import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from './keys'
import {PROFILE_API} from '../services/profile.http'
import {useGetRepresentedUser} from '@/hooks/useGetRepresentedHost'

export const useGetGuest = (id: number | string, enabled?: boolean) => {
    const {representedUserId} = useGetRepresentedUser()
    return useQuery({
        queryKey: [QUERY_KEYS.guest_profile, id, representedUserId],
        queryFn: () => (id ? PROFILE_API.getGuestProfile(id, representedUserId) : undefined),
        enabled: id !== undefined && enabled
    })
}

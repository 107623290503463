import {
    AboutIcon,
    ApproachFirstIcon,
    BlackDiamondWithStarIcon,
    CustomerSupportIcon,
    DocumentIcon,
    GiftIcon,
    HeartIcon,
    HomeIcon,
    HostsTourIcon,
    LayoutIcon,
    LoginIcon,
    MenuInviteIcon,
    MorgiAppreciateIcon,
    PaymentIcon,
    SupportIcon,
    TelegramMonoIcon,
    UserIcon,
    WhosTopFanIcon
} from '@/assets/icons/icons'
import goalIcon from '@/assets/images/goalIcon.png'
import goalIconWhite from '@/assets/images/goalIconWhite.png'
import {GuestType, HostType, User} from '@/features/authentication/types'
import {OldRoles, UserStatusesE} from '@/features/constants/constants'
import {UserRoleE} from '@/types'
import {
    HOSTS_TOUR_ARTISTS_SHOWBIZ_LINK,
    HOSTS_TOUR_ATHLETES_LINK,
    HOSTS_TOUR_ENTREPRENEURS_LINK,
    HOSTS_TOUR_GENERAL_LINK,
    HOSTS_TOUR_INFLUENCERS_LINK,
    HOSTS_TOUR_LICENSED_PROFESSIONALS_LINK,
    HOSTS_TOUR_MODELS_LINK,
    HOSTS_TOUR_TEACHERS_COACHES_LINK,
    ROUTE_ACTIVE_GIFTING_GUESTS,
    ROUTE_ACTIVE_GIFTS,
    ROUTE_APPROACH_FIRST,
    ROUTE_BILLING_INFO,
    ROUTE_BIRTHDAYS,
    ROUTE_COMMUNITY_GUIDELINES,
    ROUTE_COOKIE_POLICY,
    ROUTE_DELETE_PROFILE,
    ROUTE_EDIT_PROFILE,
    ROUTE_EDIT_REPRESENTATIVE,
    ROUTE_FAQ_GUEST,
    ROUTE_FAQ_HOST,
    ROUTE_GOALS_MAIN_PAGE,
    ROUTE_HOME,
    ROUTE_HOST_MY_PROFILE,
    ROUTE_HOST_PAYMENT_METHOD,
    ROUTE_INVITE_FRIENDS,
    ROUTE_LOGIN_GUEST,
    ROUTE_LOGIN_HOST,
    ROUTE_MANAGE_AGENT,
    ROUTE_MANAGE_REPS,
    ROUTE_MESSAGE_CENTER,
    ROUTE_MY_GOALS,
    ROUTE_MY_MICROMORGI_BALANCE,
    ROUTE_MY_MOMENTS_FEED,
    ROUTE_MY_MORGIS_TAXI,
    ROUTE_OUR_MISSION,
    ROUTE_PRIVACY_POLICY,
    ROUTE_REFER_A_GUEST,
    ROUTE_SAVED_GOALS,
    ROUTE_SAVED_HOSTS,
    ROUTE_SELECT_GOAL_TYPE,
    ROUTE_SIGNUP_GUEST_SOCIAL,
    ROUTE_SIGNUP_HOST_SOCIAL,
    ROUTE_SUPPORTED_GOALS,
    ROUTE_TOS_GUEST,
    ROUTE_TOS_HOST,
    ROUTE_TRANSACTION_HISTORY,
    ROUTE_UPDATE_CREDIT_CARD,
    ROUTE_UPDATE_RECURRING_TRANSACTION,
    ROUTE_WHAT_ARE_GOALS,
    ROUTE_WHAT_IS_MORGI,
    ROUTE_WHOS_TOP_FAN_YOU_ARE
} from '@/utilities/constants/routeNames'
import {USER_ROLE_GUEST} from '@/utilities/constants/user'
import {LocalStorageManager} from '@/utilities/localStorage'

type ActionFunctions = {
    [ActionsListE.LOGOUT]: () => void
    [ActionsListE.DELETE_ACCOUNT]: () => void
    [ActionsListE.TELEGRAM_NOTIFICATION_BOT]: () => void
    [ActionsListE.CUSTOMER_SUPPORT]: () => void
    [ActionsListE.HOME]: () => void
    [ActionsListE.ABOUT_US]: () => void
}

export const enum ActionsListE {
    LOGOUT = 'logout',
    DELETE_ACCOUNT = 'delete_account',
    TELEGRAM_NOTIFICATION_BOT = 'telegram_notification_bot',
    CUSTOMER_SUPPORT = 'customer_support',
    HOME = 'home',
    ABOUT_US = 'about_us'
}

export type ItemMenu = {
    id: string
    title: string
    show: boolean
    icon?: React.ReactNode
    to?: string
    subMenu?: ItemMenu[]
    withGradientMenuItem?: boolean
    secondaryIcon?: React.ReactNode
    titleValue?: number | string
    action?: ActionFunctions[keyof ActionFunctions]
}

export const getCorrectMenuItemsList = (
    actions: ActionFunctions,
    loggedIn: boolean,
    user?: User | null,
    search?: string | null
) => {
    const commonMenuItems = (user: User) => {
        const guestHasPreferredPaymentMethod = (user: GuestType) => {
            return !!user?.type_attributes?.preferred_payment_method
        }

        const guestHasNewCreditCard = (user: GuestType) => {
            return !!user?.type_attributes.has_new_credit_card
        }

        return [
            {
                id: 'account',
                icon: <UserIcon />,
                title: 'menu:account',
                show: true,
                subMenu: [
                    {
                        id: 'edit_profile',
                        to: ROUTE_EDIT_PROFILE,
                        title: 'menu:edit_profile',
                        show: true
                    },
                    {
                        id: 'manage_agent',
                        to: ROUTE_MANAGE_AGENT,
                        title: 'menu:manage_agent',
                        show: user?.type === UserRoleE.Host
                    },
                    {
                        id: 'billing-info',
                        to: ROUTE_BILLING_INFO,
                        title: 'menu:billing_info',
                        show: user?.type === USER_ROLE_GUEST
                    },
                    {
                        id: 'update_credit_card',
                        to: ROUTE_UPDATE_CREDIT_CARD,
                        title: 'menu:update_credit_card',
                        show: user?.type === UserRoleE.Guest && guestHasPreferredPaymentMethod(user as GuestType)
                    },
                    {
                        id: 'apply_transactions_credit_card',
                        to: ROUTE_UPDATE_RECURRING_TRANSACTION,
                        title: 'menu:apply_transactions_credit_card',
                        show: user?.type === UserRoleE.Guest && guestHasNewCreditCard(user as GuestType)
                    },
                    {
                        id: 'logout',
                        action: actions[ActionsListE.LOGOUT],
                        title: 'menu:logout',
                        show: true
                    },
                    {
                        id: 'delete_account',
                        action: actions[ActionsListE.DELETE_ACCOUNT],
                        to: ROUTE_DELETE_PROFILE,
                        title: 'menu:delete_account',
                        show: true
                    }
                ]
            },
            {
                id: 'about_us',
                icon: <AboutIcon />,
                title: 'menu:about_us',
                show: true,
                subMenu: [
                    {
                        id: 'faq',
                        to: user?.type === UserRoleE.Guest ? ROUTE_FAQ_GUEST : ROUTE_FAQ_HOST,
                        title: 'menu:faq',
                        show: true
                    },
                    {
                        id: 'what_is_morgi',
                        to: ROUTE_WHAT_IS_MORGI,
                        title: 'menu:what_is_morgi',
                        show: true
                    },
                    {
                        id: 'mission',
                        to: ROUTE_OUR_MISSION,
                        title: 'menu:mission',
                        show: true
                    }
                ]
            },
            {
                id: 'legal',
                icon: <DocumentIcon />,
                title: 'menu:legal',
                show: true,
                subMenu: [
                    {
                        id: 'tos',
                        to: user?.type === UserRoleE.Guest ? ROUTE_TOS_GUEST : ROUTE_TOS_HOST,
                        title: 'menu:tos',
                        show: true
                    },
                    {
                        id: 'community_guidelines',
                        to: ROUTE_COMMUNITY_GUIDELINES,
                        title: 'menu:community_guidelines',
                        show: true
                    },
                    {
                        id: 'privacy_policy',
                        to: ROUTE_PRIVACY_POLICY,
                        title: 'menu:privacy_policy',
                        show: true
                    },
                    {
                        id: 'cookie_policy',
                        to: ROUTE_COOKIE_POLICY,
                        title: 'menu:cookie_policy',
                        show: true
                    }
                ]
            },
            {
                id: 'telegram_notification_bot',
                action: actions[ActionsListE.TELEGRAM_NOTIFICATION_BOT],
                icon: <TelegramMonoIcon />,
                title: 'menu:telegram_notification_bot',
                show: user?.type === UserRoleE.Guest && !!user?.joined_telegram_bot_at
            },
            {
                id: 'customer_support',
                action: actions[ActionsListE.CUSTOMER_SUPPORT],
                icon: <CustomerSupportIcon />,
                title: 'menu:customer_support',
                show: true
            }
        ]
    }

    const representativeMenuItems = [
        {
            id: 'message_center',
            title: 'message_center:message_center',
            to: ROUTE_MESSAGE_CENTER,
            show: true
        },
        {
            id: 'edit_profile',
            to: ROUTE_EDIT_REPRESENTATIVE,
            title: 'menu:edit_profile',
            show: true
        },
        {
            id: 'logout',
            action: actions[ActionsListE.LOGOUT],
            title: 'menu:logout',
            show: true
        },
        {
            id: 'delete_account',
            action: actions[ActionsListE.DELETE_ACCOUNT],
            to: ROUTE_DELETE_PROFILE,
            title: 'menu:delete_account',
            show: true
        }
    ]

    const agentMenuItems = [
        {
            id: 'message_center',
            title: 'message_center:message_center',
            to: ROUTE_MESSAGE_CENTER,
            show: true
        },
        {
            id: 'manage_reps',
            title: 'menu:manage_reps',
            to: ROUTE_MANAGE_REPS,
            show: true
        },
        {
            id: 'payments',
            icon: <PaymentIcon />,
            title: 'menu:payments',
            show: true,
            subMenu: [
                {
                    id: 'how_you_want_to_get_paid',
                    to: ROUTE_HOST_PAYMENT_METHOD,
                    title: 'menu:how_you_want_to_get_paid',
                    show: true
                },
                {
                    id: 'transaction_history',
                    to: ROUTE_TRANSACTION_HISTORY,
                    title: 'menu:transaction_history',
                    show: true
                }
            ]
        },
        {
            id: 'logout',
            action: actions[ActionsListE.LOGOUT],
            title: 'menu:logout',
            show: true
        },
        {
            id: 'delete_account',
            action: actions[ActionsListE.DELETE_ACCOUNT],
            to: ROUTE_DELETE_PROFILE,
            title: 'menu:delete_account',
            show: true
        }
    ]
    const hostMenuItems = () => [
        {
            id: 'rookie_dashboard',
            icon: <LayoutIcon />,
            title: 'menu:rookie_dashboard',
            to: ROUTE_HOST_MY_PROFILE,
            show: true
        },
        {
            id: 'goals',
            icon: <img alt={'goal logo'} src={goalIcon} width={27} height={18} />,
            secondaryIcon: <img alt={'goal logo'} src={goalIconWhite} width={27} height={18} />,
            withGradientMenuItem: true,
            title: 'menu:goals',
            show: true,
            subMenu: [
                {
                    id: 'set_up_a_goal',
                    to: ROUTE_SELECT_GOAL_TYPE,
                    title: 'menu:set_up_a_goal',
                    show: true
                },
                {
                    id: 'my_goals',
                    to: ROUTE_MY_GOALS,
                    title: 'menu:my_goals',
                    show: true
                }
            ]
        },
        {
            id: 'approach_first',
            icon: <ApproachFirstIcon />,
            title: 'menu:approach_first',
            to: ROUTE_APPROACH_FIRST,
            show: true
        },
        {
            id: 'find_help',
            icon: <SupportIcon />,
            title: 'menu:find_help',
            show: user?.status === UserStatusesE.ACCEPTED,
            subMenu: [
                {
                    id: 'help_a_friend',
                    to: ROUTE_INVITE_FRIENDS,
                    title: 'menu:help_a_friend',
                    show: user?.status === UserStatusesE.ACCEPTED
                },
                {
                    id: 'refer_a_leader',
                    to: ROUTE_REFER_A_GUEST,
                    title: 'menu:refer_a_leader',
                    show: user?.status === UserStatusesE.ACCEPTED
                }
            ]
        },
        {
            id: 'morgis_taxi',
            icon: <ApproachFirstIcon />,
            title: 'menu:morgis_taxi',
            to: ROUTE_MY_MORGIS_TAXI,
            show: true
        },
        {
            id: 'payments',
            icon: <PaymentIcon />,
            title: 'menu:payments',
            show: true,
            subMenu: [
                {
                    id: 'how_you_want_to_get_paid',
                    to: ROUTE_HOST_PAYMENT_METHOD,
                    title: 'menu:how_you_want_to_get_paid',
                    show: true
                },
                {
                    id: 'active_leaders',
                    to: ROUTE_ACTIVE_GIFTING_GUESTS,
                    title: 'menu:active_leaders',
                    show: true
                },
                {
                    id: 'transaction_history',
                    to: ROUTE_TRANSACTION_HISTORY,
                    title: 'menu:transaction_history',
                    show: true
                }
            ]
        }
    ]

    const guestMenuItems = (user: GuestType) => [
        {
            id: 'all_rookies',
            icon: <LayoutIcon />,
            title: 'menu:all_rookies',
            to: ROUTE_HOME,
            show: true
        },
        {
            id: 'saved_rookies',
            icon: <HeartIcon />,
            title: 'menu:saved_rookies',
            to: ROUTE_SAVED_HOSTS,
            show: true
        },
        {
            id: 'goals',
            icon: <img alt={'goal logo'} src={goalIcon} width={27} height={18} />,
            secondaryIcon: <img alt={'goal logo'} src={goalIconWhite} width={27} height={18} />,
            title: 'menu:goals',
            withGradientMenuItem: true,
            show: true,
            subMenu: [
                {
                    id: 'what_are_goals',
                    to: ROUTE_WHAT_ARE_GOALS,
                    title: 'menu:what_are_goals',
                    show: true
                },
                {
                    id: 'goals_feed',
                    to: ROUTE_GOALS_MAIN_PAGE,
                    title: 'menu:goals_feed',
                    show: true
                },
                {
                    id: 'saved_goals',
                    to: ROUTE_SAVED_GOALS,
                    title: 'menu:saved_goals',
                    show: true
                },
                {
                    id: 'supported_goals',
                    to: ROUTE_SUPPORTED_GOALS,
                    title: 'menu:supported_goals',
                    show: true
                }
            ]
        },
        {
            id: 'my_moments',
            icon: <BlackDiamondWithStarIcon />,
            title: 'menu:my_moments',
            titleValue: `${user?.type_attributes?.memories_count}`,
            to: ROUTE_MY_MOMENTS_FEED,
            show: true
        },
        {
            id: 'whos_top_fan_you_are',
            icon: <WhosTopFanIcon />,
            title: 'menu:whos_top_fan_you_are',
            to: ROUTE_WHOS_TOP_FAN_YOU_ARE,
            show: user?.type_attributes?.top_fans_count >= 2
        },
        {
            id: 'invite_other_leaders_to_join',
            icon: <MenuInviteIcon />,
            title: 'menu:invite_other_leaders_to_join',
            to: ROUTE_INVITE_FRIENDS,
            show: true
        },
        {
            id: 'cool_features',
            icon: <MorgiAppreciateIcon />,
            title: 'menu:cool_features',
            show: true,
            subMenu: [
                {
                    id: 'todays_birthday',
                    show: true,
                    to: ROUTE_BIRTHDAYS,
                    title: 'menu:todays_birthday'
                }
            ]
        },
        {
            id: 'gifts_and_transactions',
            icon: <GiftIcon />,
            title: 'menu:gifts_and_transactions',
            show: true,
            subMenu: [
                {
                    id: 'active_monthly_gift',
                    show: true,
                    to: ROUTE_ACTIVE_GIFTS,
                    title: 'menu:active_monthly_gift'
                },
                {
                    id: 'my_micro_morgi',
                    show: true,
                    to: ROUTE_MY_MICROMORGI_BALANCE,
                    title: 'menu:my_micro_morgi'
                },
                {
                    id: 'transaction_history',
                    show: true,
                    to: ROUTE_TRANSACTION_HISTORY,
                    title: 'menu:transaction_history'
                }
            ]
        }
    ]
    const publicRole = LocalStorageManager.publicRole.get()
    const anonymHost = publicRole === OldRoles.rookie
    const anonymGuest = publicRole === OldRoles.leader

    const anonymousMenuItems = [
        {
            id: 'home',
            action: actions[ActionsListE.HOME],
            icon: <HomeIcon />,
            title: 'menu:home',
            show: true
        },
        {
            id: 'login_rookie',
            icon: <LoginIcon />,
            title: 'menu:login_rookie',
            to: `${ROUTE_LOGIN_HOST}${search}`,
            show: anonymHost
        },
        {
            id: 'login_leader',
            icon: <LoginIcon />,
            title: 'menu:login_leader',
            to: `${ROUTE_LOGIN_GUEST}${search}`,
            show: anonymGuest
        },
        {
            id: 'signup_as_rookie',
            icon: <UserIcon />,
            title: 'menu:signup_as_rookie',
            to: `${ROUTE_SIGNUP_HOST_SOCIAL}${search}`,
            show: anonymHost
        },
        {
            id: 'signup_as_leader',
            icon: <UserIcon />,
            title: 'menu:signup_as_leader',
            to: `${ROUTE_SIGNUP_GUEST_SOCIAL}${search}`,
            show: anonymGuest
        },
        {
            id: 'hosts_tour',
            icon: <HostsTourIcon />,
            title: 'menu:hosts_tour',
            show: anonymHost,
            subMenu: [
                {
                    id: 'hosts_tour_general',
                    to: HOSTS_TOUR_GENERAL_LINK,
                    title: 'menu:hosts_tour_general',
                    show: true
                },
                {
                    id: 'hosts_tour_athletes',
                    to: HOSTS_TOUR_ATHLETES_LINK,
                    title: 'menu:hosts_tour_athletes',
                    show: true
                },
                {
                    id: 'hosts_tour_artists_showbiz',
                    to: HOSTS_TOUR_ARTISTS_SHOWBIZ_LINK,
                    title: 'menu:hosts_tour_artists_showbiz',
                    show: true
                },
                {
                    id: 'hosts_tour_entrepreneurs',
                    to: HOSTS_TOUR_ENTREPRENEURS_LINK,
                    title: 'menu:hosts_tour_entrepreneurs',
                    show: true
                },
                {
                    id: 'hosts_tour_influencers',
                    to: HOSTS_TOUR_INFLUENCERS_LINK,
                    title: 'menu:hosts_tour_influencers',
                    show: true
                },
                {
                    id: 'hosts_tour_licensed_professionals',
                    to: HOSTS_TOUR_LICENSED_PROFESSIONALS_LINK,
                    title: 'menu:hosts_tour_licensed_professionals',
                    show: true
                },
                {
                    id: 'hosts_tour_models',
                    to: HOSTS_TOUR_MODELS_LINK,
                    title: 'menu:hosts_tour_models',
                    show: true
                },
                {
                    id: 'hosts_tour_teachers_coaches',
                    to: HOSTS_TOUR_TEACHERS_COACHES_LINK,
                    title: 'menu:hosts_tour_teachers_coaches',
                    show: true
                }
            ]
        },
        {
            id: 'faq',
            show: true,
            title: 'menu:faq',
            to: anonymHost ? ROUTE_FAQ_HOST : ROUTE_FAQ_GUEST
        },

        {
            id: 'what_is_morgi',
            show: true,
            title: 'menu:what_is_morgi',
            to: ROUTE_WHAT_IS_MORGI
        },
        {
            id: 'mission',
            show: true,
            title: 'menu:mission',
            to: ROUTE_OUR_MISSION
        },
        {
            id: 'about_us',
            show: true,
            title: 'menu:about_us',
            action: actions[ActionsListE.ABOUT_US]
        },
        {
            id: 'legal',
            icon: <DocumentIcon />,
            title: 'menu:legal',
            show: true,
            subMenu: [
                {
                    id: 'tos',
                    to: ROUTE_TOS_GUEST,
                    title: 'menu:tos',
                    show: true
                },
                {
                    id: 'community_guidelines',
                    to: ROUTE_COMMUNITY_GUIDELINES,
                    title: 'menu:community_guidelines',
                    show: true
                },
                {
                    id: 'privacy_policy',
                    to: ROUTE_PRIVACY_POLICY,
                    title: 'menu:privacy_policy',
                    show: true
                },
                {
                    id: 'cookie_policy',
                    to: ROUTE_COOKIE_POLICY,
                    title: 'menu:cookie_policy',
                    show: true
                }
            ]
        }
    ]

    if (user) {
        const menuItems = {
            [UserRoleE.Guest]: [...guestMenuItems(user as GuestType), ...commonMenuItems(user as GuestType)],
            [UserRoleE.Host]: [...hostMenuItems(), ...commonMenuItems(user as HostType)],
            [UserRoleE.Representative]: representativeMenuItems,
            [UserRoleE.Agent]: agentMenuItems
        }
        return menuItems[user?.type]
    } else if (!loggedIn) {
        return anonymousMenuItems
    } else {
        return []
    }
}

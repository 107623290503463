import {carouselStyles, StyledFeaturedHostRoot, StyledSplide, StyledSplideSlide, StyledSplideTrack} from './style'
import {useTheme} from 'styled-components'
import {MorgisTaxiHostCard} from '@/features/morgis-taxi/components/morgis-taxi-host-card/MorgisTaxiHostCard'
import {StyledSectionTitle} from '../host-profile-section/style'
import {useTranslation} from 'react-i18next'
import {FeaturedHostType, HostListTypesE} from '@/features/morgis-taxi/types'
import {CarouselOptionsType} from '@/components/ui/carousel/Carousel'

export const HostProfileFeaturedHostSection = ({
    firstName,
    featuredHosts
}: {
    firstName?: string
    featuredHosts: FeaturedHostType[]
}) => {
    const {t} = useTranslation()
    const theme = useTheme()

    const options: CarouselOptionsType = {
        rewind: true,
        arrows: false,
        gap: 10,
        direction: theme.direction
    }

    return (
        <div>
            <StyledSectionTitle>
                <p>{t('common:featured_host', {HOST_NAME: firstName})}</p>
            </StyledSectionTitle>
            <StyledFeaturedHostRoot>
                <StyledSplide options={options} hasTrack={false} $carouselStyles={() => carouselStyles({theme})}>
                    <StyledSplideTrack>
                        {featuredHosts?.map(hostProfile => (
                            <StyledSplideSlide key={hostProfile.id} style={{padding: '16px 3px'}}>
                                <MorgisTaxiHostCard
                                    hostProfile={hostProfile}
                                    hostUsername={hostProfile?.username}
                                    hostName={hostProfile?.full_name}
                                    birthDate={hostProfile?.birth_date || undefined}
                                    pathName={
                                        hostProfile?.path ? hostProfile?.path?.name : hostProfile?.expertise?.key_name
                                    }
                                    featuredHostId={hostProfile?.featured_rookie_id}
                                    photos={hostProfile?.photos}
                                    type={HostListTypesE.PROFILE}
                                    description={hostProfile?.description}
                                    inSlider={true}
                                />
                            </StyledSplideSlide>
                        ))}
                    </StyledSplideTrack>
                </StyledSplide>
            </StyledFeaturedHostRoot>
        </div>
    )
}

HostProfileFeaturedHostSection.displayName = 'HostProfileFeaturedHostSection'

import styled, {css} from 'styled-components'

export const StyledPlanOfSubscription = styled.div<{isSelected: boolean; disabled: boolean}>`
    ${({isSelected, disabled, theme: {palette, spacing}}) => css`
        display: flex;
        padding: ${spacing * 2}px ${spacing}px;
        flex-direction: column;
        align-items: center;
        gap: ${spacing}px;
        position: relative;
        border-bottom: 0.5px solid rgba(0, 6, 61, 0.45);
        ${!disabled
            ? css`
                  cursor: pointer;
                  &:hover {
                      border-radius: 4px;
                      background: rgba(134, 75, 210, 0.2);
                      transition: all 0.5s;
                  }
              `
            : css`
                  opacity: 0.5;
              `}

        ${isSelected &&
        css`
            border-radius: 4px;
            border-color: ${palette.primary[900]};
            border-bottom: 0.5px solid;
            border-left: 0.5px solid;
            border-right: 0.5px solid;
            background: rgba(134, 75, 210, 0.2);
            transition: all 0.5s;
        `}
    `}
`
export const StyledPlanOfSubscriptionContent = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        width: 342px;
        height: 79px;
        align-items: center;
        padding-right: ${spacing}px;
    `}
`
export const StyledPlanOfSubscriptionPartOfContent = styled.div`
    ${({theme: {spacing, palette, typography}}) => css`
        display: flex;
        justify-content: center;
        flex: 1;
        flex-direction: column;
        gap: ${spacing}px;
        text-wrap: pretty;
        & div {
            display: flex;
            align-items: center;
            gap: ${spacing}px;
            & h1 {
                ${typography.text2Xl}
                color: ${palette.neutral.black};
                font-weight: 700;
                letter-spacing: -1.3px;
            }
            & h2 {
                ${typography.textSm}
                color: ${palette.primary[900]};
                text-align: center;
                line-height: 140%;
                font-weight: 700;
            }
            & p {
                color: ${palette.primary[900]};
                overflow-wrap: anywhere;
                line-height: 140%; /* 19.6px */
            }
        }
        & span {
            ${typography.textSm}
            color: ${palette.primary[900]};
            text-align: center;
            line-height: 140%;
            opacity: 0.5;
        }
    `}
`
export const StyledMicromorgiContainer = styled.div`
    justify-content: center;
`

import {BackIcon, CloseIcon} from '@/assets/icons/icons'
import ConfettiAnimation from '@/components/commons/confetti-animation/ConfettiAnimation'
import {useLockBodyScroll} from '@/hooks/useLockBodyScroll'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import {StyledHeader, StyledModal, StyledOverlay} from '@components/ui/modal/style.ts'
import {AnimationControls, Target, TargetAndTransition, VariantLabels} from 'framer-motion'
import {DefaultNamespace} from 'i18next'
import {FC, ReactNode, Ref} from 'react'
import {createPortal} from 'react-dom'
import {DefaultTheme, FlattenSimpleInterpolation, useTheme} from 'styled-components'

interface ModalProps {
    children: ReactNode
    subHeader?: ReactNode
    onClose?: () => void
    overlayVariant?: 'filled' | 'gradient'
    forwardRef?: Ref<HTMLDivElement>
    animation?: {
        initial?: boolean | Target | VariantLabels
        animate?: AnimationControls | TargetAndTransition | VariantLabels | boolean
        exit?: TargetAndTransition | VariantLabels
    }
    lockBodyScroll?: boolean
    fullScreen?: boolean
    hideCloseButton?: boolean
    width?: string
    height?: string
    minWidth?: string
    maxWidth?: string
    minHeight?: string
    maxHeight?: string
    onBack?: () => void | null
    title?: string | DefaultNamespace | ReactNode
    modalStyles?: (theme: DefaultTheme) => FlattenSimpleInterpolation
    className?: string
    showConfettiAnimation?: boolean
    id?: string
    closeIconColor?: string
    isCloseAfterClickOutside?: boolean
}

export const Modal: FC<ModalProps> = ({
    children,
    onClose,
    fullScreen = false,
    width,
    title,
    animation,
    forwardRef,
    height = 'auto',
    minWidth,
    maxWidth,
    minHeight,
    maxHeight,
    lockBodyScroll = true,
    hideCloseButton = false,
    subHeader,
    onBack = null,
    modalStyles,
    showConfettiAnimation = false,
    className = '',
    id,
    closeIconColor,
    isCloseAfterClickOutside = true
}) => {
    const {height: vh} = useWindowDimensions()
    useLockBodyScroll({enable: lockBodyScroll})
    const theme = useTheme()
    return createPortal(
        <>
            <span id={`modal_with_${Date.now()}`} />
            <StyledOverlay onClick={isCloseAfterClickOutside ? onClose : undefined} />
            <StyledModal
                as={!animation ? 'div' : undefined}
                {...animation}
                ref={forwardRef}
                fullScreen={fullScreen}
                width={width}
                height={height}
                minWidth={minWidth}
                maxWidth={maxWidth}
                minHeight={minHeight}
                maxHeight={maxHeight}
                modalStyles={modalStyles}
                withSubHeader={!!subHeader}
                vh={vh}
                className={className}
                id={id}
            >
                {showConfettiAnimation && <ConfettiAnimation isModal />}
                <StyledHeader dir={theme.direction}>
                    {onBack && (
                        <span>
                            <BackIcon onClick={onBack} />
                        </span>
                    )}
                    {title && <h4>{title}</h4>}

                    {!hideCloseButton && <CloseIcon data-color onClick={onClose} fill={closeIconColor} />}
                </StyledHeader>
                {subHeader}
                {children}
            </StyledModal>
        </>,
        document.body
    )
}

Modal.displayName = 'Modal'

import {
    ActivitiesIcon,
    FilterAgeGenderIcon,
    FilterCountryLanguageIcon,
    FilterFilledChatTopics,
    FilterSpecialHostIcon
} from '@/assets/icons/icons'
import {HostFiltersType} from '../types'
import {FilterItem} from '@/components/commons/selected-filters-list/SelectedFiltersList'

export const remapHostActiveFilters = (filters: HostFiltersType): FilterItem[] => {
    const remappedActivities =
        filters?.activities.map(item => ({
            label: item.name,
            id: item.id,
            icon: <ActivitiesIcon />,
            key_name: item.key_name
        })) || []

    const remappedChatTopics =
        filters?.chatTopics.map(item => ({
            label: item.name,
            id: item.id,
            icon: <FilterFilledChatTopics />,
            key_name: item.key_name
        })) || []

    const remappedCountries =
        filters?.countries.map(item => ({
            label: item.name,
            id: item.id,
            icon: <FilterCountryLanguageIcon />
        })) || []

    const remappedLanguages =
        filters?.languages.map(item => ({
            label: item.name,
            id: item.id,
            icon: <FilterCountryLanguageIcon />
        })) || []

    const remappedSpecialHostCategory =
        filters?.specialHostCategory?.map(item => ({
            label: item.label,
            id: item.value,
            icon: <FilterSpecialHostIcon />
        })) || []

    const remappedGenders =
        filters?.genders?.map(item => ({
            label: item.name,
            id: item.id,
            icon: <FilterAgeGenderIcon />
        })) || []

    return [
        ...remappedChatTopics,
        ...remappedGenders,
        ...remappedCountries,
        ...remappedLanguages,
        ...remappedActivities,
        ...remappedSpecialHostCategory
    ]
}

export const getActiveFiltersIds = (filters: HostFiltersType) => {
    if (!filters) return {}

    const chat_topics_ids = filters.chatTopics.length ? filters.chatTopics.map(item => item.id) : undefined
    const favorite_activities_ids = filters.activities.length ? filters.activities.map(item => item.id) : undefined
    const genders_ids = filters?.genders?.length ? filters.genders.map(item => item.id) : undefined
    const languages_ids = filters.languages.length ? filters.languages.map(item => item.id) : undefined
    const countries_ids = filters.countries.length ? filters.countries.map(item => item.id) : undefined
    const expertises_ids = filters?.specialHostCategory?.length
        ? filters?.specialHostCategory?.map(item => item.value)
        : undefined

    return {
        chat_topics_ids,
        favorite_activities_ids,
        genders_ids,
        languages_ids,
        countries_ids,
        from_age: filters.fromAge || undefined,
        to_age: filters.toAge || undefined,
        expertises_ids
    }
}

import {useGetAgentHosts} from '@/features/agent/queries/useGetAgentHosts'
import {useMe} from '@/features/authentication/queries/useMe'
import {useGetRepresentativeHosts} from '@/features/representative/queries/useGetRepresentativeHosts'
import {useRootStore} from '@/store'
import {selectChatStore} from '@/store/selectors'
import {UserRoleE} from '@/types'
import {LocalStorageManager} from '@/utilities/localStorage'
import {useMemo} from 'react'

export const useGetRepresentedUser = (enabled = true) => {
    const {data: user} = useMe(enabled)
    const {needReInitAfterChangedRepresentedHost} = useRootStore(selectChatStore)

    const isRepresentative = user?.type === UserRoleE.Representative
    const isAgent = user?.type === UserRoleE.Agent

    const {data: agentHosts, isLoading: isLoadingAgentHosts} = useGetAgentHosts(isAgent && enabled)
    const {data: representativeHosts, isLoading: isLoadingRepresentativeHosts} = useGetRepresentativeHosts(
        isRepresentative && enabled
    )
    const agentAndRepsHosts = agentHosts ?? representativeHosts ?? []

    const representedUserId = useMemo(() => {
        return LocalStorageManager.chatUserId.get() ?? agentAndRepsHosts[0]?.id
    }, [needReInitAfterChangedRepresentedHost, agentAndRepsHosts])

    const representedUser = useMemo(() => {
        const correctUser = agentAndRepsHosts.find(item => item?.id === +representedUserId)
        if (correctUser) {
            return correctUser
        }
    }, [representedUserId, agentAndRepsHosts, needReInitAfterChangedRepresentedHost])

    const isLoading = isLoadingAgentHosts || isLoadingRepresentativeHosts

    return {representedUser, isLoading, agentAndRepsHosts, representedUserId}
}

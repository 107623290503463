export type Normalized<K extends string | number, PK extends string | number, T extends {[key in PK]: K}> = Record<
    T[PK],
    T
>
export type RequiredPartial<T, K extends keyof T> = Partial<T> & Pick<T, K>

export type Percentage = `$= {number}%`

export enum UserRoleE {
    Host = 'rookie',
    Guest = 'leader',
    Representative = 'representative',
    Agent = 'agent'
}

export enum MediaTypeE {
    VIDEO = 'video',
    IMAGE = 'image',
    PHOTO = 'photo',
    DOCUMENT = 'document',
    AUDIO = 'audio'
}

export enum DirectionsE {
    LTR = 'ltr',
    RTL = 'rtl'
}

export const KNOWN_COOKIES = {
    mainDomain: '.morgis.com',
    authCookie: 'access_token'
}

import styled, {DefaultTheme, css} from 'styled-components'
import * as RPopover from '@radix-ui/react-popover'
import { Link } from 'react-router-dom'

export const StyledStatusBox = styled.div`
    ${({withTooltip, theme: {spacing}}: {withTooltip: boolean; theme: DefaultTheme}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing}px;
        cursor: ${withTooltip ? 'pointer' : 'default'};
    `}
`
export const StyledStatus = styled.div<{
    isLight?: boolean
    backgroundColor?: string
}>`
    ${({isLight, backgroundColor, theme: {typography, spacing, palette}}) => css`
        display: flex;
        align-items: center;
        background: ${backgroundColor};
        padding: 1px ${spacing / 1.5}px;
        ${typography.textXs};
        font-weight: 600;
        line-height: 15px;
        border-radius: 10px;
        color: ${isLight ? palette.neutral.white : palette.primary['900']};
        gap: ${spacing / 2}px;
        svg {
            width: 13px;
            height: 13px;
        }
    `}
`

export const StyledTooltipContent = styled.div`
    ${({theme: {spacing, palette, typography, shadows}}) => css`
        max-width: 230px;
        text-align: center;
        font-weight: 400;
        ${typography.textXs};
        line-height: 16px;
        padding: ${spacing * 2}px ${spacing * 3}px;
        letter-spacing: 0;
        color: ${palette.primary['275']};
        box-shadow: ${shadows['8xl']};
    `}
`

export const StyledPopoverArrow = styled(RPopover.Arrow)`
    ${({theme: {palette}}) => css`
        fill: ${palette.neutral.white};
    `}
`

export const StyledLink = styled(Link)`
    text-decoration: underline;
    cursor: pointer;
`

import {Animation} from '@/components/ui/animation/Animation'
import {Modal} from '@/components/ui/modal/Modal'
import Alert from '@assets/animations/Alert-1.json'
import {GiftContent} from './GiftContent'
import {BoughtMicromorgiContent} from './BoughtMicromorgiContent'
import {StyledAnimation} from '../common/error-modal/style'
import {ModalContainer} from './style'
import {useRootStore} from '@/store'
import {selectAnonRetryModal, selectChoosePaymentsMethodModal, selectErrorModal} from '@/store/selectors'
import {useMe} from '../authentication/queries/useMe'
import {GuestType} from '../authentication/types'
import {useGetAvoidedTransaction} from './queries/useGetAvoidedTransaction'
import {useRetryTransaction} from './queries/useRetryTransaction'
import {useEffect, useState} from 'react'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {USER_ROLE_GUEST} from '@/utilities/constants/user'
import {Trans, useTranslation} from 'react-i18next'
import {TransactionsE} from './services/anonTransaction.schema'
import {MixpanelEventNameE} from '@/integrations/mixpanel/types'
import useMixPanel from '@/integrations/mixpanel/hooks/useMixpanel'
import {PaymentMethodUrls} from '../guest-payments/types'
import {SessionStorageManager} from '@/utilities/sessionStorage'
import { GiftModalSuccess } from '../profile/components/membersip-plans/SuccessModal'
import { AnonTransactionSubscriptionPackageType } from './services/types'

export const AnonRetryModal = () => {
    const {t} = useTranslation()
    const {data: userData} = useMe()
    const user = userData?.type === USER_ROLE_GUEST ? (userData as GuestType) : null
    const mixpanel = useMixPanel()

    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [sendRetryTransaction, setRetryTransaction] = useState(false)
    const transactionId = user?.type_attributes.anonymous_transaction_to_retry_id
    const {closeModal} = useRootStore(selectAnonRetryModal)
    const {openErrorModal} = useRootStore(selectErrorModal)

    const {openModal: openChoosePaymentsMethodModal} = useRootStore(selectChoosePaymentsMethodModal)
    const {data, isLoading: isLoadingTransaction} = useGetAvoidedTransaction(transactionId)
    const {
        data: retryTransactionData,
        isLoading,
        error: retryTransactionError
    } = useRetryTransaction({id: transactionId, enabled: sendRetryTransaction})

    const onRetryTransaction = () => {
        setRetryTransaction(true);
    }

    useEffect(() => {
        if (retryTransactionError) {
            if (retryTransactionError?.response?.status === 303) {
                openChoosePaymentsMethodModal({
                    paymentMethodUrls: (retryTransactionError.response.data as PaymentMethodUrls) ?? null,
                    description: 'choose_payment_method:description_gift',
                    currencyAmount: data?.amount_micromorgi || undefined,
                    rookieName: data?.rookie?.full_name,
                    isMonthlySupport: true
                })
                mixpanel?.trackEvent(MixpanelEventNameE.CheckoutContinue)
            } else {
                openErrorModal({message: retryTransactionError.message || null})
            }
        }
        if(data?.type === TransactionsE.gift && retryTransactionData?.message === 'Gift successfully created!'){
            setShowSuccessModal(true)
        }
    }, [retryTransactionData, retryTransactionError])

    return (
        <>
            <Modal
                onClose={() => {
                    SessionStorageManager.checkAnonTransactionModal.set(true)
                    closeModal()
                }}
                title={t('anon_retry_modal:title')}
            >
                <ModalContainer>
                    {isLoading && <Spinner />}
                    <div>
                        <Trans t={t} i18nKey={'anon_retry_modal:void_transaction'} components={{br: <br />}} />
                    </div>
                    <StyledAnimation>
                        <Animation animationData={Alert} />
                    </StyledAnimation>
                    <div>{t('anon_retry_modal:confirm')}</div>
                    {isLoadingTransaction ? (
                        <Spinner />
                    ) : (
                        <div>
                            {data?.type === TransactionsE.gift ? (
                                <GiftContent onRetryTransaction={onRetryTransaction} data={data} />
                            ) : (
                                <BoughtMicromorgiContent onRetryTransaction={onRetryTransaction} data={data} />
                            )}
                        </div>
                    )}
                </ModalContainer>
            </Modal>
            {showSuccessModal && (
                 <GiftModalSuccess
                 selectedMembership={data?.subscription_package as AnonTransactionSubscriptionPackageType | null}
                 hostID={data?.rookie?.id}
                 handleCloseModal={() => {setShowSuccessModal(false); closeModal(); SessionStorageManager.checkAnonTransactionModal.set(true)}}
             />
            )}
        </>
    )
}

import styled, {FlattenSimpleInterpolation, css, DefaultTheme} from 'styled-components'

export const StyledListWrapper = styled.div`
    ${({theme: {mediaQueries}, isInFeed}: {isInFeed?: boolean; theme: DefaultTheme}) => css`
        display: flex;
        align-items: center;
        gap: ${isInFeed ? '3px' : '5px'};
        ${mediaQueries.m} {
            gap: 5px;
        }
    `}
`
export const StyledIcon = styled.span`
    ${({theme: {mediaQueries}, isInFeed}: {isInFeed?: boolean; theme: DefaultTheme}) => css`
        & > svg {
            width: ${isInFeed ? '16px' : '24px'};
            height: ${isInFeed ? '16px' : '24px'};
        }
        display: flex;
        align-items: center;
        justify-content: center;
        ${mediaQueries.m} {
            min-width: 25px;
            & > svg {
                width: 24px;
                height: 24px;
            }
        }
    `}
`

export const StyledTitle = styled.span`
     ${({theme: {typography, mediaQueries, palette}, isInFeed}: {isInFeed?: boolean; theme: DefaultTheme}) => css`
        margin-right: ${isInFeed ? "0px" : "4px"};
        ${typography.textXs};
        font-weight: ${isInFeed ? 500 : 700};
        color: ${palette.primary[200]};
        ${mediaQueries.m} {
            ${typography.textSm};
        }
     `}
`

export const StyledTopicItem = styled.span`
    margin-right: 2px;
`

export const StyledTopicsList = styled.div`
    ${({
        theme,
        topicsListStyles
    }: {
        topicsListStyles?: (theme: DefaultTheme) => FlattenSimpleInterpolation
        theme: DefaultTheme
    }) => css`
        ${theme.truncateText}
        ${topicsListStyles && topicsListStyles(theme)};
    `}
`

import {CloseIcon} from '@/assets/icons/icons'
import morgisLogo from '@/assets/images/morgisLogo3x.png'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {envVars} from '@/envVars'
import {useMe} from '@/features/authentication/queries/useMe'
import {useLockBodyScroll} from '@/hooks/useLockBodyScroll'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import {useRootStore} from '@/store'
import {selectAboutUsModal, selectLogoutModal} from '@/store/selectors'
import {UserRoleE} from '@/types'
import {
    ROUTE_FAQ_GUEST,
    ROUTE_FAQ_HOST,
    ROUTE_HOME,
    ROUTE_LOGIN_GUEST,
    ROUTE_LOGIN_HOST,
    ROUTE_SIGNUP_GUEST,
    ROUTE_SIGNUP_GUEST_SOCIAL,
    ROUTE_SIGNUP_HOST,
    ROUTE_SIGNUP_HOST_SOCIAL
} from '@/utilities/constants/routeNames'
import {getPublicUrl} from '@/utilities/helpers'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {TelegramCardGuest} from '../telegram-card-guest/TelegramCardGuest'
import {TelegramCardHost} from '../telegram-card-host/TelegramCardHost'
import {MenuItem} from './menu-item/MenuItem'
import Menu from './menu/Menu'
import {ActionsListE, ItemMenu, getCorrectMenuItemsList} from './menuItemsList'
import {SelectAppsLanguage} from './select-apps-language/SelectAppsLanguage'
import {StyledButton, StyledHeader, StyledLogo, StyledOverlay, StyledRoot} from './style'
import {SubMenu} from './sub-menu/SubMenu'
import useMixPanel from '@/integrations/mixpanel/hooks/useMixpanel'
import {MixpanelEventNameE} from '@/integrations/mixpanel/types'
import {customerCareGuestLink, customerCareHostLink, telegramBaseURL} from '@/utilities/constants/misc'
import {LocalStorageManager} from '@/utilities/localStorage'
import {OldRoles} from '@/features/constants/constants'

const redirectMap: {[path: string]: string} = {
    [ROUTE_LOGIN_HOST]: ROUTE_LOGIN_GUEST,
    [ROUTE_LOGIN_GUEST]: ROUTE_LOGIN_HOST,
    [ROUTE_SIGNUP_HOST]: ROUTE_SIGNUP_GUEST_SOCIAL,
    [ROUTE_SIGNUP_GUEST_SOCIAL]: ROUTE_SIGNUP_HOST_SOCIAL,
    [ROUTE_SIGNUP_HOST_SOCIAL]: ROUTE_SIGNUP_GUEST_SOCIAL,
    [ROUTE_SIGNUP_GUEST]: ROUTE_SIGNUP_HOST,
    [ROUTE_FAQ_HOST]: ROUTE_FAQ_GUEST,
    [ROUTE_FAQ_GUEST]: ROUTE_FAQ_HOST
}

export const Nav = ({onClose, isOpen}: {onClose: () => void; isOpen: boolean}) => {
    const {height} = useWindowDimensions()
    const {data: user, isLoading, loggedIn} = useMe()
    const {openModal} = useRootStore(selectLogoutModal)
    const {openModal: openAboutUsModal} = useRootStore(selectAboutUsModal)
    const {t} = useTranslation()
    const mixpanel = useMixPanel()
    const {search} = useLocation()
    const publicRole = LocalStorageManager.publicRole.get()
    const isPublicHost = publicRole === OldRoles.rookie
    const navigate = useNavigate()
    useLockBodyScroll({enable: isOpen})

    const handleClickDeleteProfile = () => {
        mixpanel?.trackEvent(MixpanelEventNameE.DeleteAccountClick)
    }

    const changeAnonymRole = () => {
        LocalStorageManager.publicRole.set(isPublicHost ? OldRoles.leader : OldRoles.rookie)
        const path = redirectMap[location.pathname]
        path ? navigate(path) : location.reload()
    }
    useLockBodyScroll({enable: isOpen})

    const handleCustomerSupport = () => {
        if (user?.type === UserRoleE.Host) {
            window.open(customerCareHostLink, '_blank')
        } else {
            window.open(customerCareGuestLink, '_blank')
        }
    }

    const actions = {
        [ActionsListE.LOGOUT]: () => openModal(true),
        [ActionsListE.DELETE_ACCOUNT]: () => handleClickDeleteProfile(),
        [ActionsListE.TELEGRAM_NOTIFICATION_BOT]: () =>
            window.open(`${telegramBaseURL}${envVars.VITE_APP_TELEGRAM_BOT_NAME}`),
        [ActionsListE.CUSTOMER_SUPPORT]: handleCustomerSupport,
        [ActionsListE.HOME]: () => window.open(getPublicUrl(isPublicHost ? '/host' : undefined), '_self'),
        [ActionsListE.ABOUT_US]: () => openAboutUsModal({})
    }
    const itemsList: ItemMenu[] = getCorrectMenuItemsList(actions, loggedIn, user, search)
    return (
        <>
            <StyledOverlay isOpen={isOpen} onClick={onClose} />
            <StyledRoot isOpen={isOpen} vh={height}>
                <StyledHeader>
                    <CloseIcon onClick={onClose} />

                    {user ? (
                        <Link style={{textDecoration: 'none'}} to={ROUTE_HOME} onClick={onClose}>
                            <StyledLogo src={morgisLogo} />
                        </Link>
                    ) : (
                        <a
                            style={{textDecoration: 'none'}}
                            href={getPublicUrl(isPublicHost ? '/host' : undefined)}
                            onClick={onClose}
                        >
                            <StyledLogo src={morgisLogo} />
                        </a>
                    )}

                    <SelectAppsLanguage />
                </StyledHeader>

                <Menu isOpen={isOpen}>
                    {isLoading ? (
                        <Spinner />
                    ) : (
                        itemsList.map(item =>
                            item.show ? (
                                <React.Fragment key={item.id}>
                                    {item.subMenu ? (
                                        <SubMenu item={item} onClose={onClose} isMenuOpen={isOpen} />
                                    ) : (
                                        <MenuItem item={item} onClose={onClose} />
                                    )}
                                </React.Fragment>
                            ) : null
                        )
                    )}

                    {!!user && user?.type === UserRoleE.Guest && (
                        <TelegramCardGuest
                            description={t('menu:telegram_leader_bot')}
                            joinedTelegramBot={!!user?.joined_telegram_bot_at}
                            telegramToken={user?.telegram_bot_token}
                        />
                    )}

                    {!!user && user?.type === UserRoleE.Host && (
                        <TelegramCardHost onClickHandler={onClose} joinedTelegramBot={!!user?.joined_telegram_bot_at} />
                    )}
                </Menu>
                {!user && (
                    <StyledButton variant="primaryOutlined" onClick={changeAnonymRole}>
                        {t(isPublicHost ? 'menu:are_you_a_guest' : 'menu:are_you_a_host')}
                    </StyledButton>
                )}
            </StyledRoot>
        </>
    )
}

import axios from '@/clientAPI.ts'
import {TransactionStatus, TransactionType, TransactionsResponse} from '@/features/transactions/types.ts'
import {TransactionResponseSchema} from './transactions.schema'
import {TransactionPropsType} from '../queries/useGetTransactions'

export const TRANSACTIONS_API = {
    getTransactions: async ({
        page,
        limit,
        month,
        startAt,
        endAt,
        show_payments,
        represented_rookie_id
    }: TransactionPropsType): Promise<TransactionsResponse> => {
        const url = `/transactions`
        const {data} = await axios.get(url, {
            params: {limit, page, month, start_at: startAt, end_at: endAt, show_payments, represented_rookie_id}
        })
        return data
    },
    getGuestGiftingTransactions: async ({guestId}: {guestId: string}): Promise<TransactionsResponse> => {
        const url = `/transactions/morgi/${guestId}`
        const {data} = await axios.get(url)
        return data
    },
    getGoalsDetailedTransactions: async ({
        page,
        limit,
        goalId
    }: {
        page: string
        limit: number
        goalId: string
    }): Promise<TransactionsResponse> => {
        const url = `/goals/${goalId}/transactions`
        const {data} = await axios.get(url, {params: {limit, page, goalId}})
        return TransactionResponseSchema.parse(data)
    },
    getCurrentTransaction: async ({
        referralInternalId
    }: {
        referralInternalId: string | null
    }): Promise<TransactionType> => {
        const url = `/transactions/internal-id/${referralInternalId}`
        const {data} = await axios.get(url)
        return data
    },
    checkTransactionStatus: async (): Promise<TransactionStatus> => {
        const {data} = await axios.get(`/profile/latest-payment`)
        return data
    }
}

import {useQuery} from '@tanstack/react-query'
import {MEMBERSHIP_PLANS_API} from '../services/membershipPlans.http'
import {QUERY_KEYS} from './keys'

export const useGetMembershipPlans = (rookieId?: string) => {
    return useQuery({
        queryKey: [QUERY_KEYS.membership_plans, rookieId],
        queryFn: () => MEMBERSHIP_PLANS_API.getMembershipPlans({rookieId: String(rookieId)}),
        enabled: !!rookieId
    })
}

import styled, {css} from 'styled-components'

export const StyledFeedFilterTitle = styled.h2`
    ${({theme: {spacing, palette, typography}}) => css`
        ${typography.textXXl}
        color: ${palette.primary[900]};
        text-align: center;
        font-weight: 400;
        letter-spacing: -1.1px;
        padding-top: ${spacing * 2}px;
    `}
`

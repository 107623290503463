import styled, {css} from 'styled-components'

export const StyledFeedBarWrapper = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 2.5}px;
        ${mediaQueries.m} {
            gap: ${spacing * 4}px;
        }
    `}
`

export const StyledFeedBarRoot = styled.div<{isActive?: boolean}>`
    ${({isActive = true, theme: {mediaQueries, spacing}}) => css`
        max-height: ${isActive ? '100px' : '0'};
        height: fit-content;
        overflow: hidden;
        transition: ${'max-height 1s'};
        margin: ${spacing}px 0px;
        display: flex;
        justify-content: space-between;
        padding: 0px ${spacing * 1.5}px;
        ${mediaQueries.m} {
            padding: 0;
            width: 100%;
            max-width: 410px;
        }
    `}
`

export const StyledTab = styled.div`
    ${({theme: {spacing}}) => css`
        gap: ${spacing}px;
        cursor: pointer;
        display: flex;
        width: 78px;
        align-items: center;
        text-align: center;
        flex-direction: column;
    `}
`

export const StyledIcon = styled.div<{isActive?: boolean}>`
    ${({theme: {palette, flexUtils}, isActive = false}) => css`
        ${flexUtils.centered}
        width: 30px;
        height: 30px;
        ${!isActive &&
        css`
            & svg {
                & > path {
                    fill: ${palette.primary['900']};
                }
                & > g > path {
                    fill: ${palette.primary['900']};
                }
            }
        `}
    `}
`

export const StyledActiveFilters = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing}px;
        align-items: center;
        align-self: end;
        max-width: 340px;
    `}
`

export const StyledSelectedFilters = styled.div`
    ${({theme: {mediaQueries}}) => css`
        width: 100vw;
        ${mediaQueries.m} {
            padding: 0;
            width: 100%;
            max-width: 410px;
        }
    `}
`

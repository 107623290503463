export const MUTATION_KEYS = {
    user: 'user',
    signup_host: 'signup-host',
    signup_guest: 'signup-guest',
    attach_social_user: 'attach_social_user',
    login_social_user: 'login-social-user'
}

export const QUERY_KEYS = {
    user: 'user',
    activate_account: 'activate-account',
    genders: 'genders',
    localize: 'localize',
    verify_email: 'verify-email',
    check_status_video_file: 'check-status-video-file'
}

import {Button} from '@/components/ui/button/Button'
import styled, {css} from 'styled-components'

export const StyledPauseConnectionModalContainer = styled.div`
    ${({theme: {flexUtils, spacing, typography}}) => css`
        ${flexUtils.centered}
        flex-direction: column;
        padding: ${spacing * 3}px;
        gap: ${spacing * 3}px;
        & strong {
            ${typography.textMd}
            text-align: center;
            line-height: 20px;
            font-weight: bold;
        }
    `}
`
export const StyledPauseConnectionModalTitle = styled.div`
    ${({theme: {spacing, typography}}) => css`
        ${typography.textMd}
        padding: 0 ${spacing}px;
        text-align: center;
        font-weight: 400;
        line-height: 20px;
    `}
`
export const StyledPauseConnectionModalDescription = styled.div<{weight?: number}>`
    ${({weight}) => css`
        text-align: center;
        font-weight: ${!weight ? 400 : weight};
        line-height: 20px;
    `}
`
export const StyledButton = styled(Button)`
    ${({theme: {typography}}) => css`
        ${typography.textMd}
        font-weight: 600;
    `}
`
export const StyledPauseConnectionImage = styled.img`
    ${() => css`
        max-width: 250px;
    `}
`

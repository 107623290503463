import ResponsiveVideo from '@/components/commons/responsive-video/ResponsiveVideo'
import {HostPartialType} from '@/features/authentication/types'
import {DirectionsE, MediaTypeE} from '@/types'
import '@splidejs/react-splide/css'
import {ReactNode} from 'react'
import {DefaultTheme, FlattenSimpleInterpolation, useTheme} from 'styled-components'
import WaveSurferPlayer from '../audioPlayer/AudioPlayer'
import VideoPlayer from '../video-player/VideoPlayer'
import {
    StyledArrowsWrapper,
    StyledAudioContainer,
    StyledImage,
    StyledOverlay,
    StyledSplide,
    StyledSplideSlide,
    StyledSplideTrack
} from './style'

//      - remap photos or videos to media array like
//           const photosMap = photos?.map((photo) => {
//             return {
//                 type: 'image',
//                 url: photo?.url,
//             }
//         })

type MediaType =
    | {type: MediaTypeE; url: string | null | undefined; options?: Plyr.Options; extraPayload?: unknown}
    | undefined

export type CarouselOptionsType = {
    type?: string
    arrows?: boolean
    rewind?: boolean
    pagination?: boolean
    gap?: number
    start?: number //start index
    perPage?: number
    padding?: {right?: string; top?: string; left?: string; bottom?: string} | string
    drag?: boolean | 'free'
    heightRatio?: number
    direction?: DirectionsE | 'ttb'
    perMove?: number
    flickMaxPages?: number
    waitForTransition?: boolean
    flickPower?: number
    easing?: string
}

export type SplideType = {
    root: HTMLElement
    go: (index: number) => void
}

type CarouselType = {
    children?: ReactNode
    media: MediaType[]
    customOptions?: CarouselOptionsType
    videoOptions?: Plyr.Options | null
    withArrows?: boolean
    customArrows?: {prev: ReactNode; next: ReactNode} | null
    arrowsStyles?: (theme: DefaultTheme) => FlattenSimpleInterpolation
    carouselStyles?: (theme: DefaultTheme) => FlattenSimpleInterpolation
    slideStyles?: (theme: DefaultTheme) => FlattenSimpleInterpolation
    videoPreview?: boolean
    onSwipe?: ({splide, newIndex, prevIndex}: {splide: SplideType; newIndex: number; prevIndex: number}) => void
    additionComponent?: ReactNode
    onClickSlide?: () => void
    videoPreviewWithPlayButton?: boolean
    withOverlay?: boolean
    isProfileSlider?: boolean
    ChildrenWithProps?: ({media}: {media: MediaType}) => JSX.Element
    host?: HostPartialType
    isGuest?: boolean
    isInFeed?: boolean
    subscriptionStatusActive?: boolean
}

export const Carousel = ({
    children,
    media,
    videoOptions,
    customOptions,
    customArrows = null,
    arrowsStyles,
    carouselStyles,
    videoPreview = false,
    onSwipe,
    additionComponent,
    slideStyles,
    onClickSlide,
    videoPreviewWithPlayButton = false,
    withOverlay = false,
    isProfileSlider = false,
    ChildrenWithProps
}: CarouselType) => {
    const theme = useTheme()

    const correctOption = {
        direction: theme.direction,
        rewind: true,
        perMove: 1,
        flickMaxPages: 1,
        waitForTransition: true,
        flickPower: 30,
        easing: 'cubic-bezier(0.21, 1.02, 0.73, 1)',
        ...customOptions
    }

    return (
        <StyledSplide
            onClick={onClickSlide}
            onMove={(splide: SplideType, newIndex: number, prevIndex: number) => {
                onSwipe?.({splide, newIndex, prevIndex})
            }}
            options={correctOption}
            hasTrack={false}
            $carouselStyles={carouselStyles}
        >
            <StyledSplideTrack>
                {!!media &&
                    media?.map((item, index) => {
                        return (
                            item &&
                            !!item?.url && (
                                <StyledSplideSlide
                                    key={index}
                                    $slideStyles={slideStyles}
                                    style={{
                                        backgroundColor: isProfileSlider ? 'rgb(127, 130, 158)' : 'none'
                                    }}
                                >
                                    {withOverlay && <StyledOverlay />}
                                    {item.type === MediaTypeE.IMAGE ? (
                                        isProfileSlider ? (
                                            <StyledImage url={item.url} />
                                        ) : (
                                            <img src={item.url} />
                                        )
                                    ) : item.type === MediaTypeE.AUDIO ? (
                                        <StyledAudioContainer>
                                            <WaveSurferPlayer playerId={item.url} audioSrc={item.url} autoPlay={true} />
                                        </StyledAudioContainer>
                                    ) : videoPreview ? (
                                        <ResponsiveVideo
                                            video={item.url}
                                            controls={false}
                                            withPlayButton={videoPreviewWithPlayButton}
                                        />
                                    ) : (
                                        <VideoPlayer source={item.url} options={{...item?.options, ...videoOptions}} />
                                    )}
                                    {children}
                                    {!!ChildrenWithProps && <ChildrenWithProps media={item} />}
                                </StyledSplideSlide>
                            )
                        )
                    })}
            </StyledSplideTrack>
            {customArrows && (
                <StyledArrowsWrapper className="splide__arrows" arrowsStyles={arrowsStyles}>
                    <button className="splide__arrow splide__arrow--prev">{customArrows.prev}</button>
                    <button className="splide__arrow splide__arrow--next">{customArrows.next}</button>
                </StyledArrowsWrapper>
            )}
            {additionComponent}
        </StyledSplide>
    )
}

Carousel.displayName = 'Carousel'

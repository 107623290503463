import {useQuery} from '@tanstack/react-query'
import {TRANSACTIONS_API} from '../services/transactions.http'
import {QUERY_KEYS} from './keys'

export const useCheckTransactionStatus = () => {
    return useQuery({
        queryKey: [QUERY_KEYS.check_transaction_status],
        queryFn: TRANSACTIONS_API.checkTransactionStatus
    })
}

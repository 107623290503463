import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from './keys'
import {ANON_API} from '../services/anonTransaction.http'

export const useGetAvoidedTransaction = (id?: number | null) => {
    return useQuery({
        queryKey: [QUERY_KEYS.show_transaction, id],
        queryFn: () => ANON_API.getAvoidedTransaction(id)
    })
}

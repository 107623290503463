import {FC, ReactNode, useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {StyledExpandableText, StyledExpandableTextTrigger} from './style'

type ExpandableTextProps = {
    children: ReactNode
    maxLines?: number
}

export const ExpandableText: FC<ExpandableTextProps> = ({children, maxLines = 1}) => {
    const textRef = useRef<HTMLDivElement>(null)
    const [isExpanded, setIsExpanded] = useState(false)
    const [shouldTruncate, setShouldTruncate] = useState(false)
    const {t} = useTranslation()

    useEffect(() => {
        if (textRef.current) {
            const styles = window.getComputedStyle(textRef.current)
            const computedLineHeight = parseFloat(styles.lineHeight)

            const elementHeight = textRef.current.getBoundingClientRect().height
            const calculatedLines = Math.round(elementHeight / computedLineHeight)

            if (calculatedLines > maxLines) setShouldTruncate(true)
        }
    }, [children, maxLines])

    const toggleExpand = () => {
        setIsExpanded(!isExpanded)
    }

    return (
        <div>
            <StyledExpandableText
                ref={textRef}
                isExpanded={isExpanded}
                shouldTruncate={shouldTruncate}
                maxLines={maxLines}
            >
                {children}
            </StyledExpandableText>
            {shouldTruncate && (
                <StyledExpandableTextTrigger onClick={toggleExpand}>
                    {isExpanded ? t('common:show_less') : t('common:show_more')}
                </StyledExpandableTextTrigger>
            )}
        </div>
    )
}

export default ExpandableText

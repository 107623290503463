import {useMe} from '@/features/authentication/queries/useMe'
import {GenderKeyNameE} from '@/features/constants/constants'
import {useGetHost} from '@/features/guest/queries/useGetHost'
import {ShareProfileModal} from '@/features/host/components/share-profile-modal/ShareProfileModal'
import {
    StyledAnimation,
    StyledSuccessBox,
    StyledBannerWrapper,
    StyledGiftModalStepContainer,
    StyledSuccessContainer,
    StyledSuccessTitleBox,
    StyledSuccessTitle,
    StyledSuccessSubTitle,
    StyledSuccessButtonBox,
    StyledRedirectToChatButton,
    StyledShareButton,
    StyledModalTitle,
    modalStyles
} from '@/features/recurring-gift/gift-modal/style'
import useRedirectToChatChannel from '@/hooks/useRedirectToChatChannel'
import {TagManager} from '@/integrations/tag-manager/tagManager'
import {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router-dom'
import {CashbackBanner} from '@/components/ui/cashback-banner/CashbackBanner'
import {Animation} from '@/components/ui/animation/Animation'
import successGiftedAnimation from '@assets/animations/Success-Gifted.json'
import {Modal} from '@/components/ui/modal/Modal'
import {DonationType} from '@/features/recurring-gift/types'
import { AnonTransactionSubscriptionPackageType } from '@/features/anonRetryModal/services/types'

export const GiftModalSuccess = ({
    selectedMembership,
    hostID,
    handleCloseModal
}: {
    selectedMembership?: DonationType | AnonTransactionSubscriptionPackageType | null
    hostID?: number
    handleCloseModal: () => void
}) => {
    const {t} = useTranslation()
    const location = useLocation()
    const {data: user} = useMe()
    const {data: host} = useGetHost({filter: `${hostID}`})
    const redirectToChatChannel = useRedirectToChatChannel({receiverId: parseInt(`${hostID}`)})
    const [isOpenShareProfileModal, setIsOpenShareProfileModal] = useState(false)

    const hostGender = useMemo(() => {
        if (host) {
            switch (host?.gender?.id) {
                case 1:
                    return GenderKeyNameE.MALE
                case 2:
                    return GenderKeyNameE.FEMALE
                default:
                    return GenderKeyNameE.OTHER
            }
        }
    }, [host])

    useEffect(() => {
        TagManager.dataLayer({
            event: 'giftThankYouPage',
            currentUrl: location?.pathname
        })
    }, [])

    return (
        <Modal
            showConfettiAnimation
            onClose={handleCloseModal}
            fullScreen
            height={'550px'}
            maxHeight={'600px'}
            width={'400px'}
            title={
                <StyledModalTitle>
                    {t('gift_rookie:title_send_gift', {
                        USER: host?.full_name
                    })}
                </StyledModalTitle>
            }
            modalStyles={modalStyles}
        >
            <StyledGiftModalStepContainer>
                <StyledBannerWrapper>
                    <CashbackBanner />
                </StyledBannerWrapper>
                <StyledSuccessContainer>
                    <StyledSuccessBox>
                        <StyledAnimation>
                            <Animation loop autoplay={true} animationData={successGiftedAnimation} />
                        </StyledAnimation>
                        <div>
                            <StyledSuccessTitleBox>
                                <StyledSuccessTitle>
                                    {t('gift_rookie:success:title', {
                                        Morgis: selectedMembership?.amount,
                                        USER: host?.full_name
                                    })}
                                </StyledSuccessTitle>
                            </StyledSuccessTitleBox>
                            <StyledSuccessTitleBox>
                                <StyledSuccessSubTitle>
                                    {t('gift_rookie:success:description', {
                                        USER: host?.full_name
                                    })}
                                </StyledSuccessSubTitle>
                            </StyledSuccessTitleBox>
                        </div>
                    </StyledSuccessBox>
                    <StyledSuccessButtonBox>
                        <StyledRedirectToChatButton
                            onClick={() => {
                                if (user && hostID) {
                                    redirectToChatChannel?.()
                                    handleCloseModal()
                                }
                            }}
                        >
                            {t('gift_rookie:go_to_chat')}
                        </StyledRedirectToChatButton>
                        <StyledShareButton onClick={() => setIsOpenShareProfileModal(true)} variant="outlined">
                            {t('gift_rookie:share_with_friends')}
                        </StyledShareButton>
                    </StyledSuccessButtonBox>
                </StyledSuccessContainer>

                {isOpenShareProfileModal && (
                    <ShareProfileModal
                        hostGender={hostGender}
                        username={`${host?.username}`}
                        onClose={() => setIsOpenShareProfileModal(false)}
                        type="gift"
                    />
                )}
            </StyledGiftModalStepContainer>
        </Modal>
    )
}

import {useMutation, useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from './keys'
import {MORGIS_TAXI_API} from '../services/morgisTaxi.http'

export const useAddFeaturedHost = ({onSuccess}: {onSuccess?: () => void}) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: ({hostId}: {hostId: number}) => MORGIS_TAXI_API.addFeaturedHost(hostId),
        onSuccess: () => {
            onSuccess?.()
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.featured_hosts]})
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.suggested_hosts]})
        }
    })
}

import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from './keys'
import {MORGIS_TAXI_API} from '../services/morgisTaxi.http'

export const useGetFeaturedHosts = ({enabled = true}: {enabled?: boolean}) => {
    return useQuery({
        queryKey: [QUERY_KEYS.featured_hosts],
        queryFn: () => MORGIS_TAXI_API.getFeaturedHosts(),
        enabled
    })
}

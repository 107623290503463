import {MicromorgiIcon} from '@/assets/icons/icons'
import noAvatar from '../../../assets/images/noAvatar.png'
import {useTranslation} from 'react-i18next'
import {generatePath, useNavigate} from 'react-router-dom'
import {
    StyledDonationStartDate,
    StyledGuestAvatar,
    StyledGuestMorgiAmount,
    StyledGuestName,
    StyledListItem,
    StyledListItemContent
} from './style'
import {ROUTE_GUEST_GIFT_HISTORY} from '@/utilities/constants/routeNames'
import {getLocalDate} from '@/dayjs'
import {GuestType} from '@/features/authentication/types'

export const enum SubscriptionStatuses {
    ACTIVE = 'active',
    CANCELED = 'canceled'
}

type GuestCardProps = {
    amount?: number
    guestId?: number
    guest?: GuestType
    nextDonationAt?: string
    status?: string
}

export const GuestCard = ({guest, amount, guestId, nextDonationAt, status}: GuestCardProps) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const isActiveGift = status && status === SubscriptionStatuses.CANCELED
    return (
        <StyledListItem
            isActiveGift={!!isActiveGift}
            onClick={() => {
                navigate(generatePath(ROUTE_GUEST_GIFT_HISTORY, {guestId: `${guestId}`}))
            }}
        >
            <StyledGuestAvatar src={guest?.avatar?.url || noAvatar} />
            <StyledListItemContent>
                <StyledGuestName>{guest?.username}</StyledGuestName>
                <StyledDonationStartDate>
                    {t('active_gifting_leaders:donation_start_date', {
                        DATE: getLocalDate(String(nextDonationAt), 'DD/MM/YYYY')
                    })}
                </StyledDonationStartDate>
            </StyledListItemContent>
            <StyledGuestMorgiAmount>
                <MicromorgiIcon />
                {amount}
            </StyledGuestMorgiAmount>
        </StyledListItem>
    )
}

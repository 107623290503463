import {HeaderState} from '@/components/commons/header/store/createHeaderModeSlice'
import {AboutUsModal} from '@/features/aboutUsModal/store/createAboutUsModalSlice'
import {ApproachFirstModal} from '@/features/approach-first/send-first-message-to-guest-modal/store/createApproachFirstModalSlice'
import {LogoutModal} from '@/features/authentication/components/logout-modal/store/createLogoutModalSlice'
import {ChatFavTopics} from '@/features/chat-fav-topics/store/createChatFavTopicsSlice'
import {PauseConnectionModal} from '@/features/chat/pause-connection-modal/store/createPauseConnectionModalSlice'
import {StarredMessageModal} from '@/features/chat/starred-message-modal/store/createStarredMessageModalSlice'
import {ChatSlice} from '@/features/chat/store'
import {ErrorModal} from '@/features/common/error-modal/store/createErrorModalSlice'
import {BuyMorgisModal} from '@/features/gift/buy-morgis-modal/store/createBuyMorgisModalSlice'
import {ConfirmSendGiftModal} from '@/features/gift/confirm-send-gift-modal/store/createConfirmSendGiftModalSlice'
import {CustomSendGiftModal} from '@/features/gift/custom-send-gift-modal/store/createCustomSendGiftModalSlice'
import {PaidGifsModal} from '@/features/gift/one-time-gifs-modal/store/createPaidGifsModalSlice'
import {GoalDeletionSuccessModal} from '@/features/goal/components/goal-deletion-success-modal/store/createGoalDeletionSuccessModalSlice'
import {GoalPageModal} from '@/features/goal/components/goal-page-modal/store/createGoalModalSlice'
import {WalletModal} from '@/features/goal/wallet-modal/store/createWalletModalSlice'
import {ChoosePaymentsMethodModal} from '@/features/guest-payments/components/choose-payment-method-modal/store/createChoosePaymentsMethodModalSlice'
import {UpdateCreditCard} from '@/features/guest/update-credit-card-modal/store/createUpdateCreditCardModalSlice'
import {SendMegaGiftModal} from '@/features/mega-gift/send-mega-gift-modal/store/createSendMegaGiftModalSlice'
import {SuccessMegaGiftOverlay} from '@/features/mega-gift/success-mega-gift-overlay/store/createSuccessMegaGiftOverlaySlice'
import {AlertsStoreType} from '@/features/profile/components/alerts-section/store/createAlertsSlice'
import {ConnectNowModal} from '@/features/profile/components/connect-now-modal/store/createConnectNowModalSlice'
import {CancelRecurringGiftModal} from '@/features/recurring-gift/cancel-recurring-gift-modal/store/createCancelRecurringGiftModalSlice'
import {RecurringGiftModal} from '@/features/recurring-gift/gift-modal/store/createRecurringGiftModalSlice'
import {TopFanCompleteModal} from '@/features/top-fan/top-fan-complete-modal/store/createTopFanCompleteModalSlice'
import {TopFanPendingModal} from '@/features/top-fan/top-fan-pending-modal/store/createTopFanPendingModalSlice'
import {SelectGlobalLanguageModal} from '@/features/translation/select-global-language-modal/store/createSelectGlobalLanguageModalSlice'
import {GlobalTranslateModal} from '@/features/translation/store/createGlobalTranslateSlice'
import {GetUltimateStatusModal} from '@/features/ultimate-status-modal/components/ultimateStatusModal/store/createGetUltimateStatusModalSlice'
import {AnimationTriggers} from './createAnimationTriggersSlice'
import {GoalFiltersState} from './createGoalFiltersSlice'
import {GifModal} from './modals/createGifsModalSlice'
import {GoalTeaserModal} from './modals/createGoalTeaserModalSlice'
import {ReachedLimitModal} from './modals/createReachedLimitModalSlice'
import {PaidMessageModal} from '@/store/modals/createPaidMessageModal.ts'
import {HostProfileModal} from '@/store/modals/createHostProfileModalSlice.ts'
import {SendGifModal} from './modals/createSendGifModalSlice'
import {MeetMoreHostModal} from '@/features/meetMoreHost/store/createMeetMoreHostModalSlice'
import {OpenSurprisesModal} from '@/features/my-surprises/components/open-surprises-modal/store/createOpenSurprisesModalSlice'
import {SendBffModal} from './modals/createSendBffModalSlice'
import {AnonRetryModal} from '@/features/anonRetryModal/store/createAnonRetryModalSlice'

export const selectAlertsStore = (state: {alerts: AlertsStoreType}) => state.alerts
export const selectAnimationsStore = (state: {animations: AnimationTriggers}) => state.animations
export const selectGlobalTranslateStore = (state: {globalTranslate: GlobalTranslateModal}) => state.globalTranslate
export const selectHeaderModeStore = (state: {headerMode: HeaderState}) => state.headerMode
export const selectGoalFilters = (state: {goalsFilters: GoalFiltersState}) => state.goalsFilters
export const selectChatStore = (state: {chat: ChatSlice}) => state.chat
export const selectChatConversations = (state: {chat: ChatSlice}) => state.chat.conversations
export const selectChatChannels = (state: {chat: ChatSlice}) => state.chat.channels
export const selectChatActiveChannels = (state: {chat: ChatSlice}) =>
    Object.values(state.chat.channels).filter(ch => ch.channel_status === 'active')
export const selectChatInactiveChannels = (state: {chat: ChatSlice}) =>
    Object.values(state.chat.channels).filter(ch => ch.channel_status === 'inactive')
export const selectChatPayersChannels = (state: {chat: ChatSlice}) =>
    Object.values(state.chat.channels).filter(ch => ch.channel_status === 'payers')
export const selectChatRemoveMessageAction = (state: {chat: ChatSlice}) => state.chat.removeMessage
export const selectResetChatState = (state: {chat: ChatSlice}) => state.chat.reset

export const selectModals = (state: {
    modals: {
        approachFirstModal: ApproachFirstModal
        buyMorgisModal: BuyMorgisModal
        cancelRecurringGiftModal: CancelRecurringGiftModal
        choosePaymentsMethodModal: ChoosePaymentsMethodModal
        confirmSendGiftModal: ConfirmSendGiftModal
        customSendGiftModal: CustomSendGiftModal
        openSurprisesModal: OpenSurprisesModal
        errorModal: ErrorModal
        goalDeletionSuccessModal: GoalDeletionSuccessModal
        goalModal: GoalPageModal
        hostProfileModal: HostProfileModal
        logoutModal: LogoutModal
        paidGifsModal: PaidGifsModal
        pauseConnectionModal: PauseConnectionModal
        recurringGiftModal: RecurringGiftModal
        selectGlobalLanguageModal: SelectGlobalLanguageModal
        sendMegaGiftModal: SendMegaGiftModal
        successMegaGiftOverlay: SuccessMegaGiftOverlay
        topFanCompleteModal: TopFanCompleteModal
        topFanPendingModal: TopFanPendingModal
        updateCreditCardModal: UpdateCreditCard
        walletModal: WalletModal
        connectNowModal: ConnectNowModal
        getUltimateStatusModal: GetUltimateStatusModal
        starredMessageModal: StarredMessageModal
        gifModal: GifModal
        sendGifModal: SendGifModal
        sendBffModal: SendBffModal
        goalTeaserModal: GoalTeaserModal
        reachedLimitModal: ReachedLimitModal
        paidMessageModal: PaidMessageModal
        aboutUsModal: AboutUsModal
        meetMoreHostModal: MeetMoreHostModal
        anonRetryModal: AnonRetryModal
    }
}) => state.modals

//modals
export const selectApproachFirstModal = (state: {modals: {approachFirstModal: ApproachFirstModal}}) =>
    state.modals.approachFirstModal
export const selectConnectModal = (state: {modals: {connectNowModal: ConnectNowModal}}) => state.modals.connectNowModal
export const selectBuyMorgisModal = (state: {modals: {buyMorgisModal: BuyMorgisModal}}) => state.modals.buyMorgisModal
export const selectCancelRecurringGiftModal = (state: {modals: {cancelRecurringGiftModal: CancelRecurringGiftModal}}) =>
    state.modals.cancelRecurringGiftModal
export const selectChoosePaymentsMethodModal = (state: {
    modals: {choosePaymentsMethodModal: ChoosePaymentsMethodModal}
}) => state.modals.choosePaymentsMethodModal
export const selectSetPaymentMethodUrls = (state: {modals: {choosePaymentsMethodModal: ChoosePaymentsMethodModal}}) =>
    state.modals.choosePaymentsMethodModal.setPaymentMethodUrls
export const selectConfirmSendGiftModal = (state: {modals: {confirmSendGiftModal: ConfirmSendGiftModal}}) =>
    state.modals.confirmSendGiftModal
export const selectCustomSendGiftModal = (state: {modals: {customSendGiftModal: CustomSendGiftModal}}) =>
    state.modals.customSendGiftModal
export const selectOpenSurprisesModal = (state: {modals: {openSurprisesModal: OpenSurprisesModal}}) =>
    state.modals.openSurprisesModal
export const selectErrorModal = (state: {modals: {errorModal: ErrorModal}}) => state.modals.errorModal
export const selectGoalDeletionSuccessModal = (state: {modals: {goalDeletionSuccessModal: GoalDeletionSuccessModal}}) =>
    state.modals.goalDeletionSuccessModal
export const selectGoalModal = (state: {modals: {goalModal: GoalPageModal}}) => state.modals.goalModal
export const selectLogoutModal = (state: {modals: {logoutModal: LogoutModal}}) => state.modals.logoutModal
export const selectPaidGifsModal = (state: {modals: {paidGifsModal: PaidGifsModal}}) => state.modals.paidGifsModal
export const selectPauseConnectionModal = (state: {modals: {pauseConnectionModal: PauseConnectionModal}}) =>
    state.modals.pauseConnectionModal
export const selectRecurringGiftModal = (state: {modals: {recurringGiftModal: RecurringGiftModal}}) =>
    state.modals.recurringGiftModal
export const selectSelectGlobalLanguageModal = (state: {
    modals: {selectGlobalLanguageModal: SelectGlobalLanguageModal}
}) => state.modals.selectGlobalLanguageModal
export const selectSendMegaGiftModal = (state: {modals: {sendMegaGiftModal: SendMegaGiftModal}}) =>
    state.modals.sendMegaGiftModal
export const selectSuccessMegaGiftOverlay = (state: {modals: {successMegaGiftOverlay: SuccessMegaGiftOverlay}}) =>
    state.modals.successMegaGiftOverlay
export const selectTopFanCompleteModal = (state: {modals: {topFanCompleteModal: TopFanCompleteModal}}) =>
    state.modals.topFanCompleteModal
export const selectTopFanPendingModal = (state: {modals: {topFanPendingModal: TopFanPendingModal}}) =>
    state.modals.topFanPendingModal
export const selectUpdateCreditCardModal = (state: {modals: {updateCreditCardModal: UpdateCreditCard}}) =>
    state.modals.updateCreditCardModal
export const selectGetUltimateStatusModal = (state: {modals: {getUltimateStatusModal: GetUltimateStatusModal}}) =>
    state.modals.getUltimateStatusModal
export const selectReachedLimitModal = (state: {modals: {reachedLimitModal: ReachedLimitModal}}) =>
    state.modals.reachedLimitModal
export const selectWalletModal = (state: {modals: {walletModal: WalletModal}}) => state.modals.walletModal
export const selectStarredMessageModal = (state: {modals: {starredMessageModal: StarredMessageModal}}) =>
    state.modals.starredMessageModal
export const selectGifModal = (state: {modals: {gifModal: GifModal}}) => state.modals.gifModal
export const selectSendGifModal = (state: {modals: {sendGifModal: SendGifModal}}) => state.modals.sendGifModal
export const selectSendBffModal = (state: {modals: {sendBffModal: SendBffModal}}) => state.modals.sendBffModal
export const selectGoalTeaserModal = (state: {modals: {goalTeaserModal: GoalTeaserModal}}) =>
    state.modals.goalTeaserModal
export const selectHostProfileModal = (state: {modals: {hostProfileModal: HostProfileModal}}) =>
    state.modals.hostProfileModal
export const selectMeetMoreHostModal = (state: {modals: {meetMoreHostModal: MeetMoreHostModal}}) =>
    state.modals.meetMoreHostModal

export const selectPaidMessageModal = (state: {modals: {paidMessageModal: PaidMessageModal}}) =>
    state.modals.paidMessageModal
export const selectChatFavTopics = (state: {chatFavTopics: ChatFavTopics}) => state.chatFavTopics.topics
export const selectChatFavLanguages = (state: {chatFavTopics: ChatFavTopics}) => state.chatFavTopics.languages
export const selectChatFavTopicsSetTopicMethod = (state: {chatFavTopics: ChatFavTopics}) =>
    state.chatFavTopics.setTopics
export const selectChatFavTopicsSetLanguagesMethod = (state: {chatFavTopics: ChatFavTopics}) =>
    state.chatFavTopics.setLanguages
export const selectAboutUsModal = (state: {modals: {aboutUsModal: AboutUsModal}}) => state.modals.aboutUsModal
export const selectAnonRetryModal = (state: {modals: {anonRetryModal: AnonRetryModal}}) => state.modals.anonRetryModal

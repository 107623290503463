import {PaymentMethodUrls} from '@/features/guest-payments/types'
import {Lens} from '@dhmk/zustand-lens'

type ChoosePaymentsMethodModalState = {
    isOpen: boolean
    paymentMethodUrls: PaymentMethodUrls | null
    rookieName?: string
    currencyAmount?: number
    morgisAmount?: number
    description?: string
    isRecurring?: boolean
    apiCallback?: (onlyLinks?: boolean, withOnlyLink?: boolean) => void
    isBuyingPackage?: boolean
    isFetchingPackages?: boolean
    isMonthlySupport?: boolean
    isUpdateCreditCard?: boolean
    isMegaGift?: boolean
}

type ChoosePaymentsMethodModalActions = {
    setPaymentMethodUrls: (paymentMethodUrls: PaymentMethodUrls) => void
    openModal: (params: Omit<ChoosePaymentsMethodModalState, 'isOpen'>) => void
    closeModal: () => void
}

export type ChoosePaymentsMethodModal = ChoosePaymentsMethodModalState & ChoosePaymentsMethodModalActions

const initialModal: ChoosePaymentsMethodModalState = {
    isOpen: false,
    paymentMethodUrls: null,
    description: 'choose_payment_method:description_without_name',
    isRecurring: false,
    isMonthlySupport: false,
    isUpdateCreditCard: false,
    isMegaGift: false,
    morgisAmount: undefined
}

const createChoosePaymentsMethodModalSlice: Lens<ChoosePaymentsMethodModal> = set => ({
    ...initialModal,
    openModal: params => set(() => ({...params, isOpen: true})),
    closeModal: () => set(() => ({...initialModal})),
    setPaymentMethodUrls: paymentMethodUrls => set(() => ({paymentMethodUrls}))
})

export default createChoosePaymentsMethodModalSlice

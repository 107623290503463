import {useTheme} from 'styled-components'
import {
    StyledButton,
    StyledButtonContent,
    StyledCircle,
    StyledContainer,
    StyledContentBox,
    StyledContentContainer,
    StyledImageContainer,
    StyledInfoBox,
    StyledInfoContainer,
    StyledInfoIcon,
    StyledNameBox,
    StyledPopoverContent,
    StyledSplide,
    StyledSplideSlide,
    StyledTooltipBox
} from './style'
import {SplideTrack} from '@splidejs/react-splide'
import {MicromorgiIcon} from '@/assets/icons/icons'
import {useTranslation} from 'react-i18next'
import {Popover} from '@/components/ui/popover/Popover'
import {GuestType} from '@/features/authentication/types'
import {useMe} from '@/features/authentication/queries/useMe'
import {USER_ROLE_GUEST} from '@/utilities/constants/user'
import {useEffect, useState} from 'react'
import {useRootStore} from '@/store'
import {selectChoosePaymentsMethodModal, selectErrorModal} from '@/store/selectors'
import {TagManager} from '@/integrations/tag-manager/tagManager'
import {useGetHost} from '@/features/guest/queries/useGetHost'
import {MixpanelEventNameE} from '@/integrations/mixpanel/types'
import useMixPanel from '@/integrations/mixpanel/hooks/useMixpanel'
import {useSendGift} from '@/features/recurring-gift/queries/useSendGift'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useGetMembershipPlans} from '@/features/configure-membership-plans/edit-configure-membership-plans-modal/queries/useGetMembershipPlans'
import {Donation, DonationType} from '@/features/recurring-gift/types'
import {GiftModalSuccess} from './SuccessModal'
import {useFetchSubscribedHosts} from '@/features/recurring-gift/queries/useFetchSubscribedHosts'
import {SubscriptionStatusesE} from '@/features/chat/helpers/helpers'
import {remapCurrency} from '../../constants'

export const MemberShipPlans = ({hostId}: {hostId: number}) => {
    const {t} = useTranslation()
    const mixpanel = useMixPanel()
    const theme = useTheme()
    const {data} = useMe()
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [selectedMembership, setSelectedMembership] = useState<DonationType>()
    const {data: packagesListForGuest, isLoading: isLoadingGuest} = useGetMembershipPlans(`${hostId}`)
    const {openModal: openChoosePaymentsMethodModal} = useRootStore(selectChoosePaymentsMethodModal)
    const {openErrorModal} = useRootStore(selectErrorModal)
    const {data: subscriptionHosts} = useFetchSubscribedHosts({
        enabled: true,
        status: SubscriptionStatusesE.ACTIVE
    })
    const user = data?.type === USER_ROLE_GUEST ? (data as GuestType) : null
    const hasBillingData = !!user?.type_attributes?.has_invoicing_profile
    const handleToggleShowSuccessModal = () => {
        setShowSuccessModal(!showSuccessModal)
    }
    const {data: host} = useGetHost({filter: `${hostId}`})

    const currencySymbol = remapCurrency[data?.currency || 'default']

    const {mutate: sendGift, data: sendGiftResponse, error: sendGiftError, isPending: isPendingSendGift} = useSendGift()

    const correctPackages = packagesListForGuest?.filter(pkg => !pkg.is_locked && !!pkg.group)

    const onSuccess = () => {
        if (!hasBillingData) {
            return
        } else {
            TagManager.dataLayer({
                event: 'giftmenu_pop_click_redirect'
            })
            user && hostId && sendGift({hostID: `${hostId}`, subscriptionId: `${selectedMembership?.id}`})
        }
    }

    const activeSubscription = subscriptionHosts?.find(
        (subsriptionsHost: Donation) => subsriptionsHost.rookie_id.toString() === `${hostId}`
    )
    useEffect(() => {
        if (sendGiftError) {
            if (sendGiftError.response?.status === 303) {
                openChoosePaymentsMethodModal({
                    apiCallback: onSuccess,
                    paymentMethodUrls: sendGiftError.response?.data?.url ?? null,
                    description: 'choose_payment_method:description_gift',
                    currencyAmount: selectedMembership?.amount,
                    rookieName: host?.type_attributes.first_name,
                    isMonthlySupport: true
                })
                mixpanel?.trackEvent(MixpanelEventNameE.CheckoutContinue)
            } else {
                openErrorModal({message: sendGiftError.message || null})
            }
        }
        if (sendGiftResponse) {
            handleToggleShowSuccessModal()
        }
    }, [sendGiftResponse, sendGiftError, hasBillingData])

    return (
        <>
            {(isPendingSendGift || isLoadingGuest) && <Spinner />}
            {!!correctPackages && correctPackages?.length >= 1 && (
                <div>
                    <StyledNameBox>{t('rookie_my_profile:membership:title')}</StyledNameBox>

                    <StyledContentContainer>
                        <StyledSplide
                            options={{rewind: true, arrows: false, gap: 32, direction: theme.direction}}
                            hasTrack={false}
                        >
                            <SplideTrack>
                                {correctPackages?.map((plan, index) => {
                                    const hasDefaultImage = plan.group.url?.includes('default_group_image.png') || false
                                    return (
                                        <StyledSplideSlide key={index}>
                                            <StyledContentBox>
                                                {plan.group.url && (
                                                    <StyledImageContainer hasDefaultImage={hasDefaultImage}>
                                                        <img src={plan.group.url} />
                                                    </StyledImageContainer>
                                                )}
                                                <StyledContainer>
                                                    <StyledInfoContainer>
                                                        <StyledInfoBox>
                                                            <StyledCircle />
                                                            {t('rookie_my_profile:membership:surprises', {
                                                                SURPRISES_COUNT: plan.surprises_count
                                                            })}
                                                        </StyledInfoBox>
                                                        <StyledTooltipBox>
                                                            <StyledInfoBox>
                                                                <StyledCircle />
                                                                {plan?.group.name}
                                                            </StyledInfoBox>
                                                            {plan.group.description && (
                                                                <div>
                                                                    <Popover
                                                                        isModal={true}
                                                                        withArrow={true}
                                                                        trigger={<StyledInfoIcon />}
                                                                    >
                                                                        <StyledPopoverContent>
                                                                            {plan.group.description}
                                                                        </StyledPopoverContent>
                                                                    </Popover>
                                                                </div>
                                                            )}
                                                        </StyledTooltipBox>
                                                    </StyledInfoContainer>

                                                    <StyledButton
                                                        onClick={() => {
                                                            setSelectedMembership(plan)
                                                            sendGift({
                                                                hostID: `${hostId}`,
                                                                subscriptionId: `${plan?.id}`
                                                            })
                                                        }}
                                                    >
                                                        <div>
                                                            {!activeSubscription
                                                                ? t('rookie_my_profile:membership:subscribe')
                                                                : t('rookie_my_profile:membership:upgrade')}
                                                        </div>
                                                        <div>
                                                            <StyledButtonContent>
                                                                {plan.amount}
                                                                <MicromorgiIcon />
                                                                {`(${currencySymbol}${plan.dollar_amount})`}
                                                            </StyledButtonContent>
                                                            <StyledButtonContent>
                                                                {t('rookie_my_profile:membership:month')}
                                                            </StyledButtonContent>
                                                        </div>
                                                    </StyledButton>
                                                </StyledContainer>
                                            </StyledContentBox>
                                        </StyledSplideSlide>
                                    )
                                })}
                            </SplideTrack>
                        </StyledSplide>
                    </StyledContentContainer>
                </div>
            )}
            {showSuccessModal && (
                <GiftModalSuccess
                    selectedMembership={selectedMembership as DonationType}
                    hostID={hostId}
                    handleCloseModal={() => handleToggleShowSuccessModal()}
                />
            )}
        </>
    )
}

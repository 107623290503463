import {MicromorgiIcon} from '@/assets/icons/icons'
import styled, {css} from 'styled-components'

export const ModalContainer = styled.div`
    ${({theme: {spacing}}) => css`
        padding: ${spacing}px ${spacing * 3}px;
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 16px;
    `}
`

//Morgi
export const StyledMorgiAmountBox = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    border: 2px solid rgb(204, 205, 216);
    padding: 12px;
    border-radius: 8px;
    align-items: end;
    gap: 24px;
`

export const StyledMorgiAmountContainer = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 16px;
`

export const StyledMicroMorgi = styled(MicromorgiIcon)`
    width: 42px;
    height: 42px;
`

export const StyledAmount = styled.div`
    font-size: 24px;
    font-weight: 700;
    line-height: 1;
`

export const StyledIconBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`

export const StyledButtonBox = styled.div`
    padding-bottom: 16px;
`

//Subscr

export const StyledSubscriptionContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 36px;
    padding-bottom: 16px;
`

export const StyledSubscriptionBox = styled.div`
    ${({theme: {palette, typography}}) => css`
        display: grid;
        grid-template-rows: 1fr 1fr;
        gap: 4px;
        justify-content: center;
        align-items: center;

        & h1 {
            ${typography.text2Xl}
            color: ${palette.neutral.black};
            font-weight: 700;
            letter-spacing: -1.3px;
        }
        & span {
            ${typography.textSm}
            color: ${palette.primary[900]};
            text-align: center;
            line-height: 140%;
            opacity: 0.5;
        }
        & h2 {
            ${typography.textSm}
            color: ${palette.primary[900]};
            text-align: center;
            line-height: 140%;
            font-weight: 700;
        }
        & p {
            color: ${palette.primary[900]};
            overflow-wrap: anywhere;
            line-height: 140%; /* 19.6px */
        }
    `}
`

export const StyledBox = styled.div`
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    text-align: start;
`

export const StyledStartBox = styled.div`
    display: flex;
    gap: 8px;
    justify-content: start;
    align-items: center;
    text-align: start;
`

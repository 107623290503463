export const QUERY_KEYS = {
    featured_hosts: 'featured-hosts',
    featured_host_by_id: 'featured-host-by-id',
    suggested_hosts: 'suggested-hosts'
}

export const MUTATION_KEYS = {
    remove_featured_host: 'remove-featured-host',
    add_featured_host: 'add-featured-host'
}

import {useMe} from '@/features/authentication/queries/useMe.ts'

import {AxiosError} from 'axios'
import {Navigate, Outlet, generatePath, useSearchParams} from 'react-router-dom'
import * as routes from '@/utilities/constants/routeNames'
import {SessionStorageManager} from '@/utilities/sessionStorage'

import {LocalStorageManager} from '@/utilities/localStorage'
import {UserRoleE} from '@/types'
import {generateChatChannelName} from '@/utilities/helpers'
import {OldRoles} from '@/features/constants/constants'
import {useEffect} from 'react'
import {PARAM_GOAL, PARAM_HOST_PROFILE} from '@/utilities/constants/searchParams'

export const PublicRoute = () => {
    const {loggedIn, isError, data: user, error} = useMe(false)
    const [searchParams] = useSearchParams()
    const goalReferral = searchParams.get(PARAM_GOAL)
    const hostReferral = searchParams.get(PARAM_HOST_PROFILE)
    const isPublicHost = location.pathname.includes(OldRoles.rookie)
    const isPublicGuest = location.pathname.includes(OldRoles.leader)

    useEffect(() => {
        if (isPublicHost || isPublicGuest) {
            const oldPublicRole = LocalStorageManager.publicRole.get()
            const role = String(isPublicHost ? OldRoles.rookie : isPublicGuest && !isPublicHost && OldRoles.leader)
            if (oldPublicRole !== role) LocalStorageManager.publicRole.set(role)
        }
    }, [isPublicGuest, isPublicHost])

    const getCorrectRedirectPath = () => {
        const isGuest = user?.type === UserRoleE.Guest
        const isHost = user?.type === UserRoleE.Host
        const isRepresentative = user?.type === UserRoleE.Representative
        const isAgent = user?.type === UserRoleE.Agent
        const isFirstLogin = !!SessionStorageManager.checkIsFirstLogin.get()
        const AgentAndRepsInvitationToken = LocalStorageManager.invitationRepsAndAgentToken.get()

        const channelID = generateChatChannelName(user?.id as number, user?.type as string, user?.referred_by as number)

        switch (true) {
            case isGuest && !!goalReferral:
                return routes.ROUTE_GOAL.replace(':slug', goalReferral ?? '')
            case isFirstLogin && !!user?.referred_by:
                return `${routes.ROUTE_MESSAGE_CENTER}/${channelID}`
            case isGuest && !!hostReferral:
                return `${routes.ROUTE_HOME}${hostReferral}`
            case isGuest:
                return routes.ROUTE_HOME
            case isHost && isFirstLogin:
                return routes.ROUTE_WELCOME_HOST
            case isHost:
                return routes.ROUTE_HOST_MY_PROFILE
            case (isAgent || isRepresentative) && !!AgentAndRepsInvitationToken:
                return generatePath(routes.ROUTE_ACCEPT_JOIN_REQUEST, {token: AgentAndRepsInvitationToken})
            case isRepresentative || isAgent:
                return routes.ROUTE_MESSAGE_CENTER
            default:
                return routes.ROUTE_WHAT_IS_MORGI
        }
    }

    if (isError) {
        const allowableStatusError = [401]
        const errorStatus = (error as AxiosError)?.response?.status as number
        if (!allowableStatusError.includes(errorStatus)) throw error
    }

    return loggedIn ? <Navigate to={getCorrectRedirectPath()} /> : <Outlet />
}

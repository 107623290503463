import {Button} from '@/components/ui/button/Button'
import {TextareaWithBannedElementsTrap} from '@/features/generated-content-security/components/text-area-with-word-trap/TextareaWithBannedElementsTrap'
import styled, {DefaultTheme, css} from 'styled-components'

export const StyledRoot = styled.div<{vh: number}>(
    ({theme: {spacing, mediaQueries}, vh}) => css`
        display: grid;
        grid-template-rows: 0fr auto 0fr;
        margin: 0 -${spacing * 1.5}px;
        height: ${vh}px;
        ${mediaQueries.m} {
            margin: 0;
        }
    `
)

export const StyledSpinnerWrapper = styled.div(
    ({theme: {flexUtils}}) => css`
        ${flexUtils.centered}
        height: 100%;
    `
)

export const StyledHeaderWrapper = styled.div(
    ({theme: {shadows, mediaQueries, spacing}}) => css`
        box-shadow: ${shadows.md};
        padding: ${spacing}px ${spacing * 2}px;
        ${mediaQueries.m} {
            padding: ${spacing * 2}px ${spacing * 3}px;
        }
    `
)

export const StyledConversationHeaderWrapper = styled.div(
    ({theme: {mediaQueries, spacing}}) => css`
        display: grid;
        grid-template-columns: auto 1fr auto;
        grid-template-areas: 'back name avatar';
        gap: ${spacing}px;
        align-items: center;
        ${mediaQueries.m} {
            gap: ${spacing * 3}px;
            grid-template-columns: auto 1fr;
            grid-template-areas: 'avatar name goalButton';
        }
    `
)

export const StyledBackButtonWrapper = styled.div(
    ({theme: {mediaQueries}}) => css`
        grid-area: back;
        display: inline;
        ${mediaQueries.m} {
            display: none;
        }
    `
)

export const StyledNameWrapper = styled.div(
    ({theme: {spacing, mediaQueries}}) => css`
        grid-area: name;
        display: flex;
        gap: ${spacing * 0.25}px;
        align-items: center;
        flex-direction: column;
        ${mediaQueries.m} {
            flex-direction: row;
            gap: ${spacing * 2}px;
        }
    `
)

export const StyledName = styled.span(
    ({theme: {typography}}) => css`
        ${typography.textLg}
        font-weight: 500;
        white-space: nowrap;
    `
)

export const StyledAvatarWrapper = styled.div(
    ({theme: {mediaQueries}}) => css`
        grid-area: avatar;
        transform: scale(0.5);
        ${mediaQueries.m} {
            transform: scale(1);
        }
    `
)

export const StyledGoalButton = styled(Button)`
    ${({theme: {mediaQueries}}) => css`
        grid-area: goalButton;
        display: none;
        text-transform: capitalize;
        ${mediaQueries.m} {
            display: inline;
        }
    `}
`

export const StyledMessagesWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        padding: ${spacing * 2}px;
        display: flex;
        flex-direction: column;
        gap: ${spacing * 2}px;
        height: 100%;
        overflow-y: scroll;
    `}
`

export const StyledEmptyMessage = styled.div`
    ${() => css`
        text-align: center;
        font-weight: 700;
    `}
`

export const StyledEmptyMessageGoalMessage = styled.p`
    ${({theme: {palette}}) => css`
        text-align: center;
        color: ${palette.neutral[300]};
    `}
`

export const StyledMessageForm = styled.div(
    ({theme: {palette}}) => css`
        background-color: ${palette.primary[100]};
    `
)

export const StyledInputWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        padding: ${spacing}px ${spacing}px;
        display: flex;
        align-items: center;
    `}
`

export const StyledInput = styled(TextareaWithBannedElementsTrap)`
    ${() => css`
        flex-grow: 1;
        & > div {
            max-height: 30vh;
            min-width: 200px;
            &:focus {
                box-shadow: none;
                border-color: none;
            }
        }
    `}
`

export const StyledSendWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        cursor: pointer;
        height: ${spacing * 5.5}px;
        padding: ${spacing}px;
        display: flex;
        align-items: center;
    `}
`

type StyledMessageProps = {
    isMine: boolean
    theme: DefaultTheme
}

export const StyledMessageWrapper = styled.div(
    ({isMine}: StyledMessageProps) => css`
        display: flex;
        justify-content: ${isMine ? 'flex-end' : 'flex-start'};
    `
)

export const StyledGoalVideoAvatar = styled.video`
    ${({theme: {mediaQueries}}) => css`
        width: 30px;
        height: 30px;
        border-radius: 5px;
        cursor: pointer;
        object-fit: cover;
        ${mediaQueries.m} {
            width: 65px;
            height: 65px;
            border-radius: 10px;
        }
    `}
`

export const InsertNicknameWrapper = styled.span`
    ${({theme: {spacing}}) => css`
        margin-right: ${spacing * 2}px;
        height: 16.8px;
        & > span {
            cursor: pointer;
        }
    `}
`

import i18n from '@/translations/i18n'
import {Dispatch, SetStateAction, useState} from 'react'
import {useGetFilterCountries} from '@/features/country/queries/useGetFilterCountries'
import {useGetFilterLanguages} from '@/features/country/queries/useGetFilterLanguages'
import {useHandleError} from '@/hooks/useHandleError'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {SearchedFiltersList} from '../searched-filters-list/SearchedFiltersList'
import {HostFiltersType} from '@/features/feed/types'

type CountryAndLanguageFilterProps = {
    countries: HostFiltersType['countries']
    setCountries: Dispatch<SetStateAction<HostFiltersType['countries']>>
    languages: HostFiltersType['languages']
    setLanguages: Dispatch<SetStateAction<HostFiltersType['languages']>>
}

export const CountryAndLanguageFilter = ({
    countries,
    setCountries,
    languages,
    setLanguages
}: CountryAndLanguageFilterProps) => {
    const [countrySearchTerm, setCountrySearchTerm] = useState('')
    const [languageSearchTerm, setLanguageSearchTerm] = useState('')

    const {
        data: countriesList,
        isLoading: isCountriesLoading,
        isError: isCountriesError,
        error: countriesError
    } = useGetFilterCountries({appLang: i18n.language})

    const {
        data: languagesList,
        isLoading: isLanguagesLoading,
        isError: isLanguagesError,
        error: languagesError
    } = useGetFilterLanguages({appLang: i18n.language})

    useHandleError({isError: isCountriesError || isLanguagesError, error: countriesError || languagesError})

    return (
        <div>
            {(isCountriesLoading || isLanguagesLoading) && <Spinner />}
            <SearchedFiltersList
                title="common:country"
                data={
                    countriesList?.filter(item => item.name.toLowerCase().includes(countrySearchTerm.toLowerCase())) ||
                    []
                }
                handleChange={(value: string) => setCountrySearchTerm(value)}
                defaultData={countries}
                handleSelect={setCountries}
            />
            <SearchedFiltersList
                title="common:language"
                data={
                    languagesList?.filter(item => item.name.toLowerCase().includes(languageSearchTerm.toLowerCase())) ||
                    []
                }
                handleChange={(value: string) => setLanguageSearchTerm(value)}
                defaultData={languages}
                handleSelect={setLanguages}
            />
        </div>
    )
}

import {useMutation, useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from './keys'
import {MORGIS_TAXI_API} from '../services/morgisTaxi.http'

export const useDeleteFeaturedHost = ({onSuccess}: {onSuccess?: () => void}) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: ({id}: {id: number}) => MORGIS_TAXI_API.deleteFeaturedHosts({id}),
        onSuccess: () => {
            onSuccess?.()
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.featured_hosts]})
        }
    })
}

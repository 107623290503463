import styled, {css, DefaultTheme} from 'styled-components'

export const modalStyles = (theme: DefaultTheme) => css`
    max-height: 100vh;
    width: 100vw;

    ${theme.mediaQueries.m} {
        width: 500px;
        max-height: 668px;
    }
`

export const StyledBody = styled.div`
    ${({theme: {spacing, mediaQueries, palette}}) => css`
        width: 100%;
        padding: 0 ${spacing * 2}px ${spacing * 2}px;
        overflow: auto;

        ${mediaQueries.m} {
            padding: 0 ${spacing * 3}px ${spacing * 3}px;
        }

        &::-webkit-scrollbar {
            background: ${palette.neutral.transparent};
            width: 5px;
            border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb {
            background: ${palette.neutral.black};
        }

        &::-webkit-scrollbar-track {
            margin-bottom: ${spacing * 4}px;
        }
        &::-webkit-scrollbar-button:start {
            display: none;
        }
        &::-webkit-scrollbar-button:end {
            display: none;
        }
    `}
`

export const StyledListWrap = styled.div`
    ${() => css`
        width: 100%;
    `}
`

import {LastReadsTimetokensSchema} from '@/features/chat/services/chat.schema'
import {z} from 'zod'

export const enum REPRESENTATIVE_MODEL {
    FirstName = 'first_name',
    LastName = 'last_name',
    Email = 'email',
    Description = 'description',
    GenderID = 'gender_id',
    InputPhoneNumber = 'test_phone_number',
    PhoneNumber = 'phone_number',
    PhoneCountryCode = 'phone_number_country',
    PathLocation = 'path_location',
    RemoveAvatar = 'remove_avatar'
}

export const InviteRepresentativeValidationSchema = z.object({
    [REPRESENTATIVE_MODEL.Email]: z.string().min(1, {message: 'validation:required_email'}).email({
        message: 'validation:invalid_email'
    }),
    [REPRESENTATIVE_MODEL.FirstName]: z.string().min(1, {message: 'validation:required_first_name'}),
    [REPRESENTATIVE_MODEL.LastName]: z.string().min(1, {message: 'validation:required_last_name'}),
    [REPRESENTATIVE_MODEL.Description]: z
        .string()
        .min(1, {message: 'validation:required_description'})
        .max(120, {message: 'validation:max_description_length'}),
    [REPRESENTATIVE_MODEL.InputPhoneNumber]: z.object({
        country_code: z.string().optional(),
        local_number: z
            .string()
            .max(20, {message: 'validation:phone_number_long'})
            .refine(val => val === '' || /^\d+$/.test(val), {message: 'validation:only_digits'})
            .optional()
    })
})

export const InvitationPayloadSchema = z.object({
    first_name: z.string(),
    last_name: z.string(),
    email: z.string(),
    country_calling_code: z.string().optional(),
    number: z.string().optional(),
    description: z.string(),
    phone_number: z.string().optional()
})

export const InvitationAgentAndRepsResponseSchema = z.object({
    message: z.string(),
    res: z.object({
        id: z.number(),
        firstName: z.string(),
        lastName: z.string(),
        email: z.string(),
        phoneNumberCountry: z.string().nullable(),
        phoneNumber: z.string().nullable(),
        status: z.string(),
        expiresAt: z.string(),
        createdAt: z.string(),
        type: z.enum(['request', 'invitation']).optional(),
        agentId: z.number().optional()
    })
})

export const HostChannelObjectSchema = z.object({
    id: z.number(),
    rookie_id: z.number(),
    leader_id: z.number(),
    display_name: z.string(),
    last_reads_timetokens: z.array(LastReadsTimetokensSchema).nullable()
})
export const HostChannelSchema = z.record(z.number(), HostChannelObjectSchema)
export const HostChannelsResponseSchema = z.record(z.number(), HostChannelSchema)

import {useTranslation} from 'react-i18next'
import {Popover} from '@/components/ui/popover/Popover'
import {useEffect, useMemo, useState} from 'react'
import {StyledEmptySection, StyledHostSelectContainer, StyledHostSelectRoot} from './style'
import {ChatButton} from '../chat-button/ChatButton'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {LocalStorageManager} from '@/utilities/localStorage'
import {useRootStore} from '@/store'
import {selectChatStore} from '@/store/selectors'
import {useGetRepresentedUser} from '@/hooks/useGetRepresentedHost'
import {useNavigate} from 'react-router-dom'
import {ROUTE_MESSAGE_CENTER} from '@/utilities/constants/routeNames'
import {useGetRepresentativeHostsChannels} from '@/features/representative/queries/useGetRepresentativeHostsChannels'
import {ChatHostOption} from './ChatHostOption'
import {useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/features/representative/queries/keys.ts'
import useChat from '@/features/chat/hooks/useChat'

type ChatHostsSelectProps = {
    isLight: boolean
    isAgent: boolean
    getCountOfSelectedUser: (num: number) => void
}

export const ChatHostsSelect = ({isLight, isAgent, getCountOfSelectedUser}: ChatHostsSelectProps) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {conversations} = useChat()
    const {setNeedReInitAfterChangedRepresentedHost} = useRootStore(selectChatStore)
    const {isLoading, agentAndRepsHosts: hosts, representedUserId} = useGetRepresentedUser()
    const [isOpen, setIsOpen] = useState(false)
    const [selectedHostId, setSelectedHostId] = useState<number | undefined>(representedUserId)
    const queryClient = useQueryClient()
    const {data: hotsChannels} = useGetRepresentativeHostsChannels(!isAgent)
    const count = Object.values(conversations)
        .filter(conversation => selectedHostId && conversation.pk?.includes(String(selectedHostId)))
        .reduce((acc, item) => acc + (item.unreadCounter || 0), 0)

    const correctHosts = useMemo(() => {
        if (isAgent) {
            return hosts
        } else {
            return hosts.length
                ? hosts.map(host => ({
                      ...host,
                      hostChannels: hotsChannels[`${host.id}`]
                  }))
                : []
        }
    }, [hotsChannels, hosts])

    useEffect(() => {
        getCountOfSelectedUser(count || 0)
    }, [count, conversations])

    const setSelectedHostIdHandler = (hostID: number) => {
        LocalStorageManager.chatUserId.set(hostID)
        setSelectedHostId(hostID)
        setNeedReInitAfterChangedRepresentedHost(true)
    }

    useEffect(() => {
        if (
            (!selectedHostId && correctHosts.length) ||
            (selectedHostId && correctHosts.length && !correctHosts.find(host => host.id === selectedHostId))
        ) {
            setSelectedHostIdHandler(correctHosts[0].id as number)
            navigate(ROUTE_MESSAGE_CENTER)
        }
    }, [correctHosts])

    return (
        <StyledHostSelectContainer>
            <Popover
                trigger={<ChatButton isLight={isLight} />}
                rootProps={{
                    open: isOpen,
                    onOpenChange: () => {
                        setIsOpen(!isOpen)
                        !isOpen && queryClient.invalidateQueries({queryKey: [QUERY_KEYS.representative_hosts_channels]})
                    }
                }}
                isModal
            >
                <StyledHostSelectRoot>
                    {isLoading ? (
                        <Spinner />
                    ) : correctHosts?.length ? (
                        correctHosts.map(host => {
                            return (
                                <ChatHostOption
                                    key={host.id}
                                    onClick={() => {
                                        setIsOpen(false)
                                        setSelectedHostIdHandler(host.id as number)
                                        navigate(ROUTE_MESSAGE_CENTER)
                                    }}
                                    host={host}
                                    selectedHostId={selectedHostId}
                                />
                            )
                        })
                    ) : (
                        <StyledEmptySection>{t('common:no_options')}</StyledEmptySection>
                    )}
                </StyledHostSelectRoot>
            </Popover>
        </StyledHostSelectContainer>
    )
}

import styled, {css} from 'styled-components'
import {Splide, SplideSlide} from '@splidejs/react-splide'
import {Button} from '@/components/ui/button/Button'
import {InfoIcon} from '@/assets/icons/icons'

export const StyledContentContainer = styled.div`
    ${({theme: {spacing}}) => css`
        border-radius: 14px;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 10px 0px;
        padding: ${spacing * 2}px;
    `}
`

export const StyledSplide = styled(Splide)`
    ${({theme: {palette}}) => css`
        position: relative;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        height: 100%;
        .splide__pagination__page {
            height: 7px;
            width: 7px;
            border-radius: 50%;
            background-color: ${palette.neutral['75']};
        }
        .splide__pagination__page.is-active {
            height: 7px;
            width: 7px;
            opacity: 1;
            border-radius: 50%;
            background-color: ${palette.primary['900']};
            transform: none;
        }
    `}
`

export const StyledSplideSlide = styled(SplideSlide)(
    ({theme: {spacing}}) => css`
        background-color: transparent;
        display: flex;
        align-items: center;
        flex-direction: column;
        cursor: grab;
        text-align: center;
        margin-bottom: ${spacing * 1.25}px;
        padding-bottom: ${spacing * 2.5}px;
    `
)

export const StyledNameBox = styled.div`
    ${({theme: {typography, spacing}}) => css`
        margin-bottom: ${spacing * 2}px;
        ${typography.textXl};
        font-weight: 700;
    `}
`

export const StyledContentBox = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing}px;
        width: 100%;
        height: 100%;
    `}
`

export const StyledInfoContainer = styled.div`
    ${({theme: {typography}}) => css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        ${typography.textXs};
    `}
`

export const StyledTooltipBox = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        align-self: center;
        gap: ${spacing * 1.5}px;
    `}
`

export const StyledCircle = styled.div`
    ${({theme: {palette}}) => css`
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: ${palette.primary[200]};
    `}
`

export const StyledInfoBox = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing / 2}px;
    `}
`

export const StyledInfoIcon = styled(InfoIcon)`
    opacity: 0.7;
    width: 10px;
    height: 10px;
    cursor: pointer;
`

export const StyledImageContainer = styled.div<{hasDefaultImage: boolean}>`
    ${({hasDefaultImage, theme: {spacing}}) => css`
        width: 100%;
        padding-bottom: ${spacing * 2.5}px;
        max-height: 120px;
        & img {
            width: 100%;
            border-radius: ${hasDefaultImage ? '12px' : '12px'};
            object-fit: ${hasDefaultImage ? 'contain' : 'cover'};
            border: ${hasDefaultImage ? '1px solid black' : 'none'};
            max-height: 100px;
        }
    `}
`

export const StyledButton = styled(Button)`
    ${({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: ${spacing / 2}px ${spacing * 3}px;
    `}
`

export const StyledContainer = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing}px;
    `}
`

export const StyledButtonContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const StyledPopoverContent = styled.div`
    ${({theme: {spacing}}) => css`
        max-width: 315px;
        padding: ${spacing}px;
        text-align: center;
    `}
`

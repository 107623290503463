import {StyledOverlay} from '@/components/ui/modal/style'
import {useMe} from '@/features/authentication/queries/useMe'
import {OldRoles} from '@/features/constants/constants'
import {useLockBodyScroll} from '@/hooks/useLockBodyScroll'
import {useRootStore} from '@/store'
import {selectSuccessMegaGiftOverlay} from '@/store/selectors'
import megaGift from '@assets/images/megaGift.png'
import {FC} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {
    StyledContinueButton,
    StyledSuccessMegaGiftOverlayContainer,
    StyledSuccessMegaGiftOverlayDescription,
    StyledSuccessMegaGiftOverlayImage
} from './style'

export const SuccessMegaGiftOverlay: FC = () => {
    const {t} = useTranslation()
    const {closeModal: closeSuccessMegaGiftOverlay, onSuccess} = useRootStore(selectSuccessMegaGiftOverlay)
    const {data: user} = useMe()
    useLockBodyScroll({enable: true})
    if (!user) return <></>
    return (
        <>
            <StyledOverlay onClick={closeSuccessMegaGiftOverlay} />
            <StyledSuccessMegaGiftOverlayContainer>
                <StyledSuccessMegaGiftOverlayImage src={megaGift} />
                <StyledSuccessMegaGiftOverlayDescription>
                    <Trans
                        t={t}
                        i18nKey={t(
                            `congrats_mega_gift_modal:description_${OldRoles[user.type as 'leader' | 'rookie']}`
                        )}
                        components={{span: <span />}}
                    />
                </StyledSuccessMegaGiftOverlayDescription>
                <StyledContinueButton
                    onClick={() => {
                        closeSuccessMegaGiftOverlay()
                        onSuccess?.()
                    }}
                >
                    {t('congrats_mega_gift_modal:cta_text')}
                </StyledContinueButton>
            </StyledSuccessMegaGiftOverlayContainer>
        </>
    )
}

import {Spinner} from '@/components/ui/spinner/Spinner'
import {useMe} from '@/features/authentication/queries/useMe'
import {remapCurrency} from '@/features/profile/constants'
import {useFetchSubscribedAmounts} from '@/features/recurring-gift/queries/useFetchSubscribedAmounts'
import {DonationType} from '@/features/recurring-gift/types'
import {TFunction} from 'i18next'
import {FC, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {PlanOfSubscription} from './components/plan-of-subscription/PlanOfSubscription'
import {StyledPlansList, StyledPlansListHeader} from './style'
import {useGetMembershipPlans} from '@/features/configure-membership-plans/edit-configure-membership-plans-modal/queries/useGetMembershipPlans'
import {MixpanelEventNameE} from '@/integrations/mixpanel/types'
import useMixPanel from '@/integrations/mixpanel/hooks/useMixpanel'

type PlansListProps = {
    selectedPlan: DonationType | null
    columnNames?: string[]
    disabledByMinAmount?: number
    hostID?: string
    onSelectPlanHandler: (plan: DonationType) => void
    renderMegaphoneText?: (
        plan: DonationType,
        numberOfOthersGroups: number,
        t?: TFunction<'translation', undefined>
    ) => React.ReactNode
}
export const PlansList: FC<PlansListProps> = ({
    selectedPlan,
    onSelectPlanHandler,
    renderMegaphoneText,
    disabledByMinAmount = 0,
    hostID,
    columnNames = ['configure_membership_plans_modal:plan_price', 'configure_membership_plans_modal:rewards']
}) => {
    const {t} = useTranslation()
    const mixpanel = useMixPanel()
    const {data: user} = useMe()
    const {data: packagesListForGuest, isLoading: isLoadingGuest} = useGetMembershipPlans(hostID)
    const {data: packagesListForHost, isLoading: isLoadingHost} = useFetchSubscribedAmounts(false, !hostID)
    const packagesList = hostID
        ? packagesListForGuest?.filter(pack => !pack.is_locked && !!pack.group)
        : packagesListForHost?.filter(pack => pack.group.status === 'inactive' && pack)

    useEffect(() => {
        if (selectedPlan) {
            const updatedSelectedPlan = packagesList?.find(({id}) => id === selectedPlan.id)
            if (updatedSelectedPlan) onSelectPlanHandler(updatedSelectedPlan)
        }
    }, [packagesList])

    if (
        (hostID ? isLoadingGuest : isLoadingHost) ||
        (hostID ? !packagesListForGuest?.length : !packagesListForHost?.length)
    )
        return <Spinner />
    const currencySymbol = remapCurrency[user?.currency || 'USD']

    return (
        <>
            <StyledPlansListHeader>
                {columnNames.map((name: string) => (
                    <span key={name}>{t(name)}</span>
                ))}
            </StyledPlansListHeader>
            <StyledPlansList>
                {packagesList?.map((plan, idx) => (
                    <PlanOfSubscription
                        key={plan.id}
                        plan={plan}
                        isSelected={plan.id === selectedPlan?.id}
                        onSelectPlanHandler={() => {
                            mixpanel?.trackEvent(MixpanelEventNameE.GiftAmountClick, {
                                amountSelected: plan.amount
                            })
                            onSelectPlanHandler(plan)
                        }}
                        currencySymbol={currencySymbol}
                        renderMegaphoneText={() => renderMegaphoneText?.(plan, idx, t)}
                        disabled={!!disabledByMinAmount && disabledByMinAmount > plan.amount}
                    />
                ))}
            </StyledPlansList>
        </>
    )
}

import {useMe} from '@/features/authentication/queries/useMe'
import {useGetFavoriteActivities} from '../queries/useGetFavoriteActivities'
import {USER_ROLE_GUEST, USER_ROLE_HOST, USER_ROLE_REPRESENTATIVE} from '@/utilities/constants/user'

export const useGetAllActivities = () => {
    const {data: user} = useMe()
    const enabled =
        user?.type === USER_ROLE_GUEST ||
        [USER_ROLE_HOST, USER_ROLE_REPRESENTATIVE].includes(user?.type as string) ||
        !user
    const {data: chatFavoriteActivitiesData} = useGetFavoriteActivities(true, enabled)
    const {data: chatUnfavoriteActivitiesData} = useGetFavoriteActivities(false, enabled)
    const chatFavoriteActivities = chatFavoriteActivitiesData ?? []
    const chatUnfavoriteActivities = chatUnfavoriteActivitiesData ?? []

    return {
        activities: [...chatFavoriteActivities, ...chatUnfavoriteActivities]
    }
}

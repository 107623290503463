import styled, {css, CSSObject, DefaultTheme} from 'styled-components'
import {GroupBase, StylesConfig} from 'react-select'
import {OptionType} from './types'

export const StyledContainer = styled.div`
    ${({disabled}: {disabled: boolean}) => css`
        cursor: ${disabled ? 'not-allowed' : 'pointer'};
        text-align: start;
        position: relative;
        display: flex;
        flex-direction: column;
    `}
`
export const StyledIndicatorBox = styled.div`
    ${({theme: {flexUtils, spacing}}) => css`
        ${flexUtils.centered};
        padding: 0 ${spacing}px;
    `}
`

type StylesProps = {
    theme: DefaultTheme
    isError: boolean
    touched: boolean
}

//the following code should be jss for react select custom styling
export const customStyles = ({
    theme,
    isError
}: StylesProps): StylesConfig<OptionType, boolean, GroupBase<OptionType>> => ({
    control: (baseStyles, state) => ({
        ...baseStyles,
        padding: `${theme.spacing}px`,
        width: '100%',
        minHeight: '54px',
        borderRadius: '4px',
        cursor: 'pointer',
        border: state.menuIsOpen
            ? `1px solid ${theme.palette.primary['200']}`
            : `1px solid ${theme.palette.neutral['950']}`,
        boxShadow: state.menuIsOpen ? `0 0 0 2px ${theme.palette.primary['400']}` : theme.shadows.xs,
        ':focus': {
            border: `1px solid ${theme.palette.neutral['950']}`
        },
        ...(isError && {
            border: `1px solid ${theme.palette.danger[25]}`,
            boxShadow: 'none'
        }),
        backgroundColor: state.isDisabled ? theme.palette.neutral[950] : theme.palette.neutral.transparent
    }),
    placeholder: provided => ({
        ...provided,
        fontSize: '16px', // {theme.typography.textMd} need a number or a string, but in the types it is listed as "FlattenSimpleInterpolation"
        fontWeight: 400,
        marginLeft: `${theme.spacing / 4}px`,
        color: theme.palette.neutral['500']
    }),
    valueContainer: baseStyles => ({
        ...baseStyles,
        padding: 0,
        textAlign: 'start',
        color: theme.palette.primary['900'],
        textTransform: 'capitalize',
        //text/md medium,
        fontSize: '16px', // {theme.typography.textMd} need a number or a string, but in the types it is listed as "FlattenSimpleInterpolation"
        fontWeight: 700
    }),
    indicatorsContainer: baseStyles => ({
        ...baseStyles,
        '& svg': {
            fill: `${theme.palette.neutral['25']}`
        }
    }),

    dropdownIndicator: baseStyles => ({
        ...baseStyles,
        padding: 0,
        transition: 'transform 0.2s ease'
    }),

    indicatorSeparator: baseStyles => ({
        ...baseStyles,
        display: 'none'
    }),

    clearIndicator: baseStyles => ({
        ...baseStyles,
        '& svg': {
            fill: `${theme.palette.neutral['25']}`,
            width: '20px',
            height: '20px'
        }
    }),

    menu: baseStyles => ({
        ...baseStyles,
        borderRadius: `${theme.spacing * 1.25}px`,
        overflow: 'hidden',
        margin: `${theme.spacing}px 0`,
        boxShadow: theme.shadows.lg,
        border: `1px solid ${theme.palette.neutral['200']}`,
        zIndex: 2
    }),

    menuList: baseStyles => ({
        ...baseStyles,
        margin: 0,
        padding: 0,
        cursor: 'pointer'
    }),
    option: (baseStyles, state) =>
        ({
            ...baseStyles,
            textAlign: 'start',
            padding: `${theme.spacing}px ${theme.spacing * 2}px`,
            color: state.data.disabled ? theme.palette.neutral['950'] : theme.palette.primary['900'],
            lineHeight: '24px',
            fontWeight: 700,
            '&:hover': {
                backgroundColor: !state.data.disabled ? theme.palette.primary['25'] : undefined
            },
            textTransform: 'capitalize',
            cursor: state.data.disabled ? 'not-allowed' : 'pointer',
            background:
                state.isFocused && !state.isSelected
                    ? theme.palette.primary['625']
                    : state.isSelected
                      ? theme.palette.primary['300']
                      : theme.palette.neutral.transparent,
            '&:active': {
                background: theme.palette.neutral.transparent
            }
        }) as CSSObject,
    multiValue: baseStyles => ({
        ...baseStyles,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: `${theme.spacing * 0.5}px ${theme.spacing}px`,
        gap: theme.spacing,
        height: 24,
        background: theme.palette.neutral.white,
        border: `1px solid ${theme.palette.neutral['300']}`,
        borderRadius: '6px'
    }),
    multiValueLabel: baseStyles => ({
        ...baseStyles,
        fontWeight: 500,
        textTransform: 'capitalize',
        color: theme.palette.neutral['700']
    }),
    multiValueRemove: baseStyles => ({
        ...baseStyles
    }),
    input: baseStyles => ({
        ...baseStyles,
        margin: 0
    })
})

import {TranslateSwitch} from '@/features/translation/components/translate-switch/TranslateSwitch'
import {StyledSwitchWrapper, StyledTranslationSwitchWrapper} from './style'
import {StyledTabSubTitle, StyledTabTitle} from '../style'
import {useTranslation} from 'react-i18next'

export const TranslationSwitchSection = () => {
    const {t} = useTranslation()

    return (
        <StyledTranslationSwitchWrapper>
            <StyledTabTitle>{t('path_filter:translation_title')}</StyledTabTitle>
            <StyledTabSubTitle>{t('path_filter:translation_subtitle')}</StyledTabSubTitle>
            <StyledSwitchWrapper>
                <TranslateSwitch isUseMainAppLanguage />
            </StyledSwitchWrapper>
        </StyledTranslationSwitchWrapper>
    )
}

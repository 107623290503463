import {Header} from '@/components/commons/header/Header'
import {Container} from '@/components/ui/container/Container'
import {ErrorModal} from '@/features/common/error-modal/ErrorModal.tsx'
import {AlertsSection} from '@/features/profile/components/alerts-section/AlertsSection'
import {UltimateStatusModal} from '@/features/ultimate-status-modal/components/ultimateStatusModal/UltimateStatusModal'
import {useLocationChange} from '@/hooks/useLocationChange'
import useMixPanel from '@/integrations/mixpanel/hooks/useMixpanel'
import {MixpanelEventNameE} from '@/integrations/mixpanel/types'
import MoengageProvider from '@/integrations/moengage/MoengageProvider'
import {useRootStore} from '@/store'
import {selectModals} from '@/store/selectors'
import {getThemeMode} from '@/utilities/helpers'
import {FC} from 'react'
import {Outlet, useLocation, useMatches} from 'react-router-dom'
import {StyledLayout} from './style'
import {LogoutModal} from '@/features/authentication/components/logout-modal/LogoutModal'
import {AboutUsModal} from '@/features/aboutUsModal/AboutUsModal'
import {useChatFavTopics} from '@/features/chat-fav-topics/hooks/useChatFavTopics'
import useMoEngageSDK from '@/integrations/moengage/hooks/useMoengageSdk'
import {useTheme} from 'styled-components'
import {CustomSelectProvider} from '@/components/commons/custom-select/context'
import { LocalStorageManager } from '@/utilities/localStorage'
import { AudioPlayerProvider } from '@/components/ui/audioPlayer/context'

export const SharedLayout: FC = () => {
    const mixpanel = useMixPanel()
    const moEngage = useMoEngageSDK()
    const matches = useMatches()
    const themeMode = getThemeMode(matches)
    const isLight = themeMode === 'light'
    const theme = useTheme()
    useChatFavTopics()
    const {errorModal, logoutModal, getUltimateStatusModal, aboutUsModal} = useRootStore(selectModals)

    const existingUtmParams = LocalStorageManager.utmParams.get()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    
    const queryParams: Record<string, string> = {}
    searchParams.forEach((value, key) => {
        queryParams[key] = value
    })

    useLocationChange(pageName => {
        mixpanel?.trackEvent(MixpanelEventNameE.PageView, {page_name: pageName, ...searchParams, ...existingUtmParams})
        moEngage?.trackEvent(MixpanelEventNameE.PageView, {page_name: pageName})
    })

    return (
        <MoengageProvider>
            <AudioPlayerProvider>
                <CustomSelectProvider>
                    <AlertsSection />
                    <StyledLayout isLight={isLight} dir={theme.direction}>
                        {errorModal.isOpen && <ErrorModal />}
                        {getUltimateStatusModal.isOpen && <UltimateStatusModal />}
                        {logoutModal.isOpen && <LogoutModal />}
                        {aboutUsModal.isOpen && <AboutUsModal />}
                        <Header isLight={isLight} />
                        <Container>
                            <Outlet />
                        </Container>
                    </StyledLayout>
                </CustomSelectProvider>
            </AudioPlayerProvider>
        </MoengageProvider>
    )
}

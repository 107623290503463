import {Button} from '@/components/ui/button/Button'
import {
    StyledAmount,
    StyledButtonBox,
    StyledIconBox,
    StyledMicroMorgi,
    StyledMorgiAmountBox,
    StyledMorgiAmountContainer
} from './style'
import {Trans, useTranslation} from 'react-i18next'
import {AnonTransactionType} from './services/types'
import { useMe } from '../authentication/queries/useMe'
import { remapCurrency } from '../profile/constants'

export const BoughtMicromorgiContent = ({
    onRetryTransaction,
    data
}: {
    onRetryTransaction: () => void
    data?: AnonTransactionType
}) => {
    const {t} = useTranslation()
    const {data: user} = useMe()
    const currencySymbol = remapCurrency[user?.currency || 'USD']
    return (
        <div>
            <StyledMorgiAmountContainer>
                <StyledMorgiAmountBox>
                    <StyledIconBox>
                        <div>
                            <StyledMicroMorgi />
                        </div>
                        <StyledAmount>{data?.amount_micromorgi}</StyledAmount>
                    </StyledIconBox>
                    <div>
                        <Trans
                            t={t}
                            i18nKey={'anon_retry_modal:bought_morgis'}
                            values={{
                                CURRENCY: currencySymbol,
                                AMOUNT: data?.amount_dollars
                            }}
                        />
                    </div>
                </StyledMorgiAmountBox>
            </StyledMorgiAmountContainer>
            <StyledButtonBox>
                <Button onClick={() => onRetryTransaction()}>
                    <Trans
                        t={t}
                        i18nKey={'anon_retry_modal:purchase'}
                        values={{
                            MICROMORGI: data?.amount_micromorgi,
                            CURRENCY:  currencySymbol,
                            DOLLARS: data?.amount_dollars
                        }}
                    />
                </Button>
            </StyledButtonBox>
        </div>
    )
}

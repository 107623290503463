import {GuestSchema, HostSchema} from '@/features/authentication/services/auth.schema.ts'
import {GoalSchema} from '@/features/goal/services/goal.schema'
import { ChatAttachmentSchema } from '@/features/my-moments/services/myMoments.schema'
import {z} from 'zod'

export enum TransactionTypesE {
    BOUGHT_MICROMORGI = 'bought_micromorgi',
    BOUGHT_MORGI = 'bought_morgi',
    CHAT = 'chat',
    GOAL_WITHDRAW = 'goal_withdraw',
    GIFT = 'gift',
    GOAL = 'goal',
    REFUND = 'refund',
    WITHDRAWAL = 'withdrawal',
    WITHDRAWAL_REJECTED = 'withdrawal_rejected',
    WITHDRAWAL_PENDING = 'withdrawal_pending',
    FINE = 'fine',
    ULTIMATE_STATUS_OFFER = 'ultimate_status_offer',
    WITHDRAWAL_GOAL_PENDING = 'withdrawal_goal_pending',
    GOAL_REFUND = 'goal_refund',
    MEGA_GIFT = 'mega_gift',
    BONUS = 'bonus'
}

const TransactionTypes = z.nativeEnum(TransactionTypesE)

export const TransactionSchema = z.object({
    created_at: z.string().datetime(),
    description: z.string(),
    id: z.number(),
    notes: z.string().nullable().optional(),
    type: TransactionTypes,
    refund_type: z
        .union([TransactionTypes, z.literal('void'), z.null()])
        .nullable()
        .optional(),
    coupon_id: z.union([z.number(), z.null()]),
    goal_id: z.union([z.number(), z.null()]),
    referal_internal_id: z.union([z.number(), z.null()]),
    taxed_morgi: z.union([z.number(), z.null()]),
    taxed_micromorgi: z.union([z.number(), z.null()]),
    taxed_dollars: z.number(),
    rookie_chronological_id: z.union([z.number(), z.null()]),
    leader_chronological_id: z.union([z.number(), z.null()]),
    campaign_id: z.union([z.number(), z.null()]),
    discounted_amount: z.union([z.number(), z.string(), z.null()]),
    amount_dollars: z.number(),
    amount_micromorgi: z.number(),
    amount_morgi: z.number(),
    external_id: z.number(),
    referal_external_id: z.number().nullable().optional(),
    is_refunded: z.boolean(),
    refund_date: z.union([z.string(), z.null()]),
    sign: z.enum(['+', '-']),
    refund_by_biller: z.boolean(),
    is_bonus: z.boolean(),
     chat_attachment: z.union([ChatAttachmentSchema, z.null()]),
    rookie: z.union([z.null(), HostSchema.partial()]),
    leader: z.union([z.null(), GuestSchema.partial()]),
    goal: z.union([z.null(), GoalSchema.partial()]),
    paid_message_id: z.number().nullable(),
    chat_attachment_id: z.number().nullable(),
    agent_taxed_dollars: z.number().nullable().optional()
})

export const TransactionResponseSchema = z.object({
    current_page: z.number(),
    first_page_url: z.string(),
    from: z.union([z.number(), z.null()]),
    last_page: z.number(),
    last_page_url: z.string(),
    next_page_url: z.union([z.string(), z.null()]),
    path: z.string(),
    per_page: z.union([z.string(), z.number()]),
    prev_page_url: z.union([z.string(), z.null()]),
    to: z.union([z.number(), z.null()]),
    total: z.number(),
    dollar_sum: z.number().optional(),
    micro_morgi_given: z.number().optional(),
    morgi_given: z.number().optional(),
    morgi_balance: z.number().optional(),
    micromorgi_balance: z.number().optional(),
    total_donations: z.number().optional(),
    total_leaders: z.number().optional(),
    payments_pending: z.number().optional(),
    payments_received: z.number().optional(),
    recurring_payments: z.number().optional(),
    first_transaction_at: z.string().datetime().optional(),
    links: z.array(
        z.object({
            url: z.union([z.string(), z.null()]),
            label: z.string(),
            active: z.boolean()
        })
    ),
    data: z.array(TransactionSchema)
})
export enum TransactionType {
    micromorgi = 'micromorgi',
    gift = 'gift',
    renew = 'renew',
    credit_card = 'credit_card',
    mega_gift = 'mega_gift'
}

export enum TransactionStatusEnum {
    default = 'default',
    success = 'success',
    failure = 'failure'
}

export const TransactionStatusSchema = z.object({
    type: z.nativeEnum(TransactionType),
    status: z.nativeEnum(TransactionStatusEnum),
    amount: z.number(),
    dollar_amount: z.number(),
    jpost_url: z.string().url(),
    subscription_id: z.nullable(z.string()),
    leader_payment_id: z.nullable(z.string()),
    has_active_payment_method: z.boolean(),
    rookie: HostSchema
})

import {CheckIcon, MicromorgiIcon} from '@/assets/icons/icons'
import {useMe} from '@/features/authentication/queries/useMe'
import {remapCurrency} from '@/features/profile/constants'
import {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {z} from 'zod'
import {MicromorgiPackageSchema} from '../../services/micromorgi.schema'
import {
    StyledAmount,
    StyledAmountContainer,
    StyledDelAmount,
    StyledDisAmountContainer,
    StyledMicromorgiPackageItemContainer,
    StyledMicromorgiPackageItemRow,
    StyledNowSpan,
    StyledPriceSpan
} from './style'
import {useTheme} from 'styled-components'

type MicromorgiPackage = z.infer<typeof MicromorgiPackageSchema>
type MicromorgiPackageItemProps = {
    micromorgiPackage: MicromorgiPackage
    originalPackage?: MicromorgiPackage
    onSelectPackage: () => void
    isSelected: boolean
}

export const MicromorgiPackageItem: FC<MicromorgiPackageItemProps> = ({
    micromorgiPackage,
    originalPackage,
    isSelected,
    onSelectPackage
}) => {
    const {t} = useTranslation()
    const {data: user} = useMe()
    const theme = useTheme()

    const isGreater = micromorgiPackage.level_status !== 'guest'
    return (
        <StyledMicromorgiPackageItemContainer $isSelected={isSelected} onClick={onSelectPackage}>
            {isGreater ? (
                <>
                    <StyledMicromorgiPackageItemRow>
                        {originalPackage?.micromorgi_count &&
                        <StyledDisAmountContainer>
                            <span>{`${t('common:was')} `}</span>
                            <StyledDelAmount>{originalPackage.micromorgi_count}</StyledDelAmount>
                        </StyledDisAmountContainer>
                        }
                        {isSelected && <CheckIcon fill={theme.palette.primary[200]} />}
                    </StyledMicromorgiPackageItemRow>
                    <StyledMicromorgiPackageItemRow justifyContent="flex-start">
                        <MicromorgiIcon width={50} height={50} style={{marginLeft: -theme.spacing / 2}} />
                        <StyledAmountContainer>
                            <StyledNowSpan>{t('common:now')}</StyledNowSpan>
                            <StyledAmount>{micromorgiPackage?.micromorgi_count}</StyledAmount>
                            <StyledPriceSpan>
                                {t('gift_rookie:morgi_prise', {
                                    PRICE: `${`${remapCurrency[user?.currency as string]}`}${micromorgiPackage?.price}`
                                })}
                            </StyledPriceSpan>
                        </StyledAmountContainer>
                    </StyledMicromorgiPackageItemRow>
                </>
            ) : (
                <>
                    <StyledMicromorgiPackageItemRow>
                        <MicromorgiIcon width={40} height={40} />
                        {isSelected && <CheckIcon />}
                    </StyledMicromorgiPackageItemRow>
                    <StyledMicromorgiPackageItemRow>
                        <StyledAmount>{originalPackage?.micromorgi_count}</StyledAmount>
                        <StyledPriceSpan>
                            {t('gift_rookie:morgi_prise', {
                                PRICE: `${`${remapCurrency[user?.currency as string]}`}${originalPackage?.price}`
                            })}
                        </StyledPriceSpan>
                    </StyledMicromorgiPackageItemRow>
                </>
            )}
        </StyledMicromorgiPackageItemContainer>
    )
}

import styled, {css} from 'styled-components'

export const StyledExpandableText = styled.div<{isExpanded: boolean; shouldTruncate: boolean; maxLines: number}>`
    ${({isExpanded, shouldTruncate, maxLines}) => css`
        ${!isExpanded &&
        shouldTruncate &&
        css`
            display: -webkit-box;
            -webkit-line-clamp: ${maxLines};
            -webkit-box-orient: vertical;
            overflow: hidden;
        `}
    `}
`
export const StyledExpandableTextTrigger = styled.button`
    ${({theme: {spacing}}) => css`
        margin-top: ${spacing}px;
        text-decoration: underline;
        cursor: pointer;
    `}
`

import {useMutation} from '@tanstack/react-query'
import {TRANSLATE_API} from '../services/translation.http'
import {MUTATION_KEYS} from './keys'

export const useGetTranslation = () => {
    return useMutation({
        mutationKey: [MUTATION_KEYS.translation],
        mutationFn: TRANSLATE_API.getTranslation
    })
}

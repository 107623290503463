import {Spinner} from '@/components/ui/spinner/Spinner'
import {useGetFilterChatTopics} from '@/features/chat-fav-topics/queries/useGetFilterChatTopics'
import {useHandleError} from '@/hooks/useHandleError'
import {FilterChatTopics} from '@/assets/icons/icons'
import {HostFiltersType} from '@/features/feed/types'
import {Dispatch, SetStateAction} from 'react'
import {TopicsList} from '../../topics-list/TopicsList'
import {StyledFeedFilterTitle} from './style'
import {useTranslation} from 'react-i18next'

type ChatTopicsFilterProps = {
    chatTopics: HostFiltersType['chatTopics']
    setChatTopics: Dispatch<SetStateAction<HostFiltersType['chatTopics']>>
}

export const ChatTopicsFilter = ({chatTopics, setChatTopics}: ChatTopicsFilterProps) => {
    const {data: chatTopicsList, isLoading, error, isError} = useGetFilterChatTopics()
    useHandleError({isError, error})
    const {t} = useTranslation()
    return (
        <>
            {isLoading && <Spinner />}
            <StyledFeedFilterTitle>{t('common:favorite_chat_topics')}</StyledFeedFilterTitle>
            <TopicsList
                defaultTopicsList={chatTopics}
                handleSelect={setChatTopics}
                icon={<FilterChatTopics />}
                topicsList={chatTopicsList || []}
            />
        </>
    )
}

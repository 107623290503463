import {feedCardVariants} from '@/features/feed/components/feed-card/FeedCard.tsx'
import {CardTrackItem, FeedCardVariant} from '@/features/feed/types.ts'
import {
    LOCAL_STORAGE_CONFETTI_FOR_CASHBACK,
    LOCAL_STORAGE_CONFETTI_FOR_ULTIMATE_STATUS,
    LOCAL_STORAGE_LOGGED_IN,
    LOCAL_STORAGE_ROOKIE_ID_FOR_GOAL,
    LOCAL_STORAGE_SHOW_SLOGAN_ANIMATION,
    LOCAL_STORAGE_IS_SAW_ON_BOARD_TOUR,
    LOCAL_STORAGE_GOAL_GUEST_TOUR_VIEWED,
    LOCAL_STORAGE_GOAL_HOST_TOUR_VIEWED,
    LOCAL_STORAGE_FIRST_ENTRY_CHAT,
    LOCAL_STORAGE_INTERACTIVE_FEED_SPECIAL_CARDS_TRACKER,
    LOCAL_STORAGE_SESSION_COUNT_FOR_ADD_HOME_SCREEN_BANNER,
    LOCAL_STORAGE_REFUSED_ULTIMATE_STATUS,
    LOCAL_STORAGE_50_ROOKIE_CARD_SESSION_COUNT,
    LOCAL_STORAGE_OPENED_CHANNEL_COUNT,
    LOCAL_STORAGE_GIFT_PANEL_TAB,
    LOCAL_STORAGE_SEEN_CONGRATS_MEGS_GIFT,
    LOCAL_STORAGE_LEADER_PAYMENT_START_LOCATION,
    LOCAL_STORAGE_UTM_PARAMS,
    LOCAL_STORAGE_SHOWN_ULTIMATE_MODAL_FIRST_TIME,
    LOCAL_STORAGE_SEEN_TALENT_IDS,
    LOCAL_STORAGE_GUEST_REFERRAL,
    LOCAL_STORAGE_REPS_AGENT_INVITATION_TOKEN,
    LOCAL_STORAGE_CHAT_USER_ID,
    LOCAL_STORAGE_OPEN_BUYMORGIS_MODAL,
    LOCAL_STORAGE_OPEN_GIFT_MODAL,
    LOCAL_STORAGE_PUBLIC_ROLE
} from './constants/localStorage'
import {LOCAL_STORAGE_ROOKIE_SCORE_REF} from './constants/rookieScoreRef'
import {OldRoles} from '@/features/constants/constants'

const showSloganAnimation = {
    set: (value: string) => {
        const showAnimationSlogan = showSloganAnimation.get() || {}
        showAnimationSlogan[value] = true
        localStorage.setItem(LOCAL_STORAGE_SHOW_SLOGAN_ANIMATION, JSON.stringify(showAnimationSlogan))
    },
    get: () => {
        const data = localStorage.getItem(LOCAL_STORAGE_SHOW_SLOGAN_ANIMATION)
        return data ? JSON.parse(data) : null
    },
    remove: (value: string) => {
        const showAnimationSlogan = showSloganAnimation.get() || {}
        if (value in showAnimationSlogan) {
            delete showAnimationSlogan[value]
            localStorage.setItem(LOCAL_STORAGE_SHOW_SLOGAN_ANIMATION, JSON.stringify(showAnimationSlogan))
        }
    }
}

const rookieScoreRef = {
    get: () => {
        const data = localStorage.getItem(LOCAL_STORAGE_ROOKIE_SCORE_REF)
        return data
    },
    set: (value: string) => {
        return localStorage.setItem(LOCAL_STORAGE_ROOKIE_SCORE_REF, value)
    },
    remove: () => {
        localStorage.removeItem(LOCAL_STORAGE_ROOKIE_SCORE_REF)
    }
}

const loginUser = {
    set: (userType: string) => {
        localStorage.setItem(LOCAL_STORAGE_LOGGED_IN, JSON.stringify(userType))
    },
    get: () => {
        const data = localStorage.getItem(LOCAL_STORAGE_LOGGED_IN)
        return data ? JSON.parse(data) : null
    },
    remove: () => {
        localStorage.removeItem(LOCAL_STORAGE_LOGGED_IN)
    }
}

const sessionCountForAddHomeScreenBanner = {
    get: () => {
        const data = localStorage.getItem(LOCAL_STORAGE_SESSION_COUNT_FOR_ADD_HOME_SCREEN_BANNER)
        return data ? JSON.parse(data) : 0
    },
    set: (value: number) => {
        return localStorage.setItem(LOCAL_STORAGE_SESSION_COUNT_FOR_ADD_HOME_SCREEN_BANNER, JSON.stringify(value))
    }
}

const sawOnBoardTour = {
    set: () => {
        localStorage.setItem(LOCAL_STORAGE_IS_SAW_ON_BOARD_TOUR, JSON.stringify(true))
    },
    get: () => {
        return localStorage.getItem(LOCAL_STORAGE_IS_SAW_ON_BOARD_TOUR)
    }
}

const rookieIdForGoal = {
    get: () => {
        const data = localStorage.getItem(LOCAL_STORAGE_ROOKIE_ID_FOR_GOAL)
        return data ? JSON.parse(data) : null
    },
    set: (value: number) => {
        return localStorage.setItem(LOCAL_STORAGE_ROOKIE_ID_FOR_GOAL, JSON.stringify(value))
    },
    remove: () => {
        localStorage.removeItem(LOCAL_STORAGE_ROOKIE_ID_FOR_GOAL)
    }
}

// export const LocalStorageManager = {showSloganAnimation, loginUser, sessionCountForAddHomeScreenBanner, rookieIdForGoal}
const goalGuestTourViewed = {
    get: () => {
        const data = localStorage.getItem(LOCAL_STORAGE_GOAL_GUEST_TOUR_VIEWED)
        return data ? JSON.parse(data) : false
    },
    set: (value: boolean) => {
        return localStorage.setItem(LOCAL_STORAGE_GOAL_GUEST_TOUR_VIEWED, JSON.stringify(value))
    }
}

const goalHostTourViewed = {
    get: () => {
        const data = localStorage.getItem(LOCAL_STORAGE_GOAL_HOST_TOUR_VIEWED)
        return data ? JSON.parse(data) : false
    },
    set: (value: boolean) => {
        return localStorage.setItem(LOCAL_STORAGE_GOAL_HOST_TOUR_VIEWED, JSON.stringify(value))
    }
}

const counterConfettiForCashback = {
    get: () => {
        const data = localStorage.getItem(LOCAL_STORAGE_CONFETTI_FOR_CASHBACK)
        return data ? JSON.parse(data) : 0
    },
    set: (value: number) => {
        return localStorage.setItem(LOCAL_STORAGE_CONFETTI_FOR_CASHBACK, JSON.stringify(value))
    },
    remove: () => {
        localStorage.removeItem(LOCAL_STORAGE_CONFETTI_FOR_CASHBACK)
    }
}

const counterConfettiForUltimateStatus = {
    get: () => {
        const data = localStorage.getItem(LOCAL_STORAGE_CONFETTI_FOR_ULTIMATE_STATUS)
        return data ? JSON.parse(data) : 0
    },
    set: (value: number) => {
        return localStorage.setItem(LOCAL_STORAGE_CONFETTI_FOR_ULTIMATE_STATUS, JSON.stringify(value))
    },
    remove: () => {
        localStorage.removeItem(LOCAL_STORAGE_CONFETTI_FOR_ULTIMATE_STATUS)
    }
}
// to show GetStartedModal
const leaderFirstEntryToChat = {
    set: (value: boolean) => {
        localStorage.setItem(LOCAL_STORAGE_FIRST_ENTRY_CHAT, JSON.stringify(value))
    },
    get: () => {
        return localStorage.getItem(LOCAL_STORAGE_FIRST_ENTRY_CHAT)
    }
}

const interactiveFeedSpecialCardsTracker = {
    get: function (cardType: FeedCardVariant): number {
        if (!cardType) return 0
        const valueExists = sessionStorage.getItem(LOCAL_STORAGE_INTERACTIVE_FEED_SPECIAL_CARDS_TRACKER)
        const allValues = !!valueExists && JSON.parse(valueExists)
        return allValues[cardType] ?? 0
    },
    set: function (value: CardTrackItem) {
        const valueExists = sessionStorage.getItem(LOCAL_STORAGE_INTERACTIVE_FEED_SPECIAL_CARDS_TRACKER)
        const oldValue = valueExists && JSON.parse(valueExists)
        sessionStorage.setItem(
            LOCAL_STORAGE_INTERACTIVE_FEED_SPECIAL_CARDS_TRACKER,
            JSON.stringify({...oldValue, ...value})
        )
    },
    remove: function () {
        sessionStorage.removeItem(LOCAL_STORAGE_INTERACTIVE_FEED_SPECIAL_CARDS_TRACKER)
    }
}

const fiftyRookieCardSessionCount = {
    get: () => {
        return localStorage.getItem(LOCAL_STORAGE_50_ROOKIE_CARD_SESSION_COUNT)
    },
    set: (value: string) => {
        return localStorage.setItem(LOCAL_STORAGE_50_ROOKIE_CARD_SESSION_COUNT, value)
    }
}

/** Filter card */
const checkFeedFilterCardType = {
    set: (value: FeedCardVariant[]) => {
        sessionStorage.setItem(feedCardVariants.filter, JSON.stringify(value))
    },
    get: () => {
        const valueExists = sessionStorage.getItem(feedCardVariants.filter)
        return valueExists && JSON.parse(valueExists)
    },
    remove: () => {
        sessionStorage.removeItem(feedCardVariants.filter)
    }
}

const refusedUltimateStatus = {
    set: (value: boolean) => {
        localStorage.setItem(LOCAL_STORAGE_REFUSED_ULTIMATE_STATUS, JSON.stringify(value))
    },
    get: (): boolean | null => {
        const refused = localStorage.getItem(LOCAL_STORAGE_REFUSED_ULTIMATE_STATUS)
        if (refused) return JSON.parse(refused)
        return null
    }
}

const giftPanelTab = {
    set: ({channel, tab}: {channel: string; tab: string}) => {
        localStorage.setItem(LOCAL_STORAGE_GIFT_PANEL_TAB, JSON.stringify({[channel]: tab}))
    },
    get: () => {
        const selectedGiftPanelTab = localStorage.getItem(LOCAL_STORAGE_GIFT_PANEL_TAB)
        return JSON.parse(`${selectedGiftPanelTab}`)
    },
    remove: () => {
        localStorage.removeItem(LOCAL_STORAGE_GIFT_PANEL_TAB)
    }
}

const seenCongratsMegaGift = {
    set: ({channelID, timeToken}: {channelID: string; timeToken: string | number}) => {
        const seenBeforeFromLocalStorage = localStorage.getItem(LOCAL_STORAGE_SEEN_CONGRATS_MEGS_GIFT)
        const seenBefore = seenBeforeFromLocalStorage ? JSON.parse(seenBeforeFromLocalStorage) : null
        const valueToSet = seenBefore
            ? {
                  ...seenBefore,
                  [channelID]: timeToken
              }
            : {[channelID]: timeToken}

        localStorage.setItem(LOCAL_STORAGE_SEEN_CONGRATS_MEGS_GIFT, JSON.stringify(valueToSet))
    },
    get: ({channelID}: {channelID: string}) => {
        const seenBeforeFromLocalStorage = localStorage.getItem(LOCAL_STORAGE_SEEN_CONGRATS_MEGS_GIFT)
        return seenBeforeFromLocalStorage ? JSON.parse(seenBeforeFromLocalStorage)[channelID] : null
    }
}

const openedChannelsCount = {
    set: (channelID: number) => {
        const data = localStorage.getItem(LOCAL_STORAGE_OPENED_CHANNEL_COUNT)
        const channelList = data ? JSON.parse(data) : {}
        if (!channelList[channelID]) {
            channelList[channelID] = 0
        }
        channelList[channelID]++
        localStorage.setItem(LOCAL_STORAGE_OPENED_CHANNEL_COUNT, JSON.stringify(channelList))
    },
    get: () => {
        const data = localStorage.getItem(LOCAL_STORAGE_OPENED_CHANNEL_COUNT)
        return data ? JSON.parse(data) : {}
    },
    remove: () => {
        localStorage.removeItem(LOCAL_STORAGE_OPENED_CHANNEL_COUNT)
    }
}

const paymentStartLocation = {
    set: (value: string) => {
        localStorage.setItem(LOCAL_STORAGE_LEADER_PAYMENT_START_LOCATION, value)
    },
    get: () => {
        return localStorage.getItem(LOCAL_STORAGE_LEADER_PAYMENT_START_LOCATION)
    },
    remove: () => {
        localStorage.removeItem(LOCAL_STORAGE_LEADER_PAYMENT_START_LOCATION)
    }
}

const utmParams = {
    set: (value: {[key: string]: string}) => {
        localStorage.setItem(LOCAL_STORAGE_UTM_PARAMS, JSON.stringify(value))
    },
    get: () => {
        const valueExists = localStorage.getItem(LOCAL_STORAGE_UTM_PARAMS)
        return valueExists ? JSON.parse(valueExists) : {}
    },
    remove: () => {
        localStorage.removeItem(LOCAL_STORAGE_UTM_PARAMS)
    }
}
const shownUltimateStatusModalFirstTime = {
    get: () => {
        const data = localStorage.getItem(LOCAL_STORAGE_SHOWN_ULTIMATE_MODAL_FIRST_TIME)
        return data ? JSON.parse(data) : false
    },
    set: () => {
        return localStorage.setItem(LOCAL_STORAGE_SHOWN_ULTIMATE_MODAL_FIRST_TIME, JSON.stringify(true))
    }
}

const invitationRepsAndAgentToken = {
    set: (token: string | null) => {
        localStorage.setItem(LOCAL_STORAGE_REPS_AGENT_INVITATION_TOKEN, JSON.stringify(token))
    },
    get: () => {
        const invitation = localStorage.getItem(LOCAL_STORAGE_REPS_AGENT_INVITATION_TOKEN)
        return invitation ? JSON.parse(invitation) : null
    },
    remove: () => {
        localStorage.removeItem(LOCAL_STORAGE_REPS_AGENT_INVITATION_TOKEN)
    }
}
const chatUserId = {
    set: (chatUserId: string | number | undefined) => {
        if (chatUserId) {
            localStorage.setItem(LOCAL_STORAGE_CHAT_USER_ID, JSON.stringify(chatUserId))
        }
    },
    get: () => {
        const chatUserId = localStorage.getItem(LOCAL_STORAGE_CHAT_USER_ID)
        return chatUserId ? JSON.parse(chatUserId) : null
    },
    remove: () => {
        localStorage.removeItem(LOCAL_STORAGE_CHAT_USER_ID)
    }
}
const seenTalentIds = {
    get: () => {
        const data = localStorage.getItem(LOCAL_STORAGE_SEEN_TALENT_IDS)
        return data ? JSON.parse(data) : []
    },
    set: (value: number[]) => {
        return localStorage.setItem(LOCAL_STORAGE_SEEN_TALENT_IDS, JSON.stringify(value))
    }
}

const guestReferralModal = {
    set: (value: boolean) => {
        localStorage.setItem(LOCAL_STORAGE_GUEST_REFERRAL, JSON.stringify(value))
    },
    get: () => {
        const data = localStorage.getItem(LOCAL_STORAGE_GUEST_REFERRAL)
        return data ? JSON.parse(data) : false
    }
}

const openBuyMorgisModal = {
    get: () => {
        return localStorage.getItem(LOCAL_STORAGE_OPEN_BUYMORGIS_MODAL)
    },
    set: (value: string) => {
        localStorage.setItem(LOCAL_STORAGE_OPEN_BUYMORGIS_MODAL, value)
    },
    remove: () => {
        localStorage.removeItem(LOCAL_STORAGE_OPEN_BUYMORGIS_MODAL)
    }
}

const openGiftModal = {
    get: () => {
        const data = localStorage.getItem(LOCAL_STORAGE_OPEN_GIFT_MODAL)
        return data ? JSON.parse(data) : null
    },
    set: (data: {redirectUrl: string; hostId: string}) => {
        localStorage.setItem(LOCAL_STORAGE_OPEN_GIFT_MODAL, JSON.stringify(data))
    },
    remove: () => {
        localStorage.removeItem(LOCAL_STORAGE_OPEN_GIFT_MODAL)
    }
}

const publicRole = {
    get: () => {
        const data: OldRoles | undefined = localStorage.getItem(LOCAL_STORAGE_PUBLIC_ROLE) as OldRoles
        return data || null
    },
    set: (role: string) => {
        localStorage.setItem(LOCAL_STORAGE_PUBLIC_ROLE, role)
    },
    remove: () => {
        localStorage.removeItem(LOCAL_STORAGE_PUBLIC_ROLE)
    }
}

export const LocalStorageManager = {
    showSloganAnimation,
    loginUser,
    sessionCountForAddHomeScreenBanner,
    goalGuestTourViewed,
    goalHostTourViewed,
    rookieScoreRef,
    sawOnBoardTour,
    counterConfettiForCashback,
    counterConfettiForUltimateStatus,
    leaderFirstEntryToChat,
    interactiveFeedSpecialCardsTracker,
    checkFeedFilterCardType,
    rookieIdForGoal,
    refusedUltimateStatus,
    fiftyRookieCardSessionCount,
    openedChannelsCount,
    giftPanelTab,
    seenCongratsMegaGift,
    paymentStartLocation,
    utmParams,
    shownUltimateStatusModalFirstTime,
    seenTalentIds,
    guestReferralModal,
    invitationRepsAndAgentToken,
    chatUserId,
    openBuyMorgisModal,
    openGiftModal,
    publicRole
}

import {PhotosIcon, VideoInteractiveFeedIcon} from '@/assets/icons/icons'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {HostCardContent} from '@/features/feed/types'
import {FC} from 'react'
import {StyledVideoContainer} from './style'

type HostMediaProps = {
    content: HostCardContent,
    inTalentCard?: boolean
}

export const HostMedia: FC<HostMediaProps> = ({content, inTalentCard}) => {
    // const activeRookiesGoals = content.type_attributes?.goals?.filter(goals => goals.status === GoalStatusesE.ACTIVE)

    return (
        <Flexbox direction="row" gap={0.25}>
            {content?.type_attributes?.photos && content.type_attributes.photos.length && (
                <StyledVideoContainer inTalentCard={inTalentCard}>
                    <span>{content.type_attributes.photos.length}</span>
                    <PhotosIcon />
                </StyledVideoContainer>
            )}
            {!!content?.type_attributes?.videos?.length && (
                <StyledVideoContainer inTalentCard={inTalentCard}>
                    <span>{content?.type_attributes?.videos?.length}</span>
                    <VideoInteractiveFeedIcon />
                </StyledVideoContainer>
            )}
            {/* {!!activeRookiesGoals?.length && (
                        <StyledGoalContainer>
                            <span>
                                {t('interactive_feed_rookie_card:goals', {
                                    COUNT_GOALS: activeRookiesGoals?.length,
                                    context: activeRookiesGoals?.length === 1 ? 'single' : 'plural'
                                })}
                            </span>
                        </StyledGoalContainer>
                    )} */}
        </Flexbox>
    )
}

HostMedia.displayName = 'HostMedia'

import {Lens} from '@dhmk/zustand-lens'

type PauseConnectionModalState = {
    isOpen: boolean
    guestID?: string | null
    channelID?: string | null
    unPause?: boolean
}

type PauseConnectionModalActions = {
    openModal: (params: Omit<PauseConnectionModalState, 'isOpen'>) => void
    closeModal: () => void
}

export type PauseConnectionModal = PauseConnectionModalState & PauseConnectionModalActions

const initialModal: PauseConnectionModalState = {isOpen: false, guestID: null, channelID: null, unPause: false}

const createPauseConnectionModalSlice: Lens<PauseConnectionModal> = set => ({
    ...initialModal,
    openModal: params => set(() => ({...params, isOpen: true})),
    closeModal: () => set(() => ({...initialModal}))
})

export default createPauseConnectionModalSlice

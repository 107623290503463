import axios from '@/clientAPI.ts'
import {
    AccessToken,
    AttachSocialUser,
    AuthEmailPayload,
    AuthEmailVerifyResponseType,
    AuthState,
    GenderResponse,
    LoginPayload,
    LoginSocialUserRequest,
    ProgressLoadingVideoTypes,
    SignupGuestPayload,
    SignupHostPayload,
    SignupRepresentativePayload,
    UploadVideoRequest,
    User
} from '@/features/authentication/types.ts'
import {CountryType} from '@/features/country/types'
import {AxiosResponse} from 'axios'
import {GenderResponseSchema, UserMeSchema} from './auth.schema'

export const AUTH_API = {
    login: async (values: LoginPayload): Promise<AuthState> => {
        const {data} = await axios.post('/auth/login', values)
        return data
    },
    logout: (): Promise<void> => {
        return axios.get('/auth/logout')
    },
    me: async (): Promise<User> => {
        //TODO BE get avatar null is it possible?
        //TODO get social iterated object instead of array
        const {data} = await axios.get('/profile')
        return UserMeSchema.parse({userType: data.type, data}).data
    },
    signup: (
        data: SignupHostPayload | SignupGuestPayload | SignupRepresentativePayload,
        queryParams?: Record<string, string>
    ): Promise<AxiosResponse<User>> => {
        let url = '/auth/signup'
        if (queryParams) {
            const queryString = new URLSearchParams(queryParams).toString()
            url = `${url}?${queryString}`
        }
        return axios.post(url, data)
    },
    activateAccount: async (token: string): Promise<AuthState> => {
        const url = `/auth/signup/activate/${token}`
        const {data} = await axios.get(url, {params: {is_morgi: 1}})
        return data
    },
    loginSocialUser: async ({type, authResponse}: LoginSocialUserRequest): Promise<AxiosResponse<AccessToken>> => {
        const data = await axios.post(`/auth/signup/${type}`, authResponse)
        return data
    },
    getGenders: async (isLeader?: boolean): Promise<GenderResponse> => {
        const url = `/genders`
        const {data} = await axios.get(url, {params: {is_leader: isLeader}})
        return GenderResponseSchema.parse(data)
    },
    uploadVideo: async ({body, options}: UploadVideoRequest) => {
        const url = '/video'
        const {data} = await axios.post(url, body, {
            ...options,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        return data
    },
    checkStatusOfVideoFile: async ({
        jobId
    }: {
        jobId: string
    }): Promise<{
        percentage: number
        status: ProgressLoadingVideoTypes
        code: number
        file_path?: string
        error?: string
    }> => {
        const {data} = await axios.get(`/video/status/${jobId}`)
        return data
    },
    getLocalize: async (): Promise<CountryType> => {
        const url = '/countries/localize'
        const {data} = await axios.get(url)
        return data
    },
    attachSocialUser: async ({userID, password, data, platform}: AttachSocialUser): Promise<AccessToken> => {
        const url = `/auth/attach/${userID}/${platform}`
        const response = await axios.post(url, {password, ...data})
        return response.data
    },
    signupEmail: async (body: AuthEmailPayload, queryParams?: Record<string, string>): Promise<AuthState> => {
        let url = `auth/emails/signup`
        if (queryParams) {
            const queryString = new URLSearchParams(queryParams).toString()
            url = `${url}?${queryString}`
        }
        const {data} = await axios.post(url, {...body})
        return data
    },
    loginEmail: async (email: string, queryParams?: Record<string, string>): Promise<AuthState> => {
        let url = `auth/emails/login`
        if (queryParams) {
            const queryString = new URLSearchParams(queryParams).toString()
            url = `${url}?${queryString}`
        }
        const {data} = await axios.post(url, {email})
        return data
    },
    verifyEmail: async (token: string): Promise<AuthEmailVerifyResponseType> => {
        const url = `auth/emails/verify/${token}`
        const {data} = await axios.get(url)
        return data
    },
    loginEmailVerification: async ({email, token, queryParams}: {email: string; token: string, queryParams?: Record<string, string>} ): Promise<AuthState> => {
        let url = '/auth/login'
        if (queryParams) {
            const queryString = new URLSearchParams(queryParams).toString()
            url = `${url}?${queryString}`
        }
        const {data} = await axios.post(url, {email, auth_token: token})
        return data
    }
}

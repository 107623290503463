import {Trans, useTranslation} from 'react-i18next'
import {Status, labelBackgroundColor} from '../statusBadge/StatusBadge'
import {Popover} from '../popover/Popover'
import {StyledLink, StyledStatus, StyledStatusBox, StyledTooltipContent} from './style'
import {MicromorgiIcon, RefreshIcon} from '@/assets/icons/icons'
import {ChatBadgeTrigger} from './trigger/ChatBadgeTrigger'
import {replaceThousands} from '@/utilities/helpers'
import {ROUTE_FAQ_HOST} from '@utilities/constants/routeNames.ts'
import { SubscriptionStatuses } from '@/features/recurring-gift/components/GuestCard'

type ChatBadgeProps = {
    guestStatus: string
    withTooltip?: boolean
    isLight?: boolean
    isModal?: boolean
    monthlyAmount?: number | null
    onChatAmount?: number
    subscriptionStatus?: string | null
    subscriptionValidUntil?: string | null
}

export enum userStatuses {
    GUEST = 'guest',
    SILVER = 'silver',
    GOLD = 'gold',
    DIAMOND = 'diamond',
    ULTIMATE = 'ultimate'
}

export const ChatBadge = ({
    guestStatus,
    withTooltip,
    isLight,
    isModal = false,
    monthlyAmount,
    onChatAmount,
    subscriptionStatus,
    subscriptionValidUntil
}: ChatBadgeProps) => {
    const {t} = useTranslation()
    const background = labelBackgroundColor[guestStatus as Status]
    if (!guestStatus) {
        return null
    }
    return (
        <>
            {withTooltip ? (
                <Popover
                    isModal={isModal}
                    trigger={
                        <ChatBadgeTrigger
                            guestStatus={guestStatus}
                            withTooltip={withTooltip}
                            isLight={isLight}
                            monthlyAmount={monthlyAmount}
                            onChatAmount={onChatAmount}
                        />
                    }
                >
                    <StyledTooltipContent>
                        <Trans
                            t={t}
                            i18nKey="leader_status_tooltip:for_rookie:toolip"
                            values={{
                                context: guestStatus
                            }}
                            components={{
                                a1: <StyledLink to={ROUTE_FAQ_HOST} />
                            }}
                        />
                        {` `}
                        {!!monthlyAmount && t('leader_status_tooltip:for_rookie:monthly_support')}
                        {` `}
                        {!!onChatAmount &&
                            t('leader_status_tooltip:for_rookie:amount_in_chat', {x: replaceThousands(onChatAmount)})}
                    </StyledTooltipContent>
                </Popover>
            ) : (
                <StyledStatusBox withTooltip={!!withTooltip}>
                    <StyledStatus isLight={isLight} backgroundColor={background}>
                        <Trans
                            t={t}
                            i18nKey="leader_statuses:status"
                            values={{
                                context: guestStatus
                            }}
                        />
                        {monthlyAmount && !(subscriptionValidUntil && subscriptionValidUntil <= new Date().toISOString().slice(0, 19).replace('T', ' ')  && subscriptionStatus === SubscriptionStatuses.CANCELED) ? (
                            <>
                                <RefreshIcon />
                                <span>{monthlyAmount}</span>
                            </>
                        ) : onChatAmount ? (
                            <MicromorgiIcon />
                        ) : (
                            <></>
                        )}
                    </StyledStatus>
                </StyledStatusBox>
            )}
        </>
    )
}

import {z} from 'zod'
import * as schemas from './services/goal.schema'
import {AxiosRequestConfig} from 'axios'

export type GoalSizeType = z.infer<typeof schemas.GoalSizeSchema>
export type CreateGoalValidationSchema = z.infer<typeof schemas.CreateGoalValidationSchema>
export type CreationGoalType = z.infer<typeof schemas.CreationGoalSchema>
export type GoalType = z.infer<typeof schemas.GoalSchema>
export type EditGoal = z.infer<typeof schemas.EditGoalSchema>
export type EditMedia = z.infer<typeof schemas.EditMediaSchema>
export type GetGoalsListRequest = z.infer<typeof schemas.GetGoalsListRequestSchema>
export type ResponseActiveGoalsList = z.infer<typeof schemas.GoalsResponseSchema>
export type PathGoalsResponse = z.infer<typeof schemas.PathGoalsResponseSchema>
export type SortParam = z.infer<typeof schemas.SortParamSchema>
export type GetSavedGoalsList = z.infer<typeof schemas.GetSavedGoalsListSchema>
export type GetPastGoalsList = z.infer<typeof schemas.GetPastGoalsListSchema>
export type GetSupportedGoalsList = z.infer<typeof schemas.GetSupportedGoalsListSchema>
export type CreateBroadcast = z.infer<typeof schemas.CreateBroadcastSchema>
export type Broadcast = z.infer<typeof schemas.BroadcastSchema>
export type Message = z.infer<typeof schemas.MessageSchema>
export type NicknameOfLeader = z.infer<typeof schemas.NicknameOfLeaderSchema>
export type ProofRequest = z.infer<typeof schemas.ProofRequestSchema>
export type ProofValidationSchema = z.infer<typeof schemas.ProofGoalValidationSchema>
export type GoalsPathsRequest = z.infer<typeof schemas.GoalsPathsRequestSchema>
export type UpdateLeaderNicknameRequest = z.infer<typeof schemas.UpdateLeaderNicknameRequestSchema>
export type DeleteLeaderNicknameRequest = Omit<z.infer<typeof schemas.UpdateLeaderNicknameRequestSchema>, 'data'>
export type AddLeaderNickname = Omit<z.infer<typeof schemas.UpdateLeaderNicknameRequestSchema>, 'nicknameId'>
export type UploadMediaRequest = {data: FormData; options: AxiosRequestConfig | null; fileType: string}
export type SupporterType = z.infer<typeof schemas.SupporterSchema>
export type GoalMediaType = z.infer<typeof schemas.GoalMediaSchema>
export type ProofType = z.infer<typeof schemas.GoalProofSchema>

export enum GoalStatusesE {
    ACTIVE = 'active',
    AWAITING_PROOF = 'awaiting_proof',
    PROOF_PENDING_APPROVAL = 'proof_pending_approval',
    PENDING = 'pending', //pending_admin_approval
    DEFAULT = 'default',
    SUCCESSFUL = 'successful',
    PROOF_STATUS_DECLINED = 'proof_status_declined',
    CANCELED = 'cancelled',
    SUSPENDED = 'suspended',
    REVIEW = 'review'
}

export enum PoofStatusesE {
    APPROVED = 'approved',
    PENDING = 'pending',
    DECLINED = 'declined',
    DEFAULT = 'default'
}

export enum UploadAreaTypeE {
    PROOF_VIDEO = 'proof_video',
    PROOF_PHOTO = 'proof_photo',
    AUDIO = 'audio'
}

export type GeneralError = Error & {
    response?: {data: {message: string}; status: number}
}

import {Modal} from '@/components/ui/modal/Modal'
import styled, {DefaultTheme, css} from 'styled-components'

export const StyledModal = styled(Modal)`
    ${({theme: {mediaQueries}, vh}: {theme: DefaultTheme; vh: number}) => css`
        border-radius: 0 !important;
        display: grid;
        grid-template-rows: 0fr 1fr;
        overflow: hidden;
        height: ${vh}px;
        width: 100vw;

        ${mediaQueries.m} {
            width: 500px;
            max-height: 668px;
            border-radius: 10px !important;
        }
    `}
`

export const StyledBody = styled.div`
    ${({theme: {palette, typography, spacing}}) => css`
        color: ${palette.primary[700]};
        text-align: center;
        ${typography.textMd}
        font-weight: 400;
        line-height: 140%;
        margin-bottom: ${spacing * 2}px;
        height: 100%;
    `}
`

export const StyledContentWrapper = styled.div`
    ${({theme: {palette, spacing, mediaQueries}}) => css`
        height: 95%;
        padding: 0 ${spacing * 2}px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        gap: ${spacing * 2}px;

        ${mediaQueries.m} {
            padding: 0 ${spacing * 3}px;
        }

        &::-webkit-scrollbar {
            background: transparent;
            border-right-radius: 80px;
            height: 5px;
            width: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background: ${palette.primary[900]};
        }
    `}
`

export const StyledChangePaymentMethodsButton = styled.span`
    ${({theme: {palette}}) => css`
        align-self: center;
        font-weight: 700;
        color: ${palette.primary[900]};
    `}
`

export const StyledOtherMethodDescription = styled.div`
    ${({theme: {palette}}) => css`
        text-align: center;
        font-size: 16px;
        font-weight: 800;
        color: ${palette.primary[900]};
        position: relative;
    `}
`

export const StyledDivider = styled.div`
    ${({theme: {palette}}) => css`
        display: block;
        color: ${palette.primary[900]};
    `}
`

import {TopicOptionToShow} from '@/features/profile/helpers/helpers'
import {useRootStore} from '@/store'
import {selectChatFavTopics} from '@/store/selectors'
import {capitalize} from '@/utilities/helpers'
import {FC, ReactNode} from 'react'
import {DefaultTheme, FlattenSimpleInterpolation} from 'styled-components'
import {StyledListWrapper, StyledTitle, StyledTopicItem, StyledTopicsList} from './style'
import { useTranslation } from 'react-i18next'

type HostTopicsListProps = {
    items: TopicOptionToShow[]
    icon?: ReactNode
    withIcon?: boolean
    colorTopics?: string
    className?: string
    topicsListStyles?: (theme: DefaultTheme) => FlattenSimpleInterpolation
    isInFeed?: boolean
    topicsTitle?: string
}

export const HostTopicsList: FC<HostTopicsListProps> = ({
    items = [],
    colorTopics,
    className,
    topicsListStyles,
    isInFeed = false,
    topicsTitle=''
}) => {
    const topics = useRootStore(selectChatFavTopics)
    const filteredItems = items?.filter(item => item?.key_name in topics)
    const {t} = useTranslation()
    return (
        <StyledListWrapper isInFeed={isInFeed}>
            {isInFeed && <StyledTitle isInFeed={isInFeed}> {t(topicsTitle)}{":"} </StyledTitle>}
            <StyledTopicsList className={className} topicsListStyles={topicsListStyles}>
            {!isInFeed && <StyledTitle isInFeed={isInFeed}> {t(topicsTitle)} </StyledTitle>}
                {filteredItems.map((item, index) => (
                    <StyledTopicItem key={item.id} style={{color: colorTopics ? colorTopics : item.color}}>
                        {capitalize(topics[item?.key_name])}
                        {index < filteredItems.length - 1 && ', '}
                    </StyledTopicItem>
                ))}
            </StyledTopicsList>
            {/* {withDots && '...'} */}
        </StyledListWrapper>
    )
}

HostTopicsList.displayName = 'HostTopicsList'

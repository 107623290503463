import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from './keys'
import {ANON_API} from '../services/anonTransaction.http'
import {MonthlySupportError} from '@/features/guest-payments/types'
import {AxiosResponse} from 'axios'
import { RetryTransactionGiftResponse } from '../services/types'

export const useRetryTransaction = ({id, enabled}: {id?: number | null; enabled: boolean}) => {
    return useQuery<AxiosResponse, MonthlySupportError, RetryTransactionGiftResponse>({
        queryKey: [QUERY_KEYS.retry_transaction, id],
        queryFn: () => ANON_API.retryTransaction(id),
        enabled
    })
}

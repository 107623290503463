import {useMutation, useQueryClient} from '@tanstack/react-query'
import {MUTATION_KEYS, QUERY_KEYS} from './keys'
import {CHAT_API} from '../services/chat.http'

export const useSendFeaturedHost = (onSuccess: () => void) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.send_featured_host],
        mutationFn: ({chanelID, hostId}: {chanelID: string; hostId: number}) =>
            CHAT_API.sendFeaturedHost({chanelID, hostId}),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.fetchChannels]})
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.fetchChannelDetails]})
            onSuccess()
        }
    })
}

import {GoalStatusesE, GoalType} from '@/features/goal/types'
import {IconButton} from '../../../icon-button/IconButton'
import {GoalShareIcon, HeartIcon, HeartIconFilled, Chat} from '@/assets/icons/icons'
import {useSaveGoal} from '@/features/goal/queries/useSaveGoal'
import {useUnsaveGoal} from '@/features/goal/queries/useUnsaveGoal'
import {useHandleError} from '@/hooks/useHandleError'
import useRedirectToChatChannel from '@/hooks/useRedirectToChatChannel'

type GuestStatusSectionProps = {
    goal?: Partial<GoalType>
    onShareClick?: () => void
    onClose?: () => void
}

export const GuestStatusSection = ({goal, onShareClick, onClose}: GuestStatusSectionProps) => {
    const {mutate: saveGoal, isError: isSaveGoalError, error: saveGoalError} = useSaveGoal(`${goal?.id}`)
    const {mutate: unSaveGoal, isError: isUnSaveGoalError, error: unSaveGoalError} = useUnsaveGoal(`${goal?.id}`)
    const redirectToChatChannel = useRedirectToChatChannel({receiverId: parseInt(`${goal?.rookie_id}`)})
    const onRedirectChatHandler = () => {
        redirectToChatChannel?.()
        onClose?.()
    }
    const hideAllButtons =
        goal?.status === GoalStatusesE.SUSPENDED ||
        goal?.status === GoalStatusesE.REVIEW ||
        goal?.status === GoalStatusesE.CANCELED
    const hasGuestSupportedGoal = !!goal?.leader_donations_amount
    const isActiveGoal = goal?.status === GoalStatusesE.ACTIVE
    const showShareButton =
        (hasGuestSupportedGoal && isActiveGoal) ||
        goal?.status === GoalStatusesE.AWAITING_PROOF ||
        goal?.status === GoalStatusesE.PROOF_PENDING_APPROVAL

    const onSave = () => {
        if (!goal?.is_saved) {
            saveGoal()
        } else {
            unSaveGoal()
        }
    }

    useHandleError({isError: isSaveGoalError || isUnSaveGoalError, error: saveGoalError || unSaveGoalError})

    if (hideAllButtons) return null

    return (
        <>
            {isActiveGoal && (
                <IconButton
                    icon={goal?.is_saved ? <HeartIconFilled height={20} width={20} /> : <HeartIcon />}
                    onClick={onSave}
                />
            )}
            {hasGuestSupportedGoal && <IconButton icon={<Chat />} onClick={onRedirectChatHandler} />}
            {showShareButton && <IconButton icon={<GoalShareIcon />} onClick={onShareClick} />}
        </>
    )
}

// !!!IMPORTANT - this rules only for GUEST side!!!

// 1. If status "active" and guest hasn't supported goal - show only SAVE BUTTON
// 2. If status "active" and guest has supported goal - show SAVE BUTTON, CHAT BUTTON and SHARE BUTTON
// 3. If status "awaiting_proof", "proof_pending_approval", "successful" -  CHAT BUTTON and SHARE BUTTON
// 4. If status "suspended", "review", "canceled" - hide all buttons

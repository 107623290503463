export const palette = {
    light: {
        neutral: {
            white: '#FFFFFF',
            25: '#737373', //darkGrey
            50: '#80839E', //midGrey
            75: '#cccdd8', //lightGrey
            100: '#333333', //text
            125: '#bfbfbf',
            150: '#dce0e0',
            200: '#D1D1D1', //stroke
            225: '#e8e8e8',
            300: '#9D9D9D', //subText
            350: '#f2f2f5',
            400: '#DEE5EF', //#xLightGrey
            500: '#A4B0C0', //grey
            600: 'rgb(0,0,0, 0.1)', //blackOpacity
            625: 'rgba(255, 255, 255, 0.3)',
            650: 'rgb(0,0,0, 0.2)',
            700: '#00000080', //blackOpacityMid
            750: '#171d29b8',
            800: '#2C2C2C', //lightBlack
            850: '#a4b0c01a',
            900: '#394A64', //iconGrey
            950: '#E3E3E3', //lightGrey
            1000: `#00000026`,
            black: '#000000',
            transparent: 'transparent'
        },
        primary: {
            25: 'rgba(239,236,255, 0.5)', //lowPurpleOpacity
            50: 'rgba(0, 6, 61, 0.1)', //darkPurpleOpacity
            75: '#6055DB',
            100: 'rgba(134, 73, 209, 0.1)',
            125: 'rgba(134, 73, 209, 0.6)',
            150: '#8C6EE0',
            200: '#8649D1', //primary
            225: '#8B6DE8',
            250: '#8F92FF',
            275: '#26323d', //tooltip
            300: '#EFECFF', //
            325: '#9747FF',
            350: '#723eb2', //chat isMine message background
            400: '#B99AD8', //inactivePurple
            450: '#9A85F5', //primaryLight
            500: '#F0E4FF', //primaryAlertBg
            550: '#B80959',
            600: '#50109D', //primaryCloseAlert
            625: '#efecff80',
            650: '#8649d1b3',
            750: '#B2B4C4', //rgba(0, 6, 61, 0.3)
            700: 'rgba(0, 6, 61, 0.5)', //darkPurpleMidOpacity
            800: 'rgba(0, 6, 61, 0.7)',
            850: '#7906c7',
            900: '#00063D', //darkPurple
            950: '#b692e3',
            1000: '#5e2eba1a'
        },
        secondary: {
            100: '#80829E',
            200: '#80DEEA',
            300: '#BCAAA4',
            400: '#EF9A9A',
            500: 'rgb(164, 176, 192)',
            600: 'rgb(250, 250, 251)',
            625: '#FED75A',
            700: '#ffcb0b',
            800: '#feb80c',
            900: '#fef8e2'
        },
        success: {
            25: '#CFFFE2', //successAlertBg
            50: '#00613E', //successCloseAlert
            125: '#AED581',
            100: '#0BC196',
            200: '#089171',
            250: '#00955f1a',
            300: '#68EC92', //greenChat
            400: '#6FCF97', //green
            500: '#00D015', //notificationGreen
            600: '#00955F', //successAlert
            625: '#6BDAB2',
            650: '#60C29F',
            700: '#96FFB3', //greenLabel
            800: '#8b9898'
        },
        tertiary: {
            25: '#FDBA0D', //yellow
            50: '#FFBF1B',
            75: 'rgba(253, 186, 13, 0.15)', //lightOrange
            100: '#FFBF1B',
            125: 'rgba(253, 186, 13, 0.6)',
            200: '#FC9F21',
            300: '#FFDA48', //yellowInactive
            400: '#FDCC0D' //yellowLight
        },
        warning: {
            25: '#FFFCF5',
            50: '#EB5757', //warningRed
            100: '#701919', //darkWarningRed
            150: '#fc9f211a',
            200: '#FEDF89',
            300: '#FFF1F1', //failureAlertBg
            400: '#f69f9f', //lightRed
            500: '#F79009',
            600: '#DC6803',
            700: '#B54708',
            800: '#93370D',
            900: '#7A2E0E',
            950: '#4E1D09'
        },
        danger: {
            25: '#FF0000', //red
            50: '#DF3030',
            75: '#df30301a',
            100: '#FEF0C7',
            125: '#EC8383',
            150: '#E66060',
            200: '#B11B1B', //failureAlert
            300: '#FEC84B',
            400: '#FDB022',
            500: '#F79009',
            600: '#DC6803',
            625: '#F10000',
            700: '#B54708',
            750: '#F18526',
            800: '#FF0000',
            900: '#7A2E0E',
            950: '#4E1D09'
        },
        gradient: {
            25: 'linear-gradient(0deg, rgba(0, 0, 0, 0.36) 0%, rgba(0, 0, 0, 0.01) 41%), rgb(134, 73, 209)', //starred message item moments page
            50: 'linear-gradient(90deg, #7e41ef 0%, #9a3af5 38.54%, #bb35fc 100%)', //starred message chat
            75: 'linear-gradient(308deg, #FFBF1B 0%, #FFC300 38.54%, #FDCC0D 100%)', //starred message chat
            100: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.32) 29.17%, rgba(255, 255, 255, 0.99) 52.08%)', // white fade
            125: 'linear-gradient(0deg, #502486 0%, #6d53c1 38.54%, #6163d1 100%)', //surprises modal
            150: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 50.52%, #fff 100%)',
            200: 'linear-gradient(rgba(0, 0, 0, 0.7) -10.63%, rgba(0, 0, 0, 0.7) -10.61%,rgba(0, 0, 0, 0.1) 52.47%)', // media overlay
            300: 'linear-gradient(0deg, #8649d1 0%, #8b6de8 38.54%, #8f92ff 100%)', // for whom background
            400: 'linear-gradient(to top, #8c6ee0 0%, #b39aff 100%)',
            500: '-webkit-linear-gradient(to top, #8C6EE0 0%, #B39AFF 100%)',
            600: '-moz-linear-gradient(to top, #8C6EE0 0%, #B39AFF 100%)',
            700: '-webkit-linear-gradient(#d4cbfa, #8464dd)', //text gradient light
            750: '-webkit-linear-gradient(#6055DB,#00063D)', //text gradient dark
            800: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 60.94%, rgba(0, 0, 0, 0.71) 100%)', //black bottom fade
            850: 'linear-gradient(0deg, rgba(0, 0, 0, 0) 60.94%, rgba(0, 0, 0, 0.71) 100%)', //black top fade
            900: 'linear-gradient(rgba(0, 0, 0, 0.28) 0%, rgba(0, 0, 0, 0.12) 25%, rgba(0, 0, 0, 0.133) 75%, rgba(0, 0, 0, 0.67) 100%)', //black preview overlay
            950: 'linear-gradient(180deg,rgba(255, 255, 255, 0) 0%,rgba(255, 255, 255, 0.32) 55.17%,rgba(255, 255, 255, 0.99) 100.08%)', //light text bottom overlay
            1000: 'linear-gradient(0deg, rgb(134, 73, 209) 0%, rgb(139, 109, 232) 38.54%, rgb(143, 146, 255) 100%) text'
        },
        textGradients: {
            primary: {
                background: 'linear-gradient(to top, #8C6EE0 0%, #B39AFF 100%)',
                '-webkit-background-clip': 'text',
                '-webkit-text-fill-color': 'transparent',
                fallbacks: [
                    {
                        background: '-webkit-linear-gradient(to top, #8C6EE0 0%, #B39AFF 100%)'
                    },
                    {
                        background: '-moz-linear-gradient(to top, #8C6EE0 0%, #B39AFF 100%)'
                    },
                    {background: '#8C6EE0'}
                ]
            }
        }
    }
}

import {useQuery} from '@tanstack/react-query'
import {GOAL_API} from '../services/goal.http'
import {QUERY_KEYS} from './keys'

export const useFetchGoal = (goalId?: string | null) => {
    return useQuery({
        queryKey: [QUERY_KEYS.selected_goal, goalId],
        queryFn: () => GOAL_API.getGoal({goalId: `${goalId}`}),
        enabled: !!goalId
    })
}

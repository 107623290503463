import createHeaderModeSlice from '@/components/commons/header/store/createHeaderModeSlice'
import createChatFavTopicsSlice from '@/features/chat-fav-topics/store/createChatFavTopicsSlice'
import createChatSlice from '@/features/chat/store'
import createAlertsSlice from '@/features/profile/components/alerts-section/store/createAlertsSlice'
import createGlobalTranslateSlice from '@/features/translation/store/createGlobalTranslateSlice'
import {lens, withLenses} from '@dhmk/zustand-lens/esm'
import {create} from 'zustand'
import {immer} from 'zustand/middleware/immer'
import {createAnimationTriggersSlice} from './createAnimationTriggersSlice'
import {createGoalFiltersSlice} from './createGoalFiltersSlice'
import * as modals from './modals'

export const useRootStore = create(
    immer(
        withLenses(() => ({
            alerts: lens(createAlertsSlice),
            animations: lens(createAnimationTriggersSlice),
            globalTranslate: lens(createGlobalTranslateSlice),
            headerMode: lens(createHeaderModeSlice),
            chat: lens(createChatSlice),
            goalsFilters: lens(createGoalFiltersSlice),
            chatFavTopics: lens(createChatFavTopicsSlice),
            modals: lens(() => ({
                approachFirstModal: lens(modals.createApproachFirstModalSlice),
                connectNowModal: lens(modals.createConnectNowModalSlice),
                buyMorgisModal: lens(modals.createBuyMorgisModalSlice),
                cancelRecurringGiftModal: lens(modals.createCancelRecurringGiftModalSlice),
                choosePaymentsMethodModal: lens(modals.createChoosePaymentsMethodModalSlice),
                confirmSendGiftModal: lens(modals.createConfirmSendGiftModalSlice),
                customSendGiftModal: lens(modals.createCustomSendGiftModalSlice),
                openSurprisesModal: lens(modals.createOpenSurprisesModalSlice),
                errorModal: lens(modals.createErrorModalSlice),
                goalDeletionSuccessModal: lens(modals.createGoalDeletionSuccessModalSlice),
                goalModal: lens(modals.createGoalModalSlice),
                hostProfileModal: lens(modals.createHostProfileModalSlice),
                logoutModal: lens(modals.createLogoutModalSlice),
                paidGifsModal: lens(modals.createPaidGifsModalSlice),
                pauseConnectionModal: lens(modals.createPauseConnectionModalSlice),
                recurringGiftModal: lens(modals.createRecurringGiftModalSlice),
                selectGlobalLanguageModal: lens(modals.createSelectGlobalLanguageModalSlice),
                sendMegaGiftModal: lens(modals.createSendMegaGiftModalSlice),
                successMegaGiftOverlay: lens(modals.createSuccessMegaGiftOverlaySlice),
                topFanCompleteModal: lens(modals.createTopFanCompleteModalSlice),
                topFanPendingModal: lens(modals.createTopFanPendingModalSlice),
                updateCreditCardModal: lens(modals.createUpdateCreditCardModalSlice),
                walletModal: lens(modals.createWalletModalSlice),
                getUltimateStatusModal: lens(modals.createGetUltimateStatusModalSlice),
                starredMessageModal: lens(modals.createStarredMessageModalSlice),
                gifModal: lens(modals.createGifModalSlice),
                sendGifModal: lens(modals.createSendGifModalSlice),
                sendBffModal: lens(modals.createSendBffModalSlice),
                goalTeaserModal: lens(modals.createGoalTeaserModalSlice),
                reachedLimitModal: lens(modals.createReachedLimitModalSlice),
                paidMessageModal: lens(modals.createPaidMessageModalSlice),
                aboutUsModal: lens(modals.createAboutUsModalSlice),
                meetMoreHostModal: lens(modals.createMeetMoreHostModalSlice),
                anonRetryModal: lens(modals.createAnonRetryModalSlice)
            }))
        }))
    )
)

import styled, {css, DefaultTheme, keyframes} from 'styled-components'

type StyledOverlayProps = {
    overlayFullscreen?: boolean
}

type StyledSvgProps = {
    $size: number
    zIndex: number
}

const rotateAnimation = keyframes`
    100% {
        transform: rotate(360deg);
    }
`

const dashAnimation = keyframes`
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
`

export const StyledOverlay = styled.div<StyledOverlayProps>`
    ${({theme, overlayFullscreen}: {theme: DefaultTheme; overlayFullscreen?: boolean}) => css`
        z-index: ${theme.zIndex.tooltip};
        position: ${overlayFullscreen ? 'fixed' : 'absolute'};
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(255, 255, 255, 0.5);
    `}
`

export const StyledSvgBox = styled.div`
    ${({
        theme,
        inline,
        $size,
        center,
        zIndex,
        withMargin = true
    }: {
        theme: DefaultTheme
        inline: boolean
        $size: number
        center: boolean
        zIndex: number
        withMargin?: boolean
    }) => css`
        position: ${inline ? 'absolute' : 'fixed'};
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: ${zIndex ? zIndex : theme.zIndex.tooltip};
        ${center &&
        css`
            left: 50%;
            margin-left: ${-$size / 2}px;
            top: 50%;
            margin-top: ${-$size / 2}px;
        `}

        ${inline &&
        css`
            position: static;
            margin: ${withMargin ? `${$size}px 0` : 'unset'};
        `}
    `}
`

export const StyledSvg = styled.svg<StyledSvgProps>`
    ${({theme, $size, zIndex}: {theme: DefaultTheme; $size: number; zIndex: number}) => css`
        z-index: ${zIndex ? zIndex : theme.zIndex.tooltip};
        width: ${$size}px;
        height: auto;
        animation: ${rotateAnimation} 2s linear infinite;
        & circle {
            animation: ${dashAnimation} 1.5s ease-in-out infinite;
        }
    `}
`
export const Circle = styled.circle``

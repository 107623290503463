import {envVars} from '@/envVars'
import {MediaTypeE, UserRoleE} from '@/types'
import {LanguageOptionType} from '@/features/country/types'
import {AxiosError} from 'axios'
import i18n, {TFunction} from 'i18next'
import toast from 'react-hot-toast'
import {RouteObject} from 'react-router-dom'
import {ACCEPTABLE_AUDIO_TYPES, ACCEPTABLE_IMAGE_TYPES, ACCEPTABLE_VIDEO_TYPES} from './constants/fileUploader'
import {USER_ROLE_GUEST} from './constants/user'
import {User} from '@/features/authentication/types'
import dayjs from 'dayjs'
import {ChannelID, ClickableLinkType} from '@/features/chat/types.ts'
import {ALL_LINKS, LINKS_WITH_HTTPS} from './constants/regex'
import {LocalStorageManager} from './localStorage'

export const capitalize = (s: string) => (s ? s.charAt(0).toUpperCase() + s.slice(1) : '')

type ReactSelect = {
    label: string
    value: string | number
}
export const retrieveSelectSingleValue = (options: ReactSelect[], value: string) => {
    return options.find(option => option.value.toString() === value)
}

export const retrieveSelectMultiValues = (options: ReactSelect[], values: [string]) => {
    return options.filter(option => values.find(value => value.toString() === option.value.toString()))
}

export const getInitials = (name = 'unknown') => {
    return name.match(/^[\w\W]{2}/g) || []
}

export const getUniqueColor = (colorSet: string[], string?: string) => {
    const getNumericHash = (string = 'unknown') => {
        let hash = 0
        if (string.length === 0) return hash
        for (let i = 0; i < string.length; i++) {
            const char = string.charCodeAt(i)
            hash = (hash << 5) - hash + char
            hash = hash & hash // Convert to 32bit integer
        }
        return hash
    }

    const modulo = colorSet.length
    const colorIndex = ((getNumericHash(string) % modulo) + modulo) % modulo
    return colorSet[colorIndex]
}

export const megabytesToBytes = (megabytes: number) => megabytes * 1_000_000

export const errorHandler = (error: AxiosError) => {
    const messageKey = error.message && error.status != 500 ? `errors:${error.message}` : 'errors:default'
    toast.error(i18n.t(messageKey))
    return error
}

export const getThemeMode = (matches: RouteObject[]) => {
    return matches.filter(match => Boolean(match.handle)).map(match => match.handle.themeMode)[0] ?? 'light'
}

export const getPublicUrl = (route = '', queryString = '') => {
    return envVars.VITE_APP_PUBLIC_WEBSITE + route + queryString
}

export const computeAge = (birthday: string) => {
    if (!birthday) return Number.NaN
    const bDay = new Date(birthday)
    const ageDifMs = Date.now() - bDay.getTime()
    const ageDate = new Date(ageDifMs)
    return Math.abs(ageDate.getUTCFullYear() - 1970)
}

export const calculatePercentage = (value: number, total: number) => {
    if (total === 0) {
        return 0
    }
    return (value / total) * 100
}

export const contains = (arr: string[], str: string) =>
    arr.some(element => {
        return typeof str === 'string' ? str.toLowerCase().includes(element.toLowerCase()) : false
    })

export const checkMediaExtension = (mediaSrc: string) => {
    const imagesTypes = ACCEPTABLE_IMAGE_TYPES.map(item => `.${item}`)
    const videoTypes = ACCEPTABLE_VIDEO_TYPES.map(item => `.${item}`)
    const audioTypes = ACCEPTABLE_AUDIO_TYPES.map(item => `.${item}`)
    const isImage = contains(imagesTypes, mediaSrc)
    const isVideo = contains(videoTypes, mediaSrc)
    const isAudio = contains(audioTypes, mediaSrc)
    if (isVideo) {
        return {
            type: MediaTypeE.VIDEO,
            url: mediaSrc
        }
    } else if (isImage) {
        return {
            type: MediaTypeE.IMAGE,
            url: mediaSrc
        }
    } else if (isAudio) {
        return {
            type: MediaTypeE.AUDIO,
            url: mediaSrc
        }
    }
}
export const getFileType = (fileType: string) => {
    const parts = fileType.split('/')
    switch (true) {
        case parts.length && (parts[0] === MediaTypeE.IMAGE || parts[0] === MediaTypeE.PHOTO):
            return MediaTypeE.IMAGE
        case parts.length && parts[0] === MediaTypeE.VIDEO:
            return MediaTypeE.VIDEO
        case parts.length && parts[0] === MediaTypeE.AUDIO:
            return MediaTypeE.AUDIO
        case fileType.includes('pdf'):
            return MediaTypeE.DOCUMENT
        default:
            return undefined
    }
}

export const convertFileToFormData = ({key, file}: {key: string; file: File}) => {
    const formData = new FormData()
    formData.append(key, file)
    return formData
}

export const getGoalIdFromSlug = (slug?: string) => {
    const arrayFromSlug = slug && slug.match(/.*-(\d+)/)
    const goalId = arrayFromSlug?.length && arrayFromSlug.length > 1 ? arrayFromSlug[1] : ''
    return goalId
}

// TODO - add correct styles for toast
export const successToast = (text: string) => {
    return toast.success(i18n.t(text))
}

type ParamType = {
    options?: {id: number; name: string}[]
    isCapitalize?: boolean
}
export const remapApiOption = ({id, name, ...rest}: {id: number; name: string}, isCapitalize = true) => {
    return {
        id,
        value: String(id),
        label: isCapitalize ? capitalize(name) : name,
        name: name,
        extraPayload: rest
    }
}
export const remapApiOptions = ({options = [], isCapitalize = true}: ParamType) => {
    return options?.map(({id, name, ...rest}) => {
        return remapApiOption({id, name, ...rest}, isCapitalize)
    })
}
export const getDefaultUserLanguageCodeFromBrowser = () => navigator.language.split('-')[0].toLowerCase()

export const getDefaultUserLanguage = (spokenLanguages: LanguageOptionType[], isSpokenLanguagesFetched: boolean) => {
    if (isSpokenLanguagesFetched) {
        const browserLanguage = navigator.language.toLowerCase().includes('-')
            ? navigator.language.toLowerCase().split('-')[0]
            : navigator.language.toLowerCase()

        const foundLanguage = spokenLanguages.filter(lang => lang.extraPayload.code.toLowerCase() === browserLanguage)
        const defaultLanguage = spokenLanguages.filter(lang => lang.extraPayload.code.toLowerCase() === 'en')
        const defaultUserLanguage = foundLanguage.length ? foundLanguage : defaultLanguage

        return defaultUserLanguage
    } else {
        return []
    }
}

export const extractSelectedIds = (selectedOptions: {id: string | number}[]) => {
    return selectedOptions.map(option => +option.id)
}
export const getCorrectStringWithTranslation = (string: string, isTranslationEnabled: boolean) => {
    const t = i18n.t.bind(i18n)
    let correctString = string
    if (isTranslationEnabled) {
        correctString = t(`moengage:${string}`)
    }
    return correctString
}

export const replaceThousands = (amount: number, withoutK = false) => {
    let number
    if (isNaN(amount)) {
        number = '-'
        return number
    }
    if (amount < 1000) {
        number = amount
    } else if (withoutK) {
        const string = String(amount)
        number = string.slice(0, string.length - 3) + ',' + string.slice(string.length - 3)
    } else {
        const sum = amount / 1000
        const roundAmount = Math.round(sum * 10) / 10
        number = roundAmount + 'k'
    }
    return number
}

export const generateChatChannelName = (myId: number, myType: string, receiverId: number): ChannelID => {
    return myType === USER_ROLE_GUEST ? `${myId}-${receiverId}` : `${receiverId}-${myId}`
}

export const generateChatChannelPayers = (userId: number, role: UserRoleE, channelId: number, status: string) => {
    return `${userId}-${role}-${channelId}-${status}`
}

export const generateGoalSlug = (slug: string, goalId: string) => {
    return `${slug}-${goalId}`
}

export const hexToHSL = (hex: string) => {
    const result: RegExpExecArray | null = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    if (!result) return ''

    let r: number = parseInt(result[1], 16)
    let g: number = parseInt(result[2], 16)
    let b: number = parseInt(result[3], 16)
    r /= 255
    g /= 255
    b /= 255
    const max: number = Math.max(r, g, b),
        min: number = Math.min(r, g, b)
    let h: number = 0

    const d: number = max - min
    switch (max) {
        case r:
            h = (g - b) / d + (g < b ? 6 : 0)
            break
        case g:
            h = (b - r) / d + 2
            break
        case b:
            h = (r - g) / d + 4
            break
    }
    h /= 6

    h = Math.round(h * 360)

    return h
}

export const getChatChannelUserIds = (channelName: string) => {
    return channelName.split('-')
}

export const getChatChannelGuestId = (channelName: string) => {
    return channelName.split('-')[0]
}

export const getChatChannelHostId = (channelName: string) => {
    return channelName.split('-')[1]
}

export const getInfoByChannel = (channel?: string, user?: User) => {
    const splitChannel = channel?.split('-')
    if (user?.type && splitChannel?.length === 2) {
        if (user.type === USER_ROLE_GUEST)
            return {
                userId: splitChannel[0],
                channel: splitChannel[1]
            }
        return {
            userId: splitChannel[1],
            channel: splitChannel[0]
        }
    }
    return {
        userId: undefined,
        channel: undefined
    }
}

export const roundUpToTwoPoints = (number: number) => {
    return Math.round((number + Number.EPSILON) * 100) / 100
}

export const getCorrectUserId = (user: Partial<User>) => {
    const userRepresentative = user.type === UserRoleE.Representative || user.type === UserRoleE.Agent

    const correctUserId = userRepresentative ? LocalStorageManager.chatUserId.get() ?? 0 : user?.id
    return +correctUserId
}

export const pubnubTimetokenToDate = (timetoken: string | number) => {
    const t = `${timetoken}`
    return dayjs.unix(Number(t) / 10000000).toDate()
}

export const dateToPubnubTimetoken = (date: string) => {
    return dayjs(date).unix() * 10000000
}

export const formatConversationDate = (date: string | Date): string => {
    const inputDate = dayjs(date)
    const formattedDate = inputDate.format('L')
    return formattedDate
}

export const formatConversationTime = (date: string | Date): string => {
    const inputDate = dayjs(date)
    const formattedDate = inputDate.format('LT')
    return formattedDate
}

export const formatMessageDate = (date: string | Date): string => {
    const inputDate = dayjs(date)
    const formattedDate = inputDate.format('L LT')
    return formattedDate
}

/*
 * Get a random integer between min and max (excluded)
 * */
export const getRandInt = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min) + min)
}

/*
 * Get a specific number of disjoint partitions from a given array.
 * Handles the case when the array size is not divisible by the number of requested partitions:
 * if the array size is not divisible by the number of requested partitions, the remaining elements
 * are distributed one by one across the partitions to ensure the partitions are as balanced as possible.
 */
export const getDisjointPartitions = (arr: unknown[], numPartitions: number) => {
    const partitions = []
    const len = arr.length
    if (len < numPartitions) return [arr]
    const partitionSize = Math.floor(len / numPartitions)
    let remainingElements = len % numPartitions

    let startIndex = 0

    for (let i = 0; i < numPartitions; i++) {
        const partition = arr.slice(startIndex, startIndex + partitionSize)

        if (remainingElements > 0) {
            partition.push(arr[startIndex + partitionSize])
            startIndex += partitionSize + 1
            remainingElements--
        } else {
            startIndex += partitionSize
        }

        partitions.push(partition)
    }
    return partitions
}

/*
 * Extracts a random number from an array
 */
export const extractRandomElement = (arr: unknown[]) => {
    if (arr.length === 0) {
        console.log('The array is empty.')
        return undefined
    }

    const randomIndex = Math.floor(Math.random() * arr.length)
    const extractedElement = arr[randomIndex]
    arr.splice(randomIndex, 1)

    return extractedElement
}

/*
 *  Take random indexes from an array.
 *  The function exclude indexes before a start point and indexes after and ending point.
 *  E.g. given [a,b,c,d,e,f,g] and start = 1 and end = 5, the result will be an array containing a random index between 1 (included) and 5 (included)
 */
export const getRandomIndexesInRange = (arr: unknown[], start: number, end: number, numIndexes = 1): number[] => {
    if (start >= end) return [start]
    const availableIndexes = arr.map((_, index) => index).slice(start, end + 1)

    if (numIndexes > availableIndexes.length) {
        console.log('The requested number of indexes exceeds the available indexes within the range.')
        return []
    }

    const randomIndexes = []

    while (randomIndexes.length < numIndexes) {
        const randomIndex = availableIndexes[Math.floor(Math.random() * availableIndexes.length)]
        randomIndexes.push(randomIndex)
        availableIndexes.splice(availableIndexes.indexOf(randomIndex), 1)
    }
    return randomIndexes
}

/*
 * Iterate over each key-value pair in the object and
 * repeat the key in the resulting array based on the number specified in the value.
 */
export const fillArrayFromObject = (obj: {[key: string]: number}) => {
    const result = []

    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const count = obj[key]
            for (let i = 0; i < count; i++) {
                result.push(key)
            }
        }
    }

    return result
}

/*
 * Insert item in an array before a specific index
 */
export const insertBeforeIndex = (arr: unknown[], index: number, item: unknown) => {
    if (index < 0 || index > arr.length) {
        console.log('Invalid index.')
        return arr
    }

    arr.splice(index, 0, item)
    return arr
}

/*
 * Computes the difference of values across the common properties among two objects
 * e.g:  the difference between { a: 2, b: 3, c: 3 } and { a: 1, b: 1 } will be { a: 1, b: 2, c: 3 }
 * e.g:  the difference between { a: 2, b: 3 } and { a: 1, b: 1, c: 3 } will be { a: 1, b: 2 }
 * */
export const computeValuesDifferenceBetweenObj = (
    obj1: {[key: string]: number} = {},
    obj2: {[key: string]: number} = {}
) => {
    const result = {...obj1} // Copy the first object to preserve its properties

    for (const key in obj2) {
        if (Object.prototype.hasOwnProperty.call(result, key)) {
            // Subtract the values of common properties
            result[key] -= obj2[key]
        }
    }

    return result
}

export const sleep = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms))
}

export const convertTimeToLocalFromUTC = ({
    date,
    targetFormat = 'YYYY-MM-DD HH:mm:ss',
    newFormat = 'YYYY-MM-DD HH:mm:ss'
}: {
    date: string
    targetFormat?: string
    newFormat?: string
}): string => {
    const localDate = dayjs.utc(date, targetFormat).local()
    return localDate.format(newFormat)
}

export const getMessageToSend = (message: string, textToReplace: string, textToSend: string) => {
    if (message.includes(textToReplace)) {
        const regex = new RegExp(textToReplace, 'g')
        return message.replace(regex, textToSend)
    } else {
        return message
    }
}

export const getStringToCreateUrl = (text: string) => {
    if (text?.search(LINKS_WITH_HTTPS) !== -1) {
        return text
    } else if (text?.search(ALL_LINKS) !== -1) {
        return `https://${text}`
    }
    return ''
}

export const convertTextToClickableLinks = (
    message: string,
    onClickLinkCallback: (url: string) => void,
    clickableLinks: ClickableLinkType[]
) => {
    if (message?.search(ALL_LINKS) !== -1) {
        const splitString = message?.split(/[ \r\n]+/g)
        return (
            <>
                {splitString?.map(part => {
                    const correctPart = part.trim()
                    const link = clickableLinks.find(item => item.string === correctPart)
                    let url: string
                    if (link && link.query) {
                        const urlObject = new URL(getStringToCreateUrl(correctPart))
                        urlObject.search = ''

                        url =
                            !!link && link.string === correctPart && !!link.params
                                ? `${urlObject.href}?${link.params}`
                                : `${urlObject.href}`
                    } else {
                        url =
                            !!link && link.string === correctPart && !!link.params
                                ? `${correctPart}?${link.params}`
                                : `${correctPart}`
                    }

                    if (url?.search(LINKS_WITH_HTTPS) !== -1 && !!link && link.string === correctPart) {
                        return (
                            <>
                                <a target="_blank" href={url} onClick={() => onClickLinkCallback(url)}>
                                    {`${link.host}`}
                                </a>{' '}
                            </>
                        )
                    } else if (url?.search(ALL_LINKS) !== -1 && !!link && link.string === correctPart) {
                        return (
                            <>
                                <a target="_blank" href={`https://${url}`} onClick={() => onClickLinkCallback(url)}>
                                    {`${link.host}`}
                                </a>{' '}
                            </>
                        )
                    } else {
                        return `${correctPart} `
                    }
                })}
            </>
        )
    } else {
        return <>{message}</>
    }
}

export const formatUploadTime = (postDate: string, t: TFunction): string => {
    const uploadTime = new Date(postDate) // Convert the string date to a Date object
    if (isNaN(uploadTime.getTime())) {
        throw new Error('Invalid date format') // Throw an error if the date is invalid
    }

    const now = new Date() // Current date
    const timeDifferenceInMs = now.getTime() - uploadTime.getTime() // Difference in milliseconds
    const timeDifferenceInDays = Math.floor(timeDifferenceInMs / (1000 * 60 * 60 * 24)) // Difference in days

    if (timeDifferenceInDays === 0) {
        const timeDifferenceInHours = Math.floor(timeDifferenceInMs / (1000 * 60 * 60)) // Difference in hours
        if (timeDifferenceInHours === 0) {
            const timeDifferenceInMinutes = Math.floor(timeDifferenceInMs / (1000 * 60)) // Difference in minutes
            if (timeDifferenceInMinutes === 0) {
                const timeDifferenceInSeconds = Math.floor(timeDifferenceInMs / 1000) // Difference in seconds
                return `${timeDifferenceInSeconds} ${t('common:seconds')} ${t('common:ago')}`.toLowerCase()
            }
            return `${timeDifferenceInMinutes}  ${t('common:minutes')} ${t('common:ago')}`.toLowerCase()
        }
        return `${timeDifferenceInHours} ${t('common:hours')} ${t('common:ago')}`.toLowerCase()
    }

    if (timeDifferenceInDays <= 7) {
        return `${timeDifferenceInDays} ${t('common:days')} ${t('common:ago')}`.toLowerCase()
    } else {
        // Format the date as "Month, Day, Year"
        const options: Intl.DateTimeFormatOptions = {month: 'long', day: '2-digit', year: 'numeric'}
        return uploadTime.toLocaleDateString('en-US', options)
    }
}

import {feedCardVariants} from '@/features/feed/components/feed-card/FeedCard'
import {
    SESSION_STORAGE_ANON_TRANSACTION,
    SESSION_STORAGE_IS_FIRST_LOGIN,
    SESSION_STORAGE_RELOAD_PAGE_COUNT,
    SESSION_STORAGE_SEEN_ADD_HOME_SCREEN_BANNER
} from './constants/sessionStorage'
import {LocalStorageManager} from './localStorage'

const checkIsFirstLogin = {
    set: (value: boolean) => {
        sessionStorage.setItem(SESSION_STORAGE_IS_FIRST_LOGIN, JSON.stringify(value))
    },
    get: () => {
        const data = sessionStorage.getItem(SESSION_STORAGE_IS_FIRST_LOGIN)
        return data ? JSON.parse(data) : null
    },
    remove: () => {
        sessionStorage.removeItem(SESSION_STORAGE_IS_FIRST_LOGIN)
    }
}

const seenAddHomeScreenBanner = {
    get: () => {
        const data = sessionStorage.getItem(SESSION_STORAGE_SEEN_ADD_HOME_SCREEN_BANNER)
        return data ? JSON.parse(data) : false
    },
    set: (value: boolean) => {
        return sessionStorage.setItem(SESSION_STORAGE_SEEN_ADD_HOME_SCREEN_BANNER, JSON.stringify(value))
    },
    remove: () => {
        sessionStorage.removeItem(SESSION_STORAGE_SEEN_ADD_HOME_SCREEN_BANNER)
    }
}
const reloadPageCount = {
    get: function (): number {
        const storedReloadCount = sessionStorage.getItem(SESSION_STORAGE_RELOAD_PAGE_COUNT)
        return storedReloadCount ? parseInt(storedReloadCount, 10) : 0
    },
    set: function (newCount: number) {
        sessionStorage.setItem(SESSION_STORAGE_RELOAD_PAGE_COUNT, newCount.toString())
        const {set} = LocalStorageManager.interactiveFeedSpecialCardsTracker
        set({
            [feedCardVariants.topHost]: false
        })
    },
    remove: function () {
        sessionStorage.removeItem(SESSION_STORAGE_RELOAD_PAGE_COUNT)
    }
}

const checkAnonTransactionModal = {
    set: (value: boolean) => {
        sessionStorage.setItem(SESSION_STORAGE_ANON_TRANSACTION, JSON.stringify(value))
    },
    get: () => {
        const data = sessionStorage.getItem(SESSION_STORAGE_ANON_TRANSACTION)
        return data ? JSON.parse(data) : null
    },
    remove: () => {
        sessionStorage.removeItem(SESSION_STORAGE_ANON_TRANSACTION)
    }
}

export const SessionStorageManager = {checkIsFirstLogin, seenAddHomeScreenBanner, reloadPageCount, checkAnonTransactionModal}

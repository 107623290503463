import React from 'react'
import {useLocation} from 'react-router-dom'

interface RouteVisibilityFragmentProps {
    allowedRoutes?: string[]
    forbiddenRoutes?: string[]
    children: React.ReactElement
}

export const RouteVisibilityFragment = ({
    allowedRoutes = [],
    forbiddenRoutes = [],
    children
}: RouteVisibilityFragmentProps) => {
    const location = useLocation()
    if (
        (!!allowedRoutes.length && allowedRoutes.includes(location.pathname)) ||
        (!!forbiddenRoutes.length && !forbiddenRoutes.some(route => location.pathname.startsWith(route)))
    ) {
        return children
    } else {
        return null
    }
}

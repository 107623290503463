import {GuestProfileExtendedSchema} from '@/features/authentication/services/auth.schema'
import {z} from 'zod'

export const ProfilePhotoResponseSchema = z.object({path_location: z.string(), url: z.string()})
export const GuestProfileSchema = GuestProfileExtendedSchema.deepPartial()
export const ProfileRandomAvatarsSchema = z.object({
    is_gifted: z.boolean(),
    data: z.array(
        z.object({
            id: z.number(),
            user_id: z.number(),
            url: z.string(),
            path_location: z.string(),
            main: z.boolean(),
            created_at: z.string(),
            under_validation: z.boolean()
        })
    )
})
export const PubnubGroupSchema = z.object({
    id: z.number(),
    userId: z.number(),
    name: z.string(),
    category: z.string(),
    createdAt: z.string(), 
    updatedAt: z.string(),
});

export const SuggestedLeaderSchema = z.object({
    avatar: ProfilePhotoResponseSchema.nullable(), 
    fullName: z.string(),
    id: z.number(),
    isOnline: z.boolean(),
    pubnubGroups: PubnubGroupSchema.optional(),
});

import {Lens} from '@dhmk/zustand-lens'

type AnonRetryModalState = {
    isOpen: boolean
}

type AnonRetryModalActions = {
    openModal: (params: Omit<AnonRetryModalState, 'isOpen'>) => void
    closeModal: () => void
}

export type AnonRetryModal = AnonRetryModalState & AnonRetryModalActions

const initialModal: AnonRetryModalState = {isOpen: false}

const createAnonRetryModalSlice: Lens<AnonRetryModal> = set => ({
    ...initialModal,
    openModal: params => set(() => ({...params, isOpen: true})),
    closeModal: () => set(() => ({...initialModal}))
})

export default createAnonRetryModalSlice

import {AlertStatus} from '@/features/authentication/types'
import {Lens} from '@dhmk/zustand-lens'

type AlertsType = {
    variant: AlertStatus
    message?: string
    title?: string
    dismissTimeout?: number
    onAlertClick?: () => void
    currentPath?: string
    onClosed?: () => void
}

export type AlertsStoreType = {
    alerts: AlertsType[]
    addAlert: (alert: AlertsType) => void
    removeAlert: (index: number) => void
    removeAlertFromOtherPath: (path: string) => void
}

const createAlertsSlice: Lens<AlertsStoreType> = set => ({
    alerts: [],
    addAlert: alert =>
        set(state => ({
            alerts: [...state.alerts, alert]
        })),
    removeAlert: index =>
        set(state => ({
            alerts: state.alerts.filter((_, i) => i !== index)
        })),
    removeAlertFromOtherPath: (path: string) =>
        set(state => ({
            alerts: state.alerts.filter(alert => (alert.currentPath ? alert.currentPath === path : true))
        }))
})
export default createAlertsSlice

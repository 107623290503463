import {useMutation, useQueryClient} from '@tanstack/react-query'
import {CHAT_API} from '../services/chat.http'
import {ChannelMessage} from '../types'
import {MUTATION_KEYS} from './keys'
import {GeneralError} from '@/utilities/getAPIErrorMessage'
import {useGetRepresentedUser} from '@/hooks/useGetRepresentedHost'
import {QUERY_KEYS as CHANNELS_QUERY_KEYS} from '@/features/chat/queries/keys'

type MessageResponse = {
    can_send_messages?: boolean
    paid_message_id?: number
    message?: {language: string; text: string; type: string; user_id: number}
}

export const useSendMessageToChannel = () => {
    const {representedUserId} = useGetRepresentedUser()
    const queryClient = useQueryClient()
    return useMutation<MessageResponse, GeneralError, ChannelMessage>({
        mutationKey: [MUTATION_KEYS.send_message, representedUserId],
        mutationFn: (payload: ChannelMessage) => CHAT_API.sendMessage(payload, representedUserId),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [CHANNELS_QUERY_KEYS.fetchChannels]})
        }
    })
}

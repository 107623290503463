import {Button} from '@/components/ui/button/Button'
import {StyledBox, StyledButtonBox, StyledStartBox, StyledSubscriptionBox, StyledSubscriptionContainer} from './style'
import {MegaphoneIcon, MicromorgiIcon, SurprisesIcon} from '@/assets/icons/icons'
import {Trans, useTranslation} from 'react-i18next'
import {useGetHost} from '../guest/queries/useGetHost'
import {replaceThousands} from '@/utilities/helpers'
import {AnonTransactionType} from './services/types'
import { useMe } from '../authentication/queries/useMe'
import { remapCurrency } from '../profile/constants'

export const GiftContent = ({
    onRetryTransaction,
    data
}: {
    onRetryTransaction: () => void
    data: AnonTransactionType
}) => {
    const {t} = useTranslation()
    const {data: host} = useGetHost({filter: `${data?.rookie?.id}`})
    const {data: user} = useMe()
    const currencySymbol = remapCurrency[user?.currency || 'USD']

    return (
        <div>
            <StyledSubscriptionContainer>
                <StyledSubscriptionBox>
                    <StyledBox>
                        <MicromorgiIcon height={31} width={32} />
                        <h1>{data?.subscription_package?.amount}</h1>
                    </StyledBox>
                    <span>
                        {t('configure_membership_plans_modal:plan_price_details', {
                            CURRENCY: currencySymbol,
                            AMOUNT: replaceThousands(data?.subscription_package?.dollar_amount || 1),
                            MORGI: replaceThousands(data?.subscription_package?.amount || 1)
                        })}
                    </span>
                </StyledSubscriptionBox>
                <StyledSubscriptionBox>
                    <StyledStartBox>
                        <SurprisesIcon height={20} width={21} />
                        <h2>{data?.subscription_package?.surprises_count}</h2>
                        <p>{t(`configure_membership_plans_modal:surprises`)}</p>
                    </StyledStartBox>
                    <StyledBox>
                        <MegaphoneIcon height={21} width={20} stroke={'#433C92'} />
                        <p>{data?.group?.name}</p>
                    </StyledBox>
                </StyledSubscriptionBox>
            </StyledSubscriptionContainer>
            <StyledButtonBox>
                <Button onClick={() => onRetryTransaction()}>
                    <Trans
                        t={t}
                        i18nKey={'anon_retry_modal:subscribe'}
                        values={{
                            HOST_NAME: host?.full_name || ''
                        }}
                    />
                </Button>
            </StyledButtonBox>
        </div>
    )
}

import {useTranslation} from 'react-i18next'
import {StyledCardRoot, StyledDescription, StyledInfoSection, StyledName, StyledSliderWrapper} from './style'
import {HostProfilePhotoSlider} from '@/features/profile/components/host-profile-photo-slider/HostProfilePhotoSlider'
import {MediaTypeE, UserRoleE} from '@/types'
import noAvatar from '@/assets/images/noAvatar.png'
import {useMemo} from 'react'
import {computeAge} from '@/utilities/helpers'
import {HostListTypesE} from '../../types'
import {useDeleteFeaturedHost} from '../../queries/useDeleteFeaturedHost'
import {useHandleError} from '@/hooks/useHandleError'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {HostCardCtaSection} from '../host-card-cta-section/HostCardCtaSection'
import {AppStatusesE} from '@/utilities/constants/appStatus'
import {selectAlertsStore, selectHostProfileModal} from '@/store/selectors'
import {useRootStore} from '@/store'
import {MAX_FEATURED_HOSTS} from '@/featuresConfig'
import {HostPartialType, HostPhotosType} from '@/features/authentication/types'
import {useMe} from '@/features/authentication/queries/useMe'

type MorgisTaxiHostCardProps = {
    hostName?: string
    featuredHostId?: number
    birthDate?: string
    pathName?: string
    photos?: Partial<HostPhotosType>[]
    type: HostListTypesE
    guestName?: string
    position?: number
    hostProfile?: HostPartialType
    hostUsername?: string
    inSlider?: boolean
    description?: null | string
}

export const MorgisTaxiHostCard = ({
    hostName,
    hostUsername,
    featuredHostId,
    birthDate,
    pathName,
    photos,
    type,
    guestName,
    position,
    hostProfile,
    inSlider = false,
    description
}: MorgisTaxiHostCardProps) => {
    const {t} = useTranslation()
    const {data: user} = useMe()
    const {openModal: openHostProfileModal} = useRootStore(selectHostProfileModal)
    const isGuest = user?.type === UserRoleE.Guest
    const isHost = user?.type === UserRoleE.Host
    const hideCtaSection = isHost && type === HostListTypesE.CHAT_TEASER
    const {addAlert} = useRootStore(selectAlertsStore)
    const hostAge = useMemo(() => {
        return birthDate ? computeAge(birthDate) : ''
    }, [birthDate])

    const remappedMedia = photos?.length
        ? photos?.map(photo => ({
              type: MediaTypeE.IMAGE,
              url: photo?.url
          }))
        : [{type: MediaTypeE.IMAGE, url: noAvatar}]

    const avatar = photos?.find(item => item?.main)

    const onSuccess = () => {
        addAlert({
            message: t('my_morgis_taxi_page:remove_featured_host_success'),
            variant: AppStatusesE.SUCCESS,
            dismissTimeout: 5000
        })
    }

    const {
        mutate: deleteFeaturedHost,
        isPending: isPendingDeleteFeaturedHost,
        isError: isErrorDeleteFeaturedHost,
        error: errorDeleteFeaturedHost
    } = useDeleteFeaturedHost({onSuccess})

    useHandleError({isError: isErrorDeleteFeaturedHost, error: errorDeleteFeaturedHost})

    const removeHost = () => {
        if (featuredHostId) {
            deleteFeaturedHost({id: featuredHostId})
        }
    }

    const openHostProfile = () => {
        isGuest && hostUsername && openHostProfileModal({hostUsername})
    }
    return (
        <>
            {isPendingDeleteFeaturedHost && <Spinner />}
            <StyledCardRoot withAction={isGuest && !!hostUsername}>
                <StyledSliderWrapper>
                    <HostProfilePhotoSlider
                        photos={inSlider ? [{type: MediaTypeE.IMAGE, url: avatar?.url}] : remappedMedia}
                        host={hostProfile}
                    />
                </StyledSliderWrapper>
                <StyledInfoSection onClick={openHostProfile}>
                    <StyledName>
                        <h4>{hostName}</h4>
                        <span>{`, ${hostAge}`}</span>
                    </StyledName>
                    <StyledDescription>
                        {description ? description : `“${t('my_morgis_taxi_page:card_description', {PATH: pathName})}”`}
                    </StyledDescription>

                    {featuredHostId && !hideCtaSection ? (
                        <HostCardCtaSection
                            featuredHostId={featuredHostId}
                            type={type}
                            onRemove={removeHost}
                            guestName={guestName}
                            maxFeaturedHosts={MAX_FEATURED_HOSTS}
                            position={position}
                        />
                    ) : (
                        <span />
                    )}
                </StyledInfoSection>
            </StyledCardRoot>
        </>
    )
}

MorgisTaxiHostCard.displayName = 'MorgisTaxiHostCard'

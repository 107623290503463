import styled, {css} from 'styled-components'

export const StyledSaveButton = styled.div`
    ${({theme: {palette, shadows, spacing, mediaQueries}}) => css`
        width: 40px;
        cursor: pointer;
        height: 40px;
        display: flex;
        box-shadow: ${shadows.mdXl};
        align-items: center;
        border-radius: 50%;
        justify-content: center;
        background-color: ${palette.neutral.white};
        margin-right: ${spacing}px;

        ${mediaQueries.m} {
            margin-right: ${spacing * 2}px;
        }
    `}
`

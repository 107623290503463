import {z} from 'zod'

export const TopicSchema = z.object({
    id: z.number(),
    name: z.string(),
    key_name: z.string(),
    is_popular: z.boolean(),
    users_count: z.number().optional()
})

export const WishSchema = z.object({
    id: z.number(),
    name: z.string(),
    key_name: z.string()
})

export const WishToBecomeSchema = z.object({
    id: z.number(),
    name: z.string(),
    key_name: z.string(),
    is_subpath: z.boolean(),
    prepend: z.string(),
    users_count: z.number().optional(),
    parent: z.null().optional()
})

export const ExpertiseSchema = z.object({
    id: z.number(),
    key_name: z.string(),
    category: z.string(),
    name: z.string().nullable()
})

export const WishResponseSchema = z.array(WishSchema)
export const TopicsResponseSchema = z.array(TopicSchema)
export const WishToBecomeResponseSchema = z.array(WishToBecomeSchema)
export const ExpertiseResponseSchema = z.array(ExpertiseSchema)

import {Spinner} from '@/components/ui/spinner/Spinner'
import {StyledSaveButton} from './style'
import {HeartIcon, HeartIconFilled} from '@/assets/icons/icons'
import {MouseEvent, useEffect, useState} from 'react'
import {useSaveHost} from '@/features/guest/queries/useSaveHost'
import {useUnsaveHost} from '@/features/guest/queries/useUnsaveHost'
import {useGetSavedHosts} from '@/features/guest/queries/useGetSavedHosts'

type SaveHostButtonProps = {
    featuredHostId: number
}

export const SaveHostButton = ({featuredHostId}: SaveHostButtonProps) => {
    const {mutate: saveHost, isPending: isPendingSave} = useSaveHost()
    const {mutate: unsaveHost, isPending: isPendingUnsave} = useUnsaveHost()
    const {data: savedHosts} = useGetSavedHosts()
    const isSavedHost = !!savedHosts?.find(({id}) => id === featuredHostId)
    const [isSaved, setIsSaved] = useState(isSavedHost)
    const isLoading = isPendingSave || isPendingUnsave

    useEffect(() => {
        setIsSaved(isSavedHost)
    }, [isSavedHost])

    const likeButtonClickHandler = (e: MouseEvent) => {
        e.stopPropagation()
        if (isLoading) return
        if (featuredHostId) {
            if (isSaved) {
                setIsSaved(false)
                unsaveHost(featuredHostId)
            } else {
                setIsSaved(true)
                saveHost(featuredHostId)
            }
        }
    }

    return (
        <div onClick={likeButtonClickHandler}>
            {!isLoading && (
                <StyledSaveButton>
                    {isSaved ? <HeartIconFilled height={20} width={20} /> : <HeartIcon />}
                </StyledSaveButton>
            )}
            {isLoading && <Spinner inline size={16} overlay={false} />}
        </div>
    )
}

SaveHostButton.displayName = 'SaveHostButton'

import styled, {css, DefaultTheme} from 'styled-components'

export const StyledPhotoCount = styled.div(
    ({theme: {spacing, mediaQueries, palette, typography}}) => css`
        color: ${palette.neutral.white};
        width: 100%;
        padding: 0 ${spacing / 2}px 0 ${spacing / 2}px;
        opacity: 0.5;
        ${typography.textSm};
        text-align: center;
        font-weight: 700;
        line-height: ${spacing * 3}px;
        border-radius: ${spacing}px;
        letter-spacing: -0.05em;
        background-color: ${palette.neutral.black};
        padding: 0px ${spacing / 2}px;

        ${mediaQueries.l} {
            padding: 0 ${spacing}px 0 ${spacing}px;
        }
        ${mediaQueries.xl} {
            ${typography.textLg};
        }
    `
)

export const StyledVideoContainer = styled.div(
    ({theme: {spacing, palette, typography}, inTalentCard}: {theme: DefaultTheme, inTalentCard?: boolean}) => css`
        width: ${inTalentCard ? "30px" : "50px"};
        height: ${inTalentCard ? "18px" : "24px"};
        opacity: 0.5;
        text-align: center;
        border-radius: ${spacing}px;
        background-color: ${palette.neutral.black};
        padding: ${inTalentCard ? spacing / 4 : spacing / 2}px;
        ${inTalentCard ? typography.textXxs : typography.textSm};
        text-align: center;
        font-weight: 700;
        line-height: ${spacing * 3}px;
        color: ${palette.neutral.white};
        letter-spacing: -0.05em;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: ${spacing / 2}px;
        & svg {
            ${ inTalentCard &&
                `width: 10px;
                height: 10px;`
            }
            
        }
    `
)

export const StyledGoalContainer = styled.div(
    ({theme: {spacing, mediaQueries, palette, typography}}) => css`
        color: ${palette.neutral.white};
        width: 100%;
        opacity: 0.5;
        ${typography.textSm};
        text-align: center;
        font-weight: 700;
        line-height: ${spacing * 3}px;
        border-radius: ${spacing}px;
        letter-spacing: -0.05em;
        background-color: ${palette.neutral.black};
        padding: 0 ${spacing / 2}px 0 ${spacing / 2}px;

        ${mediaQueries.l} {
            padding: 0 ${spacing}px 0 ${spacing}px;
        }

        ${mediaQueries.xl} {
            ${typography.textLg};
        }
    `
)

import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from './keys'
import {REPRESENTATIVE_API} from '../services/representative.http'
import {useEffect} from 'react'
import {useRootStore} from '@/store'
import {selectChatStore} from '@/store/selectors'

export const useGetRepresentativeHostsChannels = (enabled = true) => {
    const {needReInitAfterNotification, setNeedReInitAfterNotification} = useRootStore(selectChatStore)
    const query = useQuery({
        queryKey: [QUERY_KEYS.representative_hosts_channels],
        queryFn: REPRESENTATIVE_API.representativeHostsChannels,
        enabled
    })

    useEffect(() => {
        if (needReInitAfterNotification && enabled) {
            query.refetch()
            setNeedReInitAfterNotification(false)
        }
    }, [needReInitAfterNotification])

    return query
}

import '@splidejs/react-splide/css'
import {MouseEvent, ReactNode, useEffect, useMemo, useRef} from 'react'
import {DirectionsE, MediaTypeE} from '@/types'
import {
    StyledArrowsWrapper,
    StyledBlockedSection,
    StyledCanvas,
    StyledDateWrap,
    StyledFeedBlockedSlide,
    StyledImage,
    StyledOverlay,
    StyledSplide,
    StyledSplideSlide,
    StyledSplideTrack
} from './style'
import {DefaultTheme, FlattenSimpleInterpolation, useTheme} from 'styled-components'
import {Button} from '../button/Button'
import {Trans, useTranslation} from 'react-i18next'
import {useRootStore} from '@/store'
import {selectRecurringGiftModal} from '@/store/selectors'
import {HostPartialType} from '@/features/authentication/types'
import {GenderKeyNameE} from '@/features/constants/constants'
import {Photos} from '@/features/profile/helpers/helpers'
import { formatUploadTime } from '@/utilities/helpers'

//      - remap photos or videos to media array like
//           const photosMap = photos?.map((photo) => {
//             return {
//                 type: 'image',
//                 url: photo?.url,
//             }
//         })

type MediaType =
    | {
          type: MediaTypeE
          url: string | null | undefined
          options?: Plyr.Options
          extraPayload?: unknown
          surprise?: ReactNode | null | undefined
          created_at?: string
      }
    | undefined

type OptionsType = {
    type?: string
    arrows?: boolean
    rewind?: boolean
    pagination?: boolean
    gap?: number
    start?: number //start index
    perPage?: number
    padding?: {right?: string; top?: string; left?: string; bottom?: string} | string
    drag?: boolean | 'free'
    heightRatio?: number
    direction?: DirectionsE | 'ttb'
    perMove?: number
    flickMaxPages?: number
    waitForTransition?: boolean
    flickPower?: number
    easing?: string
}

export type SplideType = {
    root: HTMLElement
    go: (index: number) => void
}

type CarouselType = {
    children?: ReactNode
    media: MediaType[] | Photos[]
    customOptions?: OptionsType
    withArrows?: boolean
    customArrows?: {prev: ReactNode; next: ReactNode} | null
    arrowsStyles?: (theme: DefaultTheme) => FlattenSimpleInterpolation
    carouselStyles?: (theme: DefaultTheme) => FlattenSimpleInterpolation
    slideStyles?: (theme: DefaultTheme) => FlattenSimpleInterpolation
    onSwipe?: ({splide, newIndex, prevIndex}: {splide: SplideType; newIndex: number; prevIndex: number}) => void
    additionComponent?: ReactNode
    onClickSlide?: () => void
    withOverlay?: boolean
    host?: HostPartialType
    isGuest?: boolean
    isInFeed?: boolean
    subscriptionStatusActive?: boolean
}

export const ProfileCarousel = ({
    children,
    media,
    customOptions,
    customArrows = null,
    arrowsStyles,
    carouselStyles,
    onSwipe,
    additionComponent,
    slideStyles,
    onClickSlide,
    withOverlay = false,
    host,
    isGuest = false,
    isInFeed = false,
    subscriptionStatusActive
}: CarouselType) => {
    const theme = useTheme()
    const {t} = useTranslation()
    const {openModal: openGiftModal} = useRootStore(selectRecurringGiftModal)
    const canvasRef = useRef<HTMLCanvasElement>(null)

    const BLOCKED_PHOTO_INDEX = 4

    const correctOption = {
        direction: theme.direction,
        rewind: true,
        perMove: 1,
        flickMaxPages: 1,
        waitForTransition: true,
        flickPower: 30,
        easing: 'cubic-bezier(0.21, 1.02, 0.73, 1)',
        ...customOptions
    }
    const userGender = useMemo(() => {
        switch (host?.gender?.id) {
            case 1:
                return GenderKeyNameE.MALE
            case 2:
                return GenderKeyNameE.FEMALE
            default:
                return GenderKeyNameE.OTHER
        }
    }, [])

    useEffect(() => {
        if (!!canvasRef?.current && !!media?.length && !!media[BLOCKED_PHOTO_INDEX - 1]?.url) {
            const context = canvasRef?.current.getContext('2d')
            const image = new Image()
            image.src = media[BLOCKED_PHOTO_INDEX - 2]?.url as string
            image.onload = () => {
                if (!!context && canvasRef.current) {
                    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height)
                    canvasRef.current.width = canvasRef.current.offsetWidth
                    canvasRef.current.height = canvasRef.current.offsetHeight
                    context.filter = 'blur(10px)'
                    context.drawImage(image, 0, 0, canvasRef.current.width, canvasRef.current.height)
                }
            }
        }
    }, [media[BLOCKED_PHOTO_INDEX - 1]?.url, canvasRef?.current])

    const handleOpenGiftModal = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        openGiftModal({hostID: `${host?.id}`})
    }

    return (
        <StyledSplide
            onClick={onClickSlide}
            onMove={(splide: SplideType, newIndex: number, prevIndex: number) => {
                onSwipe?.({splide, newIndex, prevIndex})
            }}
            options={correctOption}
            hasTrack={false}
            $carouselStyles={carouselStyles}
        >
            <StyledSplideTrack>
                {!!media &&
                    media?.map((item, index) => {
                        const dateToDisplay = !!item?.created_at && formatUploadTime(item?.created_at, t)
                        return (
                            item &&
                            !!item?.url && (
                                <StyledSplideSlide
                                    key={index}
                                    $slideStyles={slideStyles}
                                    style={{
                                        backgroundColor: 'rgb(127, 130, 158)'
                                    }}
                                >
                                    {withOverlay && <StyledOverlay />}
                                    {!!dateToDisplay && <StyledDateWrap>{dateToDisplay}</StyledDateWrap>}
                                    {
                                        <>
                                            <StyledImage url={item.url} />
                                            {!!item.surprise && item.surprise}
                                        </>
                                    }
                                    {children}
                                </StyledSplideSlide>
                            )
                        )
                    })}
                {!subscriptionStatusActive && isGuest && media?.length > BLOCKED_PHOTO_INDEX && !isInFeed && (
                    <StyledSplideSlide>
                        <>
                            <StyledCanvas ref={canvasRef} />
                            <StyledBlockedSection>
                                <span>
                                    <Trans
                                        t={t}
                                        i18nKey={'rookie_my_profile:pay_wall:bloked_photo_text'}
                                        values={{
                                            context: userGender
                                        }}
                                    />
                                </span>
                                <Button onClick={() => openGiftModal({hostID: `${host?.id}`})}>
                                    {t('rookie_my_profile:pay_wall:start_supporting_monthly_button')}
                                </Button>
                            </StyledBlockedSection>
                        </>
                    </StyledSplideSlide>
                )}
                {!subscriptionStatusActive && isGuest && media?.length > BLOCKED_PHOTO_INDEX && isInFeed && (
                    <StyledSplideSlide>
                        <>
                            {/* <StyledCanvas ref={canvasRef} /> */}
                            <StyledImage url={`${media?.[8]?.url}`} isBlur/>
                            <StyledFeedBlockedSlide>
                                <span>{t('rookie_my_profile:pay_wall:unblock_photo')}</span>
                                <Button onClick={handleOpenGiftModal}>{t('common:support_monthly')}</Button>
                            </StyledFeedBlockedSlide>
                        </>
                    </StyledSplideSlide>
                )}
            </StyledSplideTrack>
            {customArrows && (
                <StyledArrowsWrapper className="splide__arrows" arrowsStyles={arrowsStyles}>
                    <button className="splide__arrow splide__arrow--prev">{customArrows.prev}</button>
                    <button className="splide__arrow splide__arrow--next">{customArrows.next}</button>
                </StyledArrowsWrapper>
            )}
            {additionComponent}
        </StyledSplide>
    )
}

ProfileCarousel.displayName = 'ProfileCarousel'

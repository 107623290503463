import morgisLogo from '@/assets/images/morgisLogo3x.svg'
import morgisLogoLight from '@/assets/images/morgisLogoLight3x.svg'
import Slogan from '@assets/animations/Slogan.json'
import SloganLight from '@assets/animations/SloganAnimLight.json'
import {
    ROUTE_HOME,
    ROUTE_HOST_MY_PROFILE,
    ROUTE_MESSAGE_CENTER,
    ROUTE_SIGNUP_EMAIL_VERIFICATION
} from '@/utilities/constants/routeNames'
import {useLocation, useNavigate} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {Animation} from '@/components/ui/animation/Animation'
import {StyledAnimation, StyledLogo} from './style'
import {LocalStorageManager} from '@/utilities/localStorage'
import {getPublicUrl} from '@/utilities/helpers'
import {GuestType} from '@/features/authentication/types'
import {OldRoles} from '@/features/constants/constants'

interface HeaderProps {
    isLight?: boolean
    user?: GuestType
}

export const HeaderLogo = ({isLight = true, user}: HeaderProps) => {
    const navigate = useNavigate()
    const location = useLocation()
    const [showSloganAnimation, setShowSloganAnimation] = useState(false)

    const sloganAnimationLSState = LocalStorageManager.showSloganAnimation.get() ?? {
        [ROUTE_HOST_MY_PROFILE]: false,
        [ROUTE_HOME]: false,
        [ROUTE_MESSAGE_CENTER]: false
    }
    const isBlockRedirect = location.pathname.includes(ROUTE_SIGNUP_EMAIL_VERIFICATION.split(':')[0])
    //animation shows one time after login on home and message center pages
    useEffect(() => {
        if (sloganAnimationLSState[location.pathname]) {
            setShowSloganAnimation(true)
        }
    }, [sloganAnimationLSState, location])
    const anonymHost = LocalStorageManager.publicRole.get() === OldRoles.rookie
    const onAnimationCompleteCallback = () => {
        setShowSloganAnimation(false)
        LocalStorageManager.showSloganAnimation.remove(location.pathname)
    }
    const publicURL = getPublicUrl(anonymHost ? '/host' : '', location.search)
    return showSloganAnimation ? (
        <StyledAnimation>
            <Animation
                animationData={isLight ? SloganLight : Slogan}
                onCompleteCallback={onAnimationCompleteCallback}
            />
        </StyledAnimation>
    ) : (
        <StyledLogo
            src={isLight ? morgisLogo : morgisLogoLight}
            onClick={
                !isBlockRedirect
                    ? user
                        ? () => navigate(ROUTE_HOME)
                        : () => {
                              window.open(publicURL, '_self')
                          }
                    : undefined
            }
        />
    )
}

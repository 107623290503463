import styled, {FlattenSimpleInterpolation, css, DefaultTheme} from 'styled-components'
import {Splide, SplideSlide, SplideTrack} from '@splidejs/react-splide'

export const StyledFeaturedHostRoot = styled.div`
    ${({theme: {mediaQueries, spacing}}) => css`
        position: relative;
        max-width: calc(100vw - ${spacing * 1.5}px);
        margin-right: -${spacing * 3}px;
        padding-bottom: ${spacing * 3}px;

        ${mediaQueries.m} {
            width: 100%;
            max-width: 480px;
            padding-bottom: ${spacing * 4}px;
        }
    `}
`

export const carouselStyles = ({theme: {palette}}: {theme: DefaultTheme}) => css`
    .splide__pagination {
        bottom: -24px;
    }

    /* pagination buttons */
    .splide__pagination__page {
        height: 6px;
        width: 6px;
    }

    .splide__pagination__page.is-active {
        background: ${palette.primary[200]};
        opacity: 1;
        transform: none;
    }
`

export const StyledSplide = styled(Splide)`
    ${({
        $carouselStyles,
        theme
    }: {
        $carouselStyles?: (theme: DefaultTheme) => FlattenSimpleInterpolation
        theme: DefaultTheme
    }) => css`
        height: 100%;
        ${$carouselStyles && $carouselStyles(theme)}
    `}
`

export const StyledSplideSlide = styled(SplideSlide)(
    ({theme: {palette, mediaQueries}}: {theme: DefaultTheme}) => css`
        background-color: ${palette.neutral.transparent};
        min-width: 300px;
        max-width: 330px;
        width: fit-content !important;

        ${mediaQueries.m} {
            max-width: 440px;
        }
    `
)

export const StyledSplideTrack = styled(SplideTrack)`
    height: 100%;
`

// It has default styles which we use for text area
// To add custom style for it you can use this
// export const StyledBannedElementsTrapArea = styled(TextareaWithBannedElementsTrap)`
//       ${({theme: {palette, spacing}}) => css`
//          Styles for wrapper

//        & > div {
//              styles for text area

//           & > p {
//              styles for text
//          }

//          &:focus {
//              style if text area in focus
//          }

//       }
//  `}
// `

import {ReactElement, forwardRef, useEffect, useRef, useState} from 'react'
import {LexicalComposer} from '@lexical/react/LexicalComposer'
import {PlainTextPlugin} from '@lexical/react/LexicalPlainTextPlugin'
import {ContentEditable} from '@lexical/react/LexicalContentEditable'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import {EditorRefPlugin} from '@lexical/react/LexicalEditorRefPlugin'
import {
    StyledButtonWrapper,
    StyledMaxChars,
    StyledPlaceholder,
    StyledTextareaContainer,
    StyledTextareaWrapper,
    StyledToolTip,
    TooltipLink
} from './style'
import {MaxLengthPlugin} from '../max-length-plugin.tsx/MaxLengthPlugin'
import {OnChangePlugin} from '../on-change-plugin/OnChangePlugin'
import {MyCustomAutoFocusPlugin} from '../my-custom-focus-plugin/MyCustomAutoFocusPlugin'
import {Label} from '@/components/ui/label/Label'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'
import {useGetStoredLinksList} from '../../queries/useGetStoredLinksList'
import {useGetBadWordsList} from '../../queries/useGetBadWordsList'
import {getBadWordsListFromString, getNumbersFromString, getStoredLinksFromString, validateString} from '../../helpers'
import {ShowTrappedTextPlugin} from '../show-trapped-text-plugin/ShowTrappedTextPlugin'
import {Trans, useTranslation} from 'react-i18next'
import {useMe} from '@/features/authentication/queries/useMe'
import {USER_ROLE_GUEST} from '@/utilities/constants/user'
import {ROUTE_TOS_GUEST, ROUTE_TOS_HOST} from '@/utilities/constants/routeNames'
import {BannedElement} from '../banned-element-node/BannedElement'
import {HaveBannedElementObj} from '../../types'
import {SendMessagePlugin} from '../send-message-plugin/SendMessagePlugin'

type TextareaWithBannedElementsTrapProps = {
    name?: string
    label?: string
    placeholder?: string
    maxChars?: number
    button?: ReactElement | null
    autoFocus?: boolean
    // If you use with react hook form it should be (value)=> setValues(fieldName, value)
    onChange: (value: string) => void
    className?: string
    defaultValue?: string
    errorMessage?: string
    isReplaceValueToInsert?: boolean
    // if use with react-hook-form needed
    // This is needed to toggle error inside form on blur
    setError?: () => void
    clearErrors?: () => void

    // this we need to get if content is banned outside
    getHaveBannedElements?: (haveBannedElementObj: HaveBannedElementObj | null) => void
    valueToInsert?: string
    inModal?: boolean
    // only for chat input needed
    isChatInput?: boolean
    sendMessage?: () => void
    isDisabled?: boolean
    isErrorCondition?: boolean
}

// TODO can be use full later, if not remove this
const theme = {
    // Theme styling goes here
    ltr: 'ltr',
    rtl: 'rtl'
}

// TODO can be use full later, if not remove this
function onError(error: {message: string}) {
    console.error(error)
}

export const TextareaWithBannedElementsTrap = forwardRef(
    (
        {
            placeholder = '',
            maxChars,
            label = '',
            button = null,
            autoFocus = false,
            onChange,
            className,
            defaultValue = '',
            errorMessage,
            setError,
            clearErrors,
            getHaveBannedElements,
            name = '',
            valueToInsert = '',
            inModal = false,
            isChatInput = false,
            sendMessage,
            isDisabled = false,
            isReplaceValueToInsert = false,
            isErrorCondition = false
        }: TextareaWithBannedElementsTrapProps,
        ref
    ) => {
        const {t} = useTranslation()
        const initialConfig = {
            namespace: 'Textarea',
            defaultValue,
            theme,
            onError,
            // make this editor editable needed to fix issue with autofocus when we have default value
            // here is link to issue: https://github.com/facebook/lexical/issues/4474
            editable: autoFocus,
            nodes: [BannedElement]
        }

        const {data: user} = useMe()
        const [value, setValue] = useState(defaultValue)
        const {data: badWordsList} = useGetBadWordsList()
        const {data: linksList} = useGetStoredLinksList()

        const targetBannedNumbers = getNumbersFromString(value)
        const targetBannedWords = getBadWordsListFromString(value, badWordsList)
        const targetStoredLinks = getStoredLinksFromString(value)

        const bannedElementsList = [...(targetBannedWords || []), ...(linksList || []), ...targetBannedNumbers]
        const targetBannedElementsList = [...targetBannedNumbers, ...targetBannedWords, ...targetStoredLinks]
        const filteredValue = validateString(value, bannedElementsList, targetBannedElementsList)

        const myElementRef = useRef<HTMLElement | null>(null)

        const [tooltipContent, setTooltipContent] = useState('')

        const handleChange = (value: string) => {
            setValue(value)
            onChange(value)
        }

        const onBlurToggleError = () => {
            if (!value || isErrorCondition) {
                setError?.()
            } else {
                clearErrors?.()
            }
        }

        useEffect(() => {
            getHaveBannedElements?.(
                filteredValue?.bannedString ? {name, isBanned: !!filteredValue?.bannedString} : null
            )
        }, [filteredValue?.bannedString])

        const showTooltipHandle = (type: string, element: HTMLElement) => {
            switch (type) {
                case 'bannedWord':
                    myElementRef.current = element
                    setTooltipContent('chat:banned_elements_tooltip:bad_word')
                    return
                case 'bannedNumber':
                    myElementRef.current = element
                    setTooltipContent('chat:banned_elements_tooltip:phone_number')
                    return
                case 'bannedLink':
                    myElementRef.current = element
                    setTooltipContent('chat:banned_elements_tooltip:bad_url')
                    return
                case 'validLink':
                    myElementRef.current = element
                    setTooltipContent('chat:banned_elements_tooltip:valid_url')
                    return
            }
        }

        const handleCloseTooltip = () => {
            myElementRef.current = null
            setTooltipContent('')
        }

        useEffect(() => {
            handleCloseTooltip()
        }, [filteredValue.numberOfBannedElements])

        return (
            <StyledTextareaContainer>
                {label && <Label text={label} />}
                <StyledToolTip
                    anchorRef={myElementRef}
                    isModal={inModal}
                    withArrow={true}
                    withAnchor={true}
                    rootProps={{onOpenChange: handleCloseTooltip}}
                >
                    <Trans
                        t={t}
                        i18nKey={tooltipContent}
                        components={{
                            a1: <TooltipLink to={user?.type === USER_ROLE_GUEST ? ROUTE_TOS_GUEST : ROUTE_TOS_HOST} />
                        }}
                    />
                </StyledToolTip>
                <LexicalComposer initialConfig={initialConfig}>
                    <StyledTextareaWrapper className={className} isValid={!errorMessage} onBlur={onBlurToggleError}>
                        <PlainTextPlugin
                            contentEditable={<ContentEditable />}
                            placeholder={<StyledPlaceholder>{placeholder}</StyledPlaceholder>}
                            ErrorBoundary={LexicalErrorBoundary}
                        />
                        <EditorRefPlugin editorRef={() => ref} />
                        {autoFocus && <MyCustomAutoFocusPlugin />}
                        <ShowTrappedTextPlugin filteredValue={filteredValue} onBannedElementClick={showTooltipHandle} />
                        <OnChangePlugin
                            onChange={handleChange}
                            valueToInsert={valueToInsert}
                            isDisabled={isDisabled}
                            value={value}
                            isReplaceValueToInsert={isReplaceValueToInsert}
                        />
                        {!!maxChars && (
                            <>
                                <MaxLengthPlugin maxLength={maxChars} />
                                <StyledMaxChars>
                                    {value?.length}/{maxChars}
                                </StyledMaxChars>
                            </>
                        )}
                        {!!button && !isChatInput && <StyledButtonWrapper>{button}</StyledButtonWrapper>}
                        {isChatInput && (
                            <SendMessagePlugin sendMessage={sendMessage} banned={filteredValue?.bannedString}>
                                {button ? <StyledButtonWrapper>{button}</StyledButtonWrapper> : null}
                            </SendMessagePlugin>
                        )}
                    </StyledTextareaWrapper>
                </LexicalComposer>
                {!!errorMessage && <InputHelpText error={errorMessage} />}
            </StyledTextareaContainer>
        )
    }
)

TextareaWithBannedElementsTrap.displayName = 'TextareaWithBannedElementsTrap'

import {Spinner} from '@/components/ui/spinner/Spinner'
import {useMe} from '@/features/authentication/queries/useMe.ts'
import {commonRoutes} from '@/utilities/constants'
import {LocalStorageManager} from '@/utilities/localStorage'
import {useEffect} from 'react'
import {Navigate, Outlet} from 'react-router-dom'

export const PrivateRoute = () => {
    // use http-only cookie
    const {loggedIn, isLoading} = useMe()

    useEffect(() => {
        LocalStorageManager.publicRole.remove()
    }, [])

    if (isLoading) {
        return <Spinner />
    }

    return loggedIn ? <Outlet /> : <Navigate to={commonRoutes.LOGIN_GUEST.path} />
}

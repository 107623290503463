import {spacing} from '@/theme/theme'
import {typography} from '@/theme/typography'
import styled, {DefaultTheme, css} from 'styled-components'

// CAROUSEL STYLES
export const StyledCoverMediaRoot = styled.div`
    ${({theme: {spacing}}) => css`
        width: 100%;
        height: 240px;
        position: relative;
        margin-bottom: ${spacing * 8}px;
    `}
`

export const slideStyles = ({theme, isPastGoal}: {theme: DefaultTheme; isPastGoal?: boolean}) => css`
    position: relative;
    max-height: 240px;
    max-width: 868px;
    border-radius: 10px;
    overflow: hidden;
    height: 240px;
    background: ${theme.palette.neutral['650']};

    /* FOR PAST GOAL ONLY */
    ${isPastGoal &&
    css`
        filter: grayscale(100%);
        opacity: 0.8;
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.7) -10.63%,
            rgba(0, 0, 0, 0.7) -10.61%,
            rgba(0, 0, 0, 0.1) 52.47%
        );
    `}

    &:before {
        content: '';
        z-index: 1;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
    }

    & img,
    & div {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: relative;
    }

    ${theme.mediaQueries.m} {
        border-radius: 15px;
    }
`

export const carouselStyles = ({theme, isPastGoal}: {theme: DefaultTheme; isPastGoal?: boolean}) => css`
    position: relative;
    visibility: visible;

    /* pagination buttons */
    & button {
        height: 6px;
        width: 6px;
        position: relative;
        bottom: ${isPastGoal ? '80px' : '60px'};

        &.splide__pagination__page {
            transform: none;
            background: ${theme.palette.neutral.white};
        }

        &.splide__pagination__page.is-active {
            background: ${theme.palette.primary['200']};
        }
    }
`

// CONTENT STYLES
export const StyledContent = styled.div`
    ${({theme: {spacing}}) => css`
        width: 100%;
        position: absolute;
        top: 0;
        padding: ${spacing * 2}px ${spacing * 2}px 0px ${spacing * 2}px;
    `}
`

export const StyledItemWrapper = styled.div`
    ${({theme: {spacing}, isLeftPosition = false}: {theme: DefaultTheme; isLeftPosition?: boolean}) => css`
        width: fit-content;
        display: grid;
        gap: ${spacing * 2}px;
        position: absolute;
        ${isLeftPosition ? 'left: 16px' : 'right: 16px'};
        & div {
            ${!isLeftPosition && 'justify-self: end'}
        }
    `}
`

export const StyledName = styled.div`
    ${({theme: {palette, truncateText, mediaQueries}}) => css`
        ${typography.textLg};
        color: ${palette.neutral.white};
        display: flex;
        align-items: center;
        gap: ${spacing}px;

        & span {
            ${truncateText}
            width: 100%;
            max-width: 200px;

            ${mediaQueries.s} {
                max-width: 240px;
            }

            ${mediaQueries.m} {
                max-width: 100%;
            }
        }
    `}
`

export const StyledDetailsCard = styled.div`
    position: absolute;
    width: 100%;
    bottom: -50px;
`

export const cardRootStyles = (theme: DefaultTheme) => css`
    background-color: ${theme.palette.neutral.white};
    border-radius: 10px;
    overflow: hidden;
    max-width: 315px;
    margin: 0 auto;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 10px 0px;
    ${theme.mediaQueries.m} {
        max-width: 450px;
    }
`

import {RecurringIcon} from '@/assets/icons/icons'
import {Button} from '@/components/ui/button/Button'
import styled, {DefaultTheme, css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledListItemContent = styled.div`
    display: grid;
`

export const StyledListItem = styled.div`
    ${({
        isInactive = false,
        isActiveGift,
        isHover = true,
        theme: {spacing, mediaQueries, palette}
    }: {
        isInactive?: boolean
        isActiveGift?: boolean
        isHover?: boolean
        theme: DefaultTheme
    }) => css`
        display: ${isActiveGift ? 'none' : 'grid'};
        grid-template-columns: 0fr 1fr 0fr;
        grid-column-gap: ${spacing * 2}px;
        align-items: center;
        padding: ${spacing * 2.5}px;
        border-bottom: 1px solid ${palette.neutral['400']};
        cursor: pointer;
        &:hover {
            background-color: ${isHover ? palette.neutral['75'] : palette.neutral.transparent};
        }
        ${mediaQueries.l} {
            grid-column-gap: ${spacing * 4}px;
            grid-template-columns: ${isInactive ? '0fr 1fr auto' : '0fr 1fr 0fr'};
        }
    `}
`

export const StyledDonationStartDate = styled.span`
    ${({theme: {mediaQueries, palette, typography}}) => css`
        ${typography.textXs}
        color: ${palette.neutral['500']};
        ${mediaQueries.l} {
            ${typography.textLg}
            color: ${palette.primary['900']};
        }
    `}
`

export const StyledGuestAvatar = styled.img`
    ${({theme: {mediaQueries}}) => css`
        width: 60px;
        height: 60px;
        max-width: none;
        border-radius: 10px;
        ${mediaQueries.l} {
            width: 100px;
            height: 100px;
        }
    `}
`

export const StyledGuestMorgiAmount = styled.div`
    ${({theme: {spacing, mediaQueries, typography}}) => css`
        display: grid;
        grid-template-columns: 0fr auto;
        align-items: center;
        ${typography.textLg}
        font-weight: 700;
        & svg {
            width: 21px;
            height: 21px;
        }
        ${mediaQueries.l} {
            ${typography.text2Xl}
            grid-column-gap: ${spacing / 2}px;
            & svg {
                width: 32px;
                height: 32px;
            }
        }
    `}
`

export const StyledGuestName = styled.span`
    ${({theme: {spacing, mediaQueries, typography}}) => css`
        font-weight: 700;
        margin-bottom: ${spacing}px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        ${mediaQueries.l} {
            ${typography.text2Xl}
            margin-bottom: ${spacing * 2}px;
        }
    `}
`

export const StyledHostAvatar = styled.img`
    ${({theme: {mediaQueries, shadows}}) => css`
        max-width: 60px;
        max-height: 60px;
        min-width: 60px;
        border-radius: 10px;
        object-fit: cover;
        box-shadow: ${shadows['6xl']};
        ${mediaQueries.m} {
            max-height: 100px;
            max-width: 100px;
            min-width: 100px;
        }
    `}
`

export const StyledDonationStart = styled.span`
    ${({theme: {mediaQueries, palette, typography}}) => css`
        ${typography.textXs}
        color: ${palette.neutral['500']};
        ${mediaQueries.m} {
            ${typography.textLg}
            color: ${palette.primary['900']};
        }
    `}
`

export const StyledMorgiAmountWrapper = styled.div`
    display: grid;
    grid-auto-flow: row;
`
export const StyledRecurringIcon = styled(RecurringIcon)`
    ${({theme: {mediaQueries, spacing}}) => css`
        width: 14px;
        height: 14px;
        margin-top: ${spacing / 2}px;
        justify-self: end;
        ${mediaQueries.m} {
            width: 20px;
            height: 20px;
        }
    `}
`

//Modal

export const ModalContainer = styled.div`
    ${({theme: {spacing}}) => css`
        padding: ${spacing * 3}px;
    `}
`

export const StyledButton = styled(Button)`
    ${({theme: {spacing}}) => css`
        padding: ${spacing * 2}px ${spacing * 3}px;
    `}
`

export const StyledDescription = styled.p`
    ${({theme: {spacing, typography}}) => css`
        margin: ${spacing * 2}px 0 ${spacing * 4}px 0;
        ${typography.textMd}
        line-height: 140%;
        opacity: 0.5;
        white-space: pre-wrap;
        text-align: left;
    `}
`
//GiftHistory

export const StyledTotalWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-auto-flow: column;
        column-gap: ${spacing * 3}px;
    `}
`

export const StyledTotalMorgiCard = styled.div`
    ${({theme: {mediaQueries, spacing, shadows}}) => css`
        display: grid;
        grid-template-rows: 1fr;
        justify-items: center;
        justify-self: center;
        row-gap: ${spacing}px;
        max-width: 256px;
        width: 100%;
        padding: ${spacing * 2}px;
        border-radius: 10px;
        box-shadow: ${shadows['6xl']};
        margin-bottom: ${spacing * 4}px;
        ${mediaQueries.l} {
            row-gap: ${spacing * 1.5}px;
        }
    `}
`

export const StyledTotalMorgiTitle = styled.span`
    ${({theme: {palette, mediaQueries, typography}}) => css`
        text-align: center;
        color: ${palette.primary['900']};
        ${mediaQueries.l} {
            ${typography.textLg}
        }
    `}
`

export const StyledTotalMorgiAmount = styled.div`
    ${({theme: {palette, spacing, typography, mediaQueries}}) => css`
        display: grid;
        grid-template-columns: 0fr auto;
        align-items: center;
        column-gap: ${spacing}px;
        ${typography.textXl};
        font-weight: 700;
        color: ${palette.primary['900']};
        overflow: hidden;
        & svg {
            width: 38px;
            height: 38px;
        }
        ${mediaQueries.m} {
            ${typography.displayMd};
        }
    `}
`
//HistoryBalance

export const StyledHistoryListItem = styled.div`
    ${({theme: {spacing, palette}}) => css`
        display: grid;
        grid-template-columns: 0fr 1fr 0fr;
        column-gap: ${spacing * 2}px;
        align-items: center;
        height: 54px;
        border-bottom: 1px solid ${palette.neutral['400']};
    `}
`

export const StyledTransactionIcon = styled.div`
    ${({theme: {mediaQueries}}) => css`
        svg {
            width: 18px;
            height: 18px;
        }
        ${mediaQueries.l} {
            svg {
                width: 28px;
                height: 28px;
            }
        }
    `}
`

export const StyledTransactionDate = styled.span`
    ${({theme: {mediaQueries, typography}}) => css`
        ${mediaQueries.m} {
            ${typography.textLg}
        }
    `}
`

export const StyledHostMorgiAmount = styled.div`
    ${({theme: {mediaQueries, spacing, typography}}) => css`
        display: grid;
        grid-template-columns: 0fr auto;
        align-items: center;
        ${typography.textLg}
        font-weight: 700;
        & svg {
            width: 21px;
            height: 21px;
        }
        ${mediaQueries.l} {
            ${typography.text2Xl}
            column-gap: ${spacing / 2}px;
            & svg {
                width: 32px;
                height: 32px;
            }
        }
    `}
`

export const StyledContainerTabs = styled.div`
    ${({theme: {palette}}) => css`
        background-color: ${palette.neutral['white']};
        max-width: 280px;
        border-radius: 50px;
        height: 28px;

        & > * {
            font-weight: 600;
        }
    `}
`

export const StyledHeaderMonthly = styled(Flexbox)`
    ${({$heightOfHeader, theme: {spacing}}: {$heightOfHeader: number; theme: DefaultTheme}) => css`
        padding-top: ${$heightOfHeader}px;
        margin-top: ${spacing * 2}px;
    `}
`

export const StyledButtonToConnectNow = styled(Button)`
    ${({theme}) => css`
        ${theme.typography.textXxs};
        padding: ${theme.spacing / 2}px ${theme.spacing * 3}px;
        min-width: 116px;
        ${theme.mediaQueries.m} {
            ${theme.typography.textSm};
            font-weight: 700;
            padding: 0 ${theme.spacing}px;
        }
    `}
`

export const StyledNoInactiveSupport = styled.div`
    ${() => css`
        font-size: 21px;
        font-weight: 700;
        text-align: center;
        margin-top: 20px;
    `}
`

export const StyledNoRecordsBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 56px;
`
import React, {createContext, useContext, useState} from 'react'

interface AudioPlayerContextProps {
    currentPlayerId: string | null
    setCurrentPlayerId: (id: string | null) => void
}

const AudioPlayerContext = createContext<AudioPlayerContextProps | null>(null)

export const useAudioPlayer = () => {
    const context = useContext(AudioPlayerContext)
    if (!context) {
        throw new Error('useAudioPlayer must be used within AudioPlayerProvider')
    }
    return context
}

export const AudioPlayerProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
    const [currentPlayerId, setCurrentPlayerId] = useState<string | null>(null)

    return (
        <AudioPlayerContext.Provider value={{currentPlayerId, setCurrentPlayerId}}>
            {children}
        </AudioPlayerContext.Provider>
    )
}

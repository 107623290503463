import {useTranslation} from 'react-i18next'
import {StyledHostsList, StyledTitle} from './style'
import {MorgisTaxiHostCard} from '../morgis-taxi-host-card/MorgisTaxiHostCard'
import {FeaturedHostType, HostListTypesE} from '../../types'
import {Spinner} from '@/components/ui/spinner/Spinner'

type MorgisTaxiHostListProps = {
    hostsList?: FeaturedHostType[]
    type: HostListTypesE
    guestName?: string
    isLoading?: boolean
}

export const FeaturedMorgisTaxiHostList = ({
    hostsList,
    type,
    guestName,
    isLoading = false
}: MorgisTaxiHostListProps) => {
    const {t} = useTranslation()
    const centered = type === HostListTypesE.CHAT_FEATURED || type === HostListTypesE.CHAT_SUGGESTED

    return isLoading ? (
        <Spinner />
    ) : (
        <>
            <StyledTitle>{t('my_morgis_taxi_page:added_hosts_title')}</StyledTitle>
            <StyledHostsList centered={centered}>
                {hostsList?.map(hostProfile => {
                    return (
                        <div key={hostProfile?.id}>
                            <MorgisTaxiHostCard
                                hostName={hostProfile?.full_name}
                                birthDate={hostProfile?.birth_date || undefined}
                                pathName={
                                    hostProfile?.path ? hostProfile?.path?.name : hostProfile?.expertise?.key_name
                                }
                                featuredHostId={hostProfile?.featured_rookie_id}
                                hostProfile={hostProfile}
                                photos={hostProfile?.photos}
                                type={type}
                                guestName={guestName}
                                position={hostProfile?.position}
                                description={hostProfile?.description}
                            />
                        </div>
                    )
                })}
            </StyledHostsList>
        </>
    )
}

FeaturedMorgisTaxiHostList.displayName = 'FeaturedMorgisTaxiHostList'

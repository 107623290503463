import { BadWordsResponseType, WordObject } from '../types'

const createFlexibleRegex = (word: string): RegExp => {
    const flexibleWord = word
        .split('')
        .map(char => {
            switch (char.toLowerCase()) {
                case 'i':
                    return '[i1!ії]'
                case 'n':
                    return '[n]'
                case 's':
                    return '[s$5ѕ]'
                case 't':
                    return '[t†τ]'
                case 'a':
                    return '[a@4аäâà]'
                case 'g':
                    return '[gğ9q]'
                case 'r':
                    return '[rřŕ]'
                case 'm':
                    return '[mм]'
                default:
                    return char.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&') 
            }
        })
        .join('[^a-zA-Z0-9а-яёА-ЯЁ]*') 

    return new RegExp(`(^|[^a-zA-Z0-9а-яёА-ЯЁ])${flexibleWord}([^a-zA-Z0-9а-яёА-ЯЁ]|$)`, 'gi')
}


export const getBadWordsListFromString = (
    text: string | undefined,
    bannedWordsList: BadWordsResponseType | undefined
): WordObject[] => {
    let badWordsArray: string[] = []

    const textToCheck = text?.toLowerCase()
    const bannedWordsListStringList = bannedWordsList?.map(({ name }) => name)

    bannedWordsListStringList?.forEach(word => {
        const regex = createFlexibleRegex(word)
        const partBadWordsArray = textToCheck?.match(regex)

        if (partBadWordsArray && partBadWordsArray.length) {
            badWordsArray = [...partBadWordsArray, ...badWordsArray]
        }
    })

    badWordsArray = [...new Set(badWordsArray)] 

    const wordsArrayWithObjects: WordObject[] = badWordsArray.map(word => {
        const value = word.trim()
        return {
            string: value,
            host: value,
            url: value,
            type: 'word'
        }
    })

    return wordsArrayWithObjects
}
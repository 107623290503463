import {
    StyledCardContainer,
    StyledCardContent,
    StyledConnectNowButton,
    StyledExpertiseIconWrapper,
    StyledOnlineCircle,
    StyledSavedButtonContainer,
    StyledSliderWrapper,
    StyledTitleWrapper,
    StyledInfoContainer,
    StyledInfoWrapper,
    StyledFavTopicsContainer,
    topicsListStyles
} from './style'
import {FC, MouseEvent, useEffect, useMemo, useState} from 'react'
import {HostCardContent} from '@/features/feed/types'
import {useTranslation} from 'react-i18next'
import {computeAge} from '@/utilities/helpers'
import heartFilled from '@/assets/images/heart-filled.png'
import heartEmpty from '@/assets/images/heart-empty.png'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {useRootStore} from '@/store'
import {selectConnectModal, selectHostProfileModal} from '@/store/selectors.ts'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {useSaveHost} from '@/features/guest/queries/useSaveHost.ts'
import {useUnsaveHost} from '@/features/guest/queries/useUnsaveHost.ts'
import {useGetSavedHosts} from '@/features/guest/queries/useGetSavedHosts.ts'
import {HostTopics, TopicOption} from '@/features/profile/components/host-profile-topics/HostTopics.tsx'
import {HostVerificationIcon} from './host-verification-icon/HostVerificationIcon'
import {HostMedia} from './host-media/HostMedia'
import {useTheme} from 'styled-components'
import {useGetConnectedUser} from '@/hooks/useGetConnectedUser'
import useRedirectToChatChannel from '@/hooks/useRedirectToChatChannel'
import {GuestType} from '@/features/authentication/types'
import {useMe} from '@/features/authentication/queries/useMe'
import {commonTopicsNames} from '@/features/profile/helpers/helpers'
import {HostProfilePhotoSlider} from '@/features/profile/components/host-profile-photo-slider/HostProfilePhotoSlider'
import {MediaTypeE} from '@/types'
import noAvatar from '@/assets/images/noAvatar.png'
import {Avatar} from '@/components/ui/avatar/Avatar'
import {breakpoints} from '@/theme/mediaQueries'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import {ExpertiseIcon} from '@/components/commons/expertise-icon/ExpertiseIcon'
import {Translate} from '@/features/translation/components/translate/Translate'

type HostCardsProps = {
    content: HostCardContent
    carouselCardIndex?: number
    inTalentCard?: boolean
}

export const HostCard: FC<HostCardsProps> = ({inTalentCard, content, carouselCardIndex}) => {
    const {openModal: openHostProfileModal} = useRootStore(selectHostProfileModal)
    const {openModal: openConnectNowModal} = useRootStore(selectConnectModal)
    const {t} = useTranslation()
    const theme = useTheme()

    const {width} = useWindowDimensions()
    const {mutate: saveHost, isPending: isPendingSave} = useSaveHost()
    const {mutate: unsaveHost, isPending: isPendingUnsave} = useUnsaveHost()
    const {data: savedHosts} = useGetSavedHosts()
    const isSavedHost = !!savedHosts?.find(({id}) => id === content?.id)
    const isLoading = isPendingSave || isPendingUnsave
    const expertise = content?.type_attributes?.expertise?.key_name
    const expertiseCategory = content?.type_attributes?.expertise?.category

    const [isMobile, setIsMobile] = useState(width <= breakpoints.width.l)
    const [isSaved, setIsSaved] = useState(isSavedHost)

    useEffect(() => {
        setIsSaved(isSavedHost)
    }, [isSavedHost])

    useEffect(() => {
        setIsMobile(width <= breakpoints.width.l)
    }, [width])

    const {connectedUser: connectedHost} = useGetConnectedUser(content?.id)
    const redirectToChatChannel = useRedirectToChatChannel({receiverId: parseInt(`${content?.id}`)})

    const birthDate = content?.type_attributes?.birth_date || ''
    const isVisibleAge = content?.type_attributes?.is_visible_age
    const rookieAge = useMemo(() => (isVisibleAge && birthDate ? computeAge(birthDate) : ''), [birthDate, isVisibleAge])
    // mixpanel
    const {data: user} = useMe()
    const guestProfile = user as GuestType
    const activeFilters = guestProfile?.type_attributes.leaders_carousels_filters
    const countryFilters = activeFilters
        ? guestProfile?.type_attributes.leaders_carousels_filters?.filters.countries.map(country => country.name)
        : []
    const guestChatTopics = guestProfile?.chat_topics
    const guestActivities = guestProfile?.favorite_activities
    const hostChatTopics = content?.chat_topics?.length ? content?.chat_topics : []
    const hostActivities = content?.favorite_activities?.length ? content?.favorite_activities : []

    const mixpanelData = {
        carousel_card_number: carouselCardIndex,
        click_share: false,
        common_activities: commonTopicsNames(guestChatTopics, hostChatTopics as TopicOption[]),
        common_chat_topics: commonTopicsNames(guestActivities, hostActivities as TopicOption[]),
        country_filter_applied: countryFilters?.length ? countryFilters : 'No',
        from: 'Carousel',
        morgi_balance: guestProfile?.type_attributes.micro_morgi_balance,
        scrolled_images: 0,
        time_on_profile: 0,
        watched_video: false
    }

    const mainCtaClickHandler = (e: MouseEvent) => {
        e.stopPropagation()
        if (connectedHost) {
            redirectToChatChannel?.()
        } else {
            openConnectNowModal({mixpanelData: mixpanelData, hostID: content?.id})
        }
    }

    const likeButtonClickHandler = (e: MouseEvent) => {
        e.stopPropagation()
        if (isLoading) return
        if (content?.id) {
            if (isSaved) {
                setIsSaved(false)
                unsaveHost(content?.id)
            } else {
                setIsSaved(true)
                saveHost(content?.id)
            }
        }
    }

    const cardClickHandler = (e: MouseEvent) => {
        e.stopPropagation()
        openHostProfileModal({hostUsername: content?.username})
    }

    if (!content?.username) return <Spinner />

    const remappedMedia = content?.type_attributes?.photos?.length
        ? content?.type_attributes?.photos?.map(photo => ({
              type: MediaTypeE.IMAGE,
              url: photo?.url
          }))
        : [{type: MediaTypeE.IMAGE, url: noAvatar}]

    return (
        <StyledCardContainer onClick={cardClickHandler} isSpecial={!!content?.type_attributes?.special_type}>
            <StyledSliderWrapper>
                <StyledExpertiseIconWrapper>
                    {expertise && !!content?.type_attributes?.special_type && (
                        <ExpertiseIcon expertise={expertise} expertiseCategory={expertiseCategory} />
                    )}
                </StyledExpertiseIconWrapper>

                {/* <HostPhotoSlider images={photosUrls} /> */}
                {!inTalentCard ? (
                    <HostProfilePhotoSlider
                        photos={remappedMedia}
                        mixpanelData={mixpanelData}
                        isGuest
                        host={content}
                        isInFeed
                    />
                ) : (
                    <Avatar
                        width={!isMobile ? 165 : 105}
                        height={!isMobile ? 215 : 138}
                        radius={8}
                        imageUrl={content?.avatar?.url}
                    />
                )}
            </StyledSliderWrapper>

            <StyledCardContent>
                <StyledInfoContainer>
                    <StyledInfoWrapper>
                        <StyledTitleWrapper>
                            <h4>{content?.full_name}</h4>
                            {rookieAge && <span>{`, ${rookieAge}`}</span>}

                            {!!content?.has_verified_id && (
                                <HostVerificationIcon
                                    hostGenderId={content?.gender?.id}
                                    hostName={content?.full_name}
                                />
                            )}
                            {!!content?.is_online && <StyledOnlineCircle />}

                            <StyledSavedButtonContainer onClick={likeButtonClickHandler} isSaving={isLoading}>
                                {!isLoading && <img alt={'like button'} src={isSaved ? heartFilled : heartEmpty} />}
                                {isLoading && <Spinner inline size={16} overlay={false} />}
                            </StyledSavedButtonContainer>
                        </StyledTitleWrapper>
                        {!!content?.description && (
                            <Translate content={content.description} isUseMainAppLanguage={true} />
                        )}
                    </StyledInfoWrapper>
                    <StyledFavTopicsContainer>
                        {(!!content?.chat_topics?.length || !!content?.favorite_activities?.length) && (
                            <>
                                <HostTopics
                                    withChatTopics={!!content?.chat_topics?.length}
                                    comparedChatTopics={content?.chat_topics as TopicOption[]} // TODO: this is due to deepPartial
                                    withActivities={!!content?.favorite_activities?.length}
                                    comparedActivities={content?.favorite_activities as TopicOption[]}
                                    topicsListStyles={() => topicsListStyles({theme})}
                                    isInFeed
                                />
                            </>
                        )}
                    </StyledFavTopicsContainer>
                </StyledInfoContainer>

                <Flexbox justify="space-between" align="end">
                    <HostMedia content={content} inTalentCard={inTalentCard}/>
                    <Flexbox align="end" height={'100%'}>
                        <StyledConnectNowButton onClick={mainCtaClickHandler}>
                            {t('common:connect_now')}
                        </StyledConnectNowButton>
                    </Flexbox>
                </Flexbox>
            </StyledCardContent>
        </StyledCardContainer>
    )
}

HostCard.displayName = 'HostCard'

import {Modal} from '@/components/ui/modal/Modal'
import {
    StyledActionButton,
    StyledAnimation,
    StyledBody,
    StyledButtonSection,
    StyledDivider,
    StyledTextWrapper
} from './style'
import {Button} from '@/components/ui/button/Button'
import {Trans, useTranslation} from 'react-i18next'
import {Animation} from '@/components/ui/animation/Animation'
import Delete from '@assets/animations/Alert-1.json'
import {useQueryClient} from '@tanstack/react-query'
import {useBlockUser} from '@/features/profile/queries/useBlockUser'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {QUERY_KEYS} from '@/features/guest/queries/keys'
import {QUERY_KEYS as CHANNELS_QUERY_KEYS} from '@/features/chat/queries/keys'
import {QUERY_KEYS as NOTIFICATIONS_QUERY_KEYS} from '@/features/notifications/queries/keys'
import {UserRoleE} from '@/types'
import useMixPanel from '@/integrations/mixpanel/hooks/useMixpanel'
import {MixpanelEventNameE} from '@/integrations/mixpanel/types'
import {useEffect} from 'react'
import {useHandleError} from '@/hooks/useHandleError'
import {useRootStore} from '@/store'
import {selectAlertsStore, selectHostProfileModal} from '@/store/selectors'
import {useNavigate} from 'react-router-dom'
import {ROUTE_HOME} from '@/utilities/constants/routeNames'
import {AppStatusesE} from '@/utilities/constants/appStatus'

type BlockGuestModalProps = {
    onClose: () => void
    hostName: string
    hostId: number
}

export const BlockHostModal = ({onClose, hostName, hostId}: BlockGuestModalProps) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {closeModal: onCloseHostProfileModal} = useRootStore(selectHostProfileModal)
    const queryClient = useQueryClient()
    const {addAlert} = useRootStore(selectAlertsStore)
    const mixpanel = useMixPanel()
    const eventData = {
        userRole: UserRoleE.Guest,
        userId: `${hostId}`
    }

    useEffect(() => {
        mixpanel?.triggerBackendEvent({eventType: MixpanelEventNameE.BlockUserClick, ...eventData})
    }, [])

    const onSuccess = () => {
        queryClient.invalidateQueries({queryKey: [CHANNELS_QUERY_KEYS.fetchChannels]})
        queryClient.invalidateQueries({queryKey: [QUERY_KEYS, hostId]})
        queryClient.invalidateQueries({queryKey: [NOTIFICATIONS_QUERY_KEYS.notifications_history]})
        mixpanel?.triggerBackendEvent({eventType: MixpanelEventNameE.BlockUserSuccess, ...eventData})
        onClose()
        onCloseHostProfileModal()
        navigate(ROUTE_HOME)
        addAlert({
            message: t('CTAModal:block_rookie_modal:success_blocked'),
            variant: AppStatusesE.SUCCESS,
            dismissTimeout: 5000,
            currentPath: ROUTE_HOME
        })
        navigate(ROUTE_HOME)
    }

    const {mutate, isPending, isError, error} = useBlockUser(onSuccess)
    useHandleError({isError, error, isBlankPage: true})

    return (
        <Modal
            onClose={onClose}
            title={t('CTAModal:block_rookie_modal:title', {
                ROOKIE_NAME: hostName
            })}
            width={'400px'}
            fullScreen
        >
            {isPending && <Spinner />}
            <StyledBody>
                <>
                    <p>
                        <Trans t={t} i18nKey="CTAModal:block_rookie_modal:description1" />
                    </p>

                    <StyledTextWrapper>
                        <StyledAnimation>
                            <Animation animationData={Delete} />
                        </StyledAnimation>
                        <p>
                            <Trans t={t} i18nKey="CTAModal:block_rookie_modal:be_sure" />
                        </p>
                    </StyledTextWrapper>
                </>

                <StyledButtonSection>
                    <StyledDivider />
                    <Button onClick={onClose}>{t('CTAModal:block_leader_modal:button_text')}</Button>
                    <StyledActionButton onClick={() => hostId && mutate(hostId)}>
                        {t('CTAModal:block_rookie_modal:bottom_button_text', {ROOKIE_NAME: hostName})}
                    </StyledActionButton>
                </StyledButtonSection>
            </StyledBody>
        </Modal>
    )
}

BlockHostModal.displayName = 'BlockHostModal'

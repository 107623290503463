export const ACCEPT_IMAGE_FILE_TYPES = {
    'image/png': ['.png'],
    'image/jpg': ['.jpg'],
    'image/jpeg': ['.jpeg']
}

export const ACCEPT_VIDEO_FILE_TYPES = {
    'video/mp4': ['.mp4'],
    'video/quicktime': ['.mov']
}

export const ACCEPT_DOCUMENT_FILE_TYPES = {
    'application/pdf': ['.pdf']
}

export const ACCEPT_AUDIO_FILE_TYPES = {
    'audio/mpeg': ['.mp3'],
    'audio/wav': ['.wav']
}

export const MAX_FILE_SIZE = 80740352 // 80MB TODO check with backend
export const MAX_AUDIO_SIZE = 5242880 //5MB

export const ACCEPTABLE_IMAGE_TYPES = ['jpeg', 'jpg', 'png', 'webp']
export const ACCEPTABLE_VIDEO_TYPES = ['mp4', 'mov']
export const ACCEPTABLE_AUDIO_TYPES = ['mp3', 'wav']

export const ACCEPTABLE_FILE_TYPE = [...ACCEPTABLE_IMAGE_TYPES, ...ACCEPTABLE_VIDEO_TYPES]

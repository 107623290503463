import {useMutation} from '@tanstack/react-query'
import {MUTATION_KEYS} from './keys'
import {PROFILE_API} from '../services/profile.http'
import { AxiosResponse } from 'axios'
import { GeneralError } from '@/utilities/getAPIErrorMessage'

export const useUpdateCreditCard = () => {
    return useMutation<AxiosResponse, GeneralError>({
        mutationKey: [MUTATION_KEYS.update_credit_card],
        mutationFn: () => PROFILE_API.updateCreditCard()
    })
}

import {Lens} from '@dhmk/zustand-lens'
//use this when you need light and dark header on one page
type HeaderMode = 'light' | 'dark'
type HeaderNavBarMode = 'with' | 'without' 

type HeaderModeState = {
    isHeaderLight: boolean
    onlyNavBar: boolean
}

type HeaderModeAction = {
    toggleHeaderMode: (mode: HeaderMode) => void
    toggleOnlyNavBarMode: (mode: HeaderNavBarMode) => void
}

const initialState: HeaderModeState = {
    isHeaderLight: true,
    onlyNavBar: false
}

export type HeaderState = HeaderModeState & HeaderModeAction

const createHeaderModeSlice: Lens<HeaderState> = set => ({
    ...initialState,
    toggleHeaderMode: mode => set({isHeaderLight: mode === 'light'}),
    toggleOnlyNavBarMode: mode => set({onlyNavBar: mode === 'without'}),
})
export default createHeaderModeSlice

import {useTranslation} from 'react-i18next'
import {StyledCta, StyledCtaContent, StyledCtaWrap, StyledTooltipContent} from './style'
import {useMemo} from 'react'
import {Button} from '@/components/ui/button/Button'
import {TriangleIcon} from '@/assets/icons/icons'
import {Popover} from '@/components/ui/popover/Popover'
import {HostListTypesE} from '../../types'
import {SaveHostButton} from '../save-host-button/SaveHostButton'
import {useRootStore} from '@/store'
import {selectConnectModal, selectSendBffModal} from '@/store/selectors'
import {useSendFeaturedHost} from '@/features/chat/queries/useSendFeaturedHost'
import {useParams} from 'react-router-dom'
import {useGetChannelDetails} from '@/features/chat/queries/useGetChannelDetails'
import {ChannelID} from '@/features/chat/types'
import {useAddFeaturedHost} from '../../queries/useAddFeaturedHost'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useHandleError} from '@/hooks/useHandleError'
import useSendChatMessage from '@/features/chat/hooks/useSendChatMessage'
import {MessageTypeE} from '@/utilities/constants/chat'
import {useMe} from '@/features/authentication/queries/useMe'
import {UserRoleE} from '@/types'
import {useGetConnectedUser} from '@/hooks/useGetConnectedUser'
import useRedirectToChatChannel from '@/hooks/useRedirectToChatChannel'

type MorgisTaxiHostCardProps = {
    featuredHostId: number
    type: HostListTypesE
    guestName?: string
    onRemove: () => void
    maxFeaturedHosts: number
    position?: number
}

export const HostCardCtaSection = ({
    featuredHostId,
    type,
    guestName,
    onRemove,
    maxFeaturedHosts,
    position
}: MorgisTaxiHostCardProps) => {
    const {t} = useTranslation()
    const {data: host} = useMe()
    const isHost = host?.type === UserRoleE.Host
    const inProfile = type === HostListTypesE.PROFILE
    const {connectedUser: connectedHost} = useGetConnectedUser(featuredHostId)
    const {channel} = useParams()
    const {closeModal} = useRootStore(selectSendBffModal)

    const {data: channelDetails} = useGetChannelDetails(channel as ChannelID)
    const {openModal} = useRootStore(selectConnectModal)
    const {sendChatMessage, isPending: isPendingSendFeaturedHost} = useSendChatMessage(channel as ChannelID)
    const onSuccess = async () => {
        await sendChatMessage({
            type: MessageTypeE.FEATURED_HOST,
            userId: +`${host?.id}`,
            featuredRookieId: featuredHostId
        })
        closeModal()
    }

    const {mutate, isPending, error, isError} = useSendFeaturedHost(onSuccess)

    const {
        mutate: addFeaturedHost,
        isPending: isPendingAddFeaturedHost,
        isError: isErrorAddFeaturedHost,
        error: errorAddFeaturedHost
    } = useAddFeaturedHost({})

    useHandleError({isError: isErrorAddFeaturedHost || isError, error: errorAddFeaturedHost || error})

    const sendFeaturedHost = async () => {
        mutate({
            chanelID: `${channelDetails?.channel_id}`,
            hostId: featuredHostId
        })
        closeModal()
    }

    const ctaText = useMemo(() => {
        switch (type) {
            case HostListTypesE.FEATURED_HOSTS:
                return t('my_morgis_taxi_page:added_hosts_cta', {
                    POSITION: position,
                    TOTAL: maxFeaturedHosts
                })
            case HostListTypesE.CHAT_TEASER:
            case HostListTypesE.PROFILE:
                return t('common:connect_now')
            case HostListTypesE.CHAT_FEATURED:
            case HostListTypesE.CHAT_SUGGESTED:
                return t('my_morgis_taxi_page:chat_bff_modal_cta')

            default:
                return t('my_morgis_taxi_page:suggested_hosts_cta')
        }
    }, [type])

    const openConnectNowModal = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation()
        e.preventDefault()
        openModal({hostID: featuredHostId})
    }

    const redirectToChatChannel = useRedirectToChatChannel({
        receiverId: parseInt(`${featuredHostId}`)
    })

    const ctaAction = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        switch (type) {
            case HostListTypesE.CHAT_FEATURED:
            case HostListTypesE.CHAT_SUGGESTED:
                return sendFeaturedHost()
            case HostListTypesE.SUGGESTED_HOSTS:
                return addFeaturedHost({hostId: featuredHostId})
            case HostListTypesE.FEATURED_HOSTS:
                return onRemove()
            case HostListTypesE.CHAT_TEASER:
            case HostListTypesE.PROFILE:
            default:
                openConnectNowModal(e)
                break
        }
    }

    return (
        <>
            {(isPendingAddFeaturedHost || isPendingSendFeaturedHost) && <Spinner />}
            <StyledCtaWrap>
                {isPending && <Spinner />}
                {type === HostListTypesE.FEATURED_HOSTS ? (
                    <Popover
                        trigger={
                            <StyledCta>
                                <Button icon={true} iconPosition="right" variant={'primaryOutlined'}>
                                    <StyledCtaContent>
                                        {ctaText}
                                        <TriangleIcon />
                                    </StyledCtaContent>
                                </Button>
                            </StyledCta>
                        }
                        triggerProps={{
                            asChild: true
                        }}
                    >
                        <StyledTooltipContent onClick={ctaAction}>
                            {t('my_morgis_taxi_page:remove_featured_host_tooltip')}
                        </StyledTooltipContent>
                    </Popover>
                ) : (
                    <StyledCta>
                        {type === HostListTypesE.PROFILE && <SaveHostButton featuredHostId={featuredHostId} />}
                        <Button
                            icon={true}
                            iconPosition="right"
                            variant={'primary'}
                            onClick={e => {
                                e.preventDefault()
                                e.stopPropagation()
                                connectedHost ? redirectToChatChannel?.() : ctaAction(e)
                            }}
                            disabled={isPending || (isHost && inProfile)}
                        >
                            <StyledCtaContent>
                                {ctaText} {guestName && <span>{guestName}</span>}
                            </StyledCtaContent>
                        </Button>
                    </StyledCta>
                )}
            </StyledCtaWrap>
        </>
    )
}

HostCardCtaSection.displayName = 'HostCardCtaSection'

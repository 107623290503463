import {Modal} from '@/components/ui/modal/Modal'
import {useTranslation} from 'react-i18next'
import {useRootStore} from '@/store'
import {selectSendBffModal} from '@/store/selectors'
import {modalStyles, StyledBody, StyledListWrap} from './style'
import {FeaturedMorgisTaxiHostList} from '../morgis-taxi-host-list/FeaturedMorgisTaxiHostList'
import {HostListTypesE} from '../../types'
import {useGetFeaturedHosts} from '../../queries/useGetFeaturedHosts'
import {useHandleError} from '@/hooks/useHandleError'
import {Spinner} from '@/components/ui/spinner/Spinner'

const SendBffModal = () => {
    const {closeModal, guestName} = useRootStore(selectSendBffModal)
    const {t} = useTranslation()
    const {data: featuredHosts, isLoading, isError, error} = useGetFeaturedHosts({})
    const hasFeaturedHosts = !!featuredHosts && !!featuredHosts?.length

    useHandleError({isError: isError, error: error})

    return (
        <Modal title={t('send_bff_modal:title')} onClose={closeModal} modalStyles={modalStyles} fullScreen>
            {isLoading && <Spinner />}
            <StyledBody>
                <StyledListWrap>
                    {hasFeaturedHosts && (
                        <FeaturedMorgisTaxiHostList
                            hostsList={featuredHosts}
                            type={HostListTypesE.CHAT_FEATURED}
                            guestName={guestName}
                        />
                    )}
                </StyledListWrap>
            </StyledBody>
        </Modal>
    )
}

export default SendBffModal

import styled, {css, DefaultTheme} from 'styled-components'

export const StyledWrapper = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        padding: ${spacing * 2}px ${spacing * 2}px 0;
        overflow-y: auto;

        ${mediaQueries.m} {
            padding: 0px ${spacing * 5}px ${spacing * 1.25}px;
        }
    `}
`
export const modalStyles = (theme: DefaultTheme) => css`
    ${theme.mediaQueries.m} {
        height: fit-content;
    }
`

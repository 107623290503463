import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext'
import {$createParagraphNode, $createTextNode, $getRoot} from 'lexical'
import {useEffect, useState} from 'react'

export function OnChangePlugin({
    onChange,
    valueToInsert,
    isDisabled = false,
    value,
    isReplaceValueToInsert
}: {
    onChange: (value: string) => void
    valueToInsert?: string
    isDisabled?: boolean
    value: string
    isReplaceValueToInsert: boolean
}) {
    const [editor] = useLexicalComposerContext()
    const [insertPosition, setInsertPosition] = useState<{
        startPosition: number
        endPosition: number
    }>({
        startPosition: -1,
        endPosition: -1
    })
    function update() {
        editor.update(() => {
            const root = $getRoot()
            root.getChildren().forEach(n => n.remove())
            let textNode = null
            if (insertPosition.startPosition > -1) {
                const value = root.__cachedText
                const part1 = value?.slice(0, insertPosition.startPosition)
                const part3 = value?.slice(insertPosition.endPosition)
                textNode = $createTextNode(`${part1}${valueToInsert}${part3}`)
            } else {
                textNode = $createTextNode(`${root.__cachedText}${valueToInsert}`)
            }
            const paragraphNode = $createParagraphNode()
            paragraphNode.append(textNode).selectEnd()
            root.append(paragraphNode)
        })
    }

    useEffect(() => {
        if (valueToInsert && isReplaceValueToInsert) {
            const startPosition = `${value}${valueToInsert}`.indexOf(valueToInsert)
            if (startPosition > -1)
                setInsertPosition({startPosition, endPosition: startPosition + valueToInsert.length})
        }
    }, [value, valueToInsert])

    useEffect(() => {
        !!valueToInsert && update()
    }, [valueToInsert])

    useEffect(() => {
        // make this editor editable needed to fix issue with autofocus when we have default value
        editor.setEditable(!isDisabled)
        return editor.registerUpdateListener(({editorState}) => {
            editorState.read(() => {
                const root = $getRoot()
                onChange(root.__cachedText || '')
            })
        })
    }, [editor, onChange, isDisabled])
    return null
}

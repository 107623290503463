import {AgentSchema, GuestSchema, HostSchema} from '@/features/authentication/services/auth.schema'
import {GoalSchema} from '@/features/goal/services/goal.schema'
import {z} from 'zod'

export enum TransactionsE {
    gift = 'gift',
    boughtMicromorgi = 'bought_micromorgi'
}

export const AnonTransactionSchemaSubscriptionPackage = z.object({
    id: z.number().nullable().optional(),
    amount: z.number().nullable().optional(),
    dollar_amount: z.number().nullable().optional(),
    surprises_count: z.number().nullable().optional(),
    is_default: z.boolean(),
    is_visible: z.boolean()
})

export const AnonTransactionGroup = z.object({
    id: z.number().nullable().optional(),
    user_id: z.number().optional(),
    key_name: z.string().nullable().optional(),
    name: z.string().optional(),
    description: z.string().nullable().optional(),
    image_path_location: z.string().nullable().optional(),
    subscription_package_amount: z.number().optional(),
    messages: z.array(z.any()).optional(),
    users_count: z.number().optional(),
    goal: z.nullable(GoalSchema).optional(),
    url: z.string().nullable().optional()
})
export const AnonTransactionSchema = z.object({
    agent: z.nullable(AgentSchema),
    amount_dollars: z.number().nullable().optional(),
    amount_micromorgi: z.number().nullable().optional(),
    amount_morgi: z.number().nullable().optional(),
    campaign_id: z.number().nullable().optional(),
    chat_attachment_id: z.number().nullable().optional(),
    coupon_id: z.number().nullable().optional(),
    created_at: z.string().nullable().optional(),
    description: z.string().nullable().optional(),
    goal: z.nullable(GoalSchema),
    discounted_amount: z.number().nullable().optional(),
    discounted_dollars: z.number().nullable().optional(),
    goal_id: z.number().nullable().optional(),
    id: z.number(),
    is_bonus: z.boolean().optional(),
    is_refunded: z.boolean().optional(),
    is_subscription_reactivate_transaction: z.boolean().optional(),
    leader: z.nullable(GuestSchema),
    leader_chronological_id: z.number().nullable().optional(),
    notes: z.string().nullable().optional(),
    paid_message_id: z.number().nullable().optional(),
    referal_external_id: z.number().nullable().optional(),
    referal_internal_id: z.number().nullable().optional(),
    refund_by_biller: z.boolean().optional(),
    refund_date: z.string().nullable().optional(),
    refund_type: z.string().nullable().optional(),
    rookie: z.nullable(HostSchema),
    rookie_chronological_id: z.number().nullable().optional(),
    sign: z.string().nullable().optional(),
    group: z.nullable(AnonTransactionGroup).optional(),
    subscription_package: z.nullable(AnonTransactionSchemaSubscriptionPackage).optional(),
    taxed_dollars: z.number().nullable().optional(),
    taxed_micromorgi: z.number().nullable().optional(),
    taxed_morgi: z.number().nullable().optional(),
    type: z.nativeEnum(TransactionsE)
})

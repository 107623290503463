import {Lens} from '@dhmk/zustand-lens'

type SendBffModalState = {
    isOpen: boolean
    guestName: string
}

type SendBffModalActions = {
    openModal: (params: Omit<SendBffModalState, 'isOpen'>) => void
    closeModal: () => void
}

export type SendBffModal = SendBffModalState & SendBffModalActions

const initialModal: SendBffModalState = {isOpen: false, guestName: ''}

const createSendBffModalSlice: Lens<SendBffModal> = set => ({
    ...initialModal,
    openModal: params => set(() => ({...params, isOpen: true})),
    closeModal: () => set(() => ({...initialModal}))
})

export default createSendBffModalSlice

import {PageHeader} from '@/components/commons/page-header/PageHeader'
import {Avatar} from '@/components/ui/avatar/Avatar'
import {PrivateFragment} from '@/components/ui/privateFragment/PrivateFragment'
import {getLocalDate, isPasteDate} from '@/dayjs'
import {useMe} from '@/features/authentication/queries/useMe'
import {EditGoalModal} from '@/features/goal/components/edit-goal-modal/EditGoalModal'
import {CoverMedia} from '@/features/goal/components/goal-details/cover-media/CoverMedia'
import {ForWhomGoalSection} from '@/features/goal/components/goal-details/for-whom-section/ForWhomGoalSection'
import {ProofSection} from '@/features/goal/components/goal-details/proof-section/ProofSection'
import {HostStatusExplanationSection} from '@/features/goal/components/goal-details/status-explanation-section/HostStatusExplanationSection'
import {SetNewGoalDataModal} from '@/features/goal/components/set-new-goal-data/SetNewGoalDataModal'
import {StopGoalModal} from '@/features/goal/components/stop-goal-modal/StopGoalModal'
import {WithdrawModal} from '@/features/goal/components/withdraw-modal/WithdrawModal'
import {goalStatusesWithoutTimer} from '@/features/goal/constant/goalCard'
import {additionalContent} from '@/features/goal/constant/goalSize'
import {UserRoleE} from '@/types'
import {ROUTE_HOME, ROUTE_HOST_PROFILE} from '@/utilities/constants/routeNames'
import {USER_ROLE_HOST} from '@/utilities/constants/user'
import {useEffect, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {generatePath, useLocation, useNavigate} from 'react-router-dom'
import {StyledDescription, StyledGoalPageWrapper, StyledTranslationSwitch} from './style'
import {ShareGoalModal} from '@/features/goal/components/share-goal-modal/ShareGoalModal'
import {GoalStatusesE, GoalType} from '../../types'
import {Translate} from '@/features/translation/components/translate/Translate'
import {TranslateSwitch} from '@/features/translation/components/translate-switch/TranslateSwitch'
import {useRootStore} from '@/store'
import {selectHostProfileModal} from '@/store/selectors'

export const GoalPageContent = ({
    goal,
    handleClose,
    isGoalModalMode = false
}: {
    goal: GoalType
    handleClose: () => void
    isGoalModalMode?: boolean
}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {pathname} = useLocation()
    const {openModal: openHostProfileModal} = useRootStore(selectHostProfileModal)

    const {data} = useMe()
    const isHost = data?.type === USER_ROLE_HOST
    const localEndDate = goal?.end_date && getLocalDate(goal?.end_date)
    const hasEnded = !!localEndDate && isPasteDate(localEndDate)
    const hideTimer = goal && goalStatusesWithoutTimer.includes(goal?.status)
    const isPastGoal = hasEnded || hideTimer
    const [isOpenEditGoalModal, setIsOpenEditGoalModal] = useState(false)
    const [isOpenStopGoalModal, setIsOpenStopGoalModal] = useState(false)
    const [isOpenSetNewGoalDataModal, setIsOpenSetNewGoalDataModal] = useState(false)
    const [isOpenShareGoalModal, setIsOpenShareGoalModal] = useState(false)
    const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false)
    const [languagesUsed, setLanguagesUsed] = useState<string[]>([])
    const goalTypeAdditionalContent = goal?.type && additionalContent[goal?.type?.type]

    const handleOpenStopGoalModal = () => {
        // open StopGoalModal
        setIsOpenStopGoalModal(true)
        setIsOpenEditGoalModal(false)
    }

    const onEditButtonClick = () => {
        // open SetNewGoalDataModal
        setIsOpenSetNewGoalDataModal(true)
        setIsOpenEditGoalModal(false)
    }

    const onAvatarClick = () => {
        // if in home -> close modal
        // if elsewhere -> go to rookie profile
        if (pathname !== ROUTE_HOME) {
            if (isGoalModalMode) {
                !isHost && openHostProfileModal({hostUsername: `${goal?.rookie?.username}`})
            } else {
                !isHost && navigate(generatePath(ROUTE_HOST_PROFILE, {username: `${goal?.rookie?.username}`}))
            }
        } else {
            handleClose()
        }
    }

    const onGetLanguages = (lang: string) => {
        setLanguagesUsed(prevState => [...prevState, lang])
    }

    useEffect(() => {
        return () => setLanguagesUsed([])
    }, [])

    const translationsCount = [
        !!goal.radiobox,
        !!goal.details,
        !isHost && goal?.status === GoalStatusesE.SUCCESSFUL && !!goal.proof_note
    ].filter(Boolean)

    return (
        <>
            <div>
                <PageHeader
                    extraContent={
                        <div onClick={onAvatarClick}>
                            <Avatar imageUrl={goal?.rookie.avatar?.url} height={36} width={36} />
                        </div>
                    }
                    onBack={handleClose}
                    title={
                        <>
                            <PrivateFragment permittedRoles={[UserRoleE.Guest]}>
                                <>
                                    {t('goal_page:rookie_name', {
                                        USERNAME: goal?.rookie?.full_name
                                    })}
                                </>
                            </PrivateFragment>
                            <Trans
                                t={t}
                                i18nKey={'goal_card:goal_type'}
                                values={{
                                    context: goalTypeAdditionalContent?.type_name
                                }}
                            />
                        </>
                    }
                />

                <StyledGoalPageWrapper>
                    <CoverMedia
                        goal={goal}
                        isPastGoal={isPastGoal}
                        icon={goalTypeAdditionalContent?.whiteIcon}
                        isHost={isHost}
                        onEditClick={() => setIsOpenEditGoalModal(true)}
                        onWithdrawClick={() => setIsWithdrawModalOpen(true)}
                        onShareClick={() => setIsOpenShareGoalModal(true)}
                        onClose={isGoalModalMode ? handleClose : undefined}
                    />

                    <PrivateFragment permittedRoles={[UserRoleE.Host]}>
                        <HostStatusExplanationSection goal={goal} />
                    </PrivateFragment>

                    <PrivateFragment permittedRoles={[UserRoleE.Host]}>
                        <ProofSection goal={goal} isHost={isHost} />
                    </PrivateFragment>

                    <ForWhomGoalSection
                        isForOthers={goal?.is_for_others}
                        text={
                            goal?.radiobox && (
                                <Translate content={`${goal?.radiobox}`} onGetNativeLanguageOfText={onGetLanguages} />
                            )
                        }
                    />

                    <StyledDescription>
                        <div> {t('goal_page:description:title')}</div>
                        <span>
                            <Translate content={goal?.details} onGetNativeLanguageOfText={onGetLanguages} />
                        </span>
                    </StyledDescription>

                    <PrivateFragment permittedRoles={[UserRoleE.Guest]}>
                        <ProofSection
                            goal={goal}
                            isHost={isHost}
                            translatedProofNote={
                                <Translate content={`${goal?.proof_note}`} onGetNativeLanguageOfText={onGetLanguages} />
                            }
                        />
                    </PrivateFragment>

                    <PrivateFragment permittedRoles={[UserRoleE.Guest]}>
                        <StyledTranslationSwitch>
                            <TranslateSwitch
                                languagesUsed={{
                                    languages: languagesUsed,
                                    count: translationsCount.length
                                }}
                            />
                        </StyledTranslationSwitch>
                    </PrivateFragment>
                </StyledGoalPageWrapper>
            </div>

            {isOpenEditGoalModal && (
                <EditGoalModal
                    onClose={() => setIsOpenEditGoalModal(false)}
                    onStopButtonClick={handleOpenStopGoalModal}
                    onEditButtonClick={onEditButtonClick}
                    status={goal?.status}
                />
            )}
            {isOpenStopGoalModal && (
                <StopGoalModal
                    onClose={() => setIsOpenStopGoalModal(false)}
                    onStopGoalClick={() => setIsOpenStopGoalModal(false)}
                    goalId={goal?.id}
                />
            )}
            {isOpenSetNewGoalDataModal && (
                <SetNewGoalDataModal
                    onClose={() => setIsOpenSetNewGoalDataModal(false)}
                    status={goal?.status}
                    goal={goal}
                />
            )}
            {isWithdrawModalOpen && (
                <WithdrawModal onClose={() => setIsWithdrawModalOpen(false)} goalId={`${goal?.id}`} />
            )}

            {isOpenShareGoalModal && !!goal && (
                <ShareGoalModal goal={goal} onClose={() => setIsOpenShareGoalModal(false)} />
            )}
        </>
    )
}
GoalPageContent.displayName = 'GoalPageContent'

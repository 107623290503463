import {useRootStore} from '@/store'
import {selectChoosePaymentsMethodModal, selectErrorModal, selectUpdateCreditCardModal} from '@/store/selectors'
import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {CardUpdateTypes} from '../../constants/card'
import {useGetSubscriptionsToRenew} from '../../queries/useGetSubscriptionsToRenew'
import {useRenewSubscription} from '../../queries/useRenewSubscription'
import {Subscription, SubscriptionsStatusE} from '../../types'
import {SupportedUser} from '../supported-user/SupportedUser'
import {
    StyledButton,
    StyledDescriptionEndList,
    StyledListOfSupportedUsers,
    StyledListOfSupportedUsersContainer
} from './style'
import {Spinner} from '@/components/ui/spinner/Spinner'
import { useUpdateCreditCard } from '@/features/profile/queries/useUpdateCreditCard'
import { PaymentMethodUrls } from '@/features/guest-payments/types'

export const ListOfSupportedUsers = () => {
    const {t} = useTranslation()
    const {data, isLoading} = useGetSubscriptionsToRenew()

    const [selectedSubscriptions, setSelectedSubscriptions] = useState<Subscription[]>(data?.subscriptions ?? [])
    const {mutate: renewSubscription, error: errorsRenewSubscription, isPending: isPendingRenew} = useRenewSubscription()
    const {openErrorModal} = useRootStore(selectErrorModal)
    const {closeModal: closeModalUpdateCreditCardModal} = useRootStore(selectUpdateCreditCardModal)
    const {openModal: openChoosePaymentsMethodModal} = useRootStore(selectChoosePaymentsMethodModal)
    const {mutate: updateCreditCard, error: paymentMethods, isPending: isPendingUpdate} = useUpdateCreditCard()
    const isPending = isPendingRenew || isPendingUpdate

    const onSelectSelectedSubscription = (selectedSubscriptionID: number) => {
        setSelectedSubscriptions(prevSelectedSubscriptions =>
            prevSelectedSubscriptions.map(prevSelectedSubscription =>
                prevSelectedSubscription.id === selectedSubscriptionID
                    ? {
                          ...prevSelectedSubscription,
                          status:
                              prevSelectedSubscription.status === SubscriptionsStatusE.Active
                                  ? SubscriptionsStatusE.Inactive
                                  : SubscriptionsStatusE.Active
                      }
                    : prevSelectedSubscription
            )
        )
    }

    const onSubmit = () => {
        const isRenew = !selectedSubscriptions.find(item => item.status === SubscriptionsStatusE.Active)
        !isRenew ? renewSubscription({
            need_ccbill: true,
            type: CardUpdateTypes.NEW_CARD,
            subscriptions: selectedSubscriptions
                .filter(selectedSubscription => selectedSubscription.status === SubscriptionsStatusE.Active)
                .map((selectedSubscription: Subscription) => ({
                    id: selectedSubscription.id.toString(),
                    action: 'update'
                }))
        }) : updateCreditCard()
    }

    useEffect(() => {
        data && setSelectedSubscriptions(data.subscriptions)
    }, [data])

    useEffect(() => {
        if (errorsRenewSubscription || paymentMethods) {
            if (errorsRenewSubscription?.response?.status === 303 || paymentMethods) {
                openChoosePaymentsMethodModal({
                    paymentMethodUrls: errorsRenewSubscription?.response?.data || paymentMethods?.response?.data  as PaymentMethodUrls,
                    isRecurring: true,
                    isUpdateCreditCard: true,
                    apiCallback: onSubmit
                })
            } else {
                !!errorsRenewSubscription && openErrorModal({message: errorsRenewSubscription.message})
            }
            closeModalUpdateCreditCardModal()
        }
    }, [errorsRenewSubscription, paymentMethods])

    return (
        <>
            {isLoading || isPending ? (
                <Spinner />
            ) : (
                <StyledListOfSupportedUsersContainer>
                    <StyledListOfSupportedUsers>
                        {selectedSubscriptions.map(subscription => (
                            <SupportedUser
                                key={subscription.id}
                                subscription={subscription}
                                onSelect={() => onSelectSelectedSubscription(subscription.id)}
                            />
                        ))}
                        <StyledDescriptionEndList>
                            {t('active_gifting_leaders:description_end_list')}
                        </StyledDescriptionEndList>
                    </StyledListOfSupportedUsers>
                    <StyledButton onClick={onSubmit}>{t('leader_payments:cta')}</StyledButton>
                </StyledListOfSupportedUsersContainer>
            )}
        </>
    )
}

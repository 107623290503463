import styled, {css, DefaultTheme} from 'styled-components'

export const StyledSwitchWrapper = styled.div`
    ${({theme: {spacing, flexUtils}, inMessage}: {theme: DefaultTheme; inMessage?: boolean}) => css`
        ${flexUtils.centered}
        ${inMessage && 'justify-content: flex-start;'}
        gap: ${spacing / 2}px;
    `}
`

export const StyledLabel = styled.label`
    ${({theme: {typography}}) => css`
        ${typography.textXxs}
        font-weight: 400;
        line-height: 12px;
        position: relative;
    `}
`
export const StyledLanguageLabel = styled.span<{isActive: boolean}>`
    ${({isActive}) =>
        isActive
            ? css`
                  text-decoration: underline;
                  cursor: pointer;
              `
            : css``}
`

import {useMe} from '@/features/authentication/queries/useMe'
import {useGetChatTopics} from '../queries/useGetChatTopics'
import {USER_ROLE_GUEST, USER_ROLE_HOST, USER_ROLE_REPRESENTATIVE} from '@/utilities/constants/user'

export const useGetAllTopics = () => {
    const {data: user} = useMe()
    const enabled =
        user?.type === USER_ROLE_GUEST ||
        [USER_ROLE_HOST, USER_ROLE_REPRESENTATIVE].includes(user?.type as string) ||
        !user
    const {data: chatFavoriteTopicsData} = useGetChatTopics(true, enabled)
    const {data: chatUnfavoriteTopicsData} = useGetChatTopics(false, enabled)
    const chatFavoriteTopics = chatFavoriteTopicsData ?? []
    const chatUnfavoriteTopics = chatUnfavoriteTopicsData ?? []

    return {
        topics: [...chatFavoriteTopics, ...chatUnfavoriteTopics]
    }
}

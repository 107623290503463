import {useMe} from '@/features/authentication/queries/useMe.ts'
import {USER_ROLE_GUEST, USER_ROLE_HOST} from '@/utilities/constants/user'
import {Outlet} from 'react-router-dom'
import {HostType} from '@/features/authentication/types'
import {HostRouteRedirect} from './host-route-redirect/HostRouteRedirect'
import { SessionStorageManager } from '@/utilities/sessionStorage'
import { useCheckTransactionStatus } from '@/features/transactions/queries/useCheckTransactionStatus'
import useRedirectToChatChannel from '@/hooks/useRedirectToChatChannel'
import { useEffect } from 'react'

// the user here is logged in, but can have problems on his account
// here we check the user status and choose to let him inside or
// push it backwards into a logged-in blocking route
export const UserStatusControlRoute = () => {
    const {data: user} = useMe()
    const isFirstLogin = !!SessionStorageManager.checkIsFirstLogin.get()
    const isHost = !!user && user.type === USER_ROLE_HOST
    const isGuest = !!user && user.type === USER_ROLE_GUEST
    const {data} = useCheckTransactionStatus()
    const redirectToChat = useRedirectToChatChannel({receiverId: data?.rookie?.id as number})

    useEffect(()=>{
        if(isGuest && isFirstLogin && !!redirectToChat && data?.rookie?.id) {
            redirectToChat()
            SessionStorageManager.checkIsFirstLogin.remove()
        }
    }, [data?.rookie?.id])

    switch (true) {
        case isHost:
            return <HostRouteRedirect user={user as HostType} />
        default:
            return <Outlet />
    }
}

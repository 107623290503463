import {
    CloseIcon,
    FilterByPathActiveIcon,
    FilterTabIcon,
    GoalsTabIcon,
    HostsTabIcon,
    SearchIcon
} from '@/assets/icons/icons'
import {InputText} from '@/components/commons/input-text/InputText'
import {SelectedFiltersList} from '@/components/commons/selected-filters-list/SelectedFiltersList'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useMe} from '@/features/authentication/queries/useMe'
import {GuestType} from '@/features/authentication/types'
import useContainerDimensions from '@/hooks/useContainerDimensions'
import {useHandleError} from '@/hooks/useHandleError'
import {useRootStore} from '@/store'
import {selectGoalFilters} from '@/store/selectors'
import {breakpoints} from '@/theme/mediaQueries'
import {ROUTE_GOALS_MAIN_PAGE, ROUTE_HOME} from '@/utilities/constants/routeNames'
import {FC, useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {useTheme} from 'styled-components'
import {FeedVariantE} from '../../constants'
import {remapHostActiveFilters} from '../../helpers/helpers'
import {useClearActiveFilters} from '../../queries/useClearActiveFilters'
import useFeedStore from '../../store'
import {
    StyledActiveFilters,
    StyledFeedBarRoot,
    StyledFeedBarWrapper,
    StyledIcon,
    StyledSelectedFilters,
    StyledTab
} from './style'

type FeedBarProps = {
    onFilterClick: () => void
    variant: FeedVariantE
}

const feedTabs = {
    host: {
        id: 'host',
        icon: <HostsTabIcon />,
        name: 'main_leader_tabs:hosts'
    },
    goal: {
        id: 'goal',
        icon: <GoalsTabIcon />,
        name: 'main_leader_tabs:goals'
    }
}

export const FeedBar: FC<FeedBarProps> = ({onFilterClick, variant}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {spacing} = useTheme()
    const {width} = useContainerDimensions()
    const setIsFetchingWithHostFilters = useFeedStore(state => state.setIsFetchingWithHostFilters)
    const setSearchParam = useFeedStore(state => state.setSearchParam)
    const isActiveSearch = useFeedStore(state => state.showSearchBox)
    const setIsActiveSearch = useFeedStore(state => state.setShowSearchBox)

    const searchInput = useRef<HTMLInputElement>(null)
    const [isMobile, setIsMobile] = useState(width <= breakpoints.width.m)

    const clearSearch = () => {
        if (searchInput.current) {
            searchInput.current.value = ''
            setSearchParam()
        }
    }
    // Host filters
    const {data: user} = useMe()
    const guest = user as GuestType
    const guestHasActiveFilters = !!guest?.type_attributes?.leaders_carousels_filters
    const currentFilters = guest?.type_attributes?.leaders_carousels_filters?.filters
    const remappedActiveHostFilters = currentFilters ? remapHostActiveFilters(currentFilters) : []
    const {
        mutate: clearHostFilters,
        isPending: isClearFiltersPending,
        isError: isClearFiltersError,
        error: clearFiltersError
    } = useClearActiveFilters()

    useHandleError({isError: isClearFiltersError, error: clearFiltersError})

    // Goal filters
    const {filters, clearGoalFilters} = useRootStore(selectGoalFilters)
    const selectedGoalFilters = filters.selectedFiltersNames
    const remapSelectedGoalFilters = selectedGoalFilters.map(item => ({
        label: item,
        id: item
    }))

    const hasSelectedFilters = variant === FeedVariantE.goals ? !!selectedGoalFilters.length : guestHasActiveFilters
    const showGoalActiveFilters = !!hasSelectedFilters && !isMobile
    const showHostActiveFilters = guestHasActiveFilters && !!remappedActiveHostFilters.length
    const showActiveFiltersSection = variant === FeedVariantE.goals ? showGoalActiveFilters : showHostActiveFilters

    const clearFilters = () => {
        if (variant === FeedVariantE.goals) {
            clearGoalFilters()
        } else {
            clearHostFilters()
            setIsFetchingWithHostFilters(true)
        }

        clearSearch()
    }

    const filterOnClick = () => {
        clearSearch()
        setIsActiveSearch(false)
        onFilterClick()
    }

    useEffect(() => {
        setIsMobile(width <= breakpoints.width.m)
    }, [width])

    useEffect(() => {
        clearSearch()
    }, [variant])
    useEffect(() => {
        if (!isActiveSearch) clearSearch()
    }, [isActiveSearch])
    return (
        <StyledFeedBarWrapper>
            {isClearFiltersPending && <Spinner />}
            <StyledFeedBarRoot>
                <StyledTab
                    onClick={() => {
                        clearSearch()
                        setIsActiveSearch(false)
                        navigate(ROUTE_HOME)
                    }}
                >
                    <StyledIcon isActive={variant === FeedVariantE.hosts && !isActiveSearch}>
                        {feedTabs.host.icon}
                    </StyledIcon>
                    {t(feedTabs.host.name)}
                </StyledTab>
                <StyledTab
                    onClick={() => {
                        clearSearch()
                        setIsActiveSearch(false)
                        navigate(ROUTE_GOALS_MAIN_PAGE)
                    }}
                >
                    <StyledIcon isActive={variant === FeedVariantE.goals && !isActiveSearch}>
                        {feedTabs.goal.icon}
                    </StyledIcon>
                    {t(feedTabs.goal.name)}
                </StyledTab>
                <StyledTab
                    onClick={() => {
                        setIsActiveSearch(!isActiveSearch)
                        if (!isActiveSearch) searchInput?.current?.focus()
                    }}
                >
                    <StyledIcon isActive={isActiveSearch}>
                        <SearchIcon fill="url(#paint0_linear_6177_12693)" />
                    </StyledIcon>

                    {t('common:search')}
                </StyledTab>
                <StyledTab onClick={hasSelectedFilters ? onFilterClick : filterOnClick}>
                    {hasSelectedFilters ? (
                        <StyledActiveFilters>
                            <FilterByPathActiveIcon width={30} height={30} onClick={onFilterClick} />{' '}
                            <CloseIcon onClick={clearFilters} />
                        </StyledActiveFilters>
                    ) : (
                        <StyledIcon>
                            <FilterTabIcon width={30} height={30} />
                        </StyledIcon>
                    )}
                    {t('main_leader_tabs:filter')}
                </StyledTab>
            </StyledFeedBarRoot>

            {isActiveSearch && (
                <StyledFeedBarRoot isActive={isActiveSearch}>
                    <InputText
                        ref={searchInput}
                        placeholder={`${t('common:search')}...`}
                        icon={
                            <SearchIcon
                                fill="#B4B3B3"
                                cursor="pointer"
                                onClick={() => {
                                    const value = searchInput?.current?.value
                                    setSearchParam(value ? value : undefined)
                                }}
                            />
                        }
                        name={'search'}
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                const value = searchInput?.current?.value
                                setSearchParam(value ? value : undefined)
                            }
                        }}
                    />
                </StyledFeedBarRoot>
            )}

            {showActiveFiltersSection && (
                <StyledSelectedFilters>
                    <SelectedFiltersList
                        bottomPadding={isMobile ? `${spacing}px` : undefined}
                        allSelectedFilters={
                            variant === FeedVariantE.goals ? remapSelectedGoalFilters : remappedActiveHostFilters
                        }
                    />
                </StyledSelectedFilters>
            )}
        </StyledFeedBarWrapper>
    )
}

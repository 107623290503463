import {useRootStore} from '@/store'
import {selectGlobalTranslateStore} from '@/store/selectors'
import React, {useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {useGetChannels} from '@/features/chat/queries/useGetChannels'
import usePubnubInstance from '@/features/chat/hooks/usePubnubInstance.ts'
import {PubNubProvider} from 'pubnub-react'
import {useMe} from '@/features/authentication/queries/useMe.ts'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {getCorrectUserId} from '@/utilities/helpers'
import {useGetNotificationsHistory} from '@/features/notifications/queries/useGetNotificationsHistory'
import {UserRoleE} from '@/types'
import {useGetRepresentedUser} from '@/hooks/useGetRepresentedHost'
import {LocalStorageManager} from '@/utilities/localStorage'

// here the user always exists, otherwise I should be in a PublicRoute
export const PrivateDataLayer: React.FC = () => {
    const {data: user, loggedIn, isLoading: isLoadingUser} = useMe()
    const isRepresentative = user?.type === UserRoleE.Representative
    const isAgent = user?.type === UserRoleE.Agent

    const {representedUser, agentAndRepsHosts, isLoading} = useGetRepresentedUser()
    const correctUser = isRepresentative || isAgent ? representedUser : user

    const {pubnubSDKInstance, isChatLoading} = usePubnubInstance(correctUser, loggedIn, isRepresentative)

    const {initialTranslateConfig} = useRootStore(selectGlobalTranslateStore)
    const correctUserId = user ? getCorrectUserId(user) : undefined

    // Here we initialize the chat channels from API server
    useGetChannels(correctUser?.id)
    useGetNotificationsHistory({})

    useEffect(() => {
        if (!LocalStorageManager.chatUserId.get() && agentAndRepsHosts && agentAndRepsHosts?.length) {
            LocalStorageManager.chatUserId.set(agentAndRepsHosts[0]?.id)
        }
    }, [agentAndRepsHosts])

    useEffect(() => {
        !!correctUserId &&
            initialTranslateConfig({
                userID: String(correctUserId)
            })
    }, [])

    if (isChatLoading || isLoadingUser || isLoading) return <Spinner />
    if ((isAgent || isRepresentative) && !agentAndRepsHosts?.length) return <Outlet />
    return loggedIn ? (
        pubnubSDKInstance && (
            <PubNubProvider client={pubnubSDKInstance}>
                <Outlet />
            </PubNubProvider>
        )
    ) : (
        <Outlet />
    )
}

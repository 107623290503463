import {Spinner} from '@/components/ui/spinner/Spinner'
import {useGetGenders} from '@/features/authentication/queries/useGetGenders'
import {SingleGenderType} from '@/features/authentication/types'
import {StyledGenderButton, StyledGendersWrapper} from './style'
import {StyledTabTitle} from '../style'
import {useTranslation} from 'react-i18next'
import {useHandleError} from '@/hooks/useHandleError'
import {HostFiltersType} from '@/features/feed/types'
import {Dispatch, SetStateAction} from 'react'
import i18n from '@/translations/i18n'

type GenderSectionProps = {
    genders: HostFiltersType['genders']
    setGenders: Dispatch<SetStateAction<HostFiltersType['genders']>>
}

export const GenderSection = ({genders, setGenders}: GenderSectionProps) => {
    const {t} = useTranslation()
    const {data, isLoading, isError, error} = useGetGenders({isLeader: true, appLang: i18n.language})
    useHandleError({isError, error})
    const onSelectGender = (gender: SingleGenderType, isSelected: boolean) => {
        if (!data) return;

        if (isSelected) {
            setGenders(prevState => prevState.filter(item => item.id !== gender.id));
        } else {
            setGenders(prevState => [...prevState, gender]);
        }
    }

    return (
        <div>
            {isLoading && <Spinner />}
            <StyledTabTitle>{t('common:gender')}</StyledTabTitle>
            <StyledGendersWrapper>
                {data?.map(gender => {
                    const isSelected = genders.some(selectedGender => selectedGender.id === gender.id)
                    return (
                        <StyledGenderButton
                            isSelected={isSelected}
                            onClick={() => onSelectGender(gender, isSelected)}
                            key={gender.id}
                        >
                            {gender.name}
                        </StyledGenderButton>
                    )
                })}
            </StyledGendersWrapper>
        </div>
    )
}
import styled, {css} from 'styled-components'

export const StyledCtaWrap = styled.div`
    ${() => css`
        margin-left: auto;
    `}
`

export const StyledCta = styled.div`
    ${({theme: {spacing, typography, mediaQueries}}) => css`
        display: flex;
        & button {
            padding: ${spacing}px ${spacing * 2}px;
            width: fit-content;
            ${typography.textSm};
            white-space: nowrap;
            ${mediaQueries.l} {
                ${typography.textLg};
            }
        }
    `}
`

export const StyledCtaContent = styled.div`
    ${({theme: {spacing, truncateText}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing}px;
        & span {
            width: 70px;
            ${truncateText}
        }
    `}
`

export const StyledTooltipContent = styled.div`
    ${({theme: {spacing, palette, typography, shadows}}) => css`
        max-width: 230px;
        text-align: center;
        font-weight: 700;
        ${typography.textMd};
        line-height: 16px;
        padding: ${spacing * 2}px ${spacing * 3}px;
        letter-spacing: 0;
        color: ${palette.danger['25']};
        box-shadow: ${shadows['8xl']};
        cursor: pointer;
    `}
`

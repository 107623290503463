import {z} from 'zod'
import {
    MyMomentSchema,
    MyMomentsFilteredCategoriesResponseSchema,
    MyMomentsFilterCategorySchema,
    MyMomentsResponseSchema,
    MyMomentsFilteredHostsResponseSchema
} from './services/myMoments.schema'

export type DeleteHostMomentStarredMessageProps = {
    message_id: number | string
}

export type DeleteHostMomentProps = {
    chat_attachment_id: number
    type: string
    morgi?: number
}

export type MyMomentsPropsType = {
    page?: string
    limit?: number
    rookies?: number[]
    types?: MyMomentsTypesE[]
}

export enum MyMomentsTypesE {
    IMAGE = 'image',
    VIDEO = 'video',
    PAID_IMAGE = 'paid_image',
    PAID_VIDEO = 'paid_video',
    GOAL_PROOF = 'goal_proof',
    STARRED_MESSAGE = 'starred_message',
    SURPRISE = 'surprise',
    AUDIO = 'audio',
    PAID_AUDIO = 'paid_audio',
    DOCUMENT = 'document'
}

export enum MOMENTS_FEED_FILTER_TABS_IDS {
    Hosts = 'hosts',
    Categories = 'categories'
}

export type MyMomentType = z.infer<typeof MyMomentSchema>
export type MyMomentsFilterCategoryType = z.infer<typeof MyMomentsFilterCategorySchema>
export type MyMomentsResponse = z.infer<typeof MyMomentsResponseSchema>
export type MyMomentsFilteredCategoriesResponse = z.infer<typeof MyMomentsFilteredCategoriesResponseSchema>
export type MyMomentsFilteredHostsResponse = z.infer<typeof MyMomentsFilteredHostsResponseSchema>

import styled, {DefaultTheme, css} from 'styled-components'

export const StyledCardRoot = styled.div`
    ${({withAction, theme: {spacing, palette, mediaQueries}}: {withAction: boolean; theme: DefaultTheme}) => css`
        width: 100%;
        height: 100%;
        max-width: 480px;
        display: grid;
        
        grid-template-columns: 110px 1fr;
        padding: ${spacing}px;
        box-shadow: rgba(0, 6, 61, 0.15) 0px 2px 18px;
        border-radius: ${spacing * 2}px;
        background-color: ${palette.neutral.white};
        gap: ${spacing * 3}px;
        color: ${palette.primary[900]};

        ${withAction &&
        css`
            cursor: pointer;
        `}
        ${mediaQueries.l} {
            gap: ${spacing * 3}px;
            grid-template-columns: 140px 1fr;
        }
    `}
`

export const StyledSliderWrapper = styled.div`
    ${({theme: {mediaQueries}}) => css`
        max-width: 107px;
        width: 100%;
        height: 100%;

        ${mediaQueries.m} {
            max-width: 140px;
        }
    `}
`

export const StyledInfoSection = styled.div`
    ${() => css`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    `}
`

export const StyledName = styled.div`
    ${({theme: {typography, mediaQueries, truncateText}}) => css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        font-weight: 600;
        ${typography.textMd}

        & h4 {
            max-width: 140px;
            ${truncateText}

            ${mediaQueries.m} {
                max-width: 200px;
            }
        }

        ${mediaQueries.m} {
            ${typography.text4Xl}
        }
    `}
`

export const StyledDescription = styled.div`
    ${({theme: {typography, mediaQueries}}) => css`
        ${typography.textSm}
        ${mediaQueries.m} {
            ${typography.textXl}
        }
    `}
`

import axios from '@/clientAPI.ts'
import axiosRefactor from '@/refactorClientApi'
import {
    ActiveHost,
    FeedPayload,
    FeedResponse,
    FeedTalentsCard,
    HostFiltersType,
    HostStoriesType,
    HostStoryType
} from '@/features/feed/types.ts'

export const FEED_API = {
    getFeedCards: async (params: FeedPayload): Promise<FeedResponse> => {
        const url = '/rookies'
        const {data} = await axios.get(url, {params})
        return data
    },
    seenRookie: async ({rookiesIDs, actionType}: {rookiesIDs: string[]; actionType: string}) => {
        const url = '/rookies/seen'
        const {data} = await axios.post(url, {
            action: actionType,
            rookies_ids: rookiesIDs
        })
        return data
    },
    seenTalents: async (body: {rookiesIDs: number[]}): Promise<FeedTalentsCard> => {
        const url = '/rookies/talents'
        const {data} = await axios.post(url, {exclude_rookies_ids: body.rookiesIDs})
        return data
    },
    storeActiveFilters: async ({filters}: {filters: HostFiltersType}) => {
        const jsonString = JSON.stringify(filters)
        const params = {filters: jsonString}
        const url = `/filters/carousel`
        const {data} = await axios.post(url, params)
        return data
    },
    clearActiveFilters: async () => {
        const url = '/filters/carousel'
        return await axios.delete(url)
    },
    getActiveHost: async (): Promise<ActiveHost> => {
        const url = '/rookies/active/count'
        const {data} = await axios.get(url)
        return data
    },
    getHostsStories: async (skipRookiesIds: number[]): Promise<HostStoriesType[]> => {
        const url = '/rookies/feed/get-rookies'
        const {data} = await axiosRefactor.post(url, {skipRookiesIds})
        return data
    },
    getHostStory: async ({
        rookieId,
        skipRookiesIds
    }: {
        rookieId: number
        skipRookiesIds: number[]
    }): Promise<HostStoryType[]> => {
        const url = `/rookies/${rookieId}/feed`
        const {data} = await axiosRefactor.post(url, {skipRookiesIds})
        return data
    },
    seenHostsStories: async ({rookieFeedIds}: {rookieFeedIds: number[]}) => {
        const url = `/rookies/feeds/seen`
        const {data} = await axiosRefactor.post(url, {rookieFeedIds})
        return data
    }
}

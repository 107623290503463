import {useTranslation} from 'react-i18next'
import {Card} from '@components/ui/card/Card.tsx'
import {rootCardClass, StyledHint, StyledStatusSection} from '@/features/goal/components/upload-area/style.ts'
import {CircleProgress} from '@components/ui/circle-progress/CircleProgress.tsx'
import {progressGradient} from '@/features/goal/constant/goalCard.ts'
import {
    FilledCloseIcon,
    GoalUploadIcon,
    UploadAudioIcon,
    UploadAudioPurpleIcon,
    UploadPhotoIcon,
    UploadVideoIcon
} from '@assets/icons/icons.tsx'
import {uploadAreaType} from '@components/ui/file-uploader/FileUploader.tsx'
import {useParams} from 'react-router-dom'
import {useGetChannelDetails} from '@/features/chat/queries/useGetChannelDetails.ts'
import {ChannelID} from '@/features/chat/types.ts'
import {Media} from '@components/ui/media/Media.tsx'
import {
    StyledAudioPreview,
    StyledPreview,
    StyledUploadPaidMediaContainer
} from '@/features/chat/components/AttachmentsPanel/style.ts'
import {truncate} from 'lodash'
import {MediaTypeE} from '@/types.ts'
import {useMemo} from 'react'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {StyledSpinnerWrapper} from '@/pages/all-user-role-pages/GroupConversation/style'
import {Worker, Viewer, ScrollMode} from '@react-pdf-viewer/core'
import '@react-pdf-viewer/core/lib/styles/index.css'
import {PDF_SERVERS_WORKER} from '@/featuresConfig'
import {UploadAreaTypeE} from '@/features/goal/types'
import {TypePaidMessageE} from './SendPaidMessage'

const titleKeys = {
    [MediaTypeE.AUDIO]: 'paid_media_modal:audio_subtitle',
    [MediaTypeE.DOCUMENT]: 'paid_media_modal:upload_title',
    [MediaTypeE.IMAGE]: 'create_goal:image_uploader:default:title',
    [MediaTypeE.VIDEO]: 'paid_media_modal:upload_media_hint',
    default: 'create_goal:image_uploader:default:title'
}

const hintKeys = {
    [MediaTypeE.AUDIO]: 'paid_media_modal:audio_hint',
    [MediaTypeE.DOCUMENT]: 'paid_media_modal:upload_document_hint',
    [MediaTypeE.IMAGE]: 'paid_media_modal:upload_media_hint',
    [MediaTypeE.VIDEO]: 'paid_media_modal:upload_media_hint',
    default: 'paid_media_modal:upload_media_hint'
}

export const UploadAreaPaidMessage = ({
    isDragReject,
    isDragActive,
    uploadedFile,
    fileRejection,
    handleRemove,
    progress,
    isError,
    isSuccessfulUpload,
    uploadAreaProps
}: uploadAreaType) => {
    const {t} = useTranslation()
    const isMediaUploaded = isSuccessfulUpload && uploadedFile && progress === 100
    const errorCode = fileRejection?.errors[0].code
    const {channel} = useParams()
    const {data: channelDetails} = useGetChannelDetails(channel as ChannelID)
    const isDocument = uploadedFile?.type?.includes('pdf')
    const fileType = isDocument ? MediaTypeE.DOCUMENT : uploadedFile?.type?.split('/')[0]

    const getTranslationKey = (type?: UploadAreaTypeE | TypePaidMessageE, keys?: Record<string, string>) => {
        return type && keys?.[type] ? keys[type] : keys?.default
    }
    const selectedTitleKey = getTranslationKey(uploadAreaProps?.type, titleKeys)
    const selectedHintKey = getTranslationKey(uploadAreaProps?.type, hintKeys)

    const getCorrectPreview = useMemo(() => {
        if (uploadedFile) {
            switch (fileType) {
                case MediaTypeE.AUDIO:
                    return (
                        <>
                            <UploadAudioPurpleIcon />
                        </>
                    )
                case MediaTypeE.IMAGE:
                    return (
                        <>
                            <Media variant={'cover'} imageUrl={URL.createObjectURL(uploadedFile)} />
                            <UploadPhotoIcon />
                        </>
                    )
                case MediaTypeE.VIDEO:
                    return (
                        <>
                            <video>
                                <source src={URL.createObjectURL(uploadedFile)} />
                            </video>
                            <UploadVideoIcon />
                        </>
                    )
                case MediaTypeE.DOCUMENT:
                    return (
                        <Worker workerUrl={PDF_SERVERS_WORKER}>
                            <Viewer
                                fileUrl={URL.createObjectURL(uploadedFile)}
                                renderLoader={() => (
                                    <StyledSpinnerWrapper>
                                        <Spinner inline size={30} overlay={false} />
                                    </StyledSpinnerWrapper>
                                )}
                                scrollMode={ScrollMode.Page}
                                withCredentials={false}
                            />
                        </Worker>
                    )
                default:
                    return null
            }
        }
    }, [fileType, uploadedFile])

    return (
        <Card rootCustomClass={rootCardClass}>
            <StyledUploadPaidMediaContainer>
                {!isMediaUploaded ? (
                    <CircleProgress
                        percentage={!isError ? progress : 0}
                        staticCircleStrokeWidth={5}
                        staticCircleStrokeColor={isError || errorCode ? progressGradient.singleFail : undefined}
                        progressColor={progressGradient.default}
                        icon={
                            progress !== 0 ? (
                                `${progress}%`
                            ) : uploadAreaProps?.type === UploadAreaTypeE.AUDIO ? (
                                <UploadAudioIcon />
                            ) : (
                                <GoalUploadIcon />
                            )
                        }
                        animated={false}
                    />
                ) : fileType === MediaTypeE.AUDIO ? (
                    <StyledAudioPreview>{getCorrectPreview}</StyledAudioPreview>
                ) : (
                    <StyledPreview>{getCorrectPreview}</StyledPreview>
                )}

                <StyledStatusSection>
                    {!isDragActive && !isDragReject && (
                        <>
                            {selectedTitleKey && (
                                <span>{uploadedFile?.name ? truncate(uploadedFile?.name) : t(selectedTitleKey)}</span>
                            )}
                            {!errorCode && selectedHintKey ? (
                                <StyledHint>
                                    {t(selectedHintKey, {
                                        LEADER_NAME: channelDetails?.full_name
                                    })}
                                </StyledHint>
                            ) : (
                                t(`validation:${errorCode}`)
                            )}
                        </>
                    )}
                    {isDragActive && !isDragReject && <strong>{t('common:release_here')}</strong>}
                    {isDragReject && <span>{t('validation:file_not_accepted')}</span>}
                </StyledStatusSection>
                {isMediaUploaded && <FilledCloseIcon onClick={handleRemove} />}
            </StyledUploadPaidMediaContainer>
        </Card>
    )
}

import {Button} from '@/components/ui/button/Button'
import {useRootStore} from '@/store'
import {selectCancelRecurringGiftModal} from '@/store/selectors'
import {Dispatch, FC, SetStateAction, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import BlockHostButton from '../block-host-button/BlockHostButton'
import {
    StyledActionBtn,
    StyledActionBtnWrapper,
    StyledCtaSection,
    StyledName,
    StyledReminderText,
    StyledResumeBtn,
    StyledResumeBtnWrapper,
    StyledShare
} from './style'
import {ShareProfileModal} from '@/features/host/components/share-profile-modal/ShareProfileModal'
import {GenderKeyNameE} from '@/features/constants/constants'
import {SubscriptionStatusesE, getSubscriptionStatus} from '@/features/chat/helpers/helpers'
import {useGetConnectedUser} from '@/hooks/useGetConnectedUser'
import {useFetchSubscribedHosts} from '@/features/recurring-gift/queries/useFetchSubscribedHosts'
import {useGetChannelDetails} from '@/features/chat/queries/useGetChannelDetails'
import {useSendGift} from '@/features/recurring-gift/queries/useSendGift'
import {ChannelID} from '@/features/chat/types'
import {GoalShareIcon} from '@/assets/icons/icons'
import {formatDateWithUTC} from '@/dayjs'
import {useGetMembershipPlans} from '@/features/configure-membership-plans/edit-configure-membership-plans-modal/queries/useGetMembershipPlans'

type HostProfileCtaSectionProps = {
    userGuest: boolean
    hostFirstName: string
    hostId: number
    hostUserName: string
    hostGender: GenderKeyNameE
    setIsClickShare: Dispatch<SetStateAction<boolean>>
}

export const HostProfileCtaSection: FC<HostProfileCtaSectionProps> = ({
    hostUserName,
    userGuest,
    hostFirstName,
    hostId,
    hostGender,
    setIsClickShare
}) => {
    const {t} = useTranslation()
    const {openModal: openCancelRecurringGiftModal} = useRootStore(selectCancelRecurringGiftModal)
    const [isOpenShareProfileModal, setIsOpenShareProfileModal] = useState(false)
    const {connectedUser: connectedHost, channelId: channel} = useGetConnectedUser(hostId)

    const {data: channelDetails} = useGetChannelDetails(channel as ChannelID, !!connectedHost)
    const subscriptionStatus = useMemo(() => getSubscriptionStatus(channelDetails), [channelDetails])

    const {data: amountsList} = useGetMembershipPlans(hostId ? String(hostId) : undefined)
    const {data: subscribedHosts} = useFetchSubscribedHosts({
        enabled: userGuest,
        status: SubscriptionStatusesE.CANCELED
    })

    const currentHost = subscribedHosts?.find(host => host.rookie_id === hostId)
    const resumeAmountId = amountsList?.find(item => item?.amount === currentHost?.amount)?.id
    const {mutate: sendGift} = useSendGift()
    const canResumeSubscription = !!subscriptionStatus && subscriptionStatus === SubscriptionStatusesE.REACTIVABLE

    const onShareClick = () => {
        setIsClickShare(true)
        setIsOpenShareProfileModal(true)
    }

    return (
        <StyledCtaSection>
            {canResumeSubscription ? (
                <StyledResumeBtnWrapper>
                    <StyledResumeBtn
                        fullWidth
                        onClick={() =>
                            sendGift({
                                hostID: `${currentHost?.rookie_id}`,
                                subscriptionId: `${resumeAmountId}`
                            })
                        }
                    >
                        <StyledName>{t('common:resume_gift', {ROOKIE: hostFirstName})}</StyledName>
                    </StyledResumeBtn>
                    <StyledShare onClick={onShareClick}>
                        <GoalShareIcon />
                    </StyledShare>
                </StyledResumeBtnWrapper>
            ) : (
                <Button variant="outlined" fullWidth onClick={onShareClick}>
                    {t('gift_rookie:share_with_friends')}
                </Button>
            )}
            {canResumeSubscription && (
                <StyledReminderText>
                    {t('cancel_rookie_gift:reminder', {
                        NAME: hostFirstName,
                        DATE: formatDateWithUTC(`${currentHost?.valid_until_at}`, 'MM/DD/YYYY')
                    })}
                </StyledReminderText>
            )}

            {userGuest && (
                <StyledActionBtnWrapper>
                    <BlockHostButton hostName={hostFirstName} hostId={hostId} />
                    {!!connectedHost && connectedHost?.subscription_status === SubscriptionStatusesE.ACTIVE && (
                        <StyledActionBtn onClick={() => openCancelRecurringGiftModal({hostID: `${hostId}`})}>
                            {t('common:cancel_gift')}
                        </StyledActionBtn>
                    )}
                </StyledActionBtnWrapper>
            )}
            {isOpenShareProfileModal && (
                <ShareProfileModal
                    hostGender={hostGender}
                    username={hostUserName}
                    onClose={() => setIsOpenShareProfileModal(false)}
                />
            )}
        </StyledCtaSection>
    )
}

HostProfileCtaSection.displayName = 'HostProfileCtaSection'

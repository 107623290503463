import styled, {css, DefaultTheme} from 'styled-components'

export const StyledHostsList = styled.div`
    ${({theme: {spacing, mediaQueries}, centered}: {theme: DefaultTheme; centered: boolean}) => css`
        display: grid;
        grid-template-columns: 1fr;
        gap: ${spacing * 2}px;
        justify-content: center;
        width: 100%;
        margin-bottom: ${spacing * 2}px;

        ${mediaQueries.m} {
            grid-template-columns: ${centered ? 'auto' : 'repeat(auto-fill, 480px)'};
        }
    `}
`

export const StyledTitle = styled.div`
    ${({theme: {spacing, typography, mediaQueries, palette}}) => css`
        text-align: center;
        color: ${palette.primary[200]};
        font-weight: 700;
        padding: ${spacing * 3}px 0;
        ${typography.textMd};

        ${mediaQueries.m} {
            ${typography.text2Xl}
        }
    `}
`

import {Trans, useTranslation} from 'react-i18next'
import {StyledStatus, StyledStatusBox} from '../style'
import {MicromorgiIcon, RefreshIcon} from '@/assets/icons/icons'
import {Status, labelBackgroundColor} from '../../statusBadge/StatusBadge'
import {UserRoleE} from '@/types'

type ChatBadgeTriggerProps = {
    guestStatus: string
    withTooltip?: boolean
    isLight?: boolean
    monthlyAmount?: number | null
    onChatAmount?: number
}

export const ChatBadgeTrigger = ({
    guestStatus,
    withTooltip,
    isLight,
    monthlyAmount,
    onChatAmount
}: ChatBadgeTriggerProps) => {
    const {t} = useTranslation()
    const background = labelBackgroundColor[guestStatus as Status]
    return (
        <StyledStatusBox withTooltip={!!withTooltip}>
            <StyledStatus isLight={isLight} backgroundColor={background}>
                <Trans
                    t={t}
                    i18nKey="leader_statuses:status"
                    values={{
                        context: guestStatus
                    }}
                />
                {guestStatus !== UserRoleE.Guest && monthlyAmount ? (
                    <>
                        <RefreshIcon />
                        <span>{monthlyAmount}</span>
                    </>
                ) : onChatAmount ? (
                    <MicromorgiIcon />
                ) : (
                    <></>
                )}
            </StyledStatus>
        </StyledStatusBox>
    )
}

import {CashbackBanner} from '@/components/ui/cashback-banner/CashbackBanner'
import {Modal} from '@/components/ui/modal/Modal'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useMe} from '@/features/authentication/queries/useMe'
import {GuestType} from '@/features/authentication/types'
import {remapCurrency} from '@/features/profile/constants'
import useMixPanel from '@/integrations/mixpanel/hooks/useMixpanel'
import {MixpanelEventNameE} from '@/integrations/mixpanel/types'
import {useRootStore} from '@/store'
import {selectBuyMorgisModal, selectChoosePaymentsMethodModal, selectErrorModal} from '@/store/selectors'
import {FC, useCallback, useEffect, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {z} from 'zod'
import {BuyMorgiSuccessStepModal} from '../buy-morgi-success-step-modal/BuyMorgiSuccessStepModal'
import {MicromorgiPackageItem} from '../components/micromorgi-package-item/MicromorgiPackageItem'
import {DailyMicromorgiLimitModal} from '../daily-micromorgi-limit-modal/DailyMicromorgiLimitModal'
import {useBuyMicromorgiPackage} from '../queries/useBuyMicromorgiPackage'
import {useFetchMicromorgiPackages} from '../queries/useFetchMicromorgiPackages'
import {MicromorgiPackageSchema} from '../services/micromorgi.schema'
import {
    BuyMorgisModalContainer,
    BuyMorgisModalDescription,
    BuyMorgisModalTitle,
    MicromorgiPackagesList,
    StyledAmountListToggle,
    StyledBannerWrapper,
    StyledButtonsList,
    StyledBuyMicromorgiButton,
    StyledChangePaymentMethodButton,
    StyledExplanationBanner,
    modalStyles
} from './style'
import {chunk} from 'lodash'
import {BillingInfoModal} from '@/features/billing/components/billing-info-modal/BillingInfoModal'
import {LocalStorageManager} from '@/utilities/localStorage'
import {useLocation} from 'react-router-dom'

type MicromorgiPackage = z.infer<typeof MicromorgiPackageSchema>

const userStatuses = {
    guest: 'guest',
    silver: 'silver',
    gold: 'gold',
    diamond: 'diamond',
    ultimate: 'ultimate'
}

const SOUTH_AMERICA_COUNTIES = [
    'Argentina',
    'Bolivia',
    'Brazil',
    'Chile',
    'Colombia',
    'Ecuador',
    'Guyana',
    'Paraguay',
    'Peru',
    'Suriname',
    'Uruguay',
    'Venezuela'
]

const DEFAULT_PACKAGES_TO_SHOW = 4

export const BuyMorgisModal: FC = () => {
    const {t} = useTranslation()
    const {data} = useMe()
    const mixpanel = useMixPanel()
    const user = data as GuestType
    const {
        mutate: buyMicromorgiPackage,
        data: BuyMicromorgiPackageData,
        error: buyMicromorgiPackageError,
        isPending
    } = useBuyMicromorgiPackage()
    const {data: micromorgiPackages} = useFetchMicromorgiPackages()
    const {closeModal, campaignId} = useRootStore(selectBuyMorgisModal)
    const {openErrorModal} = useRootStore(selectErrorModal)
    const {openModal: openChoosePaymentsMethodModal} = useRootStore(selectChoosePaymentsMethodModal)

    const [isShowFullList, setIsShowFullList] = useState(false)
    const [selectedMicromorgiPackage, setSelectedMicromorgiPackage] = useState<MicromorgiPackage | null>(null)
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [showDailyMicromorgiLimitModal, setShowDailyMicromorgiLimitModal] = useState(false)
    const userStatus = user?.type_attributes.level_status as string
    const filteredMicromorgiPackagesByStatus = micromorgiPackages?.filter(
        micromorgiPackage =>
            micromorgiPackage.level_status === (userStatus === userStatuses.silver ? userStatuses.guest : userStatus)
    )

    const filteredMicromorgiPackages = SOUTH_AMERICA_COUNTIES.includes(user.signup_country.name)
        ? filteredMicromorgiPackagesByStatus
        : filteredMicromorgiPackagesByStatus?.filter(micromorgiPackage =>
              micromorgiPackage.micromorgi_count === 48 && micromorgiPackage.price === 6 ? false : micromorgiPackage
          )

    const isShowBanner = userStatus !== userStatuses.silver && userStatus !== userStatuses.guest
    const hasBillingData = !!user?.type_attributes?.has_invoicing_profile
    const [showBillingInfoModal, setShowBillingInfoModal] = useState(false)
    const location = useLocation()
    const redirectUrl = location.pathname

    const handleBuyMicromorgiPackage = useCallback(
        (onlyLinks?: boolean, withOnlyLink?: boolean) => {
            buyMicromorgiPackage({
                packageId: `${selectedMicromorgiPackage?.id}`,
                ...(withOnlyLink && {only_links: onlyLinks})
            })
        },
        [buyMicromorgiPackage, selectedMicromorgiPackage]
    )

    const onBuyMicromorgiPackageButtonClick = () => {
        if (!hasBillingData) {
            setShowBillingInfoModal(true)
        } else {
            buyMicromorgiPackage({
                packageId: selectedMicromorgiPackage?.id.toString() as string,
                campaignId
            })
        }
    }

    useEffect(() => {
        LocalStorageManager.openBuyMorgisModal.remove()
        mixpanel?.triggerBackendEvent({eventType: MixpanelEventNameE.BuyMicromorgisClick})
    }, [])

    useEffect(() => {
        if (buyMicromorgiPackageError && buyMicromorgiPackageError?.response) {
            const {
                response: {data, status},
                message
            } = buyMicromorgiPackageError
            const type = (data as {type?: string})?.type || ''
            if (status === 303)
                openChoosePaymentsMethodModal({
                    paymentMethodUrls: data,
                    description: 'choose_payment_method:description_micromorgi',
                    morgisAmount: selectedMicromorgiPackage?.micromorgi_count,
                    currencyAmount: selectedMicromorgiPackage?.price,
                    apiCallback: handleBuyMicromorgiPackage
                })
            else if (['daily_micromorgi_limit', 'monthly_micromorgi_limit'].includes(type))
                setShowDailyMicromorgiLimitModal(true)
            else openErrorModal({message})
        }
        if (BuyMicromorgiPackageData) {
            setShowSuccessModal(true)
        }
    }, [BuyMicromorgiPackageData, buyMicromorgiPackageError])

    const onSelectPackage = (micromorgiPackage: MicromorgiPackage) => {
        mixpanel?.trackEvent(MixpanelEventNameE.MicromorgiAmountSelect, {
            amount_selected: micromorgiPackage.micromorgi_count
        })
        setSelectedMicromorgiPackage(micromorgiPackage)
    }

    const activeCreditCard = user?.type_attributes?.preferred_payment_method
        ? user.type_attributes.preferred_payment_method.last4
        : null

    const showButtonWithPrefMethod = activeCreditCard && (!SOUTH_AMERICA_COUNTIES.includes(user.signup_country.name) || selectedMicromorgiPackage && selectedMicromorgiPackage.micromorgi_count >= 250)

    const selectedPackageButtonText = showButtonWithPrefMethod
        ? t('buy_micromorgies:buy_button_with_preferred_method', {
              PRICE: `${remapCurrency[user.currency]}${selectedMicromorgiPackage?.price}`,
              CARD_LAST_4: `${activeCreditCard}`
          })
        : t('buy_micromorgies:buy_N_for_M', {
              PRICE: `${remapCurrency[user.currency]}${selectedMicromorgiPackage?.price}`,
              MICROMorgisES: selectedMicromorgiPackage?.micromorgi_count
          })

    if (!user) return <></>

    return (
        <>
            {!showSuccessModal && !showDailyMicromorgiLimitModal && (
                <Modal
                    width="500px"
                    fullScreen
                    title={t('common:buy_morgi_package')}
                    onClose={() => {
                        closeModal()
                    }}
                    showConfettiAnimation
                    modalStyles={modalStyles}
                >
                    <BuyMorgisModalContainer>
                        {isPending && <Spinner />}
                        <div>
                            <BuyMorgisModalTitle>{t('buy_micromorgies:title')}</BuyMorgisModalTitle>
                            <BuyMorgisModalDescription>
                                {t('buy_micromorgies:description_new')}
                            </BuyMorgisModalDescription>
                        </div>

                        {isShowBanner && (
                            <StyledExplanationBanner>
                                <Trans
                                    t={t}
                                    i18nKey={`buy_micromorgies:explanetion_banner:explanetion_banner_${userStatus}`}
                                />
                            </StyledExplanationBanner>
                        )}

                        <StyledBannerWrapper>
                            <CashbackBanner />
                        </StyledBannerWrapper>

                        <MicromorgiPackagesList>
                            {(isShowFullList
                                ? filteredMicromorgiPackages
                                : chunk(filteredMicromorgiPackages, DEFAULT_PACKAGES_TO_SHOW)[0]
                            )?.map((micromorgiPackage: MicromorgiPackage) => (
                                <MicromorgiPackageItem
                                    onSelectPackage={() => onSelectPackage(micromorgiPackage)}
                                    isSelected={selectedMicromorgiPackage?.id === micromorgiPackage.id}
                                    key={micromorgiPackage.id}
                                    micromorgiPackage={micromorgiPackage}
                                    originalPackage={micromorgiPackages?.find(
                                        ({level_status, price}) =>
                                            level_status === userStatuses.guest && price === micromorgiPackage.price
                                    )}
                                />
                            ))}
                        </MicromorgiPackagesList>

                        <StyledButtonsList>
                            {filteredMicromorgiPackages &&
                                filteredMicromorgiPackages?.length > DEFAULT_PACKAGES_TO_SHOW && (
                                    <StyledAmountListToggle
                                        onClick={() => {
                                            setIsShowFullList(prev => !prev)
                                        }}
                                    >
                                        {t(`gift_rookie:${isShowFullList ? 'show_less_amounts' : 'show_more_amounts'}`)}
                                    </StyledAmountListToggle>
                                )}
                            <StyledBuyMicromorgiButton
                                disabled={!selectedMicromorgiPackage}
                                onClick={onBuyMicromorgiPackageButtonClick}
                            >
                                {selectedMicromorgiPackage
                                    ? selectedPackageButtonText
                                    : t('buy_micromorgies:buy_micromorgies')}
                            </StyledBuyMicromorgiButton>

                            {!!user.type_attributes?.preferred_payment_method && (
                                <StyledChangePaymentMethodButton
                                    variant="transparent"
                                    disabled={!selectedMicromorgiPackage?.id}
                                    onClick={() => {
                                        handleBuyMicromorgiPackage(true, true)
                                        !!selectedMicromorgiPackage &&
                                            LocalStorageManager.openBuyMorgisModal.set(`${redirectUrl}`)
                                    }}
                                >
                                    {t('choose_payment_method:change_payment_method')}
                                </StyledChangePaymentMethodButton>
                            )}
                        </StyledButtonsList>
                    </BuyMorgisModalContainer>
                </Modal>
            )}
            {showSuccessModal && selectedMicromorgiPackage && (
                <BuyMorgiSuccessStepModal onClose={closeModal} amount={selectedMicromorgiPackage.micromorgi_count} />
            )}
            {showDailyMicromorgiLimitModal && <DailyMicromorgiLimitModal onClose={closeModal} />}
            {showBillingInfoModal && (
                <BillingInfoModal
                    onClose={() => setShowBillingInfoModal(false)}
                    onSendBillingInfoSuccess={() =>
                        buyMicromorgiPackage({
                            packageId: selectedMicromorgiPackage?.id.toString() as string,
                            campaignId
                        })
                    }
                />
            )}
        </>
    )
}

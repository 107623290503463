import {Modal} from '@components/ui/modal/Modal.tsx'
import {useRootStore} from '@/store'
import {selectPaidMessageModal} from '@/store/selectors.ts'
import {useTranslation} from 'react-i18next'
import {
    StyledAmountsWrapper,
    StyledPaidMessageModalWrapper,
    StyledParagraph,
    StyledSubtitleModal
} from '@/features/chat/components/AttachmentsPanel/style.ts'
import {TypePaidMessageE} from '@/features/chat/components/AttachmentsPanel/components/SendPaidMessage.tsx'
import {InputText} from '@components/commons/input-text/InputText.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {Divider} from '@components/ui/divider/Divider.tsx'
import {useMemo, useState} from 'react'
import {CardAmountToSelect, SelectedAmount} from '@components/ui/card-amount-to-select/CardAmountToSelect.tsx'
import {MediaTypeE, UserRoleE} from '@/types.ts'
import {useForm, useWatch} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {z} from 'zod'
import {StyledTextareaWithBannedElementsTrap} from '@/features/chat/components/AttachmentsPanel/style'
import {HaveBannedElementObj} from '@/features/generated-content-security/types.ts'
import {useGetChannelDetails} from '@/features/chat/queries/useGetChannelDetails.ts'
import {ChannelID, GroupParams} from '@/features/chat/types.ts'
import {useParams} from 'react-router-dom'
import {useSendMessageToChannel} from '@/features/chat/queries/useSendMessageToChannel.ts'
import {useMe} from '@/features/authentication/queries/useMe.ts'
import {convertFileToFormData, getCorrectUserId, getFileType} from '@utilities/helpers.tsx'
import {RepresentativeType, User} from '@/features/authentication/types.ts'
import {UploadAreaPaidMessage} from '@/features/chat/components/AttachmentsPanel/components/UploadAreaPaidMessage.tsx'
import {
    ACCEPT_AUDIO_FILE_TYPES,
    ACCEPT_DOCUMENT_FILE_TYPES,
    ACCEPT_IMAGE_FILE_TYPES,
    ACCEPT_VIDEO_FILE_TYPES,
    MAX_AUDIO_SIZE,
    MAX_FILE_SIZE
} from '@utilities/constants/fileUploader.ts'
import {dropzoneStyles} from '@/features/goal/components/goal-media-uploader/style.ts'
import {FileUploader} from '@components/ui/file-uploader/FileUploader.tsx'
import {useSendGoalMedia} from '@/features/goal/queries/useSendGoalMedia.ts'
import {AxiosRequestConfig} from 'axios'
import {FileWithPath} from 'react-dropzone'
import {useSendChatFile} from '@/features/chat/queries/useSendChatFile.ts'
import {serialize} from 'object-to-formdata'
import useMixPanel from '@/integrations/mixpanel/hooks/useMixpanel'
import {MixpanelEventNameE} from '@/integrations/mixpanel/types'
import {MessageTypeE} from '@/utilities/constants/chat'
import {PAID_MEDIA_AMOUNTS} from '@/featuresConfig'
import {useHandleError} from '@/hooks/useHandleError'
import usePubnubSendMessage from '@/features/chat/hooks/usePubnubSendMessage'
import {Spinner} from '@/components/ui/spinner/Spinner'

const amountForm = z.object({
    amount: z.coerce
        .number()
        .int()
        .min(1, {message: 'validation:enter_valid_number'})
        .max(999999, {message: 'validation:paid_message_amount_value'})
        .nullable()
        .optional(),
    message: z.string().min(1, {message: 'validation:proof_message_required'}).nullable().optional()
})
export const SendPaidMessageModal = ({
    onSendMessage = null,
    onCloseLocalModal = null,
    typeLocalModal
}: {
    onSendMessage?: ((params: GroupParams) => void) | null
    onCloseLocalModal?: (() => void) | null
    typeLocalModal?: TypePaidMessageE
}) => {
    const {channel} = useParams()
    const {t} = useTranslation()
    const {closeModal: onCloseSharedModal, type: typeSharedModal} = useRootStore(selectPaidMessageModal)
    const {data: user} = useMe()
    const {data: channelDetails} = useGetChannelDetails(channel as ChannelID)
    const mixpanel = useMixPanel()
    const type = typeLocalModal ?? typeSharedModal
    const isFile =
        type === TypePaidMessageE.MEDIA || type === TypePaidMessageE.DOCUMENT || type === TypePaidMessageE.AUDIO

    const [selectedAmount, setSelectedAmount] = useState<SelectedAmount | null>(null)
    const [haveBannedContent, setHaveBannedContent] = useState<HaveBannedElementObj | null>(null)
    const [isSuccessfulUpload, setIsSuccessfulUpload] = useState(false)
    const [file, setFile] = useState<File | FileWithPath | null>(null)

    const userID = getCorrectUserId(user as User)
    const representative = user as RepresentativeType
    const [uploadedMediaPath, setUploadedMediaPath] = useState<string>()
    const [isLoading, setIsLoading] = useState(false)
    const onSettled = () => {
        setIsLoading(false)
    }

    const {
        mutate: uploadMedia,
        isError: isErrorUploadMedia,
        error: errorUploadMedia
    } = useSendGoalMedia(mediaPath => {
        setUploadedMediaPath(mediaPath)
        setIsSuccessfulUpload(true)
    })

    const {
        mutateAsync: sendMessageToChannel,
        isError: isErrorMessage,
        error: errorMessage,
        isPending: isPendingMessage
    } = useSendMessageToChannel()
    const {
        mutateAsync: sendPaidMedia,
        isError: isErrorChatFile,
        error: errorChatFile,
        isPending: isPendingChatFile
    } = useSendChatFile(onSettled)

    const {sendMessage: sendMessageToPubnub} = usePubnubSendMessage(channel as ChannelID)
    const isSendingMessage = isLoading || isPendingMessage || isPendingChatFile

    const combinedErrorState = {
        isError: isErrorMessage || isErrorChatFile || isErrorUploadMedia,
        error: errorMessage || errorChatFile || errorUploadMedia
    }

    useHandleError(combinedErrorState)

    const {
        register,
        control,
        setValue,
        clearErrors,
        formState: {errors}
    } = useForm<z.infer<typeof amountForm>>({
        mode: 'all',
        reValidateMode: 'onChange',
        resolver: zodResolver(amountForm),
        defaultValues: {
            amount: null,
            message: null
        }
    })

    const amountWatch = useWatch({
        control: control,
        name: 'amount'
    })

    const messageWatch = useWatch({
        control: control,
        name: 'message'
    })

    const onAmountClick = (amount: SelectedAmount) => {
        setSelectedAmount(amount)
        setValue('amount', null)
        if (errors['amount']?.message) {
            clearErrors('amount')
        }
    }

    const errorMessages = useMemo(() => {
        return errors?.message?.message
    }, [errors])

    const isButtonDisabled = useMemo(() => {
        const isAmountSelected = !!selectedAmount?.amount || !!amountWatch
        const isFileSelected = !!file
        if (isFile) {
            return !(isAmountSelected && isFileSelected)
        } else {
            return !(isAmountSelected && messageWatch)
        }
    }, [selectedAmount, amountWatch, isFile, messageWatch, file])

    const closeModal = () => {
        onCloseLocalModal ? onCloseLocalModal() : onCloseSharedModal()
    }

    const handleSendPaidMediaMessage = async () => {
        if (haveBannedContent?.isBanned) return null
        setIsLoading(true)
        const fileType = file ? getFileType(file.type) : ''
        if (isFile) {
            if (onSendMessage && onCloseLocalModal) {
                const params = {
                    media: uploadedMediaPath,
                    type: fileType === MediaTypeE.IMAGE ? MediaTypeE.PHOTO : fileType,
                    morgi: (selectedAmount?.amount ?? amountWatch) || 0,
                    message: undefined
                }

                onSendMessage(params)
            } else {
                const channel_id = String(channelDetails?.channel_id)

                const data = await sendPaidMedia({
                    channelID: channel_id,
                    type: `${fileType}`,
                    formDataObject: serialize({
                        file,
                        receiver_id: channelDetails?.id,
                        morgi: (selectedAmount?.amount ?? amountWatch) || 0
                    })
                })
                const messageType = () => {
                    switch (true) {
                        case data?.type.includes('video'):
                            return MessageTypeE.VIDEO
                        case data?.type.includes('document'):
                            return MessageTypeE.DOCUMENT
                        case data?.type.includes('audio'):
                            return MessageTypeE.AUDIO
                        case data?.type.includes('photo') || data?.type.includes('image'):
                        default:
                            return MessageTypeE.IMAGE
                    }
                }
                // send message to pubnub
                const message = {
                    text: undefined,
                    type: messageType(),
                    user_id: userID,
                    meta: {
                        attachmentId: data?.id,
                        morgi: (selectedAmount?.amount ?? amountWatch) || 0,
                        ...(user?.type === UserRoleE.Representative && {
                            representative_id: representative?.id,
                            representative_first_name: representative?.type_attributes?.first_name
                        })
                    }
                }

                await sendMessageToPubnub(message)
                // track message on backend
                const channelId = String(channelDetails?.channel_id)
                await sendMessageToChannel({channelId, message})

                mixpanel?.trackEvent(MixpanelEventNameE.UncoverContentSentHostSide, {
                    file_type: `${fileType}`,
                    guest_id: `${channelDetails?.id}`,
                    morgi_amount: (selectedAmount?.amount ?? amountWatch) || 0
                })
            }
        } else {
            if (onSendMessage && onCloseLocalModal) {
                const params = {
                    message: messageWatch,
                    user_id: user?.id,
                    meta: {
                        rookie: {full_name: user?.full_name},
                        message: messageWatch
                    },
                    morgi: (selectedAmount?.amount ?? amountWatch) || 0
                }

                onSendMessage(params)
            } else {
                const message = {
                    message: messageWatch ?? undefined,
                    type: MessageTypeE.PAID_MESSAGE,
                    user_id: userID,
                    meta: {
                        micromorgiAmount: (selectedAmount?.amount ?? amountWatch) || 0,
                        ...(user?.type === UserRoleE.Representative && {
                            representative_id: representative?.id,
                            representative_first_name: representative?.type_attributes?.first_name
                        })
                    }
                }
                const channelId = String(channelDetails?.channel_id)
                const payload = {
                    channelId,
                    message: message,
                    is_starred: false,
                    morgi: (selectedAmount?.amount ?? amountWatch) || 0
                }
                // track message on backend
                const data = await sendMessageToChannel(payload)
                // send message to pubnub
                const safeMessage = {...message}
                delete safeMessage.message
                if (!data.message) {
                    const messageToSend = {
                        ...safeMessage,
                        meta: {
                            ...safeMessage.meta,
                            paid_message_id: data?.paid_message_id
                        }
                    }
                    sendMessageToPubnub(messageToSend)
                }
            }

            mixpanel?.trackEvent(MixpanelEventNameE.UncoverContentSentHostSide, {
                file_type: 'paid_text_message',
                guest_id: `${channelDetails?.id}`,
                morgi_amount: (selectedAmount?.amount ?? amountWatch) || 0
            })
        }
        setIsLoading(false)
        closeModal()
    }

    const sendFile = ({file, options}: {file: File; options: AxiosRequestConfig}) => {
        const fileType = getFileType(file.type) ?? ''
        const formData = convertFileToFormData({key: 'file', file})
        const data = {data: formData, options, fileType}

        setFile(file)
        uploadMedia(data)
    }

    const onRemove = () => {
        setFile(null)
        setIsSuccessfulUpload(false)
    }

    const content = useMemo(() => {
        switch (type) {
            case TypePaidMessageE.AUDIO:
                return {
                    title: 'paid_media_modal:audio_title',
                    subtitle: 'paid_media_modal:audio_subtitle',
                    acceptFileType: {...ACCEPT_AUDIO_FILE_TYPES},
                    description: 'paid_media_modal:description'
                }
            case TypePaidMessageE.MEDIA:
                return {
                    title: 'paid_media_modal:title',
                    subtitle: 'paid_media_modal:upload_file',
                    acceptFileType: {...ACCEPT_VIDEO_FILE_TYPES, ...ACCEPT_IMAGE_FILE_TYPES},
                    description: 'paid_media_modal:description'
                }
            case TypePaidMessageE.TEXT:
                return {
                    title: 'chat:pay_for_message:modal_title',
                    subtitle: 'chat:pay_for_message:modal_description',
                    description: 'paid_media_modal:description'
                }
            case TypePaidMessageE.DOCUMENT:
            default:
                return {
                    title: 'paid_media_modal:title_document',
                    subtitle: 'paid_media_modal:upload_file',
                    acceptFileType: ACCEPT_DOCUMENT_FILE_TYPES,
                    description: 'paid_media_modal:description_document'
                }
        }
    }, [type])

    return (
        <Modal
            onClose={closeModal}
            title={t(content.title)}
            maxHeight="600px"
            height="90%"
            minHeight="460px"
            width="90vw"
            maxWidth="400px"
        >
            {isSendingMessage && <Spinner />}

            <StyledPaidMessageModalWrapper direction={'column'} align={'center'} gap={2} padding={2}>
                <StyledSubtitleModal>{t(content.subtitle)}</StyledSubtitleModal>

                {isFile ? (
                    <FileUploader
                        uploadAreaProps={{type}}
                        UploadArea={UploadAreaPaidMessage}
                        uploadFunction={sendFile}
                        accept={content.acceptFileType}
                        onRemove={onRemove}
                        dropzoneStyles={dropzoneStyles}
                        isError={isErrorUploadMedia}
                        isSuccessfulUpload={isSuccessfulUpload}
                        uploadedFile={file}
                        maxSize={type === TypePaidMessageE.AUDIO ? MAX_AUDIO_SIZE : MAX_FILE_SIZE}
                    />
                ) : (
                    <StyledTextareaWithBannedElementsTrap
                        placeholder={t('chat:pay_for_message:placeholder')}
                        onChange={value => setValue('message', value)}
                        ref={register('message').ref}
                        errorMessage={errorMessages}
                        inModal
                        getHaveBannedElements={setHaveBannedContent}
                    />
                )}

                <StyledSubtitleModal>{t('paid_media_modal:choose_amount')}</StyledSubtitleModal>

                <StyledAmountsWrapper>
                    {PAID_MEDIA_AMOUNTS.map(amount => (
                        <CardAmountToSelect
                            key={amount.id}
                            amount={amount}
                            selected={selectedAmount?.id === amount.id}
                            onClick={onAmountClick}
                        />
                    ))}
                </StyledAmountsWrapper>

                <StyledSubtitleModal>{t('pay_at_first_connection:other')}</StyledSubtitleModal>

                <InputText
                    type={'number'}
                    placeholder={t('pay_at_first_connection:choose')}
                    {...register('amount')}
                    minLength={1}
                    onClick={() => setSelectedAmount(null)}
                    errorMessage={`${t(errors['amount']?.message || '')}`}
                />

                {(selectedAmount || amountWatch) && isFile && (
                    <StyledParagraph>
                        {t(`${content.description}`, {
                            FULE_NAME: channelDetails?.full_name,
                            AMOUNT: selectedAmount?.amount ?? amountWatch,
                            FILE_TYPE: isFile ? MediaTypeE.IMAGE : TypePaidMessageE.TEXT
                        })}
                    </StyledParagraph>
                )}

                <Divider />

                <Button
                    disabled={isButtonDisabled || isSendingMessage || haveBannedContent?.isBanned || isErrorUploadMedia}
                    onClick={handleSendPaidMediaMessage}
                >
                    {t('paid_media_modal:button')}
                </Button>
            </StyledPaidMessageModalWrapper>
        </Modal>
    )
}
